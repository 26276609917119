export default {
  topicsIndex: {
    key: ['topics'],
    path: 'topics',
  },

  topicsShow: {
    key: (params: { id: string }) => ['topic', params.id],
    path: (params: { id: string }) => `topics/${params.id}`,
  },
}
