<template>
  <HeadlessMenu v-slot="{ open }" as="div" class="relative flex" @click.stop="() => {}">
    <slot name="menuButton" :open="open">
      <MenuButton
        :class="[
          'inline-flex w-full items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75',
          open
            ? 'bg-black/30 text-white'
            : 'bg-black/20 text-slate-600 hover:bg-black/30 hover:text-white',
        ]"
      >
        {{ buttonLabel }}
        <AtroIcon
          v-if="buttonIconName"
          class="ml-2 -mr-1 h-3 w-3"
          aria-hidden="true"
          :name="buttonIconName"
        />
      </MenuButton>
    </slot>

    <Transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems :class="['absolute z-10 focus:outline-none', alignOptions[align]]">
        <div class="overflow-hidden rounded-2xl bg-white shadow-menu">
          <slot name="menuItemsPrefix" />
          <slot name="menuItems">
            <MenuItem
              v-for="item in items"
              v-slot="{ active }"
              :key="item.label"
              as="div"
              class="border-b-2 text-atro-main last:border-b-0"
            >
              <button
                v-if="item.action"
                :class="['menu-item', active && 'bg-atro-gray-4']"
                @click="$emit('action', item.action)"
              >
                <AtroMenuItemContent :item="item" />
              </button>

              <RouterLink
                v-if="item.linkTo"
                :class="['menu-item', active && 'bg-atro-gray-4']"
                :to="item.linkTo"
              >
                <AtroMenuItemContent :item="item" />
              </RouterLink>
            </MenuItem>
          </slot>
          <slot name="menuItemsSuffix" />
        </div>
      </MenuItems>
    </Transition>
  </HeadlessMenu>
</template>

<script lang="ts">
export const alignOptions = {
  bottom: 'top-full left-1/2 -translate-x-1/2 mt-2 orgin-top',
  bottomLeft: 'top-full right-full mt-2 orgin-top-left',
  bottomRight: 'top-full left-full mt-2 orgin-top-right',
  left: 'right-full top-1/2 -translate-y-1/2 mr-2 origin-right',
  right: 'left-full top-1/2 -translate-y-1/2 ml-2 origin-left',
  top: 'bottom-full left-1/2 -translate-x-1/2 mb-2 orgin-bottom',
  topLeft: 'bottom-full right-full mb-2 orgin-bottom-left',
  topRight: 'bottom-full left-full mb-2 orgin-bottom-right',
}
</script>

<script setup lang="ts">
import { Menu as HeadlessMenu } from '@headlessui/vue'
import { IconName } from '@/assets/icons/atro-icon.model'
import { MenuItem as MenuItemType } from '@/types/menu'

export interface Props {
  align?: keyof typeof alignOptions
  buttonIconName?: IconName
  buttonLabel?: string
  items?: MenuItemType[]
}

const { align = 'bottom', buttonIconName = 'chevron_down', items = [] } = defineProps<Props>()

defineEmits<{
  (e: 'action', action: string): void
}>()
</script>

<style lang="postcss" scoped>
.menu-item {
  @apply inline-flex w-full px-6 py-4;
}
</style>
