<template>
  <AtroAsync
    empty-icon-name="empty_tab_happy"
    empty-heading="No assessments assigned to you"
    :config="fetchState"
  >
    <AtroContent v-auto-animate class="space-y-3" col>
      <AssessmentUserCard
        v-for="(assessment, i) in assessments"
        :key="assessment.id"
        :assessment="assessment"
      >
        <template v-if="isOnboarding && i === onboardingAssessmentIndex" #tooltip="{ target }">
          <AtroOnboardingTooltip
            placement="bottom-end"
            :heading="ONBOARDING_COPY.myAssessmentsInProgressTooltip.heading"
            :body="ONBOARDING_COPY.myAssessmentsInProgressTooltip.body"
            :target="target"
            :dependent-keys="['seenApp', 'seenMyAssessments']"
          />
        </template>
      </AssessmentUserCard>
    </AtroContent>
  </AtroAsync>
</template>

<script setup lang="ts">
import { ONBOARDING_COPY } from '@/lib/constants'
import useAssessments from '@/composables/data/useAssessments'
import AssessmentUserCard from '@/components/assessment/user/AssessmentUserCard.vue'
import useOnboarding from '@/composables/useOnboarding'

const {
  fetchState,
  data: assessments,
  meta: { onboardingAssessmentIndex },
} = $(useAssessments('user', 'in-progress'))
const { isOnboarding } = $(useOnboarding('startedFirstAssessment'))
</script>
