export default {
  // Assessments Team INDEX
  assessmentsTeam: {
    key: (params: { type: 'in-progress' | 'caught-up' }) => ['assessmentsTeam', params.type],
    path: (params: { type: 'in-progress' | 'caught-up' }) => `assessments/team?type=${params.type}`,
  },
  // Assessment Team SHOW
  assessmentTeam: {
    key: (params: { id: string }) => ['assessmentTeam', params.id],
    path: (params: { id: string }) => `assessments/team/${params.id}`,
  },
  // Assessment's Team Member Status INDEX
  assessmentTeamMembersStatus: {
    key: (params: { assessmentId: string }) => ['assessmentTeamMembersStatus', params.assessmentId],
    path: (params: { assessmentId: string }) =>
      `assessments/team/${params.assessmentId}/team-members-status`,
  },
  // Assessment Team's Security Fixes INDEX
  assessmentTeamSecurityFixes: {
    key: (params: { id: string }) => ['assessmentTeamSecurityFixes', params.id],
    path: (params: { id: string }) => `assessments/team/${params.id}/security-fixes`,
  },

  // Assessments Discovery INDEX
  assessmentsDiscover: {
    key: ['assessmentsDiscover'],
    path: 'assessments/discovery',
  },
  // Assessments Discovery SHOW
  assessmentDiscover: {
    key: (params: { id: string }) => ['assessmentDiscover', params.id],
    path: (params: { id: string }) => `assessments/discovery/${params.id}`,
  },

  // Assessments User INDEX
  assessmentsUser: {
    key: (params: { type: 'in-progress' | 'caught-up' }) => ['assessmentsUser', params.type],
    path: (params: { type: 'in-progress' | 'caught-up' }) => `assessments/user?type=${params.type}`,
  },
  // Assessments User SHOW
  assessmentUser: {
    key: (params: { id: string }) => ['assessmentUser', params.id],
    path: (params: { id: string }) => `assessments/user/${params.id}`,
  },
  // Assessment User's Security Fixes INDEX
  assessmentUserSecurityFixes: {
    key: (params: { id: string }) => ['assessmentUserSecurityFixes', params.id],
    path: (params: { id: string }) => `assessments/user/${params.id}/security-fixes`,
  },
}
