<template>
  <AtroContent col class="w-full max-w-3xl rounded-3xl bg-white p-8 sm:p-12" items="center">
    <AtroHeading class="text-center text-atro-slate-purple" size="large" semibold
      >Review and submit</AtroHeading
    >
    <AtroSpan class="text-center text-atro-gray-1">Answers are final once submitted</AtroSpan>

    <AtroContent
      class="mt-4 max-h-[492px] w-full space-y-2 overflow-y-auto border-b-2 border-t-2 py-2"
    >
      <StepFinishedReviewStepItem
        v-for="step in stepsWithAnswers"
        :key="step.id"
        :step="step"
        @click="emit('jumpToStep', step)"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { Topic } from '@/types/topic'
import { BaseStep, TopicStep } from '@/types/topicStep'

export interface StepFinished extends BaseStep {
  kind: 'finished'
  topic?: Topic
}

const { topic } = defineProps<StepFinished>()
const emit = defineEmits<{
  (e: 'jumpToStep', step: TopicStep): void
}>()

const stepsWithAnswers = $computed(() => topic.steps.filter(step => step.answers?.length))
</script>

<style scoped>
.list-item-icon {
  @apply mr-2 h-6 w-6 shrink-0 text-atro-bright-purple;
}
</style>
