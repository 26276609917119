<template>
  <AtroContent class="w-full flex-1" col>
    <AtroContent col class="mb-6 w-full flex-1" justify="center" items="center">
      <template v-if="!invitesSent">
        <AtroHeading class="text-atro-slate-purple" semibold>Invite your team</AtroHeading>
        <AtroParagraph class="mt-2 text-center text-atro-gray-1"
          >Add team members to Atro via email invite or sharing the link</AtroParagraph
        >
        <AtroButton
          class="mt-6"
          variant="light"
          icon-right="link_regular"
          block-when-mobile
          @click="copy()"
          >{{ shareUrlText }}</AtroButton
        >
      </template>

      <template v-else>
        <AtroIcon class="h-18 w-18" name="email_sent" />
        <AtroHeading class="mt-2 text-atro-slate-purple" semibold>Invite sent!</AtroHeading>
        <AtroSpan class="mt-2 text-atro-gray-1"
          >Invited members will receive an invite soon</AtroSpan
        >
        <AtroSpan class="mt-2 text-atro-gray-1" size="small"
          >*Existing Atro users will not be sent additional invitations</AtroSpan
        >
      </template>
    </AtroContent>

    <FormKit
      v-if="!invitesSent"
      id="inviteForm"
      v-model="formData"
      type="form"
      @submit="onSendInvite"
    >
      <AtroContent class="w-full space-y-4 rounded-3xl border-2 bg-atro-gray-4 p-6" col>
        <FormKit
          type="textarea"
          name="emails"
          label="Email"
          placeholder="Emails, comma separated"
          :classes="{
            outer: 'w-full',
          }"
          :validation="[
            ['required'],
            ['matches', /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,\s*)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/],
          ]"
        />
        <FormKit
          type="select"
          name="role"
          label="Permissions"
          placeholder="Select Permissions"
          validation="required"
          :classes="{
            outer: 'w-full',
          }"
          :options="ROLE_OPTIONS"
        />
      </AtroContent>

      <template #submit="{ state: { valid } }">
        <AtroButton class="mx-auto" :block="isMobile" :disabled="!valid" :is-pending="isMutating"
          >Send Invite</AtroButton
        >
      </template>
    </FormKit>

    <AtroContent v-else class="mt-4 w-full" col>
      <AtroButton
        icon-right="chevron_right"
        variant="transparent"
        block
        @click="invitesSent = false"
        >Invite More</AtroButton
      >
      <slot name="afterSendActions" />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { reset } from '@formkit/core'
import { ROLE_OPTIONS } from '@/lib/constants'
import { useClipboard } from '@vueuse/core'
import useIsMobile from '@/composables/useIsMobile'
import useOrgInviteUrl from '@/composables/useOrgInviteUrl'
import { useTeamInviteCreateMutation } from '@/composables/mutations/useTeamInviteMutation'

const emit = defineEmits<{
  (e: 'invitesSent'): void
}>()

const { createTeamInvite, isLoading: isMutating } = useTeamInviteCreateMutation()

const isMobile = $(useIsMobile())
const shareUrl = useOrgInviteUrl()
const source = $ref(shareUrl)
const { copy, copied } = $(useClipboard({ source }))

const formData = $ref({
  emails: '',
  role: '',
})
let invitesSent = $ref(false)

const shareUrlText = $computed(() => (copied ? 'Copied!' : 'Copy Invite Link'))

const onSendInvite = async (data: typeof formData) => {
  const { emails, role } = data

  // split on comma or space
  const emailArray = emails.split(/[ ,]+/)
  const newInvites = emailArray.reduce((invitesArray, email) => {
    invitesArray.push({ role, email: email.trim() })
    return invitesArray
  }, [])

  try {
    await createTeamInvite(newInvites)
    invitesSent = true
    reset('inviteForm')
    emit('invitesSent')
  } catch (e) {
    // TODO: handler error
  }
}
</script>
