<template>
  <AtroAsync :config="fetchState">
    <template #default>
      <div
        v-auto-animate
        class="w-full space-y-3 sm:grid sm:grid-cols-assessments sm:gap-3 sm:space-y-0"
      >
        <AssessmentAdminCard
          v-for="assessment in assessments"
          :key="assessment.id"
          :assessment="assessment"
          responsive
        />
      </div>
    </template>

    <template #empty>
      <AtroEmpty heading="No assessments caught up yet" icon-name="empty_tab">
        <template #postfix>
          <AtroButtonLink variant="light" :to="{ name: 'teamAssessments' }"
            >Browse Assessments</AtroButtonLink
          >
        </template>
      </AtroEmpty>
    </template>
  </AtroAsync>
</template>

<script setup lang="ts">
import useAssessments from '@/composables/data/useAssessments'
import AssessmentAdminCard from '@/components/assessment/admin/AssessmentAdminCard.vue'

const { fetchState, data: assessments } = useAssessments('team', 'caught-up')
</script>
