<template>
  <AtroPageHeading class="mb-2" heading="My Organization" />

  <div class="w-full space-y-10">
    <AtroContent class="space-x-2" items="center">
      <AtroSpan class="text-atro-gray-1">{{ user.org.name }}</AtroSpan>
      <AtroBadge label="Beta" />
    </AtroContent>

    <AtroContent v-if="editableOrg.notifications" class="w-full" col>
      <AtroSectionHeading heading="Email preferences" divider />

      <NotificationToggleList
        scope="org"
        :notifications="editableOrg.notifications"
        @change="onNotificationSettingChange"
      />
    </AtroContent>

    <AtroContent class="w-full" col>
      <AtroSectionHeading heading="Subscription" divider />

      <AtroContent class="space-y-6" col>
        <a href="https://billing.stripe.com/p/login/5kAaGD8wa7zRckE5kk" target="_blank">
          <AtroButton variant="light">Manage Subscription</AtroButton>
        </a>
      </AtroContent>
    </AtroContent>

    <AtroContent class="w-full" col>
      <AtroSectionHeading heading="Options" divider />

      <FormKit v-model="editableOrg" variant="inline" type="form" @submit="onSubmit">
        <FormKit
          name="name"
          type="text"
          label="Organization name"
          placeholder="Organization name"
          validation="required|length:2,50"
        />
        <template #submit="{ state: { valid } }">
          <AtroButton
            variant="light"
            block-when-mobile
            :is-pending="isPending"
            :disabled="!valid || !hasChanges"
            >Save Changes</AtroButton
          >
        </template>
      </FormKit>
    </AtroContent>

    <AtroButton
      class="mt-14"
      icon-right="chevron_right"
      variant="transparent"
      @click="isDeleteModalOpen = true"
      >Delete My Organization</AtroButton
    >
  </div>

  <DeleteModal
    button-text="Delete My Account"
    :is-open="isDeleteModalOpen"
    :sub-text="{
      confirmed: 'Your account has been permanently deleted.',
      initial:
        'This action will delete your entire organization\'s account, including all completed assessments and scoring. This action cannot be undone.',
    }"
    :on-delete="deleteOrg"
    @close="isDeleteModalOpen = false"
  />
</template>

<script setup lang="ts">
import useCurrentUser from '@/composables/data/useCurrentUser'
import useFormSubmit from '@/composables/useFormSubmit'
import { useOrgDestroyMutation, useOrgUpdateMutation } from '@/composables/mutations/useOrgMutation'
import DeleteModal from '@/components/DeleteModal.vue'
import NotificationToggleList from '@/components/NotificationToggleList.vue'

const { data: user } = $(useCurrentUser())
const { updateOrg } = useOrgUpdateMutation()
const { destroyOrg } = useOrgDestroyMutation()

let editableOrg = $ref({ ...user.org })
let isDeleteModalOpen = $ref(false)

const hasChanges = $computed(() => JSON.stringify(editableOrg) !== JSON.stringify(user.org))

const deleteOrg = async () => {
  await destroyOrg()
  return true
  // TODO: Log the user out
}

const { isPending, onSubmit } = useFormSubmit<Org>(async changedOrg => {
  await updateOrg(changedOrg)
  editableOrg = changedOrg
})

const onNotificationSettingChange = (updatedNotificationSetting: Notifications) => {
  editableOrg.notifications = { ...editableOrg.notifications, ...updatedNotificationSetting }
}
</script>
