<template>
  <AtroContent col items="center">
    <AtroHeading centered class="mb-12" size="larger">
      <AtroMarkdown :markdown="config.text" />
    </AtroHeading>

    <AtroChoiceGroup
      :selected-choice-id="selectedChoiceId"
      :choices="options"
      @change="choiceSelected"
    />
  </AtroContent>
</template>

<script lang="ts">
interface StepChoiceGroupOptions {
  id: string
  createdAt: string
  updatedAt: string
  exclusive: boolean
  label: string
  position: number
  score: Score
  icon?: IconName
}
</script>

<script setup lang="ts">
import { BaseStep, BaseStepConfig } from '@/types/topicStep'
import { IconName } from '@/assets/icons/atro-icon.model'

export interface StepChoiceGroup extends BaseStep {
  kind: 'choice'
  config: BaseStepConfig
  options: StepChoiceGroupOptions[]
  answers?: Answer[]
}

const { options = [], answers = [], config } = defineProps<StepChoiceGroup>()

const emit = defineEmits<{
  (e: 'addAnswer', answers: Answer[]): void
}>()

let answer

if (answers.length) answer = answers[0].optionId

let selectedChoiceId = $ref(answer)

const choiceSelected = (selection: string) => {
  selectedChoiceId = selection
  const answers = [{ optionId: selection }]

  setTimeout(() => emit('addAnswer', answers), 300)
}
</script>
