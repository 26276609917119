import { MaybeRef } from '@/types/utils'
import { unref } from 'vue'
import { useBaseMutation } from './useBaseMutation'

export function useTeamMemberUpdateMutation(user: MaybeRef<User>) {
  const url = $computed(() => `org/team/${unref(user)?.id}`)
  const mutation = useBaseMutation({
    invalidateKeys: [['team']],
    method: 'patch',
    url: $$(url),
  })

  const updateTeamMember = async (user: User) => {
    return mutation.mutateAsync({ user })
  }

  return { updateTeamMember, ...mutation }
}

export function useTeamMemberDestroyMutation(user: MaybeRef<User>) {
  const url = $computed(() => `org/team/${unref(user)?.id}`)

  const mutation = useBaseMutation({
    invalidateKeys: [['team']],
    method: 'delete',
    url: $$(url),
  })

  const destroyTeamMember = async () => {
    return mutation.mutateAsync(null)
  }

  return { destroyTeamMember, ...mutation }
}
