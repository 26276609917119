import classNames from 'classnames'

const styles = /*tw*/ {
  // Responsible for a container at the bottom of the form with form actions like the submit button
  actions: {
    base: '!pt-2 sm:!pt-6 !mt-auto col-span-full',
    variants: {
      inline: 'sm:!pt-0',
    },
  },

  // Responsible for rendering the form tag and listening to submit events
  form: {
    base: 'w-full flex flex-1 flex-col min-w-0',
    variants: {
      default: 'flex flex-1 flex-col space-y-4 min-w-0',
      inline: 'sm:flex sm:flex-row sm:space-x-2',
      'grid-2':
        'flex flex-1 flex-col space-y-6 min-w-0 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6',
    },
  },

  // The element containing help text
  help: {
    base: 'text-base text-atro-gray-1',
    types: {
      switch: 'ml-4',
    },
  },

  // A wrapper around the actual input element
  inner: {
    base: '',
    types: {
      checkbox: 'inline-flex',
      switch: '',
    },
  },

  // The input element itself
  input: {
    base: 'block text-atro-dark-purple transition-colors focus:ring-atro-purple disabled:text-atro-gray-1 disabled:cursor-default',
    types: {
      email: {},
      password: {},
      search: {},
      textarea: {},
      checkbox:
        'w-6 h-6 border-2 border-atro-purple rounded-lg cursor-pointer bg-white hover:border-atro-purple-75 checked:bg-atro-highlight-green disabled:checked:bg-atro-highlight-green checked:border-atro-highlight-green hover:enabled:checked:bg-atro-highlight-green-50 hover:enabled:checked:border-atro-highlight-green disabled:opacity-25',
      radio:
        'mr-2 h-6 w-6 border-2 border-atro-purple hover:border-atro-purple hover:ring-2 hover:ring-inset hover:ring-white hover:bg-atro-purple-50 checked:bg-none checked:ring-2 checked:ring-inset checked:ring-white checked:bg-atro-purple checked:focus:bg-atro-purple checked:hover:bg-atro-purple-50 checked:hover:border-atro-purple',
      select: {
        base: 'w-full py-2 px-4 pr-8 rounded-xl cursor-pointer focus:ring-0',
        sizes: {
          xsmall: 'text-xs py-0.5',
          small: 'text-sm',
          normal: 'text-base',
        },
        variants: {
          default: 'border-2 border-atro-gray-2 focus:border-indigo-200',
          blue: 'border-2 rounded-lg font-semibold border-atro-purple-75 focus:ring-0 focus:bg-atro-purple focus:border-atro-purple focus:text-white text-atro-purple',
          transparent: 'bg-transparent border-none text-atro-purple text-right',
        },
      },
      submit: {
        base: 'btn primary w-full',
      },
      text: {
        base: 'w-full sm:min-w-[299px] py-2.5 px-4 rounded-xl placeholder:text-atro-gray-1 focus:ring-0',
        sizes: {
          xsmall: 'text-sx',
          small: 'text-sm',
          normal: 'text-base',
        },
        variants: {
          default: 'border-2 border-atro-gray-2 focus:border-indigo-200',
          blank: 'border-0',
        },
      },
    },
  },

  // The label of the input
  label: {
    base: 'inline-flex text-atro-dark-purple text-sm',
    types: {
      checkbox: 'ml-2 cursor-pointer',
      radio: 'cursor-pointer',
      search: 'mb-2 font-semibold',
      select: 'mb-2 block font-semibold',
      switch: '',
      text: 'mb-2 block font-semibold',
      textarea: 'mb-2 block font-semibold',
    },
  },

  legend: {
    base: 'block mb-1 font-bold',
  },

  // A wrapper around all the messages
  messages: {
    base: 'mt-2 ml-2 space-y-1',
  },

  message: {
    base: 'text-xs text-atro-magenta',
  },

  // The outermost wrapping element
  outer: {
    base: 'formkit-outer shrink-0',
    types: {
      switch: 'flex flex-row',
    },
  },

  options: {
    types: {
      checkbox: 'flex flex-col flex-grow space-y-2',
      radio: 'flex flex-col flex-grow space-y-2',
    },
  },

  // Has output by default, but allows content directly before an input element
  prefix: {
    base: '',
  },

  // Has output by default, but allows content directly after an input element.
  suffix: {
    base: '',
  },

  // A wrapper around the label and input
  wrapper: {
    types: {
      checkbox: 'flex flex-row flex-grow items-center',
      radio: 'flex flex-row flex-grow items-center',
      switch: 'flex flex-row flex-grow items-start',
    },
  },
}

// email, password, and search type has same styles as text
styles.input.types.email = styles.input.types.text
styles.input.types.password = styles.input.types.text
styles.input.types.search = styles.input.types.text
styles.input.types.textarea = styles.input.types.text

// helper function to created class object from strings
function createClassObject(classString) {
  const classList = {}
  if (classString) {
    classString.split(' ').forEach(className => {
      classList[className] = true
    })
  }
  return classList
}

function rootClasses(sectionKey, node) {
  const type = node.props.type
  const attrs = node.props.attrs
  const validation = node.props.validation
  const size = attrs?.size
  const variant = attrs?.variant

  const styleObj = styles[sectionKey]
  const typeObj = styleObj?.types?.[type]
  const typeSizeObj = typeObj?.sizes

  const hasRequiredValidation = validation?.includes('required')

  const baseStyle = styleObj?.base
  const topLevelVariantStyle = styleObj?.variants?.[variant || 'default']
  // supports both raw string value or nested styles
  const typeBaseStyle = typeof typeObj === 'string' ? typeObj : typeObj?.base
  // supports a defined size prop or defaults to normal size if exists
  const typeSizeStyle = size ? typeSizeObj?.[size] : typeSizeObj?.normal
  // supports a defined variant or defaults to the default variant if exists
  const typeVariantStyle = variant ? typeObj?.variants?.[variant] : typeObj?.variants?.default

  const classStr = classNames(
    baseStyle,
    topLevelVariantStyle,
    typeBaseStyle,
    typeSizeStyle,
    typeVariantStyle,
    hasRequiredValidation && sectionKey === 'label' && 'field-is-required'
  )

  return createClassObject(classStr)
}

export default function (node) {
  node.config.rootClasses = rootClasses
}
