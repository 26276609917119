import { unref } from 'vue'
import { Integration } from '@/types/integration'
import { MaybeRef } from '@/types/utils'

const INACTIVE_CHECK_STATUSES = ['disabled', 'pending']

export default function useOrgIntegrationsMeta(_integrations: MaybeRef<Integration[]>) {
  const integrations = $computed(() => (unref(_integrations) as Integration[]) || [])
  const allMonitoringChecks = $computed(() =>
    integrations
      .map(integration => integration.monitors)
      .flat()
      .filter(Boolean)
  )
  const nonDissmissedMonitoringChecks = $computed(() =>
    allMonitoringChecks.filter(check => !check?.dismissed)
  )
  const totalChecksCount = $computed(() => allMonitoringChecks.length)
  const totalActiveChecksCount = $computed(
    () =>
      allMonitoringChecks.filter(check => !INACTIVE_CHECK_STATUSES.includes(check?.status)).length
  )
  const totalCriticalAlertsCount = $computed(
    () => nonDissmissedMonitoringChecks.filter(check => check?.status === 'critical').length
  )
  const totalWarningAlertsCount = $computed(
    () => nonDissmissedMonitoringChecks.filter(check => check?.status === 'warning').length
  )
  const totalInfoAlertsCount = $computed(
    () => nonDissmissedMonitoringChecks.filter(check => check?.status === 'info').length
  )
  const totalOkChecksCount = $computed(
    () => allMonitoringChecks.filter(check => check?.status === 'ok' || check?.dismissed).length
  )
  const totalAlertsCount = $computed(
    () => totalCriticalAlertsCount + totalWarningAlertsCount + totalInfoAlertsCount
  )

  return $$({
    totalActiveChecksCount,
    totalAlertsCount,
    totalChecksCount,
    totalCriticalAlertsCount,
    totalInfoAlertsCount,
    totalOkChecksCount,
    totalWarningAlertsCount,
  })
}
