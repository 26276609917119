import { ComputedRef, unref } from 'vue'
import { AxiosError, AxiosResponse } from 'axios'
import { QueryClient, useMutation, useQueryClient } from '@tanstack/vue-query'
import axios from '@/utils/axios'

type InvalidateKeys = ['*'] | any[][]

type Props = {
  method: 'delete' | 'put' | 'patch' | 'post'
  url: ComputedRef<string> | string
  invalidateKeys?: InvalidateKeys
  removeKeys?: ['*'] | any[][]
}

const invalidateKeys = (queryClient: QueryClient, keys: InvalidateKeys) => {
  keys.forEach(key => {
    if (key === '*') {
      queryClient.invalidateQueries()
    } else {
      queryClient.invalidateQueries(key)
    }
  })
}

const removeKeys = (queryClient: QueryClient, keys: any[] | any[][]) => {
  keys.forEach(key => {
    queryClient.removeQueries(key)
  })
}

export function useBaseMutation<Params = any, Data = null>(config: Props, options?: any) {
  const queryClient = useQueryClient()

  const mutationProps = useMutation<AxiosResponse<Data>, AxiosError, Params>(data => {
    return axios[config.method](unref(config.url), data)
  }, options)
  const internalMutate = mutationProps.mutate
  const internalMutateAsync = mutationProps.mutateAsync
  delete mutationProps.mutate
  delete mutationProps.mutateAsync

  const mutate = async (data?: any, options?: any) => {
    internalMutate(data, {
      onSuccess: () => {
        config.invalidateKeys && invalidateKeys(queryClient, config.invalidateKeys)
        config.removeKeys && removeKeys(queryClient, config.removeKeys)
      },
    })
  }

  const mutateAsync = async (data?: any, options?: any) => {
    const response = await internalMutateAsync(data, options)
    config.invalidateKeys && invalidateKeys(queryClient, config.invalidateKeys)
    config.removeKeys && removeKeys(queryClient, config.removeKeys)
    return response
  }

  mutationProps.mutate = mutate
  mutationProps.mutateAsync = mutateAsync

  return mutationProps
}
