import { MaybeRef } from '@/types/utils'
import { unref } from 'vue'

const NULL_ASSESSMENTS_ASSIGNED_TEXT = 'No assessments'

export default function useUserMeta(
  _user: MaybeRef<User>,
  progressType: ProgressType = 'assessments'
) {
  const user = $computed(() => unref(_user))
  const userProgress = $computed(() => unref(user?.progress))
  const hasAssessmentsAssigned = $computed(() => !!userProgress?.denominator)

  const isAdmin = $computed(() => user?.role === 'admin')
  const isMember = $computed(() => user?.role === 'member')

  const progressTypeText = $computed(() => progressType.replace(/^\w/, c => c.toUpperCase()))

  const progressText = $computed(() =>
    hasAssessmentsAssigned
      ? `${userProgress?.numerator}/${userProgress?.denominator} ${progressTypeText}`
      : NULL_ASSESSMENTS_ASSIGNED_TEXT
  )

  const progressTextExtended = $computed(() =>
    hasAssessmentsAssigned
      ? `${userProgress?.numerator} of ${userProgress?.denominator} ${progressTypeText} completed`
      : NULL_ASSESSMENTS_ASSIGNED_TEXT
  )

  return $$({ hasAssessmentsAssigned, isAdmin, isMember, progressText, progressTextExtended })
}
