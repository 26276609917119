<template>
  <AtroPageHeading
    class="mb-0 sm:mb-6"
    heading="Dashboard"
    :sub-heading="`Welcome ${user.display}!`"
  />

  <AtroDivider v-if="isMobile" />

  <MobileScoreInline
    v-if="isMobile"
    heading="Atro Score"
    sub-heading="Overall security level"
    :score="score"
  />

  <div class="w-full space-y-14">
    <DashboardSectionMonitoring />
    <DashboardSectionAssessments />
    <DashboardSectionTeam rollup />
  </div>

  <AtroOnboardingModal
    icon-name="nav_item_dashboard"
    heading="Atro Dashboard"
    body="An overview of your organization's security status and actions in Atro"
    onboarding-key="seenDashboard"
  />
</template>

<script setup lang="ts">
import useCurrentUser from '@/composables/data/useCurrentUser'
import useIsMobile from '@/composables/useIsMobile'
import { useOrgScore } from '@/composables/data/useOrgScore'
import DashboardSectionAssessments from '@/components/dashboard/DashboardSectionAssessments.vue'
import DashboardSectionMonitoring from '@/components/dashboard/DashboardSectionMonitoring.vue'
import DashboardSectionTeam from '@/components/dashboard/DashboardSectionTeam.vue'
import MobileScoreInline from '@/components/mobile/MobileScoreInline.vue'

const { data: user } = $(useCurrentUser())
const isMobile = $(useIsMobile())
const { data: score } = $(useOrgScore())
</script>
