<template>
  <AtroTooltip
    v-if="show"
    v-slot="{ close }"
    ref="tooltip"
    class="text-left delay-500"
    variant="onboarding"
    open-on-mount
    :tooltip-target-fn="() => target"
    :config="onboardingTooltipConfig"
  >
    <AtroHeading class="mb-2 text-white" semibold>
      <AtroMarkdown :markdown="heading" />
    </AtroHeading>
    <AtroParagraph class="mb-6 text-white/75">
      <AtroMarkdown :markdown="body" />
    </AtroParagraph>
    <AtroButton v-if="ctaText" variant="dark" @click.prevent="onClose(close)">{{
      ctaText
    }}</AtroButton>
  </AtroTooltip>
</template>

<script setup lang="ts">
import { Ref } from 'vue'
import { ReactiveVariable } from 'vue/macros'
import { Placement, PositioningStrategy } from '@popperjs/core'
import useIsMobile from '@/composables/useIsMobile'
import useOnboarding from '@/composables/useOnboarding'
import { UsePopperOptions } from '@/composables/usePopper'

export interface Props {
  body: string
  heading: string
  target: Ref<Element> | ReactiveVariable<Element>
  ctaText?: string
  dependentKeys?: (keyof OnboardingState)[]
  offsetDistance?: number
  offsetSkid?: number
  placement?: Placement
  strategy?: PositioningStrategy
}

const {
  body,
  ctaText,
  heading,
  target,
  dependentKeys = ['seenApp'],
  offsetDistance = 16,
  offsetSkid = 0,
  placement = 'right-start',
  strategy = 'absolute',
} = defineProps<Props>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const isMobile = $(useIsMobile())
const { onboardingKeys } = $(useOnboarding())

const passesDependentKeys = $computed(() =>
  dependentKeys?.every(key => onboardingKeys?.[key] === true)
)

const show = $computed(() => passesDependentKeys && target)

const onboardingTooltipConfig = $computed<UsePopperOptions>(() => {
  return {
    strategy,
    placement: isMobile ? 'bottom' : placement,
    trigger: 'manual',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [offsetSkid, offsetDistance],
        },
      },
      {
        name: 'arrow',
        options: {
          padding: 24,
        },
      },
      {
        name: 'flip',
        enabled: !isMobile,
      },
    ],
  }
})

const onClose = (close: VoidFunction) => {
  close()
  emit('close')
}
</script>
