<template>
  <RouterLink class="flex w-full" :to="assessmentLinkTo">
    <AtroCard class="w-full" hoverable>
      <AtroContent :col="isMobile">
        <AtroContent class="w-full sm:w-auto" justify="between">
          <AtroIconSquare size="large" :icon-name="assessment.icon" />
          <AtroCircleGauge
            v-if="isCaughtUp && isMobile"
            :fill-percent="assessment.score.percent"
            :count="assessment.score.atroScore"
          />
        </AtroContent>

        <AtroContent
          class="mt-2 w-full flex-1 sm:mt-0 sm:pl-6"
          justify="between"
          wrap="nowrap"
          :col="isMobile"
        >
          <AtroContent class="pr-4 sm:pr-6" col>
            <AtroHeading class="text-atro-dark-purple line-clamp-1 group-hover:text-atro-purple" size="small" semibold>{{
              assessment.title
            }}</AtroHeading>
            <AtroParagraph class="mt-2 text-atro-gray-1 line-clamp-2 sm:mt-0" size="small">{{
              assessment.description
            }}</AtroParagraph>
            <AssessmentUserCardState v-if="!isCaughtUp" class="mt-2" :assessment="assessment" />

            <AtroProgress
              v-if="isStarted"
              class="mt-4 sm:max-w-[228px]"
              size="small"
              :progress="assessment.progress"
              show-postfix-percentage
            />
            <AssessmentUserCardState v-if="isCaughtUp" class="mt-3" :assessment="assessment" />
          </AtroContent>

          <AtroContent class="shrink-0 place-self-end" items="end" col>
            <AtroCircleGauge
              v-if="isCaughtUp && !isMobile"
              :fill-percent="assessment.score.percent"
              :count="assessment.score.atroScore"
            />

            <RouterLink ref="tooltipTarget" class="mt-4 sm:mt-6" :to="assessmentLinkTo">
              <AtroButton icon-right="chevron_right" variant="transparent">{{
                buttonText
              }}</AtroButton>
            </RouterLink>
          </AtroContent>
        </AtroContent>
      </AtroContent>
      <slot name="tooltip" :target="tooltipTarget" />
    </AtroCard>
  </RouterLink>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import useAssessmentMeta from '@/composables/meta/useAssessmentMeta'
import useIsMobile from '@/composables/useIsMobile'
import AssessmentUserCardState from '@/components/assessment/user/AssessmentUserCardState.vue'
import { Assessment } from '@/types/assessment'

export interface Props {
  assessment: Assessment
}

const { assessment } = defineProps<Props>()

const isMobile = useIsMobile()
const { isCaughtUp, isInProgress, isStarted } = $(useAssessmentMeta($$(assessment)))

const tooltipTarget = ref<Element>()

const buttonText = $computed(() => {
  switch (assessment.status) {
    case 'inProgress':
      return 'Continue'
    case 'caughtUp':
      return 'View Results'
    default:
      return 'Get Started'
  }
})

const assessmentLinkTo = $computed(() => ({
  name: 'assessmentUser',
  params: { id: assessment.id },
}))
</script>
