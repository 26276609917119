import { Assessment } from '@/types/assessment'
import { topicDevices, topicRemote, topicWelcome } from '@/data/topics'

const baseWelcomeAssessment = {
  id: '1',
  createdAt: '2022-01-05T19:50:06',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae consectetur metus.',
  duration: '5 Minutes',
  learnMore: 'Learn how Atro works and onboard your team',

  progress: {
    numerator: 2,
    denominator: 5,
  },
  score: {
    atroScore: 200,
    percent: 20,
    riskText: 'Critical',
  },
  securityFixCount: 1,
  title: 'Welcome to Atro',
  topics: [topicWelcome, topicDevices],
  topicsCount: 1,
}

const baseRemoteWorkAssessment = {
  id: '2',
  createdAt: '2022-01-10T19:50:06',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae consectetur metus.',
  duration: '10-30 Minutes',
  learnMore: 'Learn how Atro works and onboard your team',
  isOnboarding: true,
  progress: null,
  score: {
    atroScore: 0,
    percent: 0,
    riskText: 'Critical',
  },
  title: 'Remote Work Essentials',
  topics: [topicRemote],
  topicsCount: 1,
}

export const notStartedWelcomeAssessment: Assessment = {
  ...baseWelcomeAssessment,
}

export const notStartedRemoteWorkAssessment: Assessment = {
  ...baseRemoteWorkAssessment,
}

export const inProgressWelcomeAssessment: Assessment = {
  ...baseWelcomeAssessment,
  status: 'inProgress',
}

export const inProgressRemoteWorkAssessment: Assessment = {
  ...baseRemoteWorkAssessment,
  progress: {
    numerator: 2,
    denominator: 5,
  },
  status: 'inProgress',
}

export const caughtUpWelcomeAssessment: Assessment = {
  ...baseWelcomeAssessment,
  progress: {
    numerator: 1,
    denominator: 1,
  },
  status: 'caughtUp',
}

export const caughtUpRemoteWorkAssessment: Assessment = {
  ...baseRemoteWorkAssessment,
  progress: {
    numerator: 1,
    denominator: 1,
  },
  status: 'caughtUp',
}
