<template>
  <AtroGridTable :async="{ config: fetchState }" :columns="columnsConfig" :data="users">
    <template #default="{ columnStyle, items }">
      <TeamStatusTableRow
        v-for="user in items"
        :key="user.id"
        :col-style="columnStyle"
        :expandable="expandable"
        :progress-type="progressType"
        :user="user"
      />
    </template>
  </AtroGridTable>
</template>

<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'
import { UseDataReturnProps } from '@/composables/useData'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'
import TeamStatusTableRow from '@/components/team/TeamStatusTableRow.vue'

export interface Props {
  fetchState: UseDataReturnProps
  expandable?: boolean
  progressType?: ProgressType
}

const isMobile = $(useIsMobile())
const { fetchState, expandable = false } = defineProps<Props>()
const { data: users } = fetchState

const columnsConfig = $computed<ColumnConfig[]>(() => [
  {
    heading: {
      align: 'start',
      text: 'Team member progress',
    },
    sortBy: (item: User) => item.progress.numerator / Math.max(item.progress.denominator, 1),
    widthRatio: 6,
  },
  {
    heading: {
      text: isMobile ? 'Fixes' : 'Security fixes',
    },
    initialSortedBy: true,
    sortBy: (item: User) =>
      item.securityFixes?.filter(securityFix => securityFix.status !== 'fixed').length,
    minColumnWidth: 96,
    widthRatio: 2.66,
  },
  {
    heading: {
      text: 'Score',
    },
    fixedWidth: true,
    minColumnWidth: isMobile ? 54 : 72,
    sortBy: 'score.atroScore',
  },
  ...(expandable
    ? [
        {
          fixedWidth: true,
          minColumnWidth: isMobile ? 48 : 64,
        },
      ]
    : []),
])
</script>