<template>
  <FormKit
    v-model="status"
    type="select"
    size="xsmall"
    variant="blue"
    :classes="{
      input: 'pl-[28px]',
      inner: 'relative',
    }"
    :disabled="status === 'fixed'"
    :options="SECURITY_FIX_OPTIONS"
  >
    <template #prefix="context">
      <div
        :class="[
          'absolute left-4 top-1/2 h-1 w-1 -translate-y-1/2 rounded-full',
          (context.value === 'unaddressed' || !context.value) && 'bg-atro-purple/25',
          context.value === 'fixed' && 'bg-atro-sky-blue',
          ['cantFix', 'ignored'].includes(context.value) && 'bg-atro-magenta',
        ]"
      />
    </template>
  </FormKit>
</template>
<script setup lang="ts">
export interface Props {
  placeholder?: string
  status?: string
}

const { status = '', placeholder = 'Select Status' } = defineProps<Props>()

const SECURITY_FIX_OPTIONS = [
  { label: placeholder, value: '', attrs: { disabled: true } },
  { label: "Can't fix", value: 'cantFix' },
  { label: 'Ignore for now', value: 'ignored' },
  { label: 'Fixed', value: 'fixed' },
]
</script>
