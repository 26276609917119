import * as Headless from '@headlessui/vue'

const headlessui = {
  prefex: null,
  typePath: '@headlessui/vue',
  components: Headless,
  definition: false,
}

const layouts = {
  prefex: null,
  fixName: true,
  components: import.meta.globEager('/src/layouts/*.vue'),
}

const atros = {
  prefix: 'Atro',
  fixName: true,
  components: import.meta.globEager('/src/components/globals/atro/*.vue'),
}

const steps = {
  prefix: null,
  fixName: true,
  components: import.meta.globEager('/src/components/globals/topicSteps/*.vue'),
}

const processComponents = [headlessui, layouts, atros, steps]
const components = []
const types = []

processComponents.forEach(entry => {
  Object.entries(entry.components).forEach(([path, definition]) => {
    let componentName = null

    if (entry?.fixName) {
      componentName = path
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')

      if (entry.prefix) componentName = `${entry.prefix}${componentName}`
    } else {
      componentName = path
    }

    components.push({
      name: componentName,
      path: path,
      typePath: entry?.typePath,
      definition: definition?.default ? definition.default : definition,
    })
  })
})

export default {
  install: (app, options) => {
    components.forEach(component => {
      app.component(component.name, component.definition)
    })
  },
}
