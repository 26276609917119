import useData from '@/composables/useData'

export default function useTeamMember(id: string) {
  const fetchState = useData<User>('teamMember', { id })

  return {
    fetchState,
    data: fetchState.data,
    meta: {},
  }
}
