import cloneDeep from 'clone-deep'
import { useQueryClient } from '@tanstack/vue-query'
import { useBaseMutation } from './useBaseMutation'
import { Topic } from '@/types/topic'

export function useTopicCompletedMutation(topicId: string) {
  const mutation = useBaseMutation({
    method: 'post',
    url: `topics/${topicId}/complete`,
    invalidateKeys: ['*'],
  })

  const completeTopic = async (assessmentId: string) => {
    await mutation.mutateAsync({ assessmentId })
  }

  return { completeTopic, ...mutation }
}

export function useTopicCurrentStepMutation(topicId: string) {
  const queryClient = useQueryClient()

  const mutation = useBaseMutation<{ currentStep: string }>(
    {
      method: 'patch',
      url: `/topics/${topicId}/current-step`,
    },
    {
      // optimistically set current step id
      onMutate: data => {
        const key = ['topic', topicId]
        const topic = cloneDeep(queryClient.getQueryData<Topic>(key))
        topic.currentStep = data.currentStep
        queryClient.setQueryData(key, topic)
      },
    }
  )

  const updateCurrentStep = async (currentStepId: string) => {
    await mutation.mutateAsync({ currentStep: currentStepId })
  }

  return { updateCurrentStep, ...mutation }
}

export function useTopicStartedMutation(topicId: string) {
  const mutation = useBaseMutation({
    method: 'patch',
    url: `/topics/${topicId}/start`,
    invalidateKeys: [['topic', topicId], ['assessmentsUser']],
  })

  const startTopic = async () => {
    await mutation.mutateAsync(null)
  }

  return { startTopic, ...mutation }
}
