<template>
  <RouterLink :class="[(block || (blockWhenMobile && isMobile)) && 'w-full']" :to="to">
    <AtroButton
      :block="block"
      :block-when-mobile="blockWhenMobile"
      :icon-left="iconLeft"
      :icon-right="iconRight"
      :variant="variant"
      ><slot
    /></AtroButton>
  </RouterLink>
</template>

<script setup lang="ts">
import { RouteLocationRaw } from 'vue-router'
import useIsMobile from '@/composables/useIsMobile'
import { Props as ButtonProps } from '@/components/globals/atro/Button.vue'
import { IconName } from '@/assets/icons/atro-icon.model'

export interface Props {
  to: RouteLocationRaw
  block?: boolean
  blockWhenMobile?: boolean
  iconLeft?: IconName
  iconRight?: IconName
  variant?: ButtonProps['variant']
}

const {
  iconLeft,
  block = false,
  blockWhenMobile = false,
  iconRight = 'chevron_right',
  variant = 'transparent',
} = defineProps<Props>()

const isMobile = $(useIsMobile())
</script>
