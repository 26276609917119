import { unref } from 'vue'
import { differenceInSeconds, formatDistanceToNowStrict } from 'date-fns'
import { Integration } from '@/types/integration'
import { MaybeRef } from '@/types/utils'
import { IconName } from '@/assets/icons/atro-icon.model'

const STATUS_MAP = {
  critical: 1,
  warning: 2,
  info: 3,
  ok: 4,
  pending: 5,
  disabled: 6,
}

const CHECK_ALERT_STATUS = ['critical', 'warning', 'info']

export default function useIntegrationMeta(_integration: MaybeRef<Integration>) {
  const integration = $computed(() => unref(_integration))
  const monitoringChecks = $computed(() => unref(integration.monitors))
  const isConnected = $computed(() => !!integration.status)
  const isActive = $computed(() => integration.status === 'active')
  const isDisconnected = $computed(() => integration.status === 'disconnected')
  const isErrored = $computed(() => integration.status === 'error')
  const isPending = $computed(() => integration.status === 'pending')

  const allMonitoringChecksPass = $computed(() =>
    monitoringChecks.every(
      monitoringCheck =>
        monitoringCheck.dismissed || !CHECK_ALERT_STATUS.includes(monitoringCheck.status)
    )
  )

  const recentlyConnected = $computed(() => {
    return differenceInSeconds(new Date(), new Date(integration.createdAt as string)) <= 60
  })

  const lastUpdatedAt = $computed(() => {
    const lastUpdatedDate = monitoringChecks.find(monitor => !!monitor.dataUpdatedAt)?.dataUpdatedAt
    return lastUpdatedDate ? formatDistanceToNowStrict(new Date(lastUpdatedDate as string)) : null
  })

  const lastRequestedAt = $computed(() => {
    return integration.updatedAt
      ? formatDistanceToNowStrict(new Date(integration.updatedAt as string))
      : null
  })

  const sortedChecks = $computed(() => {
    return [...monitoringChecks].sort((a, b) => STATUS_MAP[a.status] - STATUS_MAP[b.status])
  })

  const statusIconName = $computed<IconName>(() => {
    switch (integration.status) {
      case 'disconnected':
        return 'connect'
      case 'pending':
        return 'spinner'
      case 'error':
        return 'circle_with_cross'
      default:
        return 'integration'
    }
  })

  const statusIconColorClass = $computed(() => {
    switch (integration.status) {
      case 'active':
        return 'text-atro-highlight-green'
      case 'pending':
        return 'text-atro-gray-1'
      case 'error':
        return 'text-atro-magenta'
      default:
        return 'text-atro-gray-5'
    }
  })

  const statusText = $computed(() => {
    switch (integration.status) {
      case 'active':
        return 'Active'
      case 'disconnected':
        return 'Disconnnected'
      case 'pending':
        return 'Loading'
      case 'error':
        return 'Error'
      default:
        return 'Inactive'
    }
  })

  return $$({
    allMonitoringChecksPass,
    isActive,
    isConnected,
    isDisconnected,
    isErrored,
    isPending,
    lastRequestedAt,
    lastUpdatedAt,
    recentlyConnected,
    sortedChecks,
    statusIconName,
    statusIconColorClass,
    statusText,
  })
}
