<template>
  <AtroModal size="small">
    <AtroContent class="mb-6 text-center" items="center" col>
      <AtroHeading class="text-atro-slate-purple" semibold>Change Password</AtroHeading>
    </AtroContent>

    <FormKit v-model="formData" type="form" @submit="updateUserPassword">
      <FormKit name="password" type="password" label="New password" validation="required" />
      <FormKit
        name="password_confirm"
        type="password"
        label="Confirm new password"
        validation="required|confirm"
      />

      <template #submit="{ state: { valid } }">
        <AtroButton block :is-pending="isPending" :disabled="!valid">Update Password</AtroButton>
      </template>
    </FormKit>
  </AtroModal>
</template>
<script setup lang="ts">
import { useUserPasswordUpdateMutation } from '@/composables/mutations/useUserMutation'
import { Props as ModalProps } from '@/components/globals/atro/Modal.vue'

interface Props extends ModalProps {}

defineProps<Props>()

const { isLoading: isPending, updateUserPassword } = useUserPasswordUpdateMutation()

const formData = $ref({
  password: '',
  password_confirm: '',
})
</script>
