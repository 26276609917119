<template>
  <Transition mode="out-in" name="fade">
    <div :key="(route.params.id as string)" class="flex h-full w-full flex-1">
      <TopicTaker />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import TopicTaker from '@/components/topicTaker/TopicTaker.vue'

const route = useRoute()
</script>
