<template>
  <div :class="['relative flex flex-shrink-0', responsive && 'responsive', size]">
    <svg :viewBox="viewBox">
      <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stop-color="#51B2F0" />
        <stop offset="25%" stop-color="#4F6BE9" />
        <stop offset="75%" stop-color="#9541F3" />
        <stop offset="100%" stop-color="#DF529C" />
      </linearGradient>

      <g>
        <path
          class="gauge-transform rotate-[75deg]"
          fill="none"
          pathLength="100"
          stroke="#F2F5FF"
          stroke-dasharray="100"
          stroke-dashoffset="41.5"
          stroke-linecap="round"
          :stroke-width="size === 'normal' ? 7 : 9"
          d="M50 83C41.2479 83 32.8542 79.5232 26.6655
        73.3345C20.4768 67.1458 17 58.7521 17 50C17 41.2479 20.4768 32.8542 26.6655 26.6655C32.8542
        20.4768 41.2479 17 50 17C58.7521 17 67.1458 20.4768 73.3345 26.6655C79.5232 32.8542 83
        41.2479 83 50C83 58.7521 79.5232 67.1458 73.3345 73.3345C67.1458 79.5232 58.7521 83 50 83"
        />
        <path
          v-if="primaryPercent"
          id="circle"
          ref="gaugeAnimationTarget"
          class="gauge-transform"
          fill="none"
          pathLength="100"
          stroke="url(#gradient)"
          stroke-dasharray="100"
          stroke-linecap="round"
          :stroke-width="size === 'normal' ? 7 : 9"
          d="M50 83C41.2479 83 32.8542 79.5232 26.6655 73.3345C20.4768 67.1458 17 58.7521 17 50C17
        41.2479 20.4768 32.8542 26.6655 26.6655C32.8542 20.4768 41.2479 17 50 17C58.7521 17 67.1458
        20.4768 73.3345 26.6655C79.5232 32.8542 83 41.2479 83 50C83 58.7521 79.5232 67.1458 73.3345
        73.3345C67.1458 79.5232 58.7521 83 50 83"
        />
      </g>
    </svg>

    <div class="divider-pill divider-pill-1" />
    <div class="divider-pill divider-pill-2" />
    <div class="divider-pill divider-pill-3" />
    <div class="divider-pill divider-pill-4" />

    <AtroContent
      :class="[
        'text-wrap absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/4 font-bold text-black',
        size,
      ]"
      col
      items="center"
    >
      <AtroContent v-if="!countDefined" class="empty-wrap" items="center" col>
        <div class="empty-large-bubble" />
        <div class="empty-small-bubble" />
      </AtroContent>
      <AtroCounter
        v-else
        :key="count"
        class="count"
        :delay="ANIMATION_DELAY"
        :duration="countAnimationDuration"
        :from="oldCount"
        :to="(count as number)"
      />
      <span v-if="showSubText" class="sub-text">{{ subText }}</span>
    </AtroContent>
  </div>
</template>

<script setup lang="ts">
import { useMotion } from '@vueuse/motion'
import { onMounted, onUpdated, ref, watch } from 'vue'

export interface Props {
  count?: string | number
  fillPercent?: number
  responsive?: boolean
  subText?: string
  size?: 'normal' | 'small'
}

const ANIMATION_DELAY = 300
const ANIMATION_DURATION = 750

const props = defineProps<Props>()
const { count, fillPercent, responsive = true, size = 'normal', subText } = $(props)

const transition = {
  delay: ANIMATION_DELAY,
  duration: ANIMATION_DURATION,
  ease: 'easeOut',
}

const countDefined = $computed(() => typeof count === 'number')
const adjustedFillPercent = $computed(() => (fillPercent === 0 ? 1 : fillPercent))
const viewBox = $computed(() => (size === 'normal' ? '13 14 74 47' : '12 12 76 52'))
const primaryPercent = $computed(() =>
  adjustedFillPercent === undefined ? 0 : adjustedFillPercent / 100
)
const primaryOffset = $computed(() => -58.5 * primaryPercent + 100)
const showSubText = $computed(() => subText && size !== 'small' && countDefined)

const gaugeAnimationTarget = ref<HTMLElement>()
const { apply } = useMotion(gaugeAnimationTarget, {
  initial: {
    rotate: 75,
    strokeDashoffset: 100,
  },
  enter: {
    transition,
    strokeDashoffset: primaryOffset,
  },
  animate: {
    transition,
    strokeDashoffset: primaryOffset,
  },
})

watch(props, () => {
  apply({
    transition,
    strokeDashoffset: primaryOffset,
  })
})

let countAnimationDuration = $ref(0)
let oldCount = $ref(0)

onMounted(() => {
  countAnimationDuration = ANIMATION_DURATION
  oldCount = count as number
})

onUpdated(() => {
  oldCount = count as number
})
</script>

<style lang="postcss" scoped>
.gauge-transform {
  transform-origin: 50px 50px;
}

.count {
  @apply font-semibold text-atro-slate-purple;
}

.sub-text {
  @apply text-lg font-semibold text-atro-gray-1;
}

.empty-large-bubble {
  @apply rounded-xl bg-atro-gray-3;
}
.empty-small-bubble {
  @apply rounded-md bg-atro-gray-3;
}

.divider-pill {
  @apply absolute z-1 bg-atro-gray-5/50;
}

/* sizes */
.normal {
  @apply h-[132px] w-[192px];
}
.normal .count {
  @apply text-5xl;
}

.normal .empty-wrap {
  @apply mt-4;
}

.normal .empty-large-bubble {
  @apply h-6 w-16 rounded-xl;
}

.normal .empty-small-bubble {
  @apply mt-4 h-2 w-10;
}

.normal .text-wrap {
  @apply mt-[20px];
}

.normal .divider-pill {
  @apply h-2 w-1 rounded-lg;
}

.normal .divider-pill-1 {
  @apply top-[55px] left-[17px] rotate-[-64deg];
}

.normal .divider-pill-2 {
  @apply top-[17px] left-[58px] rotate-[-28deg];
}

.normal .divider-pill-3 {
  @apply top-[17px] left-[130px] rotate-[28deg];
}

.normal .divider-pill-4 {
  @apply top-[55px] left-[170px] rotate-[64deg];
}

.small {
  @apply h-12 w-18;
}
.small.responsive {
  @apply h-8 w-12 sm:h-12 sm:w-18;
}

.small .count {
  @apply text-lg;
}
.small.responsive .count {
  @apply text-xs sm:text-lg;
}

.small .text-wrap {
  @apply mt-2.5;
}
.small.responsive .text-wrap {
  @apply mt-1.5 sm:mt-2.5;
}

.small .empty-wrap {
  @apply mt-1.5;
}

.small .empty-large-bubble {
  @apply h-2 w-6 rounded-xl;
}

.small .empty-small-bubble {
  @apply mt-1 h-1 w-4;
}

.small .divider-pill {
  @apply h-0.5 w-0.5 rounded-full;
}

.small .divider-pill-1 {
  @apply top-[24px] left-[6px];
}
.small.responsive .divider-pill-1 {
  @apply top-[16px] left-[3px] sm:top-[24px] sm:left-[6px];
}

.small .divider-pill-2 {
  @apply top-[8px] left-[20px];
}

.small.responsive .divider-pill-2 {
  @apply top-[5px] left-[12px] sm:top-[8px] sm:left-[20px];
}

.small .divider-pill-3 {
  @apply top-[8px] left-[50px];
}
.small.responsive .divider-pill-3 {
  @apply top-[5px] left-[33px] sm:top-[8px] sm:left-[50px];
}

.small .divider-pill-4 {
  @apply top-[24px] left-[64px];
}
.small.responsive .divider-pill-4 {
  @apply top-[16px] left-[42px] sm:top-[24px] sm:left-[64px];
}
</style>
