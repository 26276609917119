<template>
  <AtroContent class="space-x-4" wrap="nowrap">
    <AtroLineItem icon-name="clock_solid" :text="assessment.duration" />
    <AtroLineItem icon-name="campaign_series_count" :text="topicCountText" />
  </AtroContent>
</template>
<script setup lang="ts">
import useAssessmentMeta from '@/composables/meta/useAssessmentMeta'
import { Assessment } from '@/types/assessment'

export interface Props {
  assessment: Assessment
}

const { assessment } = defineProps<Props>()

const { topicCountText } = $(useAssessmentMeta($$(assessment)))
</script>
