import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'

export const mockPaths = {
  invite: 'invites/:token',
  sharesActive: 'shares/active',
  signup: 'signup',
}

const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  // GET: Validate invite token
  rest.get(getPath('invite'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),

  // GET: Validate share token
  rest.get(getPath('sharesActive'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),

  // PATCH: update invite token
  rest.patch(getPath('invite'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),

  // POST: signup
  rest.post(getPath('signup'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),
]
