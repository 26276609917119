<template>
  <AtroAsync :config="fetchState">
    <AssessmentPageHeader :assessment="assessment" view="user" />

    <AtroContent class="mt-10 w-full space-y-10">
      <template v-if="isCaughtUp">
        <AssessmentUserScore :assessment="assessment" />
        <AssessmentSectionSecurityFixes view="userRead" :assessment="assessment" />
      </template>
      <AssessmentSectionTopics view="user" :assessment="assessment" />
    </AtroContent>
  </AtroAsync>

  <AtroOnboardingModal
    v-if="isCaughtUp"
    icon-name="check"
    onboarding-key="completedFirstAssessment"
    :heading="ONBOARDING_COPY.assessmentUserModal.heading"
    :body="ONBOARDING_COPY.assessmentUserModal.body"
    :cta-text="ONBOARDING_COPY.assessmentUserModal.cta"
  />
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { ONBOARDING_COPY } from '@/lib/constants'
import useAssessment from '@/composables/data/useAssessment'
import AssessmentPageHeader from '@/components/assessment/AssessmentPageHeader.vue'
import AssessmentSectionSecurityFixes from '@/components/assessment/AssessmentSectionSecurityFixes.vue'
import AssessmentSectionTopics from '@/components/assessment/AssessmentSectionTopics.vue'
import AssessmentUserScore from '@/components/assessment/user/AssessmentUserScore.vue'

const route = useRoute()

const {
  fetchState,
  data: assessment,
  meta: { isCaughtUp },
} = useAssessment(route.name, route.params.id)
</script>
