<template>
  <AtroContent class="w-full">
    <AtroSectionHeading heading="Topics" :sub-heading="subHeading" />
    <AtroList class="!p-8" :tight="isMobile">
      <AtroListItem v-for="topic in assessment.topics" :key="topic.id">
        <TopicListItem :assessment="assessment" :topic="topic" :view="view" />
      </AtroListItem>
    </AtroList>
  </AtroContent>
</template>

<script setup lang="ts">
import useAssessmentMeta from '@/composables/meta/useAssessmentMeta'
import useIsMobile from '@/composables/useIsMobile'
import TopicListItem, { Props as TopicListItemProps } from '@/components/topic/TopicListItem.vue'
import { Assessment } from '@/types/assessment'

export interface Props {
  assessment: Assessment
  view: TopicListItemProps['view']
}

const { assessment, view } = defineProps<Props>()

const isMobile = useIsMobile()
const { topicsRemainingCount } = $(useAssessmentMeta($$(assessment)))

const subHeading = $computed(() => {
  if (view === 'admin') {
    return 'Included in this assessment'
  } else {
    return topicsRemainingCount > 0
      ? 'Complete to understand your risks'
      : 'Completed in this assessment'
  }
})
</script>
