<template>
  <AtroNavList class="space-y-3" :nav-items="userNavItems">
    <template #link="{ navItem, isActive }">
      <AtroContent
        :class="['group', isActive ? 'text-white after:opacity-100' : 'text-white/75']"
        items="center"
      >
        <AtroIcon
          :class="['w-4 rounded-xl transition-colors group-hover:text-white ']"
          :name="navItem.iconName"
        />

        <AtroSpan class="ml-4 sm:group-hover:text-white" size="large">{{ navItem.label }}</AtroSpan>
      </AtroContent>
    </template>
  </AtroNavList>
</template>
<script setup lang="ts">
import useUserNavItems from '@/composables/useUserNavItems'

const userNavItems = useUserNavItems()
</script>
