<template>
  <AtroGridTable :columns="columnsConfig" :data="securityFixes">
    <template #default="{ columnStyle, items }">
      <TeamMemberSecurityFixesStatusTableRow
        v-for="securityFix in items"
        :key="securityFix.id"
        :col-style="columnStyle"
        :expandable="expandable"
        :security-fix="securityFix"
        :show-attribution="showAttribution"
      />
    </template>
  </AtroGridTable>
</template>

<script setup lang="ts">
import TeamMemberSecurityFixesStatusTableRow from '@/components/team/TeamMemberSecurityFixesStatusTableRow.vue'
import useIsMobile from '@/composables/useIsMobile'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'

export interface Props {
  securityFixes: SecurityFix[]
  expandable?: boolean
  showAttribution?: boolean
}

const { securityFixes, expandable = false, showAttribution = true } = defineProps<Props>()

const isMobile = $(useIsMobile())

const columnsConfig = $computed<ColumnConfig[]>(() => [
  {
    heading: {
      align: 'start',
      text: 'Security Fix',
    },
    sortBy: 'title',
    widthRatio: 6,
  },
  {
    heading: {
      text: 'Risk Level',
    },
    initialSortedBy: true,
    sortBy: 'riskLevel',
    minColumnWidth: 80,
    widthRatio: 1.66,
  },
  {
    heading: {
      text: 'Resolved',
    },
    widthRatio: 1.66,
    minColumnWidth: 64,
    sortBy: (item: SecurityFix) => item.status === 'fixed',
  },
  ...(expandable
    ? [
        {
          fixedWidth: true,
          minColumnWidth: isMobile ? 32 : 64,
        },
      ]
    : []),
])
</script>
