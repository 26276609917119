import axios, { AxiosError } from 'axios'
import { auth0 } from '@/plugins/auth0'

export const unauthenticatedAxios = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 1000 * 12,
})

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 1000 * 12,
})
axiosInstance.defaults.headers.common['Accept'] = 'application/json'

// we don't do auth in storybook
if (!import.meta.env.STORYBOOK) {
  axiosInstance.interceptors.request.use(
    async config => {
      if (!window.location.search.includes('demo=true')) {
        await auth0.getAccessTokenSilently().then(authToken => {
          config.headers.AuthType = 'bearer'
          config.headers.Authorization = `Bearer ${authToken}`
        })
      }

      return config
    },
    function (err) {
      return Promise.reject(err)
    }
  )
}

axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  (error: AxiosError) => {
    if (error.config.url !== 'profile' && error.response?.status === 401) {
      auth0.logout({ returnTo: window.location.origin })
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
