import { unref, watch } from 'vue'
import { FeatureName } from '@/utils/features'
import useFeatureFlags, { overrides } from '@/composables/useFeatureFlags'
import { MaybeRef } from '@/types/utils'

export default function useFeatureFlag(name: FeatureName, user: MaybeRef<User>) {
  let isVisible = $ref(false)
  const { isVisible: _isVisible } = useFeatureFlags(unref(user))

  watch(
    overrides,
    () => {
      isVisible = _isVisible(name)
    },
    { immediate: true }
  )

  return $$({ isVisible })
}
