import { computed, ref } from 'vue'
import useCurrentUser from '@/composables/data/useCurrentUser'
import useData from '@/composables/useData'

const nullUseTeamReturn = {
  data: [],
  fetchState: {
    isLoading: ref(false),
  },
  meta: {},
}

export default function useTeam({ withInvites = false }: { withInvites?: boolean } = {}) {
  const { data: currentUser } = $(useCurrentUser())

  // TODO: abstract data fetcher null state
  if (currentUser.role === 'member') {
    return nullUseTeamReturn
  }

  const fetchState = useData<User[]>('team')
  const data = fetchState.data

  if (!withInvites) {
    fetchState.data = computed(() => data.value?.filter(user => user.role !== 'invited'))
  }

  return {
    data: fetchState.data,
    fetchState,
    meta: {},
  }
}
