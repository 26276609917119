import { Ref, unref } from 'vue'
import { MaybeRef } from '@/types/utils'
import { useBaseMutation } from './useBaseMutation'

export type CreateParams = {
  email: string
  role: User['role']
}

export type UpdateParams = {
  id: string
  role: User['role']
}

export function useTeamInviteCreateMutation() {
  const mutation = useBaseMutation({
    method: 'post',
    url: 'team-invites',
    invalidateKeys: [['team'], ['team-invites']],
  })

  const createTeamInvite = async (data: CreateParams[]) => {
    return mutation.mutateAsync({ invites: data })
  }

  return { createTeamInvite, ...mutation }
}

export function useTeamInviteUpdateMutation(id: string) {
  const mutation = useBaseMutation({
    method: 'patch',
    url: `team-invites/${id}`,
    invalidateKeys: [['team-invites']],
  })

  const updateTeamInvite = async (data: UpdateParams) => {
    return mutation.mutateAsync({ invite: data })
  }

  return { updateTeamInvite, ...mutation }
}

export function useTeamInviteDestroyMutation(invite: MaybeRef<Invite>) {
  const url = $computed(() => `team-invites/${unref(invite)?.id}`)

  const mutation = useBaseMutation({
    method: 'delete',
    url: $$(url),
    invalidateKeys: [['team-invites']],
  })

  const destoryTeamInvite = async () => {
    return mutation.mutateAsync(null)
  }

  return { destoryTeamInvite, ...mutation }
}
