import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'

export const mockPaths = {
  answerStep: 'steps/:id/answers',
}

const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  // PUT: Answer Step
  rest.put(getPath('answerStep'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),
]
