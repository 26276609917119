<template>
  <AtroContent class="w-full" col>
    <AtroSectionHeading
      heading="Security fixes"
      sub-heading="Issues to resolve from completed topics"
    >
      <AtroButtonLink :to="{ name: fixIssuesRouteName }">Fix Issues</AtroButtonLink>
    </AtroSectionHeading>
    <AtroAsync :config="fetchState">
      <template #default>
        <SecurityFixesTable :view="view" :security-fixes="securityFixes" />
      </template>

      <template #empty>
        <AtroContent class="w-full rounded-3xl border-2 p-8 text-center" items="center" col>
          <AtroHeading class="text-atro-dark-purple" semibold
            >No fixes for this assessment</AtroHeading
          >
        </AtroContent>
      </template>
    </AtroAsync>
  </AtroContent>
</template>

<script setup lang="ts">
import useSecurityFixes from '@/composables/data/useSecurityFixes'
import SecurityFixesTable, {
  Props as SecurityFixesTableProps,
} from '@/components/securityFixes/SecurityFixesTable.vue'
import { Assessment } from '@/types/assessment'

export interface Props {
  assessment: Assessment
  view: SecurityFixesTableProps['view']
}

const { assessment, view } = defineProps<Props>()

const isAdminView = $computed(() => view.includes('admin'))

const resourceName = isAdminView ? 'assessmentTeam' : 'assessmentUser'

const { fetchState, data: securityFixes } = useSecurityFixes(resourceName, { id: assessment.id })

const fixIssuesRouteName = $computed(() => (isAdminView ? 'teamSecurityFixes' : 'mySecurityFixes'))
</script>