<template>
  <AtroGridTable :columns="columnsConfig" :data="assessments">
    <template #default="{ columnStyle, items }">
      <TeamMemberAssessmentsStatusTableRow
        v-for="assessment in items"
        :key="assessment.id"
        :col-style="columnStyle"
        :assessment="assessment"
      />
    </template>
  </AtroGridTable>
</template>

<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'
import TeamMemberAssessmentsStatusTableRow from '@/components/team/TeamMemberAssessmentsStatusTableRow.vue'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'
import { Assessment } from '@/types/assessment'

export interface Props {
  assessments: Assessment[]
}

const { assessments } = defineProps<Props>()

const isMobile = $(useIsMobile())

const columnsConfig = $computed<ColumnConfig[]>(() => [
  {
    heading: {
      align: 'start',
      text: 'Assessments',
    },
    widthRatio: 6,
    sortBy: (item: Assessment) => [
      item.progress.numerator / Math.max(item.progress.denominator, 1),
      item.title,
    ],
  },
  {
    heading: {
      text: isMobile ? 'Fixes' : 'Security fixes',
    },
    fixedWidth: isMobile,
    minColumnWidth: isMobile ? 48 : 96,
    widthRatio: 2.66,
    sortBy: (item: Assessment) =>
      item.securityFixes?.filter(securityFix => securityFix.status !== 'fixed').length,
  },
  {
    heading: {
      text: 'Score',
    },
    fixedWidth: true,
    minColumnWidth: isMobile ? 48 : 72,
    sortBy: 'score.atroScore',
  },
  {
    fixedWidth: true,
    minColumnWidth: isMobile ? 32 : 64,
  },
])
</script>
