<template>
  <AtroButton variant="transparent" icon-right="plus" @click="isTeamInviteModalOpen = true">
    <AtroSpan>Invite team</AtroSpan>
  </AtroButton>
  <TeamInviteModal :is-open="isTeamInviteModalOpen" @close="isTeamInviteModalOpen = false" />
</template>

<script setup lang="ts">
import TeamInviteModal from '@/components/team/TeamInviteModal.vue'

let isTeamInviteModalOpen = $ref(false)
</script>
