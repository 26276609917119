<template>
  <TransitionRoot as="template" appear :show="isOpen" @after-leave="throttledAfterClose">
    <Dialog as="div" class="relative z-50" :initial-focus="focusRef" @close="closeModal">
      <TransitionChild
        v-if="isCentered"
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-atro-slate-purple/50" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <AtroContent
          :class="['min-h-full text-center', isCentered && 'p-10']"
          items="center"
          justify="center"
          wrap="nowrap"
        >
          <TransitionChild as="template" v-bind="modalBodyTransition">
            <DialogPanel :class="['modal-body', closable && 'closable', _variant, size, className]">
              <AtroCloseButton
                v-if="closable"
                class="!absolute top-4 right-4 !p-2"
                @click="closeModal"
              />
              <AtroContent
                class="w-full flex-1"
                wrap="nowrap"
                :justify="isFullscreen ? 'center' : 'start'"
                col
              >
                <slot :close="closeModal" :focus-ref="focusRef" />
              </AtroContent>

              <slot :close="closeModal" name="actions" />
            </DialogPanel>
          </TransitionChild>
        </AtroContent>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { useThrottleFn } from '@vueuse/core'
import useIsMobile from '@/composables/useIsMobile'

export interface Props {
  class?: string | string[]
  closable?: boolean
  fullscreenWhenMobile?: boolean
  isOpen?: boolean
  size?: 'small' | 'normal' | 'large' | 'max'
  variant?: 'centered' | 'fullscreen' | 'topicTaker'
}

let {
  variant = 'centered',
  class: className,
  closable = true,
  fullscreenWhenMobile = false,
  isOpen: isOpenProp = true,
  size = 'normal',
} = defineProps<Props>()

const emit = defineEmits<{
  (e: 'afterClose', params?: unknown): void
  (e: 'close', params?: unknown): void
}>()

const isMobile = $(useIsMobile())
const focusRef = $ref(null)
const _variant = $computed(() => (fullscreenWhenMobile && isMobile ? 'fullscreen' : variant))
const isCentered = $computed(() => _variant === 'centered')
const isFullscreen = $computed(() => _variant === 'fullscreen')

let closeParams = $ref()

// This dance allows for state to be managed internally when the modal is
// defined open true initally or externally when defined with open false
const wasInitializedOpen = isOpenProp
let open = $ref(isOpenProp)
let isOpen = $computed({
  get: () => (wasInitializedOpen ? open : isOpenProp),
  set: val => {
    if (wasInitializedOpen) open = val
  },
})

const modalBodyTransition = $computed(() => {
  switch (variant) {
    case 'centered':
      return {
        enter: 'duration-300 ease-out',
        enterFrom: 'opacity-0 scale-95',
        enterTo: 'opacity-100 scale-100',
        leave: 'duration-200 ease-in',
        leaveFrom: 'opacity-100 scale-100',
        leaveTo: 'opacity-0 scale-95',
      }
    default:
      return {
        enter: 'duration-300 ease-out',
        enterFrom: 'opacity-0',
        enterTo: 'opacity-100',
        leave: 'duration-200 ease-in',
        leaveFrom: 'opacity-100',
        leaveTo: 'opacity-0',
      }
  }
})

const closeModal = params => {
  if (params === false && closable === false) return
  isOpen = false
  closeParams = params
  emit('close', params)
}

const throttledAfterClose = useThrottleFn(() => {
  emit('afterClose', closeParams)
}, 500)
</script>

<style lang="postcss" scoped>
.modal-body {
  @apply relative w-full text-left;
}

.modal-body.centered {
  @apply overflow-hidden rounded-2xl bg-white shadow-xl;
}

.modal-body.centered.small {
  @apply max-w-sm p-6;
}

.modal-body.centered.small.closable {
  @apply pt-14;
}

.modal-body.centered.normal {
  @apply max-w-[520px];
}

.modal-body.centered.large {
  @apply max-w-3xl;
}

.modal-body.centered.max {
  @apply max-w-max;
}

.modal-body.centered.normal,
.modal-body.centered.large,
.modal-body.centered.max {
  @apply p-6 pt-14 sm:p-12;
}

.modal-body.fullscreen,
.modal-body.topicTaker {
  @apply min-h-screen w-screen;
}

.modal-body.topicTaker {
  @apply overflow-hidden;
}

.modal-body.fullscreen {
  @apply flex flex-1 flex-col overflow-y-auto bg-white p-6 sm:p-12;
}
</style>
