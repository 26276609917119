<template>
  <AtroGridTable :columns="columnsConfig" :async="{ config: fetchState }">
    <template #default="{ columnStyle }">
      <TeamManageInvitesTableRow
        v-for="invite in invites"
        :key="invite.id"
        :columns-config="columnsConfig"
        :col-style="columnStyle"
        :invite="invite"
        @uninvite="() => (inviteToReject = invite)"
      />
    </template>
  </AtroGridTable>

  <AtroConfirmModal
    :is-open="showUninviteConfirmModal"
    :on-confirm="onUninvite"
    @close="inviteToReject = null"
  />
</template>
<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'
import { UseDataReturnProps } from '@/composables/useData'
import { useTeamInviteDestroyMutation } from '@/composables/mutations/useTeamInviteMutation'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'
import TeamManageInvitesTableRow from '@/components/team/TeamManageInvitesTableRow.vue'

export interface Props {
  fetchState: UseDataReturnProps
}

const { fetchState } = defineProps<Props>()
const { data: invites } = fetchState

const isMobile = $(useIsMobile())

let inviteToReject = $ref<Invite>()
const { destoryTeamInvite } = useTeamInviteDestroyMutation($$(inviteToReject))

const columnsConfig = $computed<ColumnConfig[]>(() => [
  {
    heading: {
      align: 'start',
      text: 'Team members',
    },
    sortBy: 'email',
    widthRatio: isMobile ? 4 : 6,
  },
  {
    heading: {
      text: 'Permissions',
    },
    widthRatio: 1.66,
  },
  {
    name: 'actions',
    fixedWidth: true,
    minColumnWidth: isMobile ? 0 : 180,
    rowClass: isMobile ? 'mt-4 col-span-full' : '',
  },
])

const showUninviteConfirmModal = $computed(() => !!inviteToReject)

const onUninvite = async () => {
  await destoryTeamInvite()
  return true
}
</script>
