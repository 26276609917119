<template>
  <Transition name="fade" appear>
    <div ref="tooltip" :class="['z-10', variant]">
      <div class="tooltip-body">
        <div data-popper-arrow class="arrow" />
        <slot :close="closeTooltip" :open="openTooltip">
          <AtroMarkdown v-if="text" class="text-sm text-white" :markdown="text" />
        </slot>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { Ref, nextTick, onBeforeUnmount, onMounted, ref } from 'vue'
import { ReactiveVariable } from 'vue/macros'
import { arrow, flip, offset, preventOverflow } from '@popperjs/core'
import usePopper, { UsePopperOptions } from '@/composables/usePopper'

export interface Props {
  tooltipTargetFn: () => Ref<Element> | ReactiveVariable<Element>
  config?: UsePopperOptions
  openOnMount?: boolean
  text?: string
  variant?: 'normal' | 'onboarding'
}

const props = defineProps<Props>()
const { text, tooltipTargetFn, openOnMount = false, variant = 'normal', config = {} } = props

const tooltip = ref()
let updatePosCallbackRef = $ref<number>()

const {
  instance,
  close: closeTooltip,
  open: openTooltip,
} = usePopper(tooltipTargetFn, tooltip, {
  placement: config?.placement || 'right',
  strategy: config?.strategy || 'absolute',
  trigger: config?.trigger || 'hover',
  modifiers: [
    preventOverflow,
    flip,
    arrow,
    offset,
    {
      name: 'offset',
      options: {
        offset: [0, 10],
      },
    },
    ...(config.modifiers ? config.modifiers : []),
  ],
})

onMounted(async () => {
  openOnMount && openTooltip()
  await nextTick()
  updatePosCallbackRef = requestAnimationFrame(updateTooltipPosition)
})

// watch(props, () => {
//   instance.value?.setOptions(options => ({
//     ...props.config,
//     modifiers: [...options.modifiers, ...(props.config.modifiers ? props.config.modifiers : [])],
//   }))
// })

const updateTooltipPosition = () => {
  instance.value?.update()
  updatePosCallbackRef = requestAnimationFrame(updateTooltipPosition)
}

const cancelUpdateTooltipPosition = () => {
  cancelAnimationFrame(updatePosCallbackRef)
}
// tooltip has had plenty of time to update and finialize its position
setTimeout(cancelUpdateTooltipPosition, 1000)

onBeforeUnmount(cancelUpdateTooltipPosition)
</script>

<style lang="postcss" scoped>
.tooltip-body {
  @apply relative shadow-md;
}

.arrow,
.arrow::before {
  position: absolute;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

[data-popper-placement^='top'] .arrow {
  bottom: -4px;
}

[data-popper-placement^='bottom'] .arrow {
  top: -8px;
}

[data-popper-placement^='left'] .arrow {
  right: -4px;
}

[data-popper-placement^='right'] .arrow {
  left: -4px;
}

/* variants */
.normal .tooltip-body {
  @apply whitespace-nowrap rounded-md bg-slate-800 px-2 py-1;
}

.normal .arrow,
.normal .arrow::before {
  @apply h-2 w-2;
}

.onboarding .tooltip-body {
  @apply max-w-md rounded-3xl bg-atro-purple p-8;
}

.onboarding .arrow,
.onboarding .arrow::before {
  @apply h-6 w-4;
}
</style>
