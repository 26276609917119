<template>
  <AtroContent
    :class="[
      'flex-shrink-0 bg-atro-gray-2 text-atro-purple',
      circle ? 'rounded-full' : 'rounded-xl',
      size,
    ]"
    items="center"
    justify="center"
  >
    <AtroIcon v-if="_iconName" class="h-full w-full" :name="_iconName" />
  </AtroContent>
</template>

<script setup lang="ts">
import { IconName } from '@/assets/icons/atro-icon.model'

export interface Props {
  circle?: boolean
  iconName?: IconName
  size?: 'small' | 'normal' | 'large'
}

const { iconName, circle = false, size = 'normal' } = defineProps<Props>()

let _iconName = $computed(() => iconName || 'nav_atro_logo_no_text')
</script>

<style lang="postcss" scoped>
.small {
  @apply h-12 w-12 p-3;
}

.normal {
  @apply h-18 w-18 p-4;
}

.large {
  @apply h-20 w-20 p-4;
}
</style>
