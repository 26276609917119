<template>
  <AtroContent items="center" wrap="nowrap">
    <AtroIcon :class="['mr-2 w-4', iconColor]" :name="iconName" />
    <AtroSpan class="text-atro-gray-1" size="small" semibold>{{ fixesText }}</AtroSpan>
  </AtroContent>
</template>
<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'
import { IconName } from '@/assets/icons/atro-icon.model'

export interface Props {
  count: number
  hasAddressedSecurityFixes?: boolean
  textStyle?: 'short' | 'long'
}

const { count, hasAddressedSecurityFixes, textStyle = 'long' } = defineProps<Props>()

const isMobile = $(useIsMobile())

const hasSecurityFixes = $computed(() => count > 0)
const fixesText = $computed(() => {
  if (hasAddressedSecurityFixes) {
    return 'All fixed'
  } else if (!hasSecurityFixes) {
    return textStyle === 'short' ? (isMobile ? '0' : 'No fixes') : 'No security fixes'
  } else if (count === 1) {
    return textStyle === 'short' ? (isMobile ? '1' : '1 Fix') : '1 security fix'
  } else {
    return textStyle === 'short' ? (isMobile ? count : `${count} Fixes`) : `${count} security fixes`
  }
})

const iconColor = $computed(() =>
  hasAddressedSecurityFixes
    ? 'text-atro-sky-blue'
    : hasSecurityFixes
    ? 'text-atro-magenta'
    : 'text-atro-gray-1'
)

const iconName = $computed<IconName>(() =>
  hasAddressedSecurityFixes ? 'circle_check' : 'exclamation_circle'
)
</script>
