<template>
  <AtroCard variant="signup">
    <AtroContent class="mb-6" items="center" justify="center">
      <AtroIconSquare class="text-atro-purple" icon-name="user" circle />
    </AtroContent>
    <AtroHeading class="mb-6 text-center text-atro-slate-purple" size="large" semibold
      >Tell us about you</AtroHeading
    >

    <FormKit v-model="editableUser" class="space-y-4" type="form" @submit="onSubmit">
      <FormKit
        type="text"
        label="First name"
        placehoder="First name"
        name="firstName"
        validation="required|length:2,50"
        :autofocus="!editableUser.firstName"
      />
      <FormKit
        type="text"
        label="Last name"
        placehoder="Last name"
        name="lastName"
        validation="required|length:2,50"
      />
      <template #submit="{ state: { valid } }">
        <AtroButton block icon-right="chevron_right" :is-pending="isPending" :disabled="!valid"
          >Continue</AtroButton
        >
      </template>
    </FormKit>
  </AtroCard>
</template>

<script setup lang="ts">
import useFormSubmit from '@/composables/useFormSubmit'

interface Props {
  onContinue: ({ user }: { user: SignupUser }) => null
  user: SignupUser
}

const { onContinue, user } = defineProps<Props>()

const editableUser = $ref({ ...user })

const { isPending, onSubmit } = useFormSubmit<SignupUser>(data => onContinue({ user: data }))
</script>