<template>
  <AtroAsync :config="fetchState">
    <IntegrationDetailHeading class="mb-10" :integration="integration" />

    <AtroTabGroup :tab-configs="tabConfigs" />
  </AtroAsync>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import useIntegration from '@/composables/data/useIntegration'
import IntegrationDetailHeading from '@/components/integration/IntegrationDetailHeading.vue'
import IntegrationMonitoring from '@/components/integration/IntegrationMonitoring.vue'
import IntegrationOverview from '@/components/integration/IntegrationOverview.vue'
import { TabConfig } from '@/components/globals/atro/TabGroup.vue'

export interface Props {
  active?: boolean
}

const { active } = defineProps<Props>()

const router = useRoute()
const integrationId = router.params.id as string
const {
  data: integration,
  fetchState,
  meta: { allMonitoringChecksPass },
} = $(useIntegration(active ? 'org' : 'discovery', integrationId))

const tabConfigs = $computed<TabConfig[]>(() => [
  {
    title: 'Overview',
    component: IntegrationOverview,
    props: {
      integration,
    },
  },
  {
    title: 'Monitoring',
    count: integration?.monitors?.length,
    alert: !allMonitoringChecksPass,
    deepLink: true,
    component: IntegrationMonitoring,
    props: {
      integration,
    },
  },
])
</script>
