import { createApp } from 'vue'
import router from './router'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { MotionPlugin } from '@vueuse/motion'
import { GesturePlugin } from '@vueuse/gesture'
import {
  plugin as FormKitPlugin,
  defaultConfig as defaultFormKitConfig,
  createInput,
} from '@formkit/vue'
import { createAutoAnimatePlugin } from '@formkit/addons'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import formkitTheme from './lib/formkit/theme'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { auth0 } from '@/plugins/auth0'
import bodyScrollLock from '@/plugins/bodyScrollLock'
import icons from '@/plugins/icons'
import globalComponents from '@/plugins/globalComponents'
import App from './App.vue'
import Switch from '@/components/formkit/Switch.vue'
import '@/assets/css/main.css'

const app = createApp(App)

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'atro.com', /^\//],
      }),
    ],
    environment: (import.meta.env.VITE_ENVIRONMENT as string) || import.meta.env.MODE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    release: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
    tracesSampleRate: 1.0,
  })
}

app
  .use(router)
  .use(MotionPlugin)
  .use(GesturePlugin)
  .use(icons)
  .use(globalComponents)
  .use(VueQueryPlugin)
  .use(auth0)
  .use(autoAnimatePlugin)
  .use(bodyScrollLock)
  .use(
    FormKitPlugin,
    defaultFormKitConfig({
      plugins: [formkitTheme, createAutoAnimatePlugin()],
      config: {
        validationVisibility: 'blur',
      },
      inputs: {
        switch: createInput(Switch),
      },
    })
  )
  .mount('#app')
