import TopicTakerPage from '@/pages/topics/TopicTakerPage.vue'

export default [
  {
    path: '/topics/:id',
    name: 'topicTaker',
    component: TopicTakerPage,
    meta: { layout: 'TopicTaker', requiresAuth: true, title: 'Topic Taker' },
  },
]
