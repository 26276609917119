<template>
  <AtroButton icon-right="bullhorn_solid" @click="onNudge"
    >{{ label }}
    <AtroConfirmModal
      confirm-action-text="Send"
      icon-name="bell_solid"
      after-confifm-heading-text="Reminder sent!"
      :after-confifm-body-text="modalAfterConfirmBodyText"
      :cancelable="false"
      :body-text="modalBodyText"
      :heading-text="modalHeadingText"
      :is-open="modalOpen"
      :on-confirm="confirmNudge"
      @close="modalOpen = false"
    />
  </AtroButton>
</template>

<script setup lang="ts">
import { Props as ButtonProps } from '@/components/globals/atro/Button.vue'
import { NudgeConfig } from '@/types/nudge'

export interface Props extends ButtonProps {
  config: NudgeConfig
  label?: string
}

const { config, label = 'Send Reminder' } = defineProps<Props>()

const emit = defineEmits<{ (e: 'nudge'): void }>()

const modalBodyText = $computed(() => {
  switch (config.type) {
    case 'teamAssessment':
      return 'Anyone who has not completed this yet will receive an email reminder.'
    case 'userGeneral':
      return 'Email this member reminding them to complete their work in Atro'
  }
})

const modalHeadingText = $computed(() => {
  switch (config.type) {
    case 'teamAssessment':
      return 'Remind your team to complete this assessment'
    case 'userGeneral':
      return `Remind ${config.user.display} to finish work`
  }
})

const modalAfterConfirmBodyText = $computed(() => {
  switch (config.type) {
    case 'teamAssessment':
      return 'Assigned users have been a sent an email reminder to complete this assessment.'
    case 'userGeneral':
      return `${config.user.display} has been emailed a reminder to complete their work.`
  }
})

let modalOpen = $ref(false)
const onNudge = () => {
  modalOpen = true
}

const confirmNudge = () => {
  emit('nudge')
  return true
}
</script>
