<template>
  <AtroContent col items="center">
    <AtroIcon class="h-18 w-18 text-atro-gray-2" name="circle_check" />
    <AtroHeading class="mt-6">{{ headingText }}</AtroHeading>
    <AtroParagraph v-if="nextTopic" class="mt-2">Up next {{ nextTopic.title }}</AtroParagraph>
    <AtroButton
      class="mt-10"
      icon-right="chevron_right"
      variant="light"
      @click="emit('nextTopic')"
      >{{ buttonText }}</AtroButton
    >
  </AtroContent>
</template>

<script setup lang="ts">
import { Topic } from '@/types/topic'
import { onMounted } from 'vue'

export interface Props {
  nextTopic?: Topic
}

const { nextTopic } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'nextTopic'): void
}>()

let countdownTimer
let countdownTimerText = $ref(5)

const headingText = $computed(() => (nextTopic ? 'Topic submitted!' : 'Assessment completed!'))
const buttonText = $computed(() =>
  nextTopic ? `Start Next Topic (${countdownTimerText})` : 'View Results'
)

onMounted(() => {
  countdownTimer = setInterval(() => {
    if (countdownTimerText === 1) {
      clearInterval(countdownTimer)
      emit('nextTopic')
    }
    countdownTimerText -= 1
  }, 1000)
})
</script>
