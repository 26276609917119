<template>
  <AtroContent items="center" wrap="nowrap">
    <AtroIcon :class="['mr-2 h-6 w-6', checkIconColorClassname]" :name="checkIconName" />

    <AtroContent class="w-full" col wrap="nowrap">
      <AtroContent wrap="nowrap">
        <AtroSpan class="mr-2" size="large" semibold>{{ alertCount }}</AtroSpan>
        <AtroSpan class="text-atro-gray-1" semibold
          >{{ alertCount === 1 ? 'Alert' : 'Alerts' }} from
          {{ activeMonitoringChecks.length }} checks</AtroSpan
        >
      </AtroContent>

      <div class="grid w-full auto-cols-fr grid-flow-col gap-1">
        <div
          v-for="(check, i) in activeMonitoringChecks"
          :key="i"
          :class="[
            'h-1 rounded-lg',
            check.dismissed && '!bg-atro-highlight-green',
            check.status === 'critical' && 'bg-atro-magenta',
            check.status === 'warning' && 'bg-atro-yellow',
            check.status === 'info' && 'bg-atro-gray-1',
            check.status === 'pending' && 'bg-atro-gray-1',
            check.status === 'ok' && 'bg-atro-highlight-green',
          ]"
        />
      </div>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { IconName } from '@/assets/icons/atro-icon.model'

export interface Props {
  monitoringChecks: MonitoringCheck[]
}

const { monitoringChecks = [] } = defineProps<Props>()

const activeMonitoringChecks = $computed(() =>
  monitoringChecks.filter(monitoringCheck => monitoringCheck.status !== 'disabled')
)

const firstCheck = $computed(() => activeMonitoringChecks[0])

const alertCount = $computed(
  () =>
    activeMonitoringChecks?.filter(
      check => ['critical', 'warning', 'info'].includes(check.status) && !check.dismissed
    ).length
)

const checkIconName = $computed<IconName>(() => {
  if (firstCheck?.dismissed) return 'circle_check'
  return firstCheck?.status === 'ok' ? 'circle_check' : 'exclamation_square'
})

const checkIconColorClassname = $computed(() => {
  if (firstCheck?.dismissed) return 'text-atro-highlight-green'
  switch (firstCheck?.status) {
    case 'ok':
      return 'text-atro-highlight-green'
    case 'critical':
      return 'text-atro-magenta'
    case 'warning':
      return 'text-atro-yellow'
    default:
      return 'text-atro-gray-1'
  }
})
</script>
