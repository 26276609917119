export default {
  org: {
    key: ['org'],
    path: 'org',
  },
  orgScore: {
    key: ['orgScore'],
    path: 'org/score',
  },
}
