import activityKeyPaths from '@/utils/resourcePaths/activity'
import assessmentsKeyPaths from '@/utils/resourcePaths/assessments'
import integrationKeyPaths from '@/utils/resourcePaths/integrations'
import inivtesKeyPaths from '@/utils/resourcePaths/invites'
import orgKeyPaths from '@/utils/resourcePaths/org'
import securityFixesKeyPaths from '@/utils/resourcePaths/securityFixes'
import teamKeyPaths from '@/utils/resourcePaths/team'
import topicsKeyPaths from '@/utils/resourcePaths/topics'
import userKeyPaths from '@/utils/resourcePaths/user'

const keyPathMap = {
  ...activityKeyPaths,
  ...assessmentsKeyPaths,
  ...integrationKeyPaths,
  ...inivtesKeyPaths,
  ...orgKeyPaths,
  ...securityFixesKeyPaths,
  ...teamKeyPaths,
  ...topicsKeyPaths,
  ...userKeyPaths,
}

export type ResourcePath = keyof typeof keyPathMap

export default keyPathMap
