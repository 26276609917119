<template>
  <BluredBackground id="topic-taker" hide-magenta-blob>
    <suspense>
      <AtroError v-if="error" class="text-white" :error="error" />

      <template v-else>
        <slot />
      </template>
      <template #fallback>
        <AtroLoading />
      </template>
    </suspense>
  </BluredBackground>
</template>

<script setup lang="ts">
import { onErrorCaptured } from 'vue'
import { AxiosError } from 'axios'
import BluredBackground from '@/components/BluredBackground.vue'

let error = $ref<AxiosError>()

onErrorCaptured((e: any) => {
  if (e?.name === 'AxiosError') {
    error = e
  }
})
</script>
