<template>
  <AtroAsync
    empty-heading="No assessments in progress"
    empty-icon-name="empty_tab_happy"
    :config="fetchState"
  >
    <div v-auto-animate class="w-full space-y-3 sm:grid sm:grid-cols-assessments sm:gap-3 sm:space-y-0">
      <AssessmentAdminCard
        v-for="assessment in assessments"
        :key="assessment.id"
        :assessment="assessment"
        responsive
      />
    </div>
  </AtroAsync>
</template>

<script setup lang="ts">
import useAssessments from '@/composables/data/useAssessments'
import AssessmentAdminCard from '@/components/assessment/admin/AssessmentAdminCard.vue'

const { fetchState, data: assessments } = useAssessments('team', 'in-progress')
</script>
