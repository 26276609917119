import { unref } from 'vue'
import { useBaseMutation } from '@/composables/mutations/useBaseMutation'
import { Integration } from '@/types/integration'
import { MaybeRef } from '@/types/utils'

export function useIntegrationDestroyMutation(integration: MaybeRef<Integration>) {
  const url = $computed(() => `integrations/org-integrations/${unref(integration)?.id}`)

  const mutation = useBaseMutation({
    method: 'delete',
    url: $$(url),
    invalidateKeys: [['integrations']],
  })

  const destoryIntegration = async () => {
    return mutation.mutateAsync(null)
  }

  return { destoryIntegration, ...mutation }
}

export function useIntegrationRefreshMutation(integration: MaybeRef<Integration>) {
  const id = unref(integration)?.id
  const url = $computed(() => `integrations/org-integrations/${id}/fetch`)

  const mutation = useBaseMutation({
    method: 'post',
    url: $$(url),
    invalidateKeys: [['integrationOrg', id]],
  })

  const refreshIntegration = async () => {
    return mutation.mutateAsync(null)
  }

  return { refreshIntegration, ...mutation }
}
