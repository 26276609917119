import { Integration } from '@/types/integration'

const deaultIntegrationOverview = {
  privacyNotice: `Atro uses Google defined **best practices** to interact and integrate with your Workspaces instances. These practices include:
- AES265 encryption
- Authentication tokens encrypted at rest 
- Atro never handles or stores your Google password 

**Authentication scopes used in this integration:**
- Read user list
- Reports API `,
  automations: [
    {
      title: 'Informational',
      subtitle: 'Check users, check admins, remove users',
    },
    {
      title: 'Security checks',
      subtitle: '2fa Enforced, admin password policy',
    },
    {
      title: 'Assessments',
      subtitle: 'Google security basics',
    },
    {
      title: 'Other',
      subtitle: 'Import users, limit domain',
    },
  ],
}

const checkCritical: MonitoringCheck = {
  id: 'b099b818-9081-4e2a-b87f-bc627e860dcd',
  status: 'critical',
  dismissed: false,
  dataUpdatedAt: null,
  slug: 'google-workspace:monitors:2fa_enabled',
  title: '2-Step Enrollment',
  subtitle: 'Make sure every user has 2fa enabled',
  description:
    'Every user in your Google Workspace organization should have 2fa enabled. Regularly review your user list and ensure everyone has 2fa enabled. Even if you’ve enforced 2fa, some users may not have gone through the enrollment process. Contact these users and confirm enrollment or disable them in Workspace. <a href="http://example.com" target="_blank">foobar</a>',
  actions: [
    {
      type: 'external_link',
      label: 'Enforce enrollment',
      target: 'https://admin.google.com/ac/security/2sv',
    },
    {
      type: 'show_report',
      label: 'View user list',
      target: 'google-workspace:reports:users',
    },
  ],
  dismissActions: [],
}

const checkWarning: MonitoringCheck = {
  id: '535f897d-747b-4ccf-b3f1-a312748798e9',
  status: 'warning',
  dismissed: false,
  dataUpdatedAt: null,
  slug: 'google-workspace:monitors:2fa_enforced',
  title: '2-Step Verification',
  subtitle: 'Enforce 2fa for your organization',
  description:
    'An administrator can enforce 2fa inside of Google workspace so all users are required to enable it. It’s currently not enforced in your organization and should be completed as soon as possible. Google has a process for enabling 2fa enforcement, they will walk you through it inside of the app. Once you’ve completed turning on enforcement, come back to Atro to confirm it’s enabled properly. ',
  actions: [
    {
      type: 'external_link',
      label: 'Turn on 2fa',
      target: 'https://admin.google.com/ac/security/2sv',
    },
    {
      type: 'show_report',
      label: 'View user list',
      target: 'google-workspace:reports:users',
    },
  ],
  dismissActions: [],
}

const checkInfo: MonitoringCheck = {
  id: '727df316-40d8-4979-9c51-a20e2f0e2e38',
  status: 'info',
  dismissed: false,
  dataUpdatedAt: null,
  slug: 'google-workspace:monitors:no_less_secure_apps',
  title: 'Less secure apps',
  subtitle: 'Disallow less secure apps for your users',
  description:
    "Allowing apps to connect to Google through traditional username and passord is a less secure method of authentication and is not recommended. Google has depreciated this but admins can still enable it. If this setting is enabled, ensure that it's turned off at all times and users connect apps through modern authentication like OAuth2.0",
  actions: [
    {
      type: 'external_link',
      label: 'Disable access',
      target: 'https://admin.google.com/ac/security/lsa?cid=01vj9gjr',
    },
    {
      type: 'show_report',
      label: 'View user list',
      target: 'google-workspace:reports:users',
    },
  ],
  dismissActions: [],
}

const checkOk: MonitoringCheck = {
  id: 'bf9e80ae-3c1a-4ece-91a9-2df0376d9770',
  status: 'pending',
  dismissed: false,
  dataUpdatedAt: null,
  slug: 'google-workspace:monitors:super_admin_count',
  title: 'Number of Admins',
  subtitle: 'Make sure you have the right number of admins',
  description:
    'Having too many or not enough Workspace admins can pose a serious risk to your team. You should aim for at least one backup super admin but not more than necessary to have proper coverage of duties. If you’re assigning super admin to everyone, that raises risk.',
  actions: [
    {
      type: 'external_link',
      label: 'Manage admins',
      target: 'https://admin.google.com/ac/reporting/report/user/security',
    },
    {
      type: 'show_report',
      label: 'View admin list',
      target: 'google-workspace:reports:admins',
    },
  ],
  dismissActions: [
    {
      type: 'dismiss',
      label: 'My number of admins is correct',
      target: null,
    },
  ],
}

export const awsIntegration: Integration = {
  id: '1',
  authType: 'oauth2',
  createdAt: '2022-01-10T19:50:06',
  icon: 'integration_aws',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ex ligula, condimentum eu aliquet ut, commodo sed risus.',
  instructions:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ex ligula, condimentum eu aliquet ut, commodo sed risus.',
  title: 'Amazon Web Services',
  status: 'pending',
  slug: 'amazon-web-services',
  monitors: [],
  reports: [],
  ...deaultIntegrationOverview,
}

export const githubIntegration: Integration = {
  id: '2',
  authType: 'oauth2',
  createdAt: '2022-01-10T19:50:06',
  icon: 'integration_github',
  title: 'Github',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ex ligula, condimentum eu aliquet ut, commodo sed risus.',
  instructions:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ex ligula, condimentum eu aliquet ut, commodo sed risus.',
  status: 'disconnected',
  slug: 'github',
  monitors: [],
  reports: [],
  ...deaultIntegrationOverview,
}

export const googleIntegration: Integration = {
  id: '3',
  authType: 'oauth2',
  createdAt: '2022-01-10T19:50:06',
  updatedAt: '2022-08-08T19:50:06',
  icon: 'integration_google',
  title: 'Google Workspace',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ex ligula, condimentum eu aliquet ut, commodo sed risus.',
  instructions:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ex ligula, condimentum eu aliquet ut, commodo sed risus.',
  status: 'active',
  slug: 'google-workspace',
  monitors: [checkOk, checkCritical, checkWarning],
  reports: [],
  ...deaultIntegrationOverview,
}

export const gustoIntegration: Integration = {
  id: '4',
  authType: 'oauth2',
  createdAt: '2022-01-10T19:50:06',
  icon: 'integration_gusto',
  title: 'Gusto',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ex ligula, condimentum eu aliquet ut, commodo sed risus.',
  instructions:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ex ligula, condimentum eu aliquet ut, commodo sed risus.',
  slug: 'gusto',
  monitors: [],
  reports: [],
  ...deaultIntegrationOverview,
}

export const quickbooksIntegration: Integration = {
  id: '5',
  authType: 'oauth2',
  createdAt: '2022-01-10T19:50:06',
  icon: 'integration_quickbooks',
  title: 'Quickbooks',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ex ligula, condimentum eu aliquet ut, commodo sed risus.',
  instructions:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ex ligula, condimentum eu aliquet ut, commodo sed risus.',
  status: 'active',
  slug: 'quickbooks',
  monitors: [checkInfo],
  reports: [],
  ...deaultIntegrationOverview,
}
