<template>
  <AtroModal size="small">
    <AtroContent class="mb-6 text-center" items="center" col>
      <AtroHeading class="text-atro-slate-purple" semibold>Change Email</AtroHeading>
      <AtroSpan class="mt-2 text-atro-gray-1">Currently: {{ user.email }}</AtroSpan>
    </AtroContent>

    <FormKit v-model="formData" type="form" @submit="updateUserEmail">
      <FormKit name="email" type="email" label="New email" validation="required|email" />

      <template #submit="{ state: { valid } }">
        <AtroButton block :is-pending="isPending" :disabled="!valid">Update Email</AtroButton>
      </template>
    </FormKit>
  </AtroModal>
</template>
<script setup lang="ts">
import { useUserEmailUpdateMutation } from '@/composables/mutations/useUserMutation'
import { Props as ModalProps } from '@/components/globals/atro/Modal.vue'

export interface Props extends ModalProps {
  user: User
}

const { user } = defineProps<Props>()

const { updateUserEmail, isLoading: isPending } = useUserEmailUpdateMutation()

const formData = $ref({
  email: '',
})
</script>
