<template>
  <div
    v-auto-animate="{ duration: 200 }"
    class="w-full border-b-2 px-4 py-6 first:pt-0 last:border-b-0 last:pb-0 sm:px-6"
  >
    <div class="grid gap-x-2 sm:gap-x-6" :style="{ gridTemplateColumns: props.colStyle }">
      <slot :is-expanded="isExpanded" />

      <slot v-if="expandToggleable" name="expandToggle">
        <AtroContent
          class="group hover:cursor-pointer"
          items="center"
          justify="end"
          @click="() => toggleExpanded()"
        >
          <AtroExpandToggle :expanded="isExpanded" :disabled="expandButtonDisabled" />
        </AtroContent>
      </slot>
    </div>

    <slot v-if="isExpanded" name="expandableContent" />
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'

export interface Props {
  columnsConfig?: ColumnConfig[]
  colStyle?: string
  expanded?: boolean
  expandButtonDisabled?: boolean
  expandToggleable?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'collapse'): void
  (e: 'expand'): void
}>()

let isExpanded = $ref(props.expanded)

const toggleExpanded = () => {
  isExpanded = props.expandToggleable ? !isExpanded : isExpanded
  isExpanded ? emit('expand') : emit('collapse')
}

watch(props, _props => {
  isExpanded = _props.expanded
})
</script>
