<template>
  <AtroPageHeading
    heading="Monitoring center"
    sub-heading="Automatically monitor for security risks in your environment"
  >
    <AtroButtonLink :to="{ name: 'integrations' }">Browse Available Integrations</AtroButtonLink>
  </AtroPageHeading>

  <AtroAsync :config="fetchState">
    <template #default>
      <MonitoredIntegrationsCountsPill
        class="mt-4 sm:mt-0"
        :total-active-checks-count="totalActiveChecksCount"
        :total-ok-count="totalOkChecksCount"
        :total-critical-alerts-count="totalCriticalAlertsCount"
        :total-info-alerts-count="totalInfoAlertsCount"
        :total-warning-alerts-count="totalWarningAlertsCount"
      />

      <AtroContent class="mt-10 sm:mt-6 w-full">
        <MonitoredIntegrationsTable :integrations="data" />
      </AtroContent>
    </template>

    <template #empty>
      <AtroEmpty heading="No integrations connected yet" icon-name="monitored_integrations_empty">
        <template #postfix>
          <AtroButtonLink variant="light" :to="{ name: 'integrations' }"
            >Browse Available Integrations</AtroButtonLink
          >
        </template>
      </AtroEmpty>
    </template>
  </AtroAsync>

  <AtroOnboardingModal
    icon-name="nav_item_monitoring_center"
    onboarding-key="seenMonitoringCenter"
    :heading="ONBOARDING_COPY.monitoringCenterModal.heading"
    :body="ONBOARDING_COPY.monitoringCenterModal.body"
  />
</template>

<script setup lang="ts">
import { ONBOARDING_COPY } from '@/lib/constants'
import useIntegrations from '@/composables/data/useIntegrations'
import MonitoredIntegrationsCountsPill from '@/components/integrations/MonitoredIntegrationsCountsPill.vue'
import MonitoredIntegrationsTable from '@/components/integrations/MonitoredIntegrationsTable.vue'

const {
  data,
  fetchState,
  meta: {
    totalActiveChecksCount,
    totalCriticalAlertsCount,
    totalInfoAlertsCount,
    totalOkChecksCount,
    totalWarningAlertsCount,
  },
} = $(useIntegrations('monitoring'))
</script>
