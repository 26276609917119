<template>
  <AtroAsync
    empty-heading="No addressed fixes at this time"
    empty-icon-name="empty_tab_happy"
    :config="fetchState"
  >
    <SecurityFixesTable view="userWrite" :security-fixes="securityFixes" expanded />
  </AtroAsync>
</template>

<script setup lang="ts">
import SecurityFixesTable from '@/components/securityFixes/SecurityFixesTable.vue'
import useSecurityFixes from '@/composables/data/useSecurityFixes'

const { fetchState, data: securityFixes } = useSecurityFixes('user', { type: 'addressed' })
</script>
