<template>
  <AtroContent class="h-full w-full flex-1" items="center" justify="center">
    <AtroContent items="center" justify="center">
      <slot>
        <AtroContent
          :class="['w-full', slots.postfix && 'mb-8']"
          items="center"
          justify="center"
          col
        >
          <AtroContent v-if="iconName" class="mb-8 w-full max-w-sm" items="center" justify="center">
            <AtroIcon class="w-full" :name="iconName" />
          </AtroContent>
          <AtroHeading class="text-atro-gray-1" semibold>{{ heading }}</AtroHeading>
          <AtroParagraph v-if="subHeading" class="mt-2 text-center text-atro-gray-1" size="small">{{
            subHeading
          }}</AtroParagraph>
        </AtroContent>
      </slot>
      <slot name="postfix" />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import { IconName } from '@/assets/icons/atro-icon.model'

export interface Props {
  heading?: string
  iconName?: IconName
  subHeading?: string
}

const { heading = 'Nothing to see here', iconName, subHeading } = defineProps<Props>()

const slots = useSlots()
</script>
