<template>
  <AtroContent
    v-if="hasCount"
    class="absolute top-0.5 right-0.5 h-4 w-4 rounded-full bg-white"
    items="center"
    justify="center"
    ><span class="text-xs font-bold">{{ count }}</span></AtroContent
  >
</template>

<script setup lang="ts">
export interface Props {
  count: string | number
}

const { count } = defineProps<Props>()

const hasCount = $computed(() => count !== undefined)
</script>
