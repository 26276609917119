<template>
  <AtroContent
    class="group w-full cursor-pointer rounded-2xl border-2 p-4 transition-colors hover:border-atro-purple sm:p-8"
    col
  >
    <AtroParagraph class="mb-2 text-atro-dark-purple">
      <AtroMarkdown :markdown="step.config.text" />
    </AtroParagraph>

    <AtroContent class="w-full" justify="between" wrap="nowrap" items="start">
      <AtroContent class="mr-8 space-x-2 place-self-center" wrap="nowrap" items="center"
        ><AtroSpan class="text-atro-gray-1" size="small" semibold
          ><AtroSpan class="text-atro-dark-purple" semibold>Answer: </AtroSpan
          >{{ aggregateAnswerText }}</AtroSpan
        ></AtroContent
      >
      <AtroButton
        class="hidden text-xs opacity-0 transition-opacity group-hover:opacity-100 sm:inline-flex"
        variant="transparent"
        icon-right="chevron_right"
        >Edit Answer</AtroButton
      >
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { TopicStep } from '@/types/topicStep'

export interface Props {
  step: TopicStep
}

const { step } = defineProps<Props>()

const aggregateAnswerText = $computed(() => {
  const selectedOptions = step.options.filter(option =>
    step.answers.find(answer => answer.optionId === option.id)
  )

  switch (step.kind) {
    case 'option':
      return selectedOptions
        .map(option => {
          return `${step.answers.find(answer => answer.optionId === option.id).value}: ${
            option.label
          }`
        })
        .join(', ')
    default:
      return selectedOptions.map(option => option.label).join(', ')
  }
})
</script>
