export const BASE_MOCK_PATH = `${import.meta.env.VITE_API_URL}/`

export const COMPANY_SIZE_OPTIONS: SelectOption[] = [
  {
    label: '1 - 5',
    value: '1..5',
  },
  {
    label: '5 - 10',
    value: '5..10',
  },
  {
    label: '10+',
    value: '10..',
  },
]

export const ROLES: Role[] = ['admin', 'member']

export const ROLE_OPTIONS = [
  {
    label: '— Select —',
    value: '-----',
    attrs: { disabled: true },
  },
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Member',
    value: 'member',
  },
]

export const SYSTEM_ADMINS = [
  'jonathan@atro.com',
  'k@atro.com',
  'kb@atro.com',
  'kasey@atro.com',
  'vw@atro.com',
  'vincent@atro.com',
  'danc@atro.com',
  'pat@atro.com',
  'marco@atro.com',
  'philipp@atro.com',
]

// COPY
export const NOTIFICATION_TOGGLE_COPY = {
  org: {
    assessments: {
      email:
        'Send automated emails reminding your team to sign up and complete assessments if they forget.',
    },
    integrations: {
      email: 'Send emails to admins when a monitored service triggers an alert.',
    },
  },
  user: {
    assessments: {
      email: 'Recieve automated email reminders about pending assessments',
    },
    integrations: {
      email: 'Recieve alert emails triggered by monitored services',
    },
  },
}

export const ONBOARDING_COPY = {
  assessmentPageHeaderAdminTooltip: {
    heading: 'Activate this assessment for your team',
    body: 'This page shows details about this assessment, scroll down to see topics included or simply click **Activate Assessment** to begin.',
  },
  assessmentPageHeaderUserTooltip: {
    heading: 'Begin answering questions',
    body: 'Click on **Start Assessment** or pick a specific topic below. Topics are collections of questions based on a theme. You complete assessments by finishing all topics.',
  },
  assessmentUserScoreTooltip: {
    heading: 'There’s more to do',
    body: 'Your responses in this assessment triggered some improvements to your security. This is great, we can always improve! Scroll down to see the fixes listed below or head over to the **My security fixes** page to address them.',
  },
  myAssessmentsInProgressTooltip: {
    heading: 'Let’s start your first assessment',
    body: 'Select **Get Started** on an assessment to get going.',
  },
  teamAssessmentsDiscoverTooltip: {
    heading: 'Let’s start by securing your remote team',
    body: 'With so many people working remotely, the security of your distributed team is a great starting point in Atro. Click **Learn More** to see details about this assessment.',
  },
  dashboardAssessmentsInProgressTooltip: {
    heading: 'Assessments in progress',
    body: 'Assessments assigned to one or more team members are visible here so you can get an overview of how your team is progressing. Select **View Progress** to see more details.',
    cta: 'Ok',
  },
  securityFixesAdminTooltip: {
    heading: 'Your team’s active security issues',
    body: 'These are the unresolved security fixes active in your environment. Click to expand a fix and see details.',
  },
  securityFixesUserTooltip: {
    heading: 'Let’s address your security issues',
    body: 'Select a status for each security fix to increase your score.',
  },
  welcomeToAtroModal: {
    heading: 'Welcome to Atro!',
    subHeading: 'Let’s take you through a quick walkthrough',
    cta: 'Continue',
    owner: {
      body: 'Atro makes security simple by guiding you through securing your organization step by step. We’ve set up a walkthrough to help you activate and assign your first assessment to get started. Follow the prompts to get going.',
    },
    admin: {
      body: 'Atro makes security simple by walking you through securing your organization step by step. As you browse through the app you’ll see popups like this that help get you familiar with how Atro works. Feel free to explore to get going!',
    },
    member: {
      body: 'Atro makes security simple by getting you secure step by step. As you browse through the app you’ll see popups like this that help get you familiar with how Atro works. Feel free to explore to get going!',
    },
  },
  assessmentUserModal: {
    heading: 'You’ve completed your first Assessment!',
    body: 'Now you can see how you scored for this assessment and security improvements you may have.',
    cta: 'Continue',
  },
  myAssessmentsModal: {
    heading: 'My assessments',
    body: 'Home base for assessments **assigned to you**. Work through them here and see scores for assessments you’ve completed.',
  },
  mySecurityFixesModal: {
    heading: 'My security fixes',
    body: 'As you complete assessments, identified security fixes will be listed here for you to address. You can learn about and fix identified issues to **raise your score**.',
  },
  teamAssessmentsModal: {
    heading: 'Assessments',
    body: 'The Assessments page is where you manage all assessments in Atro. The **Browse** tab shows you all of the assessments that you can activate and the **In Progress** and **Caught Up** tabs show you assessments that have already been activated.',
  },
  teamSecurityFixesModal: {
    heading: 'Security fixes',
    body: 'Identified security fixes will be listed here as assessments are completed so you can easily track and resolve them.',
  },
  assessmentFirstActivationModal: {
    heading: 'You’ve activated this assessment!',
    body: 'This screen shows your team’s status while the assessment is in progress. You can come back at any time to see how things are going.',
    cta: 'Done',
  },
  integrationsModal: {
    heading: 'Integrations',
    body: 'Activate and manage connections to your services. Integrations make security easier by automatically monitoring and securing your service in one place.',
  },
  monitoringCenterModal: {
    heading: 'Monitoring Center',
    body: 'Automatically monitor the security status of your services via this page. Connect up services and we’ll constantly scan and alert on any security concerns.',
  },
}
