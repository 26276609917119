<template>
  <AtroContent ref="contentEl" class="w-full overflow-hidden rounded-xl border-2" col>
    <AtroContent class="w-full border-b-2 bg-atro-off-white p-4 sm:p-6">
      <FormKit
        v-model="searchTerm"
        outer-class="w-full"
        type="search"
        placeholder="Member name or email"
        label="Search team members"
      />
      <AtroContent class="mt-6 w-full" justify="between" items="center">
        <FormKit
          v-model="isAssignedToAllUsers"
          type="checkbox"
          label="Select Everyone"
          :classes="{ label: 'font-semibold !text-base' }"
        />
        <AtroButton variant="transparent" icon-right="plus" @click="emit('inviteMembers')"
          >Invite Team Members</AtroButton
        >
      </AtroContent>
    </AtroContent>

    <FormKit
      v-if="userList.length"
      v-model="model"
      type="checkbox"
      :classes="{
        outer: 'w-full max-h-52 overflow-y-auto',
        fieldset: 'w-full',
        inner: 'items-center justify-between w-full',
        options: 'w-full p-4 sm:p-6',
      }"
      :options="
        userList.map(user => {
          return {
            user,
            attrs: { disabled: initialSelectedUserIds.includes(user.id) },
            label: null,
            help: null,
            value: user.id,
          }
        })
      "
      @input="e => emit('change', e)"
    >
      <template #prefix="{ option }">
        <AtroContent class="mr-8 w-full" items="center" wrap="nowrap">
          <AtroUserAvatar
            v-if="option.user.role !== 'invited'"
            class="mr-2 h-8 w-8 shrink-0"
            :name="option.user.display"
          />
          <div v-else class="mr-2 h-8 w-8 shrink-0 rounded-full bg-atro-gray-2"></div>
          <AtroSpan class="text-atro-dark-purple" size="small" semibold>{{
            option.user.display
          }}</AtroSpan>
          <AtroSpan v-if="option.user.role === 'invited'" class="ml-4 text-atro-gray-1" size="small"
            >Invited</AtroSpan
          >
          <AtroTooltip
            v-if="option.attrs.disabled && !isMobile"
            text="Assessments cannot be unassigned"
            :config="{
              placement: 'right',
              strategy: 'fixed',
            }"
            :tooltip-target-fn="() => getElRefFromOption(option)"
          />
        </AtroContent>
      </template>
    </FormKit>
    <AtroEmpty v-else class="py-8" />
  </AtroContent>
</template>

<script setup lang="ts">
import { Ref, ref } from 'vue'
import useIsMobile from '@/composables/useIsMobile'

export interface Props {
  selectedUserIds: string[]
  users: User[]
}

const { users, selectedUserIds = [] } = defineProps<Props>()
const emit = defineEmits<{
  (e: 'change', selectedUserIds: string[]): void
  (e: 'inviteMembers'): void
}>()

const isMobile = $(useIsMobile())
const contentEl = ref()

let model = $ref([...selectedUserIds])
let initialSelectedUserIds = $ref([...selectedUserIds])
let searchTerm = $ref('')

let isAssignedToAllUsers = $computed({
  get: () => users?.length === selectedUserIds?.length,
  set: value =>
    value
      ? (model = users.map(user => user.id))
      : (model = selectedUserIds.filter(id => initialSelectedUserIds.includes(id))),
})

const lowerCaseSearchTerm = $computed(() => searchTerm.toLowerCase())
const userList = $computed(() => {
  return searchTerm
    ? users.filter(
        user =>
          user.display.toLowerCase().includes(lowerCaseSearchTerm) ||
          user.email.includes(lowerCaseSearchTerm)
      )
    : [...users]
})

const getElRefFromOption = option => {
  // Dumb type conversions
  return ref(document.getElementById(option.attrs.id).parentElement) as unknown as Ref<Element>
}
</script>
