export default {
  team: {
    key: ['team'],
    path: 'org/team',
  },

  teamMember: {
    key: (params: { id: string }) => ['teamMember', params.id],
    path: (params: { id: string }) => `org/team/${params.id}`,
  },

  teamMemberAssessments: {
    key: (params: { id: string }) => ['teamMemberAssessments', params.id],
    path: (params: { id: string }) => `org/team/${params.id}/assessments`,
  },

  teamMemberSecurityFixes: {
    key: (params: { id: string }) => ['teamMemberSecurityFixes', params.id],
    path: (params: { id: string }) => `org/team/${params.id}/security-fixes`,
  },
}
