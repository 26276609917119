<template>
  <AtroAsync
    empty-heading="No unresolved fixes at this time"
    empty-icon-name="empty_tab_happy"
    :config="fetchState"
  >
    <template #default>
      <SecurityFixesTable
        view="adminWrite"
        :can-show-onboarding="true"
        :security-fixes="securityFixes"
      />
    </template>
  </AtroAsync>
</template>

<script setup lang="ts">
import SecurityFixesTable from '@/components/securityFixes/SecurityFixesTable.vue'
import useSecurityFixes from '@/composables/data/useSecurityFixes'

const { fetchState, data: securityFixes } = useSecurityFixes('org', { type: 'unresolved' })
</script>
