import useData from '@/composables/useData'
import { Topic } from '@/types/topic'

export default function useTopic(id) {
  const fetchState = useData<Topic>('topicsShow', { id })

  return {
    fetchState,
    data: fetchState.data,
    meta: {},
  }
}
