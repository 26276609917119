import { unref } from 'vue'
import { MaybeRef } from '@/types/utils'

export default function useMonitoringCheckMeta(_monitoringCheck: MaybeRef<MonitoringCheck>) {
  const monitoringCheck = $computed(() => unref(_monitoringCheck))
  const dismissActions = $computed(() => unref(monitoringCheck.dismissActions))
  const status = $computed(() => monitoringCheck.status)
  const isEnabled = $computed(() => status !== 'disabled')
  const isPending = $computed(() => status === 'pending')
  const dismissAction = $computed(() => dismissActions?.[0])
  const isDismissable = $computed(() => !!dismissAction)

  return $$({ dismissAction, isDismissable, isEnabled, isPending, status })
}
