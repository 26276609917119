<template>
  <AtroModal size="small" @after-close="resetModal">
    <template #default>
      <AtroContent class="w-full" items="center" col>
        <AtroIcon :class="['h-18 w-18', iconColor]" :name="iconName" />
        <AtroHeading class="mt-4" size="large" semibold>{{ _headingText }}</AtroHeading>
        <AtroParagraph class="mt-2 mb-6 text-center text-atro-gray-1">{{ _subText }}</AtroParagraph>

        <FormKit
          v-if="!isDeleted"
          id="delete-form"
          v-model="deleteForm"
          type="form"
          @submit="onSubmit"
        >
          <FormKit
            autofocus
            type="text"
            label="Type DELETE to verify"
            name="deleteConfirmField"
            placeholder="DELETE"
            validation="required|matches:DELETE"
          />
          <template #submit="{ state: { valid } }">
            <AtroButton block :is-pending="isPending" :disabled="!valid">{{
              buttonText
            }}</AtroButton>
          </template>
        </FormKit>
      </AtroContent>
    </template>

    <template v-if="isDeleted" #actions="{ close }">
      <AtroModalActions>
        <AtroButton block @click="close">Ok</AtroButton>
      </AtroModalActions>
    </template>
  </AtroModal>
</template>

<script lang="ts">
type TextConfig = {
  initial: string
  confirmed: string
}

const DEFAULT_HEADING_TEXT = { initial: 'Are you sure?', confirmed: 'Deleted!' }
const DEFAULT_SUB_TEXT = { initial: '', confirmed: '' }
</script>

<script setup lang="ts">
import { Props as ModalProps } from '@/components/globals/atro/Modal.vue'

export interface Props extends ModalProps {
  onDelete: () => boolean | Promise<boolean>
  buttonText?: string
  headingText?: TextConfig
  subText?: TextConfig
}

const {
  onDelete,
  buttonText = 'Delete',
  headingText = DEFAULT_HEADING_TEXT,
  subText = DEFAULT_SUB_TEXT,
} = defineProps<Props>()

let isDeleted = $ref<boolean>()
let isPending = $ref(false)
let deleteForm = $ref({ deleteConfirmField: '' })

const _headingText = $computed(() => (isDeleted ? headingText.confirmed : headingText.initial))
const iconColor = $computed(() => (isDeleted ? 'text-atro-highlight-green' : 'text-atro-magenta'))
const iconName = $computed(() => (isDeleted ? 'circle_check' : 'exclamation_circle'))
const _subText = $computed(() => (isDeleted ? subText.confirmed : subText.initial))

const onSubmit = async () => {
  isPending = true
  await onDelete()
  isPending = false
  isDeleted = true
}

const resetModal = () => {
  isDeleted = false
}
</script>
