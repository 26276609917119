import SignupOrgInfoCard from '@/components/signup/SignupOrgInfoCard.vue'
import SignupUserInfoCard from '@/components/signup/SignupUserInfoCard.vue'

type SignupStep = {
  component: any
  otherInputPlaceholder?: string
  props?: Record<string, unknown>
}

const userInfoStep = {
  component: SignupUserInfoCard,
} as SignupStep

const orgInfoStep = {
  component: SignupOrgInfoCard,
} as SignupStep

export const signupStepsConfig = {
  org: [userInfoStep, orgInfoStep] as SignupStep[],
  user: [userInfoStep] as SignupStep[],
}
