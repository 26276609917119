import { unref } from 'vue'
import { MaybeRef } from '@/types/utils'

export default function useSecurityFixesMeta(_securityFixes: MaybeRef<SecurityFix[]>) {
  const securityFixes = $computed(() => (unref(_securityFixes) as SecurityFix[]) || [])
  const securityFixesCount = $computed(() => securityFixes.length)
  const securityFixesNotFixedCount = $computed(
    () => securityFixes.filter(securityFix => securityFix.status !== 'fixed').length
  )
  const securityFixesUnaddressedCount = $computed(
    () => securityFixes.filter(securityFix => securityFix.status === 'unaddressed').length
  )
  const hasSecurityFixes = $computed(() => securityFixesCount > 0)
  const hasSecurityFixesNotFixed = $computed(() => securityFixesNotFixedCount > 0)
  const hasAddressedSecurityFixes = $computed(() => hasSecurityFixes && !hasSecurityFixesNotFixed)

  return $$({
    hasAddressedSecurityFixes,
    hasSecurityFixes,
    hasSecurityFixesNotFixed,
    securityFixesCount,
    securityFixesNotFixedCount,
    securityFixesUnaddressedCount,
  })
}
