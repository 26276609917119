<template>
  <component
    :is="tag"
    :class="[
      'heading',
      bold && 'font-bold',
      semibold && 'font-semibold',
      centered && 'text-center',
    ]"
    ><slot
  /></component>
</template>

<script setup lang="ts">
export interface Props {
  bold?: boolean
  centered?: boolean
  semibold?: boolean
  size?: 'xsmall' | 'small' | 'normal' | 'large' | 'larger' | 'huge'
}

const { bold, centered, semibold, size = 'normal' } = defineProps<Props>()

const tag = $computed(() => {
  switch (size) {
    case 'huge':
      return 'h1'
    case 'larger':
      return 'h2'
    case 'large':
      return 'h3'
    case 'normal':
      return 'h4'
    case 'small':
      return 'h5'
    case 'xsmall':
      return 'h6'
    default:
      return 'h1'
  }
})
</script>

<style lang="postcss" scoped>
h1 {
  @apply text-5xl;
}

h2 {
  @apply text-3xl sm:text-4xl;
}

h3 {
  @apply text-2xl sm:text-3xl;
}

h4 {
  @apply text-xl sm:text-2xl;
}

h5 {
  @apply text-lg sm:text-xl;
}

h6 {
  @apply text-lg;
}
</style>
