<template>
  <AtroContent class="mt-4 w-full space-y-14" col>
    <!-- Automated Checks -->
    <AtroContent class="w-full" col>
      <AtroSectionHeading
        heading="Automated checks"
        sub-heading="Real time security monitoring of this service"
      />

      <AtroContent v-auto-animate class="w-full divide-y-2 rounded-3xl border-2" col>
        <IntegrationMonitoringCheckItem
          v-for="monitoringCheck in sortedChecks"
          :key="monitoringCheck.id"
          :integration="integration"
          :monitoring-check="monitoringCheck"
          @action="onAction"
          @on-expand="onMonitoringCheckItemExpand"
          @on-close="onMonitoringCheckItemClose"
        />
      </AtroContent>
    </AtroContent>

    <!-- Security Info -->
    <AtroContent class="w-full" col>
      <AtroSectionHeading
        heading="Security information"
        sub-heading="Additional information about this service"
      />
      <div
        v-auto-animate
        class="w-full space-y-3 sm:grid sm:grid-cols-integration-monitoring-reports sm:gap-4 sm:space-y-0"
      >
        <IntegrationMonitoringReportCard
          v-for="report in integration.reports"
          :key="report.id"
          :report="report"
          @action="onAction"
        />
      </div>
    </AtroContent>

    <IntegrationMonitoringReportModal
      :action="triggeredAction"
      :is-open="isReportModalOpen"
      :report="reportToView"
      @close="isReportModalOpen = false"
    />
  </AtroContent>
</template>

<script setup lang="ts">
import IntegrationMonitoringCheckItem from '@/components/integration/monitoring/IntegrationMonitoringCheckItem.vue'
import IntegrationMonitoringReportCard from '@/components/integration/monitoring/IntegrationMonitoringReportCard.vue'
import IntegrationMonitoringReportModal from '@/components/integration/monitoring/IntegrationMonitoringReportModal.vue'
import useIntegrationMeta from '@/composables/meta/useIntegrationMeta'
import { DynamicAction } from '@/types/dynamicAction'
import { Integration } from '@/types/integration'
import { IntegrationReport } from '@/types/integrationReport'

export interface Props {
  integration: Integration
}

const { integration } = defineProps<Props>()

const { sortedChecks } = $(useIntegrationMeta($$(integration)))

const onAction = (action: DynamicAction) => {
  const report = integration.reports.find(report => report.slug === action.target)
  switch (action.type) {
    case 'show_report':
      showReportModal(action, report)
      break
    default:
      break
  }
}

let isReportModalOpen = $ref(false)
let reportToView = $ref<IntegrationReport>()
let triggeredAction = $ref<DynamicAction>()
const showReportModal = (action: DynamicAction, report: IntegrationReport) => {
  triggeredAction = action
  reportToView = report
  isReportModalOpen = true
}

let closeOtherExpandedItem = $ref<() => void>(null)
const onMonitoringCheckItemExpand = (close: () => void) => {
  if (closeOtherExpandedItem !== null) {
    closeOtherExpandedItem()
  }
  closeOtherExpandedItem = close
}

const onMonitoringCheckItemClose = () => {
  closeOtherExpandedItem = null
}
</script>
