<template>
  <tr>
    <td class="whitespace-nowrap py-3 pl-4 text-sm sm:pr-12 sm:text-base">
      <AtroContent wrap="nowrap" items="center">
        <AtroIcon v-if="label.icon" class="mr-2 w-4" :name="label.icon"></AtroIcon>
        <span class="text-lg sm:text-base">{{ label.label }}</span>
      </AtroContent>
    </td>
    <td v-if="variant === 'select'" class="!text-right">
      <select
        class="w-auto rounded-xl border-0 bg-transparent pr-10 text-right text-lg sm:text-base"
        :value="optionSelected"
        @change="(e: any) => onChange(e.target.value, label)"
      >
        <option disabled value="">Select</option>
        <option v-for="option in options" :key="option" :value="option">
          {{ option }}
        </option>
      </select>
    </td>
    <td v-for="option in options" v-else :key="option">
      <input
        type="radio"
        :class="[
          'cursor-pointer border-2 bg-transparent p-2 text-white outline-none transition-colors',
          hasOptionSelected ? 'border-white/25' : 'border-white',
        ]"
        :name="label.id"
        :checked="optionSelected === option"
        @change="e => onChange(option, label)"
      />
    </td>
  </tr>
  <tr class="h-4"></tr>
</template>

<script setup lang="ts">
import { IconName } from '@/assets/icons/atro-icon.model'

export type Option = {
  id: string
  label: string
  icon?: IconName
}

export type SelectedOptions = Record<string, string>

interface Props {
  label: Option
  options: string[]
  optionSelected?: string
  variant?: 'select' | 'options'
}

const { label = '', options = [], optionSelected, variant = 'options' } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'change', selectedOption: SelectedOptions): void
}>()

const hasOptionSelected = $computed(() => !!optionSelected)

const onChange = (value, option: Option) => emit('change', { [option.id]: value })
</script>

<style lang="postcss" scoped>
td {
  @apply bg-white/10 text-center first:rounded-tl-xl first:rounded-bl-xl last:rounded-tr-xl last:rounded-br-xl;
}
</style>
