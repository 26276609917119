<template>
  <AtroMenu align="topRight" :items="useMenuItems">
    <template #menuButton="{ open }">
      <MenuButton :class="['w-full hover:text-white', open ? 'text-white' : 'text-white/75']">
        <AtroContent items="center">
          <AtroUserAvatar class="h-12 w-12" :name="user.display" />
          <AtroSpan v-if="expanded" class="ml-2" size="xsmall">Settings</AtroSpan>
        </AtroContent>
      </MenuButton>
    </template>

    <template #menuItemsPrefix>
      <AtroContent
        class="w-full whitespace-nowrap border-b-2 border-atro-gray-2 px-6 py-4 pt-6"
        wrap="nowrap"
      >
        <AtroContent class="w-full" col>
          <AtroHeading class="mb-2 text-atro-main" size="small" bold>{{
            user.display
          }}</AtroHeading>
          <AtroContent class="w-full" justify="between" items="center" wrap="nowrap">
            <AtroBadge class="capitalize" :label="user.role" variant="neutral" />
            <AtroContent v-if="shareUrl" class="ml-2 cursor-pointer" wrap="nowrap" @click="copy()">
              <AtroSpan size="xxsmall" semibold>{{ shareUrlText }}</AtroSpan>
              <AtroIcon class="ml-2 h-4 w-4" name="link_regular" />
            </AtroContent>
          </AtroContent>
        </AtroContent>
      </AtroContent>
    </template>
  </AtroMenu>
</template>

<script setup lang="ts">
import { useClipboard } from '@vueuse/core'
import useCurrentUser from '@/composables/data/useCurrentUser'
import useOrgInviteUrl from '@/composables/useOrgInviteUrl'
import useUserMenuItems from '@/composables/useUserMenuItems'

export interface Props {
  expanded?: boolean
}

defineProps<Props>()

const { data: user } = $(useCurrentUser())

const shareUrl = useOrgInviteUrl()
const useMenuItems = $(useUserMenuItems())

const source = $ref(shareUrl)
const { copy, copied } = $(useClipboard({ source }))

const shareUrlText = $computed(() => (copied ? 'Copied!' : 'Copy Share Link'))
</script>
