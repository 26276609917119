<template>
  <RouterLink class="flex" :to="assessmentLinkTo">
    <AtroCard
      ref="tooltipTarget"
      :class="[
        'h-[var(--assessment-card-height)]',
        disabled && 'pointer-events-none opacity-40',
        responsive
          ? 'w-full sm:w-[var(--assessment-card-width)]'
          : 'w-[var(--assessment-card-width)]',
      ]"
      hoverable
    >
      <AtroContent class="mb-4 w-full" justify="between" items="center">
        <AtroIconSquare :icon-name="assessment.icon" />
        <AtroCircleGauge
          v-if="isStarted"
          :count="assessment.score?.atroScore"
          :fill-percent="assessment.score?.percent"
        />
      </AtroContent>

      <AtroContent class="flex-1" col>
        <AtroContent class="w-full flex-1" col>
          <AtroHeading class="text-atro-dark-purple group-hover:text-atro-purple" size="small" semibold>{{
            assessment.title
          }}</AtroHeading>
          <AtroParagraph class="mt-2 text-atro-gray-1 line-clamp-2" size="small">{{
            assessment.description
          }}</AtroParagraph>

          <AssessmentCardLineItems v-if="!isStarted" class="mt-6" :assessment="assessment" />
        </AtroContent>

        <div v-if="isStarted" class="mb-8 w-full">
          <AtroSpan class="text-atro-gray-1" size="small" semibold>{{
            usersCompleteText
          }}</AtroSpan>
          <AtroProgress class="mt-1 w-[174px]" size="small" :progress="assessment.progress" />
          <SecurityFixesStateLabel
            class="mt-2"
            text-style="long"
            :count="assessment.securityFixCount"
          />
        </div>

        <AtroButtonLink class="place-self-end" :to="assessmentLinkTo">{{
          buttonText
        }}</AtroButtonLink>
      </AtroContent>
      <slot name="tooltip" :target="tooltipTarget" />
    </AtroCard>
  </RouterLink>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import useAssessmentMeta from '@/composables/meta/useAssessmentMeta'
import AssessmentCardLineItems from '@/components/assessment/AssessmentCardLineItems.vue'
import SecurityFixesStateLabel from '@/components/securityFixes/SecurityFixesStateLabel.vue'
import { Assessment } from '@/types/assessment'

export interface Props {
  assessment: Assessment
  disabled?: boolean
  responsive?: boolean
}

const { assessment, disabled = false, responsive = false } = defineProps<Props>()
const { isStarted, usersCompleteText } = $(useAssessmentMeta($$(assessment)))

const tooltipTarget = ref<Element>()

const buttonText = $computed(() => {
  switch (assessment.status) {
    case 'inProgress':
      return 'View Progress'
    case 'caughtUp':
      return 'View Results'
    default:
      return 'Learn More'
  }
})

const assessmentLinkTo = $computed(() => {
  return isStarted
    ? { name: 'assessmentTeam', params: { id: assessment.id } }
    : { name: 'assessmentDiscover', params: { id: assessment.id } }
})
</script>
