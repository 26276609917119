import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'
import { user1, user2 } from '@/data/user'
import { inProgressWelcomeAssessment, caughtUpRemoteWorkAssessment } from '@/data/assessments'
import { ignoredUserSecurityFix, fixedUserSecurityFix } from '@/data/securityFixes'

export const mockPaths = {
  team: 'org/team',
  teamMember: 'org/team/:id',
  teamMemberAssessments: 'org/team/:id/assessments',
  teamMemberSecurityFixes: 'org/team/:id/security-fixes',
}

const users = [user1, user2]

const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  // GET: SHOW Org Team
  rest.get(getPath('team'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(users))
  }),

  // GET: Team member
  rest.get(getPath('teamMember'), (req, res, ctx) => {
    const response = users.find(user => user.id === req.params.id)
    return res(ctx.delay(), ctx.json(response))
  }),

  // PATCH: Team member
  rest.patch(getPath('teamMember'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),

  // DELETE: Team member
  rest.delete(getPath('teamMember'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),

  // GET: Team member assessments
  rest.get(getPath('teamMemberAssessments'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json([inProgressWelcomeAssessment, caughtUpRemoteWorkAssessment]))
  }),

  // GET: Team member security fixes
  rest.get(getPath('teamMemberSecurityFixes'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json([ignoredUserSecurityFix, fixedUserSecurityFix]))
  }),
]
