<template>
  <AtroContent class="w-full" items="center" justify="between" wrap="nowrap" :col="isMobile">
    <AtroContent class="w-full">
      <AtroContent class="w-full" justify="between">
        <AtroHeading class="mb-1 text-atro-dark-purple" size="small" semibold>{{
          topic.title
        }}</AtroHeading>
        <TopicLineItems v-if="showTopicLineItemsTop" :topic="topic" />
      </AtroContent>

      <AtroParagraph class="mt-2 text-atro-gray-1 sm:mt-0" size="small">{{
        topic.description
      }}</AtroParagraph>
      <TopicLineItems v-if="showTopicLineItemsBottom" class="mt-4" :topic="topic" />

      <AtroContent
        v-if="view === 'user'"
        class="mt-4 w-full space-y-4 sm:mt-3 sm:space-y-0 sm:space-x-4"
        items="center"
      >
        <TopicLineItems :topic="topic" />
        <AtroProgress
          class="sm:max-w-[218px]"
          size="small"
          :progress="topic.progress"
          show-postfix-percentage
        />
      </AtroContent>
    </AtroContent>

    <RouterLink v-if="view === 'user'" v-slot="{ navigate }" :to="topicTakerRoute" custom>
      <AtroButton
        class="mt-6 min-w-[165px] text-sm sm:mt-0 sm:ml-6"
        variant="light"
        block-when-mobile
        :disabled="isCompleted"
        :icon-right="topicCTAButtonIconName"
        @click="navigate"
        >{{ topicCTAButtonText }}</AtroButton
      >
    </RouterLink>
  </AtroContent>
</template>

<script setup lang="ts">
import { getTopicTakerRoute } from '@/utils/routes'
import useIsMobile from '@/composables/useIsMobile'
import useTopicMeta from '@/composables/meta/useTopicMeta'
import TopicLineItems from '@/components/topic/TopicLineItems.vue'
import { Assessment } from '@/types/assessment'
import { Topic } from '@/types/topic'
import { IconName } from '@/assets/icons/atro-icon.model'

export interface Props {
  assessment?: Assessment
  topic: Topic
  view: 'admin' | 'user'
}

const { assessment, topic, view } = defineProps<Props>()

const isMobile = $(useIsMobile())
const { isCompleted } = useTopicMeta($$(topic))

const showTopicLineItemsTop = $computed(() => view === 'admin' && !isMobile)
const showTopicLineItemsBottom = $computed(() => view === 'admin' && isMobile)

const topicCTAButtonIconName = $computed<IconName>(() => {
  switch (topic.state) {
    case 'initial':
      return 'plus'
    case 'started':
      return 'chevron_right'
    case 'completed':
      return 'check'
    default:
      return null
  }
})

const topicCTAButtonText = $computed(() => {
  switch (topic.state) {
    case 'initial':
      return 'Get Started'
    case 'started':
      return 'Continue'
    case 'completed':
      return 'Completed'
    default:
      return ''
  }
})

const topicTakerRoute = $computed(() => {
  return getTopicTakerRoute(topic, assessment)
})
</script>
