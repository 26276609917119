<template>
  <AtroContent class="w-full" items="center" wrap="nowrap">
    <div class="w-full">
      <svg :class="['w-full', `${size}-bar`, variant]">
        <rect class="background" width="100%" height="100%" :rx="rounding" />
        <rect ref="progressBarEl" class="progress" height="100%" :rx="rounding" />
      </svg>
    </div>

    <AtroSpan v-if="showPostfixPercentage" class="ml-4 text-atro-gray-1" size="small" semibold
      >{{ percent }}%</AtroSpan
    >
  </AtroContent>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useMotion } from '@vueuse/motion'
import usePercent from '@/composables/usePercent'

interface Props {
  animate?: boolean
  percentComplete?: number
  progress?: Progress
  showPostfixPercentage?: boolean
  size?: 'small' | 'normal'
  variant?: 'default' | 'topic-taker'
}

const {
  animate = true,
  percentComplete,
  progress,
  showPostfixPercentage,
  size = 'normal',
  variant = 'default',
} = defineProps<Props>()

const progressBarEl = ref<HTMLElement>()

const percent = $computed(() =>
  percentComplete ? percentComplete : usePercent(progress?.numerator, progress?.denominator)
)

const rounding = $computed(() => (size === 'normal' ? '7px' : '4px'))

const transition = {
  delay: animate ? 300 : 0,
  duration: animate ? percent * 8 + 350 : 0,
  ease: 'easeOut',
}

const motionInstance = useMotion(progressBarEl, {
  initial: { width: '0%' },
  enter: { width: `${percent}%`, transition },
})

watch($$(percent), () => {
  motionInstance.apply({
    width: `${percent}%`,
    transition: {
      duration: 300,
      ease: 'easeOut',
    },
  })
})
</script>

<style lang="postcss" scoped>
/* Sizes */
.small-bar {
  @apply h-2;
}

.normal-bar {
  @apply h-3;
}

/* Variants */
.default .background {
  @apply fill-atro-gray-3;
}

.default .progress {
  transition: width;
  @apply fill-atro-bright-purple;
}

.topic-taker .background {
  @apply fill-white/25;
}

.topic-taker .progress {
  @apply fill-white;
}
</style>
