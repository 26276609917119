import useData from '@/composables/useData'

export function useOrgScore() {
  const fetchState = useData<Score>('orgScore')

  return {
    fetchState,
    data: fetchState.data,
    meta: {},
  }
}
