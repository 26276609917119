import { Assessment } from '@/types/assessment'
import { Topic } from '@/types/topic'

export const getTopicTakerRoute = (topic: Topic, assessment?: Assessment) => {
  return {
    name: 'topicTaker',
    params: { id: topic.id },
    query: { ...(assessment && { assessmentId: assessment.id, step: topic.currentStepId }) },
  }
}
