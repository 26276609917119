<template>
  <Switch
    v-model="enabled"
    class="relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
    :class="[context.classes.switch, enabled ? 'bg-atro-purple' : 'bg-atro-purple-50']"
  >
    <span class="sr-only">Use setting</span>
    <span
      :class="[
        'text-white text-xs font-semibold absolute -top-[2px]',
        enabled ? 'left-1' : 'right-1',
      ]"
      >{{ enabled ? 'On' : 'Off' }}</span
    >
    <span
      aria-hidden="true"
      :class="enabled ? 'translate-x-6' : 'translate-x-0'"
      class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
    />
  </Switch>
</template>

<script setup lang="ts">
import { watch } from 'vue'

export interface Props {
  context: any
}

const { context } = defineProps<Props>()
const enabled = $ref(context._value)

watch($$(enabled), () => {
  context.node.input(enabled)
})
</script>
