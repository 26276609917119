<template>
  <AtroContent col justify="center">
    <AtroHeading centered class="mb-6 text-center" size="larger">
      <AtroMarkdown :markdown="config.text" />
    </AtroHeading>

    <AtroOptionsTable
      :labels="config.labelOptions"
      :options="options"
      :selected="selected"
      @change="handledSelected"
    />
  </AtroContent>
</template>

<script lang="ts">
interface StepOptionsTableOptions {
  id: string
  createdAt: string
  updatedAt: string
  exclusive: boolean
  label: string
  position: number
  score: Score
  icon?: IconName
}

interface StepOptionsTableConfig extends BaseStepConfig {
  labelOptions: string[]
}
</script>

<script setup lang="ts">
import { IconName } from '@/assets/icons/atro-icon.model'
import { BaseStep, BaseStepConfig } from '@/types/topicStep'
import { SelectedOptions } from '@/components/globals/atro/OptionsTableRow.vue'

export interface StepOptionsTable extends BaseStep {
  kind: 'option'
  config: StepOptionsTableConfig
  options: StepOptionsTableOptions[]
  answers?: Answer[]
}

const { answers = [], config, options } = defineProps<StepOptionsTable>()

const emit = defineEmits<{
  (e: 'addAnswer', answers: Answer[]): void
}>()

const answerObj = {}
answers.forEach(answer => {
  answerObj[answer.optionId] = answer.value
})

let selected = $ref(answerObj)

const handledSelected = (selection: SelectedOptions) => {
  const answers = []
  selected = selection

  for (const [key, value] of Object.entries(selection)) {
    answers.push({ optionId: key, value: value })
  }

  emit('addAnswer', answers)
}
</script>
