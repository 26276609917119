<template>
  <MobileScoreInline
    v-if="isMobile"
    heading="My Score"
    sub-heading="Individual security level"
    :score="user.score"
  />

  <AtroPageHeading heading="My assessments" sub-heading="Learn about your security risks" />

  <AtroTabGroup :tab-configs="tabConfigs" />

  <AtroOnboardingModal
    icon-name="nav_item_my_assessments"
    onboarding-key="seenMyAssessments"
    :heading="ONBOARDING_COPY.myAssessmentsModal.heading"
    :body="ONBOARDING_COPY.myAssessmentsModal.body"
  />
</template>

<script setup lang="ts">
import { ONBOARDING_COPY } from '@/lib/constants'
import useIsMobile from '@/composables/useIsMobile'
import useAssessments from '@/composables/data/useAssessments'
import useCurrentUser from '@/composables/data/useCurrentUser'
import AssessmentsMyCaughtUp from '@/components/assessments/my/AssessmentsMyCaughtUp.vue'
import AssessmentsMyInProgress from '@/components/assessments/my/AssessmentsMyInProgress.vue'
import MobileScoreInline from '@/components/mobile/MobileScoreInline.vue'

const isMobile = useIsMobile()
const { data: user } = useCurrentUser()

const { data: assessmentsInProgress } = $(useAssessments('user', 'in-progress'))
const { data: assessmentsCaughtUp } = $(useAssessments('user', 'caught-up'))

const tabConfigs = $computed(() => [
  {
    title: 'In Progress',
    count: assessmentsInProgress?.length,
    component: AssessmentsMyInProgress,
  },
  {
    title: 'Completed',
    count: assessmentsCaughtUp?.length,
    deepLink: true,
    component: AssessmentsMyCaughtUp,
  },
])
</script>
