export const scoreZero: Score = {
  atroScore: 0,
  percent: 0,
  riskText: 'Critical',
}

export const scoreHalf: Score = {
  atroScore: 500,
  percent: 50,
  riskText: 'Good',
}

export const scoreFull: Score = {
  atroScore: 1000,
  percent: 100,
  riskText: 'Good',
}
