import useData from '@/composables/useData'
import useAssessmentsMeta from '@/composables/meta/useAssessmentsMeta'
import { ResourcePath } from '@/utils/resourcePaths'
import { Assessment } from '@/types/assessment'

export default function useAssessments(
  name: 'discover' | 'team' | 'user',
  type?: 'caught-up' | 'in-progress'
) {
  const resourcePathMap = {
    discover: 'assessmentsDiscover',
    team: 'assessmentsTeam',
    user: 'assessmentsUser',
  }
  const params = { ...(type && { type }) }
  const fetchState = useData<Assessment[]>(resourcePathMap[name] as ResourcePath, params)
  const meta = useAssessmentsMeta(fetchState.data)

  return {
    fetchState,
    meta,
    data: fetchState.data,
  }
}
