import useData from '@/composables/useData'
import useSecurityFixesMeta from '@/composables/meta/useSecurityFixesMeta'

export default function useTeamMemberSecurityFixes(id: string) {
  const fetchState = useData<SecurityFix[]>('teamMemberSecurityFixes', { id })
  const meta = useSecurityFixesMeta(fetchState.data)

  return {
    fetchState,
    meta,
    data: fetchState.data,
  }
}
