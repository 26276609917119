import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

// solid icons
import {
  faArrowLeft,
  faArrowLeftLong,
  faArrowUp,
  faArrowUpLong,
  faArrowUpRightFromSquare,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRightLong,
  faArrowDown,
  faArrowDownLong,
  faClock,
  faChairOffice,
  faChartLine,
  faChartPie,
  faCheck,
  faChevronLeft,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faCloud,
  faHouse,
  faLaptop,
  faLayerGroup,
  faLightbulb,
  faListCheck,
  faMinus,
  faPlus,
  faSpinner,
  faUser,
  faUsers,
  faUserGroup,
  faWifi,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons'

// regular icons
import {
  faCirclePlus as farCirclePlus,
  faUser as farUser,
} from '@fortawesome/pro-regular-svg-icons'

// light icons
import { faMobileButton as falMobileButton } from '@fortawesome/pro-light-svg-icons'

// duotone icons
import {
  faBringForward as fadBringForward,
  faBus as fadBus,
  faCar as fadCar,
  faChairOffice as fadChairOffice,
  faDesktop as fadDesktop,
  faForkKnife as fadForkKnife,
  faGears as fadGears,
  faHandWave as fadHandWave,
  faHotel as fadHotel,
  faHouse as fadHouse,
  faHouseLaptop as fadHouseLaptop,
  faHouseSignal as fadHouseSignal,
  faLampDesk as fadLampDesk,
  faLaptop as fadLaptop,
  faLaptopMobile as fadLaptopMobile,
  faMobileButton as fadMobileButton,
  faMugSaucer as fadMugSaucer,
  faPlane as fadPlane,
  faRadar as fadRadar,
  faTaxi as fadTaxi,
  faTowerControl as fadTowerControl,
  faTrain as fadTrain,
} from '@fortawesome/pro-duotone-svg-icons'

export const icons = {
  // solid
  faArrowLeft,
  faArrowLeftLong,
  faArrowUp,
  faArrowUpLong,
  faArrowUpRightFromSquare,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRightLong,
  faArrowDown,
  faArrowDownLong,
  faClock,
  faChairOffice,
  faChartLine,
  faChartPie,
  faCheck,
  faChevronLeft,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faCloud,
  faHouse,
  faLayerGroup,
  faLightbulb,
  faListCheck,
  faMinus,
  faPlus,
  faSpinner,
  faUser,
  faUsers,
  faUserGroup,
  faWifi,
  faXmark,
  // regular
  farCirclePlus,
  farUser,
  // light
  falMobileButton,
  // durtones
  fadBringForward,
  fadBus,
  fadCar,
  fadChairOffice,
  fadDesktop,
  fadForkKnife,
  fadGears,
  fadHandWave,
  fadHotel,
  fadHouse,
  fadHouseLaptop,
  fadHouseSignal,
  fadLampDesk,
  fadLaptop,
  fadLaptopMobile,
  faLaptop,
  fadMobileButton,
  fadMugSaucer,
  fadPlane,
  fadRadar,
  fadTaxi,
  fadTowerControl,
  fadTrain,
}

library.add(icons)

export default FontAwesomeIcon
