<template>
  <AtroCard variant="signup">
    <AtroContent class="mb-6" items="center" justify="center">
      <AtroIconSquare class="text-atro-purple" icon-name="users" circle />
    </AtroContent>
    <AtroHeading class="mb-6 text-center text-atro-slate-purple" size="large" semibold
      >Tell us about your organization</AtroHeading
    >

    <FormKit v-model="org" class="space-y-4" type="form" @submit="onSubmit">
      <FormKit
        autofocus
        type="text"
        label="Name"
        placeholder="Organization name"
        name="name"
        validation="required|length:2,50"
      />
      <FormKit
        type="select"
        label="Size"
        name="size"
        placeholder="Number of workers"
        validation="required"
        :options="COMPANY_SIZE_OPTIONS"
      />
      <template #submit="{ state: { valid } }">
        <AtroButton block icon-right="chevron_right" :is-pending="isPending" :disabled="!valid"
          >Continue</AtroButton
        >
      </template>
    </FormKit>
  </AtroCard>
</template>

<script setup lang="ts">
import { COMPANY_SIZE_OPTIONS } from '@/lib/constants'
import useFormSubmit from '@/composables/useFormSubmit'

interface Props {
  onContinue: ({ org }: { org: SignupOrg }) => null
}

const { onContinue } = defineProps<Props>()

const org = $ref<SignupOrg>({
  name: '',
  size: '',
})

const { isPending, onSubmit } = useFormSubmit(() => onContinue({ org }))
</script>
