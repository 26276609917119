<template>
  <AtroCard class="w-full bg-atro-gray-4 !p-10">
    <AtroContent class="mx-auto w-full max-w-[642px] space-y-6 text-center" items="center" col>
      <AtroContent class="w-full" items="center" col>
        <AtroHeading class="text-atro-dark-purple" size="large" semibold
          >Here's how you did</AtroHeading
        >
        <AtroHeading class="text-atro-gray-1" size="small"
          >Based on your answers we’ve calculated your score</AtroHeading
        >
      </AtroContent>

      <AtroScoreBar :score="assessment.score" />

      <AtroParagraph class="text-atro-gray-1" size="small">
        <AtroMarkdown :markdown="helpText" />
      </AtroParagraph>

      <AtroButtonLink ref="tooltipTarget" :to="{ name: ctaRouteName }" block-when-mobile>{{
        ctaText
      }}</AtroButtonLink>

      <AtroOnboardingTooltip
        v-if="isOnboarding && !meta.hasAddressedSecurityFixes"
        :heading="ONBOARDING_COPY.assessmentUserScoreTooltip.heading"
        :body="ONBOARDING_COPY.assessmentUserScoreTooltip.body"
        :offset-skid="-28"
        :target="tooltipTarget"
        :dependent-keys="['seenApp', 'completedFirstAssessment']"
      />
    </AtroContent>
  </AtroCard>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { ONBOARDING_COPY } from '@/lib/constants'
import useOnboarding from '@/composables/useOnboarding'
import useSecurityFixes from '@/composables/data/useSecurityFixes'
import { Assessment } from '@/types/assessment'

export interface Props {
  assessment: Assessment
}

const { assessment } = defineProps<Props>()

const { meta } = useSecurityFixes('assessmentUser', { id: assessment.id })
const { isOnboarding } = $(useOnboarding('addressedSecurityFix'))

const tooltipTarget = ref<Element>()

const ctaRouteName = $computed(() =>
  meta.hasSecurityFixesNotFixed.value ? 'mySecurityFixes' : 'myAssessments'
)
const ctaText = $computed(() =>
  meta.hasSecurityFixesNotFixed.value ? 'Improve My Score' : 'My Assessments'
)

const helpText = $computed(() =>
  meta.hasSecurityFixesNotFixed.value
    ? 'Great job! Your answers uncovered security fixes to address. Continue to **My security fixes** to resolve them and improve your score.'
    : 'Great job! Visit **My assessments** to continue identifying security risks'
)
</script>
