import { computed } from 'vue'
import useData from '@/composables/useData'

export default function useAssessmentTeamMemberStatus(assessmentId?: string) {
  if (!assessmentId) return { data: [], fetchState: null }
  const fetchState = useData<User[]>('assessmentTeamMembersStatus', { assessmentId })
  const data = fetchState.data

  fetchState.data = computed(() => data.value?.filter(user => user.role !== 'invited'))

  return {
    fetchState,
    data: fetchState.data,
    meta: {},
  }
}
