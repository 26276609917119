import useData from '@/composables/useData'

export default function useActivity() {
  const fetchState = useData<Activity[]>('activity')

  return {
    fetchState,
    data: fetchState.data,
    meta: {},
  }
}
