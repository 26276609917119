const baseUserSecurityFix = {
  createdAt: '2022-01-05T19:50:06',
  assessmentTitle: 'Welcome to Atro',
  description:
    'A VPN allows you to ensure that your device’s internet traffic is encrypted in transit and that your identity is masked. A VPN is a very effective way of eliminating the top network security issues associated with remote work.',
  resolvedAt: null,
  riskLevel: 1,
  title: 'Use a VPN',
  userName: 'John Doe',
}

const baseAdminSecurityFix = {
  createdAt: '2022-01-05T19:50:06',
  assessmentTitle: 'Welcome to Atro',
  description:
    'A VPN allows you to ensure that your device’s internet traffic is encrypted in transit and that your identity is masked. A VPN is a very effective way of eliminating the top network security issues associated with remote work.',
  resolved: '',
  riskLevel: 1,
  title: 'Use a VPN',
  userStatuses: [],
}

export const unaddressedUserSecurityFix: SecurityFix = {
  ...baseUserSecurityFix,
  id: '1',
  status: 'unaddressed',
}

export const ignoredUserSecurityFix: SecurityFix = {
  ...baseUserSecurityFix,
  id: '2',
  status: 'ignored',
}

export const cantFixUserSecurityFix: SecurityFix = {
  ...baseUserSecurityFix,
  id: '3',
  status: 'cantFix',
}

export const fixedUserSecurityFix: SecurityFix = {
  ...baseUserSecurityFix,
  id: '3',
  status: 'fixed',
}

export const unresolvedAdminSecurityFix: SecurityFix = {
  ...baseAdminSecurityFix,
  id: '1',
  resolved: '0/2',
  status: 'unaddressed',
  userStatuses: [unaddressedUserSecurityFix, cantFixUserSecurityFix],
}

export const resolvedAdminSecurityFix: SecurityFix = {
  ...baseAdminSecurityFix,
  id: '2',
  status: 'fixed',
  userStatuses: [fixedUserSecurityFix],
}
