export const pendingInvite = {
  id: '1',
  createdAt: '2022-01-05T19:50:06',
  updatedAt: '2022-01-05T19:50:06',
  active: true,
  email: 'testuser@example.com',
  orgId: '1',
  role: 'member',
} as Invite

export const acceptedInvite = {
  id: '2',
  createdAt: '2022-01-07T19:50:06',
  updatedAt: '2022-01-07T19:50:06',
  active: false,
  email: 'testuser2@example.com',
  orgId: '1',
  role: 'member',
} as Invite
