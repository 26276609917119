<template>
  <RouterView v-slot="{ Component }">
    <template v-if="Component">
      <suspense timeout="0">
        <component :is="layout">
          <component
            :is="Component"
            :key="route.meta.forceUpdateOnPathChange ? route.name : null"
          />
          <router-view name="topicTaker" />
        </component>

        <template #fallback>
          <AtroLoading />
        </template>
      </suspense>
    </template>
  </RouterView>

  <AtroError v-if="appFailedToLoad" text="App failed to load..." />
  <AtroLoading v-if="!appLoaded" />
</template>

<script setup lang="ts">
import { onErrorCaptured, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'
import { initializeTrackers } from '@/lib/trackers'
import { SYSTEM_ADMINS } from '@/lib/constants'
import authGuard, { AuthGuardConfig } from '@/utils/routeGuards/authGuard'
import { auth0 } from '@/plugins/auth0'
import { dashboardAdminRoute, myAssessmentsRoute } from '@/router/dashboardRoutes'
import useAnaytics from '@/composables/useAnalytics'
import useDemoMode from '@/composables/useDemoMode'
import TopicTakerPageAsModal from '@/pages/topics/TopicTakerPageAsModal.vue'

const route = useRoute()
const router = useRouter()
const layout = $computed(() => (route.meta.layout || 'Dashboad') + 'Layout')
const queryClient = useQueryClient()
const analytics = $(useAnaytics())
const { enableDemoMode, isDemoMode } = $(useDemoMode())
let appLoaded = $ref(false)
let appFailedToLoad = $ref(false)

watch(
  router.currentRoute,
  () => {
    if (window.location.search.includes('demo=true')) {
      enableDemoMode()
    }
    setTimeout(() => {
      if (
        !isDemoMode &&
        !window.location.search.includes('demo=true') &&
        !SYSTEM_ADMINS.includes(auth0.user.value?.email)
      ) {
        initializeTrackers()
      }
    }, 1000)
  },
  { immediate: true }
)

onErrorCaptured((e: any) => {
  if (e?.type === 'dataBootstrapException') appFailedToLoad = true
  return true
})

router.beforeEach(async (to, from, next) => {
  if (!to.query.demo && isDemoMode) {
    // Append demo to all transitions when in demo mode
    next({ ...to, query: { ...to.query, demo: 'true' } })
  } else {
    next()
  }
})

router.beforeResolve(async (to, from) => {
  if (to.meta.requiresAuth !== undefined) {
    const validOrRedirectTo = await authGuard(
      to,
      from,
      to.meta.requiresAuth as AuthGuardConfig,
      queryClient
    )
    if (validOrRedirectTo !== true) return validOrRedirectTo
  }
  const currentUser = queryClient.getQueryData<User>(['currentUser'])

  // This dynamically adds a child route for the series modal so
  // it can be rendered on top of on page at any time
  if (!['404', 'topicTaker'].includes(to.name as string)) {
    router.hasRoute('topicTaker') && router.removeRoute('topicTaker')
    router.addRoute(to.name, {
      path: '/topics/:id',
      name: 'topicTaker',
      components: { topicTaker: TopicTakerPageAsModal },
      // we need to retain the pages layout if/when we do end up opening the series modal
      meta: { layout: to.meta.layout, returnTo: to, requiresAuth: true, title: 'Topic Taker' },
    })
  }

  // dynamically set "home" page based on user role
  if (router.hasRoute('initial') && currentUser) {
    // home page for admins is dashboard and my assessments for users
    const homeRoute = currentUser.role === 'admin' ? dashboardAdminRoute : myAssessmentsRoute
    router.removeRoute('initial')
    router.addRoute({ ...homeRoute, path: '/', name: 'home' })
    if (to.name === 'initial') {
      router.replace({ ...to, name: 'home' })
    }
  }

  return true
})

router.afterEach(to => {
  appLoaded = true
  analytics?.page(to.name as string)
  document.title = to.meta.title ? `Atro | ${to.meta.title}` : 'Atro'
  return true
})
</script>
