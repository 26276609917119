<template>
  <AtroContent class="shrink-0 space-x-6">
    <AtroLineItem icon-name="clock_solid" :text="topic.duration" />
    <AtroLineItem icon-name="question_circle" :text="stepCountText" />
  </AtroContent>
</template>
<script setup lang="ts">
import useTopicMeta from '@/composables/meta/useTopicMeta'
import { Topic } from '@/types/topic'

export interface Props {
  topic: Topic
}

const { topic } = defineProps<Props>()

const { stepCountText } = useTopicMeta(topic)
</script>
