<template>
  <AtroContent class="w-full px-3 sm:p-0" col>
    <AtroContent
      v-motion
      class="relative mb-2"
      items="center"
      :initial="{ left: `0%`, transform: `translateX(-50%)` }"
      :enter="{
        left: `${score.percent}%`,
        transform: `translateX(-50%)`,
        transition,
      }"
      :animate="{
        left: `${score.percent}%`,
        transform: `translateX(-50%)`,
        transition,
      }"
      col
    >
      <AtroHeading class="!leading-8 text-atro-slate-purple" size="larger" semibold>
        <AtroCounter :to="score.atroScore" :delay="transition.delay" :duration="transition.duration" />
      </AtroHeading>
      <AtroSpan class="text-atro-gray-1" semibold>Score</AtroSpan>
      <AtroIcon class="mt-1 h-4 w-4 text-atro-gray-1" name="pointer_down" />
    </AtroContent>

    <div class="relative w-full">
      <svg class="h-4 w-full rounded-full">
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" stop-color="#DF529C" />
            <stop offset="33%" stop-color="#9541F3" />
            <stop offset="66%" stop-color="#4F6BE9" />
            <stop offset="100%" stop-color="#51B2F0" />
          </linearGradient>

          <clipPath id="clip">
            <rect
              id="clipRect"
              v-motion
              height="100%"
              rx="8px"
              :initial="{ width: '0%' }"
              :enter="{ width: `${score.percent}%`, transition }"
              :animate="{ width: `${score.percent}%`, transition }"
            />
          </clipPath>
        </defs>

        <rect rx="8px" width="100%" height="100%" fill="#F2F5FF" />
        <rect width="100%" height="100%" clip-path="url(#clip)" fill="url(#gradient)" />
      </svg>
      <div
        class="absolute left-[calc(20%+0.25rem)] top-1/2 -ml-1.5 h-2 w-1 -translate-y-1/2 rounded-full bg-atro-gray-5/50"
      />
      <div
        class="absolute left-[calc(40%+0.25rem)] top-1/2 -ml-1.5 h-2 w-1 -translate-y-1/2 rounded-full bg-atro-gray-5/50"
      />
      <div
        class="absolute left-[calc(60%+0.25rem)] top-1/2 -ml-1.5 h-2 w-1 -translate-y-1/2 rounded-full bg-atro-gray-5/50"
      />
      <div
        class="absolute left-[calc(80%+0.25rem)] top-1/2 -ml-1.5 h-2 w-1 -translate-y-1/2 rounded-full bg-atro-gray-5/50"
      />
    </div>

    <div class="mt-2 grid w-full grid-cols-5 text-center">
      <AtroSpan
        :class="['text-atro-gray-1', !titlesConfig.critical && 'invisible']"
        size="small"
        semibold
        >Critical</AtroSpan
      >
      <AtroSpan
        :class="['text-atro-gray-1', !titlesConfig.bad && 'invisible']"
        size="small"
        semibold
        >Bad</AtroSpan
      >
      <AtroSpan
        :class="['text-atro-gray-1', !titlesConfig.needsWork && 'invisible']"
        size="small"
        semibold
        >Needs work</AtroSpan
      >
      <AtroSpan
        :class="['text-atro-gray-1', !titlesConfig.great && 'invisible']"
        size="small"
        semibold
        >Great</AtroSpan
      >
      <AtroSpan
        :class="['text-atro-gray-1', !titlesConfig.amazing && 'invisible']"
        size="small"
        semibold
        >Amazing</AtroSpan
      >
    </div>
  </AtroContent>
</template>

<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'

export interface Props {
  score: Score
}

const { score } = defineProps<Props>()

const isMobile = useIsMobile()

const transition = {
  delay: 300,
  duration: score.percent * 10 + 350,
  ease: 'easeOut',
}

const titlesConfig = $computed(() => {
  const config = {
    critical: true,
    bad: true,
    needsWork: true,
    great: true,
    amazing: true,
  }

  if (isMobile.value) {
    config.amazing = score.atroScore >= 800
    config.great = score.atroScore >= 600 && score.atroScore < 800
    config.needsWork = score.atroScore >= 400 && score.atroScore < 600
    config.bad = score.atroScore > 200 && score.atroScore < 400
    config.critical = score.atroScore < 200
  }
  return config
})
</script>
