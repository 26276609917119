<template>
  <AtroAsync
    empty-heading="No fixes to address"
    empty-icon-name="empty_tab_happy"
    :config="fetchState"
  >
    <SecurityFixesTable
      view="userWrite"
      :can-show-onboarding="true"
      :security-fixes="securityFixes"
      expanded
    />
  </AtroAsync>
</template>

<script setup lang="ts">
import SecurityFixesTable from '@/components/securityFixes/SecurityFixesTable.vue'
import useSecurityFixes from '@/composables/data/useSecurityFixes'

const { fetchState, data: securityFixes } = useSecurityFixes('user', { type: 'inbox' })
</script>
