<template>
  <AtroGridTable
    :columns="columnsConfig"
    :async="{
      config: fetchState,
      emptyHeading: 'No team members yet',
      emptySubHeading: 'Assigned team members will show here once they join Atro',
    }"
  >
    <template #default="{ columnStyle, items }">
      <TeamManageTableRow
        v-for="user in items"
        :key="user.id"
        :columns-config="columnsConfig"
        :col-style="columnStyle"
        :user="user"
        @on-delete="onUserDelete"
      />
    </template>
  </AtroGridTable>

  <DeleteModal
    button-text="Delete User"
    :is-open="isDeleteModalOpen"
    :sub-text="{
      confirmed: 'Team member has been removed from Atro',
      initial:
        'Team member will not longer be able to access Atro. Their answers will be removed from your score.',
    }"
    :on-delete="deleteUser"
    @close="isDeleteModalOpen = false"
  />
</template>

<script lang="ts">
const isMobile = $(useIsMobile())

export const columnsConfig = $computed<ColumnConfig[]>(() => [
  {
    heading: {
      align: 'start',
      text: 'Team members',
    },
    sortBy: 'display',
    widthRatio: isMobile ? 4 : 6,
  },
  {
    heading: {
      text: 'Permissions',
    },
    initialSortedBy: 'asc',
    sortBy: (user: User) => {
      if (user.isOwner) {
        return [1, user.display]
      } else if (user.role === 'admin') {
        return [2, user.display]
      } else {
        return [3, user.display]
      }
    },
    widthRatio: 1.66,
  },
  // {
  //   name: 'actions',
  //   fixedWidth: true,
  //   minColumnWidth: isMobile ? 0 : 180,
  //   rowClass: isMobile ? 'mt-4 col-span-full' : '',
  // },
])
</script>

<script setup lang="ts">
import { UseDataReturnProps } from '@/composables/useData'
import useIsMobile from '@/composables/useIsMobile'
import { useTeamMemberDestroyMutation } from '@/composables/mutations/useTeamMutation'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import TeamManageTableRow from '@/components/team/TeamManageTableRow.vue'

export interface Props {
  fetchState: UseDataReturnProps
}

const { fetchState } = defineProps<Props>()

let isDeleteModalOpen = $ref(false)
let userToDelete = $ref<User>()

const { destroyTeamMember } = useTeamMemberDestroyMutation($$(userToDelete))

const onUserDelete = (user: User) => {
  isDeleteModalOpen = true
  userToDelete = user
}

const deleteUser = async () => {
  await destroyTeamMember()
  return true
}
</script>
