<template>
  <FormKit v-model="formData" type="form" :errors="formErrors" @submit="onSubmit">
    <FormKit autofocus type="text" placeholder="Access Token" name="token" validation="required" />
    <template #submit="{ state: { valid } }">
      <AtroButton block icon-right="chevron_right" :is-pending="isPending" :disabled="!valid"
        >Continue</AtroButton
      >
    </template>
  </FormKit>
</template>

<script setup lang="ts">
import axios from '@/utils/axios'
import { watch } from 'vue'

export type TokenValidProperties = {
  type: 'share' | 'invite' | 'orgShare'
  token: string
}

const emit = defineEmits<{
  (e: 'tokenInvalid'): void
  (e: 'tokenValid', value: TokenValidProperties): void
}>()

const formData = $ref({
  token: '',
})
let formErrors = $ref([])
let isPending = $ref(false)

watch(formData, () => {
  if (formData.token.length === 0) {
    formErrors = []
  }
})

const onSubmit = async () => {
  isPending = true
  const shareTokenValidationPromise = axios.get('shares/active', {
    params: formData,
  })
  const inviteTokenValidationPromise = axios.get(`invites/${formData.token}`)
  const orgShareTokenValidationPromise = axios.get(`org/share-token/${formData.token}`)

  Promise.allSettled([
    shareTokenValidationPromise,
    inviteTokenValidationPromise,
    orgShareTokenValidationPromise,
  ])
    .then(promises => {
      if (promises[0].status === 'fulfilled') {
        emit('tokenValid', { type: 'share', ...formData })
      } else if (promises[1].status === 'fulfilled') {
        emit('tokenValid', { type: 'invite', ...formData })
      } else if (promises[2].status === 'fulfilled') {
        emit('tokenValid', { type: 'orgShare', ...formData })
      } else {
        emit('tokenInvalid')
        formErrors = ['The token submitted is invalid']
      }
    })
    .finally(() => (isPending = false))
}
</script>
