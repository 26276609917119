<template>
  <AtroGridTableRow :expanded="expanded" :expand-toggleable="isAdminView" @expand="onExpand">
    <template #default>
      <AtroContent items="center">
        <AtroHeading ref="tooltipTargetTitle" size="xsmall" semibold>{{
          securityFix.title
        }}</AtroHeading>
      </AtroContent>
      <slot
        name="tooltip"
        :target="view === 'userWrite' ? tooltipTargetStatusSelect : tooltipTargetTitle"
      />

      <AtroContent
        items="center"
        :justify="view === 'userRead' || (isMobile && view === 'userWrite') ? 'end' : 'center'"
      >
        <AtroRiskLevelIndicator :risk-level="securityFix.riskLevel" />
      </AtroContent>

      <!-- ADMIN COLUMNS -->
      <AtroContent
        v-if="isAdminView"
        class="text-atro-dark-purple-75"
        items="center"
        justify="center"
      >
        <AtroIcon class="mr-1.5 w-4 text-atro-gray-5" name="user" />
        <AtroSpan size="large" semibold>{{ resolvedUserText }}</AtroSpan>
      </AtroContent>

      <!-- USER COLUMNS -->
      <AtroContent
        v-if="view === 'userWrite'"
        :class="statusColumnConfig?.rowClass"
        items="center"
        :justify="isMobile ? 'start' : 'center'"
      >
        <div ref="tooltipTargetStatusSelect">
          <SecurityFixStateSelect
            :status="securityFixStatus"
            @change="onSecurityFixUserStateChange"
          />
        </div>
      </AtroContent>

      <AtroConfirmModal
        v-if="view === 'adminWrite'"
        body-text="This will change the status for everyone with this fix"
        :closable="false"
        :is-open="showConfirmStateChangeModal"
        :on-cancel="cancelStateChange"
        :on-confirm="applyStoredStateChange"
        @close="showConfirmStateChangeModal = false"
      />
    </template>

    <!-- EXPANDED VIEW -->
    <template #expandableContent>
      <AtroContent class="col-span-3 mt-2" wrap="nowrap" col>
        <AtroParagraph class="text-atro-dark-purple-75" size="small">{{
          securityFix.description
        }}</AtroParagraph>
        <AtroContent
          class="mt-3 w-full flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
        >
          <AtroContent class="pr-2" items="center" wrap="nowrap">
            <AtroSpan class="mr-2 text-atro-dark-purple-50" size="xxsmall" semibold
              >Assessment</AtroSpan
            >
            <AtroBadge variant="neutral" :label="securityFix.assessmentTitle" />
          </AtroContent>

          <SecurityFixStateSelect
            v-if="view === 'adminWrite'"
            placeholder="Change Fix Status"
            :disabled="isResolved"
            :status="storedStatus"
            @change="onSecurityFixOrgStateChange"
          />
        </AtroContent>

        <SecurityFixesTeamAssignedToTable
          v-if="securityFix.userStatuses?.length"
          class="mt-6"
          :security-fixes="securityFix.userStatuses"
        />
      </AtroContent>
    </template>
  </AtroGridTableRow>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import useAnaytics from '@/composables/useAnalytics'
import useIsMobile from '@/composables/useIsMobile'
import useOnboarding from '@/composables/useOnboarding'
import useSecurityFixMeta from '@/composables/meta/useSecurityFixMeta'
import {
  useSecurityFixOrgUpdate,
  useSecurityFixUserUpdate,
} from '@/composables/mutations/useSecurityFixMutation'
import { Props as GridTableRowProps } from '@/components/globals/atro/GridTableRow.vue'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'
import SecurityFixesTeamAssignedToTable from '@/components/securityFixes/team/SecurityFixesTeamAssignedToTable.vue'
import SecurityFixStateSelect from '@/components/securityFix/SecurityFixStateSelect.vue'

export interface Props extends GridTableRowProps {
  securityFix: SecurityFix
  view: 'adminRead' | 'adminWrite' | 'userRead' | 'userWrite'
  expanded?: boolean
  columnsConfig?: ColumnConfig[]
}

const { columnsConfig, securityFix, view } = defineProps<Props>()

const analytics = $(useAnaytics())
const isMobile = useIsMobile()
const { isResolved, resolvedUserText } = useSecurityFixMeta($$(securityFix))
const { completeOnboardingTask } = useOnboarding()
const { updateOrgSecurityFix } = useSecurityFixOrgUpdate(securityFix)
const { updateUserSecurityFix } = useSecurityFixUserUpdate(securityFix)

let showConfirmStateChangeModal = $ref(false)
let storedStatus = $ref(null)

const tooltipTargetTitle = ref<Element>()
const tooltipTargetStatusSelect = ref<Element>()

const isAdminView = $computed(() => ['adminRead', 'adminWrite'].includes(view))
const securityFixStatus = $computed(() =>
  securityFix.status === 'unaddressed' ? null : securityFix.status
)
const statusColumnConfig = $computed(() => columnsConfig?.find(config => config.name === 'status'))

const applyStoredStateChange = () => {
  updateOrgSecurityFix({ ...securityFix, status: storedStatus })
  analytics?.track('security-fix:state-override', {
    ...securityFix,
    status: storedStatus,
  })
  return true
}

const cancelStateChange = () => {
  storedStatus = ''
  return true
}

const onSecurityFixOrgStateChange = e => {
  storedStatus = e.target.value
  showConfirmStateChangeModal = true
}

const onSecurityFixUserStateChange = e => {
  completeOnboardingTask('addressedSecurityFix')
  updateUserSecurityFix({ ...securityFix, status: e.target.value })
  analytics?.track('security-fix:state-change', { ...securityFix, status: e.target.value })
}

const onExpand = () => {
  completeOnboardingTask('seenTeamSecurityFixesPopulated')
}
</script>
