<template>
  <Avatar variant="beam" :colors="colors" :name="name" :size="48" />
</template>

<script setup lang="ts">
import Avatar from 'vue-boring-avatars'

export interface Props {
  name: string
}

const { name } = defineProps<Props>()

const colors = ['#4F6BE9', '#51B2F0', '#7180F7', '#9541F3', '#DF529C']
</script>
