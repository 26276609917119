<template>
  <AtroModal size="max">
    <AtroContent col>
      <AtroSectionHeading class="mb-6" :heading="action.label" :sub-heading="report.subtitle" />

      <AtroGridTable :columns="report.dataInfo.columns" :data="data">
        <template #default="{ columnStyle, items }">
          <IntegrationMonitoringReportTableRow
            v-for="rowData in items"
            :key="rowData.uuid"
            :col-style="columnStyle"
            :columns-config="report.dataInfo.columns"
            :row-data="rowData"
          />
        </template>
      </AtroGridTable>
    </AtroContent>
  </AtroModal>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'
import IntegrationMonitoringReportTableRow from '@/components/integration/monitoring/IntegrationMonitoringReportTableRow.vue'
import { Props as ModalProps } from '@/components/globals/atro/Modal.vue'
import { DynamicAction } from '@/types/dynamicAction'
import { IntegrationReport } from '@/types/integrationReport'

export interface Props extends ModalProps {
  action: DynamicAction
  report: IntegrationReport
}

const { report } = defineProps<Props>()

// add a unique UUID to every row so we can sort
const data = $computed(() => report.data.rows.map(row => ({ ...row, uuid: uuidv4() })))
</script>
