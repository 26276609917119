import useData from '@/composables/useData'

export default function useInvites() {
  const fetchState = useData<Invite[]>('teamInvites')

  return {
    fetchState,
    data: fetchState.data,
    meta: {},
  }
}
