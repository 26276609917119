import * as Sentry from '@sentry/vue'
import { signupStepsConfig } from '@/utils/signup'

type Props = {
  onComplete: () => Promise<void>
  signupType: 'user' | 'org'
}

export default function useSignupSteps({ onComplete, signupType }: Props) {
  let signupStepIndex = $ref(0)
  const signupSteps = $computed(() => signupStepsConfig[signupType])
  const signupStep = $computed(() => signupSteps[signupStepIndex])
  const signupStepComponent = $computed(() => signupStep.component)
  const signupStepProps = $computed(() => signupStep.props)

  const nextStep = async () => {
    if (signupStepIndex + 1 < signupSteps.length) {
      signupStepIndex += 1
    } else {
      // signup flow completed
      await onComplete()
    }
  }

  return {
    nextStep,
    ...$$({ signupStep, signupStepComponent, signupStepIndex, signupStepProps }),
  }
}
