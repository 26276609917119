<template>
  <table class="w-full border-collapse text-white">
    <thead v-if="!isMobile">
      <tr>
        <th class="w-32"></th>
        <th
          v-for="option in labels"
          :key="option"
          scope="col"
          class="w-36 py-3 text-center text-sm font-normal sm:text-base"
        >
          {{ option }}
        </th>
      </tr>
    </thead>
    <tbody>
      <AtroOptionsTableRow
        v-for="label in options"
        :key="label.id"
        :label="label"
        :options="labels"
        :option-selected="selected[label.id]"
        :variant="isMobile ? 'select' : 'options'"
        @change="onOptionSelected"
      />
    </tbody>
  </table>
</template>

<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'
import { Option, SelectedOptions } from '@/components/globals/atro/OptionsTableRow.vue'

export interface Props {
  labels: string[]
  options: Option[]
  selected: SelectedOptions
}

const { labels = [], options = [], selected = {} } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'change', selected: SelectedOptions): void
}>()

const isMobile = useIsMobile()

function onOptionSelected(selection: SelectedOptions) {
  emit('change', {
    ...selected,
    ...selection,
  })
}
</script>
