<template>
  <AtroContent
    id="topic-taker"
    :class="[
      'flex-1 transform-gpu px-4 transition-all',
      !topicCompleted ? 'pb-[var(--topic-taker-bottom-bar-height)]' : 'pb-0',
    ]"
    wrap="nowrap"
    col
  >
    <AtroAsync :animated="false" :config="fetchState">
      <TopicTakerTopBar :title="topic?.title" @close="close" />
      <AtroContent
        class="relative z-1 mx-auto w-full max-w-[800px] flex-1 pb-6 text-white"
        items="center"
        justify="center"
      >
        <Transition
          mode="out-in"
          enter-active-class="duration-300"
          enter-to-class="opacity-100 translate-y-0"
          leave-active-class="duration-300"
          :enter-from-class="initialAnimation"
          :leave-to-class="exitAnimation"
        >
          <TopicSubmitted v-if="topicCompleted" :next-topic="nextTopic" @next-topic="onNextTopic" />
          <component
            :is="stepComponent"
            v-else-if="stepComponent"
            v-bind="currentStep"
            :key="currentStep.id"
            class="w-full"
            :topic="topic"
            @add-answer="onAddAnswer"
            @jump-to-step="jumpToStep"
            @next="next"
          />
        </Transition>
      </AtroContent>

      <AtroProgress
        v-if="!isMobile && !topicCompleted"
        class="relative z-1 mx-auto mb-4 max-w-xl sm:mb-10"
        variant="topic-taker"
        :percent-complete="percentComplete"
      />

      <TopicTakerBottomBar
        v-if="!topicCompleted"
        :continue-button-text="continueButtonText"
        :continue-disabled="continueDisabled"
        :continue-pending="topicCompletePending"
        :next-disabled="nextDisabled"
        :prev-disabled="prevDisabled"
        :percent-complete="percentComplete"
        @continue="onContinue"
        @next="next"
        @prev="prev"
      />

      <Transition name="fade">
        <LottiAnimation
          v-if="isFinishedStep"
          class="pointer-events-none fixed top-0 left-0 h-screen w-screen"
          :animation-data="confettiJSON"
          loop
        />
      </Transition>
    </AtroAsync>
  </AtroContent>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { useTopicTakerNav } from '@/composables/useTopicTakerNav'
import {
  useTopicCompletedMutation,
  useTopicStartedMutation,
} from '@/composables/mutations/useTopicMutation'
import { useTopicStepAnswerMutation } from '@/composables/mutations/useTopicStepMutation'
import usePrefetchData from '@/composables/usePrefetchData'
import useTopic from '@/composables/data/useTopic'
import TopicTakerBottomBar from '@/components/topicTaker/TopicTakerBottomBar.vue'
import TopicTakerTopBar from '@/components/topicTaker/TopicTakerTopBar.vue'
import TopicSubmitted from '@/components/topicTaker/TopicSubmitted.vue'
import LottiAnimation from '@/components/LottiAnimation.vue'
import useAnaytics from '@/composables/useAnalytics'
import useIsMobile from '@/composables/useIsMobile'
import useOnboarding from '@/composables/useOnboarding'
import useAssessment from '@/composables/data/useAssessment'
import confettiJSON from '@/assets/animations/confetti.json'

interface Props {
  openedFromModal?: boolean
}

const { openedFromModal } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

let topicCompleted = $ref(false)

const analytics = $(useAnaytics())
const isMobile = useIsMobile()
const route = useRoute()
const router = useRouter()
const topicId = route.params.id as string
const assessmentId = route.query.assessmentId as string

const prefetch = usePrefetchData()
const { data: assessment } = $(useAssessment('assessmentUser', assessmentId))
const { fetchState, data: topic } = $(useTopic(topicId))
fetchState.isLoading.value && (await fetchState.suspense())

const { completeTopic, isLoading: topicCompletePending } = useTopicCompletedMutation(topicId)
const { addAnswers } = useTopicStepAnswerMutation()
const { startTopic } = useTopicStartedMutation(topicId)
const { completeOnboardingTask } = useOnboarding('startedFirstAssessment')

const {
  continueDisabled,
  currentStep,
  direction,
  isAllStepsAnswered,
  isContentTopic,
  nextDisabled,
  percentComplete,
  prevDisabled,
  stepComponent,
  jumpToStep,
  next,
  prev,
} = $(useTopicTakerNav($$(topic)))

if (topic?.state === 'initial') {
  await startTopic()
  completeOnboardingTask()
  analytics?.track('topic:started', topic as any)
}

const isFinishedStep = $computed(() => currentStep?.kind === 'finished')

const continueButtonText = $computed(() => (isFinishedStep ? 'Submit Answers' : 'Continue'))

const initialAnimation = $computed(() => {
  return `opacity-0 ${direction === 'forward' ? 'translate-y-full' : '-translate-y-full'}`
})

const exitAnimation = $computed(() => {
  return `opacity-0 ${direction === 'backward' ? 'translate-y-full' : '-translate-y-full'}`
})

const nextTopic = $computed(() =>
  assessment?.topics?.find(t => t.state !== 'completed' && t.id !== topic.id)
)

const onAddAnswer = (answers: Answer[]) => {
  addAnswers(answers)

  // Auto next step on answer for choice steps
  if (currentStep.kind === 'choice') {
    onContinue()
  }
}

const onContinue = async () => {
  if (isFinishedStep) {
    await completeTopic(route.query.assessmentId as string)
    analytics?.track('topic:completed', topic as any)
    // prefetch the next topic
    if (nextTopic) {
      prefetch('topicsShow', { id: nextTopic.id })
    }
    topicCompleted = true
  } else if (isAllStepsAnswered && !isContentTopic) {
    jumpToStep(topic.steps[topic.steps.length - 1])
  } else {
    next()
  }
}

const onNextTopic = () => {
  if (nextTopic) {
    router.replace({
      name: 'topicTaker',
      params: { id: nextTopic.id },
      query: { assessmentId },
    })
  } else {
    close()
  }
}

const close = () => {
  if (openedFromModal) {
    emit('close')
  } else {
    router.replace(
      route.query.assessmentId
        ? { name: 'assessmentUser', params: { id: route.query.assessmentId } }
        : { name: 'myAssessments' }
    )
  }
}
</script>

<style>
#topic-taker {
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 0.4;
}
</style>
