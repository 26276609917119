import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'
import {
  activityAssessmentAssigned,
  activityTeamScoreImproved,
  activityUserJoined,
} from '@/data/activity'

export const mockPaths = {
  activity: 'activity',
}

const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  // GET: SHOW Activity
  rest.get(getPath('activity'), (req, res, ctx) => {
    return res(
      ctx.delay(),
      ctx.json([activityAssessmentAssigned, activityTeamScoreImproved, activityUserJoined])
    )
  }),
]
