import { unref } from 'vue'
import { Assessment } from '@/types/assessment'
import { MaybeRef } from '@/types/utils'

export default function useAssessmentsMeta(_assessments: MaybeRef<Assessment[]>) {
  const assessments = $computed(() => (unref(_assessments) as Assessment[]) || [])
  const onboardingAssessmentIndex = $computed(
    () => assessments.findIndex(assessment => assessment.isOnboarding) || 0
  )

  const isAllCaughtUp = $computed(() =>
    assessments.every(assessment => assessment.status === 'caughtUp')
  )

  return $$({
    isAllCaughtUp,
    onboardingAssessmentIndex,
  })
}
