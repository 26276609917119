import useCurrentUser from '@/composables/data/useCurrentUser'
import { NavItem } from '@/types/nav'

export default function useUserNavItems() {
  const { data: user } = $(useCurrentUser())

  const integrations: NavItem = {
    type: 'link',
    label: 'Integrations',
    iconName: 'integration',
    linkTo: { name: 'integrations' },
  }
  const myProfile: NavItem = {
    type: 'link',
    label: 'My profile',
    iconName: 'user_outline',
    linkTo: { name: 'manageProfile' },
  }
  const myTeam: NavItem = {
    type: 'link',
    label: 'Team management',
    iconName: 'users',
    linkTo: { name: 'manageTeam' },
  }
  const myOrg: NavItem = {
    type: 'link',
    label: 'Organization settings',
    iconName: 'users',
    linkTo: { name: 'manageOrg' },
  }
  const logout: NavItem = {
    type: 'link',
    label: 'Log out',
    iconName: 'logout',
    linkTo: { name: 'logout' },
  }

  const navItems: NavItem[] = $computed(() =>
    user?.role === 'admin'
      ? user.isOwner
        ? [myProfile, myTeam, integrations, myOrg, logout]
        : [myProfile, myTeam, integrations, logout]
      : [myProfile, logout]
  )

  return navItems
}
