<template>
  <AtroContent class="w-full" items="center" wrap="nowrap">
    <AtroUserAvatar v-if="!hideAvatar" :class="sizeClasses[size]" :name="user.display" />
    <AtroContent class="w-full flex-1" wrap="nowrap" col>
      <AtroHeading
        class="text-atro-dark-purple group-hover:text-atro-purple"
        :size="size === 'normal' ? 'xsmall' : 'large'"
        semibold
        >{{ user?.display }}</AtroHeading
      >
      <AtroSpan v-if="showEmail" class="truncate text-atro-gray-1">{{ user?.email }}</AtroSpan>
      <AtroContent
        v-if="slots.default || showRoleBadge"
        class="w-full"
        items="center"
        :wrap="isMobile ? 'wrap' : 'nowrap'"
      >
        <AtroBadge
          v-if="showRoleBadge"
          class="mr-6 shrink-0 capitalize"
          variant="neutral"
          :label="user?.role"
        />
        <slot />
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import useIsMobile from '@/composables/useIsMobile'

export interface Props {
  hideAvatarWhenMobile?: boolean
  showEmail?: boolean
  showRoleBadge?: boolean
  size?: 'normal' | 'large'
  user?: User
}

const sizeClasses = {
  normal: 'h-8 w-8 mr-2 sm:h-12 sm:w-12 sm:mr-4',
  large: 'h-18 w-18 mr-4',
}

const {
  user,
  hideAvatarWhenMobile = false,
  size = 'normal',
  showRoleBadge = false,
  showEmail = false,
} = defineProps<Props>()

const slots = useSlots()

const isMobile = $(useIsMobile())

const hideAvatar = $computed(() => isMobile && hideAvatarWhenMobile)
</script>
