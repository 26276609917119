<template>
  <TabGroup :selected-index="selectedTab">
    <TabList class="mb-6 flex w-full flex-nowrap space-x-6 border-b-2 sm:space-x-8">
      <Tab
        v-for="tabConfig in tabConfigs"
        :key="tabConfig.title"
        v-slot="{ selected }"
        as="template"
      >
        <RouterLink
          :to="{
            name: route.name as string,
            query: tabConfig.deepLink && { tab: paramCase(tabConfig.title) },
          }"
        >
          <button
            :class="[
              'relative whitespace-nowrap pb-2 text-base font-semibold inline-flex justify-center items-center',
              selected
                ? 'text-atro-purple after:absolute after:left-0 after:-bottom-0.5 after:w-full after:border-b-2 after:border-atro-purple'
                : 'text-atro-purple/75 hover:text-atro-purple',
            ]"
          >
            <div v-if="tabConfig.alert" class="w-1.5 h-1.5 mr-1.5 rounded-full bg-atro-magenta" />
            {{ tabConfig.title }}
            <AtroSpan v-if="'count' in tabConfig" class="font-light text-atro-gray-1"
              >({{ typeof tabConfig.count === 'number' ? tabConfig.count : '–' }})</AtroSpan
            >
          </button>
        </RouterLink>
      </Tab>
    </TabList>
    <TabPanels class="flex w-full flex-1">
      <template v-if="tabConfigs">
        <TabPanel v-for="(tabConfig, i) in tabConfigs" :key="i" class="w-full" tabindex="-1">
          <component :is="tabConfig.component" v-bind="tabConfig.props" />
        </TabPanel>
      </template>
      <template v-else>
        <slot />
      </template>
    </TabPanels>
  </TabGroup>
</template>

<script setup lang="ts">
import { DefineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { paramCase } from 'change-case'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

export type TabConfig = {
  component: DefineComponent<any, any, any>
  title: string
  alert?: boolean
  count?: number
  deepLink?: boolean
  props?: Record<string, unknown>
}

export interface Props {
  tabConfigs?: TabConfig[]
}

const { tabConfigs } = defineProps<Props>()

const route = useRoute()

const selectedTab = $computed(() => {
  return tabConfigs.findIndex(
    tabConfig => tabConfig.deepLink && paramCase(tabConfig.title) === route.query?.tab
  )
})
</script>
