import { useBaseMutation } from './useBaseMutation'

export function useOrgUpdateMutation() {
  const mutation = useBaseMutation({
    method: 'patch',
    url: `/org`,
    invalidateKeys: [['currentUser']],
  })

  const updateOrg = async (org: Org) => {
    await mutation.mutateAsync({ org })
  }

  return { updateOrg, ...mutation }
}

export function useOrgDestroyMutation() {
  const mutation = useBaseMutation({
    method: 'delete',
    url: '/org',
    removeKeys: ['*'],
  })

  const destroyOrg = async () => {
    await mutation.mutateAsync(null)
  }

  return { destroyOrg, ...mutation }
}
