import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'
import {
  cantFixUserSecurityFix,
  fixedUserSecurityFix,
  ignoredUserSecurityFix,
  resolvedAdminSecurityFix,
  unaddressedUserSecurityFix,
  unresolvedAdminSecurityFix,
} from '@/data/securityFixes'

export const mockPaths = {
  securityFixesOrg: 'org-security-fixes',
  securityFixesUser: 'user-security-fixes',

  securityFixOrg: 'org-security-fixes/:id',
  securityFixUser: 'user-security-fixes/:id',
}

const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  // GET: SHOW Org Security Fix
  rest.get(getPath('securityFixOrg'), (req, res, ctx) => {
    const response = [resolvedAdminSecurityFix, unresolvedAdminSecurityFix].find(
      securityFix => securityFix.id === req.params.id
    )
    return res(ctx.delay(), ctx.json(response))
  }),

  // GET: SHOW Org Security Fixes
  rest.get(getPath('securityFixesOrg'), (req, res, ctx) => {
    const type = req.url.searchParams.get('type')
    let response
    switch (type) {
      case 'resolved':
        response = [resolvedAdminSecurityFix]
        break
      case 'unresolved':
        response = [unresolvedAdminSecurityFix]
        break
      default:
        response = []
    }
    return res(ctx.delay(), ctx.json(response))
  }),

  // GET: SHOW User Security Fix
  rest.get(getPath('securityFixUser'), (req, res, ctx) => {
    const response = [
      cantFixUserSecurityFix,
      fixedUserSecurityFix,
      ignoredUserSecurityFix,
      unaddressedUserSecurityFix,
    ].find(securityFix => securityFix.id === req.params.id)
    return res(ctx.delay(), ctx.json(response))
  }),

  // GET: SHOW User Security Fixes
  rest.get(getPath('securityFixesUser'), (req, res, ctx) => {
    const type = req.url.searchParams.get('type')
    let response
    switch (type) {
      case 'inbox':
        response = [unaddressedUserSecurityFix]
        break
      case 'addressed':
        response = [cantFixUserSecurityFix, fixedUserSecurityFix, ignoredUserSecurityFix]
        break
      default:
        response = []
    }
    return res(ctx.delay(), ctx.json(response))
  }),

  // PATCH: Update Org Security Fix
  rest.patch(getPath('securityFixOrg'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),

  // PATCH: Update User Security Fix
  rest.patch(getPath('securityFixUser'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),
]
