export default {
  integrations: {
    key: ['integrations'],
    path: 'integrations/discovery',
  },
  integrationDiscovery: {
    key: (params: { id: string }) => ['integrationsDiscovery', params.id],
    path: (params: { id: string }) => `integrations/discovery/${params.id}`,
  },
  integrationOrg: {
    key: (params: { id: string }) => ['integrationOrg', params.id],
    path: (params: { id: string }) => `integrations/org-integrations/${params.id}`,
  },
}
