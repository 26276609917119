<template>
  <AtroContent class="space-x-4 overflow-x-auto" wrap="nowrap">
    <AssessmentAdminCard
      v-for="(assessment, i) in assessments"
      :key="assessment.id"
      :assessment="assessment"
    >
      <template v-if="isOnboarding && i === onboardingAssessmentIndex" #tooltip="{ target }">
        <AtroOnboardingTooltip
          :heading="ONBOARDING_COPY.dashboardAssessmentsInProgressTooltip.heading"
          :body="ONBOARDING_COPY.dashboardAssessmentsInProgressTooltip.body"
          :cta-text="ONBOARDING_COPY.dashboardAssessmentsInProgressTooltip.cta"
          :target="target"
          :dependent-keys="['seenApp', 'seenDashboard']"
          @close="completeOnboardingTask"
        />
      </template>
    </AssessmentAdminCard>

    <DashboardAssessmentListDiscoverMoreCard />
  </AtroContent>
</template>

<script setup lang="ts">
import { ONBOARDING_COPY } from '@/lib/constants'
import useAssessmentsMeta from '@/composables/meta/useAssessmentsMeta'
import useOnboarding from '@/composables/useOnboarding'
import AssessmentAdminCard from '@/components/assessment/admin/AssessmentAdminCard.vue'
import DashboardAssessmentListDiscoverMoreCard from '@/components/dashboard/DashboardAssessmentListDiscoverMoreCard.vue'
import { Assessment } from '@/types/assessment'

interface Props {
  assessments: Assessment[]
}

const { assessments } = defineProps<Props>()

const { completeOnboardingTask, isOnboarding } = $(useOnboarding('seenDashboardAssessments'))
const { onboardingAssessmentIndex } = useAssessmentsMeta($$(assessments))
</script>
