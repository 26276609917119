import AutomatedMonitoringPage from '@/pages/MonitoringCenterPage.vue'
import DashboardAdmin from '@/pages/DashboardAdmin.vue'
import IntegrationPage from '@/pages/IntegrationPage.vue'
import IntegrationsPage from '@/pages/IntegrationsPage.vue'
import MyAssessmentsPage from '@/pages/MyAssessmentsPage.vue'
import MySecurityFixesPage from '@/pages/MySecurityFixesPage.vue'
import OrgManagementPage from '@/pages/ManageOrgPage.vue'
import ProfileManagmentPage from '@/pages/ManageProfilePage.vue'
import TeamPage from '@/pages/TeamPage.vue'
import TeamMemberPage from '@/pages/TeamMemberPage.vue'
import TeamAssessmentsPage from '@/pages/TeamAssessmentsPage.vue'
import TeamManagementPage from '@/pages/ManageTeamPage.vue'
import TeamSecurityFixesPage from '@/pages/TeamSecurityFixesPage.vue'

import DashboardSideBarTeam from '@/components/dashboard/DashboardSideBarTeam.vue'
import DashboardSideBarTeamMember from '@/components/dashboard/DashboardSideBarTeamMember.vue'
import DashboardSideBarUser from '@/components/dashboard/DashboardSideBarUser.vue'

import IntegrationPageHeading from '@/components/integration/IntegrationPageHeading.vue'

import { Props as AtroPageHeadingProps } from '@/components/globals/atro/PageHeading.vue'

export const dashboardAdminRoute = {
  path: '/dashboard',
  name: 'dashboardAdmin',
  component: DashboardAdmin,
  meta: {
    layout: 'Dashboard',
    title: 'Dashboard',
    requiresAuth: { role: 'admin' },
    SideBar: DashboardSideBarTeam,
  },
}

export const myAssessmentsRoute = {
  path: '/my-assessments',
  name: 'myAssessments',
  component: MyAssessmentsPage,
  meta: {
    layout: 'Dashboard',
    title: 'My Assessments',
    requiresAuth: true,
    SideBar: DashboardSideBarUser,
  },
}

export default [
  dashboardAdminRoute,
  myAssessmentsRoute,
  {
    path: '/monitoring-center',
    name: 'monitoringCenter',
    component: AutomatedMonitoringPage,
    meta: { layout: 'Dashboard', requiresAuth: { role: 'admin' }, title: 'Monitoring center' },
  },
  {
    path: '/integration/:id',
    name: 'integration',
    component: IntegrationPage,
    props: { active: true },
    meta: {
      forceUpdateOnPathChange: true,
      layout: 'Dashboard',
      requiresAuth: { role: 'admin' },
      title: 'Integration',
      PageHeading: IntegrationPageHeading,
    },
  },
  {
    path: '/integration/:id/discover',
    name: 'integrationDiscover',
    component: IntegrationPage,
    meta: {
      layout: 'Dashboard',
      requiresAuth: { role: 'admin' },
      title: 'Integration',
      PageHeading: IntegrationPageHeading,
    },
  },
  {
    path: '/integrations',
    name: 'integrations',
    component: IntegrationsPage,
    meta: {
      layout: 'Dashboard',
      requiresAuth: { role: 'admin' },
      title: 'Integrations',
    },
  },
  {
    path: '/team-assessments',
    name: 'teamAssessments',
    component: TeamAssessmentsPage,
    meta: { layout: 'Dashboard', requiresAuth: { role: 'admin' }, title: 'Assessments' },
  },
  {
    path: '/my-security-fixes',
    name: 'mySecurityFixes',
    component: MySecurityFixesPage,
    meta: {
      layout: 'Dashboard',
      title: 'My Security Fixes',
      requiresAuth: true,
      SideBar: DashboardSideBarUser,
    },
  },
  {
    path: '/team-security-fixes',
    name: 'teamSecurityFixes',
    component: TeamSecurityFixesPage,
    meta: {
      layout: 'Dashboard',
      title: 'Security Fixes',
      requiresAuth: { role: 'admin' },
      SideBar: DashboardSideBarTeam,
    },
  },
  {
    path: '/team',
    name: 'team',
    component: TeamPage,
    meta: {
      layout: 'Dashboard',
      title: 'Team',
      requiresAuth: { role: 'admin' },
      SideBar: DashboardSideBarTeam,
    },
  },
  {
    path: '/team/:id',
    name: 'teamMember',
    component: TeamMemberPage,
    meta: {
      layout: 'Dashboard',
      title: 'Team Member',
      requiresAuth: { role: 'admin' },
      SideBar: DashboardSideBarTeamMember,
      PageHeading: true,
      pageHeadingProps: {
        heading: 'Team overview',
        previousRouteTo: { name: 'team' },
      } as AtroPageHeadingProps,
    },
  },
  {
    path: '/manage/profile',
    name: 'manageProfile',
    component: ProfileManagmentPage,
    meta: { layout: 'Dashboard', requiresAuth: true, title: 'Manage Profile' },
  },
  {
    path: '/manage/team',
    name: 'manageTeam',
    component: TeamManagementPage,
    meta: { layout: 'Dashboard', requiresAuth: { role: 'admin' }, title: 'Manage Team' },
  },
  {
    path: '/manage/org',
    name: 'manageOrg',
    component: OrgManagementPage,
    meta: { layout: 'Dashboard', requiresAuth: { isOwner: true }, title: 'Manage Organization' },
  },
]
