import useCurrentUser from '@/composables/data/useCurrentUser'
import { useUserUpdateMutation } from '@/composables/mutations/useUserMutation'

export default function useOnboarding(key?: keyof OnboardingState) {
  const { data: user } = $(useCurrentUser())
  const { updateUser } = useUserUpdateMutation()

  const onboardingKeys = $computed(() => user.settings)
  const isOnboarding = $computed(() => onboardingKeys?.[key] === false)

  const completeOnboardingTask = (overrideTaskName?: keyof OnboardingState) => {
    const _key = overrideTaskName || key
    if (_key === undefined || user?.settings?.[_key] === true) return
    updateUser({
      ...user,
      settings: {
        ...user.settings,
        [_key]: true,
      },
    })
  }

  return {
    completeOnboardingTask,
    ...$$({ onboardingKeys, isOnboarding }),
  }
}
