<template>
  <component :is="component" :integration="integration" />
</template>

<script setup lang="ts">
import useIntegrationMeta from '@/composables/meta/useIntegrationMeta'
import IntegrationConnectButtonDefault from '@/components/integration/connectButtons/IntegrationConnectButtonDefault.vue'
import IntegrationConnectButtonGoogle from '@/components/integration/connectButtons/IntegrationConnectButtonGoogle.vue'
import { Integration } from '@/types/integration'

export interface Props {
  integration: Integration
}

const { integration } = defineProps<Props>()

const { isConnected } = $(useIntegrationMeta($$(integration)))

const component = $computed(() => {
  if (isConnected) {
    return IntegrationConnectButtonDefault
  } else {
    switch (integration.slug) {
      case 'google-workspace--disabled':
        return IntegrationConnectButtonGoogle
      default:
        return IntegrationConnectButtonDefault
    }
  }
})
</script>
