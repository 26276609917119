<template>
  <AtroModal v-if="isOpen" v-slot="{ close }" @after-close="completeOnboardingTask()">
    <AtroContent class="text-center" items="center" col>
      <AtroIconSquare v-if="iconName" class="text-atro-purple" size="large" :icon-name="iconName" />

      <AtroHeading class="mt-4 text-atro-slate-purple" semibold>
        <AtroMarkdown :markdown="heading" />
      </AtroHeading>
      <AtroSpan v-if="subHeading" class="mt-2 text-atro-slate-purple">
        <AtroMarkdown :markdown="subHeading" />
      </AtroSpan>
      <AtroParagraph class="mt-2 text-atro-gray-1">
        <AtroMarkdown :markdown="body" />
      </AtroParagraph>

      <AtroButton class="mt-10" :icon-right="ctaIcon" @click="close">{{ ctaText }}</AtroButton>
    </AtroContent>
  </AtroModal>
</template>

<script setup lang="ts">
import useOnboarding from '@/composables/useOnboarding'
import { Props as ModalProps } from '@/components/globals/atro/Modal.vue'
import { IconName } from '@/assets/icons/atro-icon.model'

export interface Props extends ModalProps {
  body: string
  heading: string
  onboardingKey: keyof OnboardingState
  dependentKeys?: (keyof OnboardingState)[]
  ctaText?: string
  ctaIcon?: IconName
  iconName?: IconName
  subHeading?: string
}

const {
  body,
  ctaIcon,
  heading,
  iconName,
  onboardingKey,
  subHeading,
  ctaText = 'Continue',
  dependentKeys = ['seenApp'],
} = defineProps<Props>()

const { completeOnboardingTask, isOnboarding, onboardingKeys } = $(useOnboarding(onboardingKey))

const passesDependentKeys = $computed(() =>
  dependentKeys?.every(key => onboardingKeys?.[key] === true)
)
const isOpen = $computed(() => passesDependentKeys && isOnboarding)
</script>
