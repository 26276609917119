import { nextTick, unref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import usePercent from '@/composables/usePercent'
import { useTopicCurrentStepMutation } from '@/composables/mutations/useTopicMutation'
import { Topic } from '@/types/topic'
import { MaybeRef } from '@/types/utils'
// step components
import StepChoiceGroup from '@/components/globals/topicSteps/StepChoiceGroup.vue'
import StepFinished from '@/components/globals/topicSteps/StepFinished.vue'
import StepImage from '@/components/globals/topicSteps/StepImage.vue'
import StepIntro from '@/components/globals/topicSteps/StepIntro.vue'
import StepOptionsTable from '@/components/globals/topicSteps/StepOptionsTable.vue'
import StepPending from '@/components/globals/topicSteps/StepPending.vue'
import StepTagCloud from '@/components/globals/topicSteps/StepTagCloud.vue'
import StepText from '@/components/globals/topicSteps/StepText.vue'
import { TopicStep } from '@/types/topicStep'

const STEP_KINDS_MAYBE_REQUIRING_ANSWER = ['choice', 'option', 'tag']

export function useTopicTakerNav(_topic: MaybeRef<Topic>) {
  let currentIndex = $ref(0)
  let direction = $ref<'forward' | 'backward'>('forward')

  const router = useRouter()
  const route = useRoute()
  const topicId = route.params.id as string
  const { updateCurrentStep } = useTopicCurrentStepMutation(topicId)

  const topic = $computed(() => unref(_topic))
  const steps = $computed(() => unref(topic?.steps))
  const stepLength = $computed(() => steps?.length)
  const currentStep = $computed(() => steps?.[currentIndex])
  const continueDisabled = $computed(
    () =>
      STEP_KINDS_MAYBE_REQUIRING_ANSWER.includes(currentStep.kind) &&
      currentStep.requiresAnswer &&
      !currentStep.answers?.length
  )
  const nextDisabled = $computed(() => currentIndex + 1 >= stepLength || continueDisabled)
  const prevDisabled = $computed(() => currentIndex === 0)
  const percentComplete = $computed(() => usePercent(currentIndex + 1, stepLength))
  const isContentTopic = $computed(() =>
    steps.every(step => !STEP_KINDS_MAYBE_REQUIRING_ANSWER.includes(step.kind))
  )

  const isAllStepsAnswered = $computed(() => {
    return steps.every(step =>
      STEP_KINDS_MAYBE_REQUIRING_ANSWER.includes(step.kind) ? step.answers?.length : true
    )
  })

  const stepComponent = $computed(() => {
    switch (currentStep.kind) {
      case 'choice':
        return StepChoiceGroup
      case 'finished':
        return StepFinished
      case 'image':
        return StepImage
      case 'intro':
        return StepIntro
      case 'option':
        return StepOptionsTable
      case 'pending':
        return StepPending
      case 'tag':
        return StepTagCloud
      case 'text':
        return StepText
      default:
        return null
    }
  })

  watch(
    $$(topic),
    (changed, old) => {
      if (old === undefined) {
        currentIndex = findStepIndex(unref(topic?.currentStepId))
      }
    },
    { immediate: true }
  )

  watch(
    $$(currentStep),
    current => {
      if (current) {
        router.replace({
          name: 'topicTaker',
          params: { id: topicId },
          query: { ...route.query, step: current.id },
        })
      }
    },
    { immediate: true }
  )

  const jumpToStep = async (step: TopicStep) => {
    direction = currentIndex > step.position ? 'backward' : 'forward'
    await nextTick()
    currentIndex = step.position
    updateCurrentStep(step.id)
  }

  const next = async () => {
    if (currentIndex !== stepLength - 1) {
      direction = 'forward'
      await nextTick()
      currentIndex++
      updateCurrentStep(currentStep.id)
    }
  }

  const prev = async () => {
    if (currentIndex !== 0) {
      direction = 'backward'
      await nextTick()
      currentIndex--
      updateCurrentStep(currentStep.id)
    }
  }

  function findStepIndex(stepId?: string) {
    return stepId ? steps?.findIndex(step => step.id === stepId) : 0
  }

  return {
    close,
    jumpToStep,
    next,
    prev,
    ...$$({
      continueDisabled,
      currentIndex,
      currentStep,
      direction,
      isAllStepsAnswered,
      isContentTopic,
      nextDisabled,
      percentComplete,
      prevDisabled,
      stepComponent,
    }),
  }
}
