<template>
  <AtroContent v-auto-animate="{ duration }">
    <slot :is-expanded="isExpanded" :toggle-expand="toggleExpand" />
    <slot v-if="isExpanded" name="expanded-content" />
  </AtroContent>
</template>

<script setup lang="ts">
import { Props as ContentProps } from '@/components/globals/atro/Content.vue'

export interface Props extends ContentProps {
  duration?: number
}

const { duration = 200 } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'expand'): void
  (e: 'collapse'): void
}>()

let isExpanded = $ref(false)

const toggleExpand = () => {
  isExpanded = !isExpanded
  isExpanded ? emit('expand') : emit('collapse')
}
</script>
