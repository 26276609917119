import useData from '@/composables/useData'
import useUserMeta from '@/composables/meta/useUserMeta'

export default function useCurrentUser() {
  const fetchState = useData<User>('currentUser')
  const meta = useUserMeta(fetchState.data)

  return {
    fetchState,
    meta,
    data: fetchState.data,
  }
}
