<template>
  <AtroGridTableRow :expand-button-disabled="!hasSecurityFixes" :expand-toggleable="isExpandable">
    <template #default>
      <RouterLink class="group contents" :to="{ name: 'teamMember', params: { id: user.id } }">
        <AtroUserDisplay :user="user" hide-avatar-when-mobile>
          <AtroProgress
            class="mt-1 mr-4 max-w-[181px] sm:mt-0"
            size="small"
            :progress="user.progress"
          />
          <AtroSpan class="mt-1 whitespace-nowrap text-atro-gray-1 sm:mt-0" size="small" semibold>{{
            progressText
          }}</AtroSpan>
        </AtroUserDisplay>

        <AtroContent items="center" justify="center">
          <SecurityFixesStateLabel
            text-style="short"
            :count="securityFixesNotFixedCount"
            :has-addressed-security-fixes="hasAddressedSecurityFixes"
          />
        </AtroContent>
        <AtroContent items="center" justify="center">
          <AtroCircleGauge
            size="small"
            :fill-percent="user.score?.percent"
            :count="user.score?.atroScore"
          />
        </AtroContent>
      </RouterLink>
    </template>

    <template #expandableContent>
      <div class="mt-6 sm:px-16">
        <SecurityFixListItem
          v-for="securityFix in user.securityFixes"
          :key="securityFix.id"
          class="border-t-2 py-2 sm:pr-6"
          :security-fix="securityFix"
        />
      </div>
    </template>
  </AtroGridTableRow>
</template>

<script setup lang="ts">
import useSecurityFixesMeta from '@/composables/meta/useSecurityFixesMeta'
import SecurityFixesStateLabel from '@/components/securityFixes/SecurityFixesStateLabel.vue'
import SecurityFixListItem from '@/components/securityFix/SecurityFixListItem.vue'
import useUserMeta from '@/composables/meta/useUserMeta'

export interface Props {
  user: User
  expandable?: boolean
  progressType?: ProgressType
}

const { expandable, user, progressType } = defineProps<Props>()

// TODO: figure out how to send security fixes down while retaining reactivity
const { hasSecurityFixes, hasAddressedSecurityFixes, securityFixesNotFixedCount } = $(
  useSecurityFixesMeta(user.securityFixes)
)
const { progressText } = useUserMeta(user, progressType)

const isExpandable = $computed(() => expandable && hasSecurityFixes)
</script>