<template>
  <AtroContent class="h-full w-full flex-1" items="center" justify="center">
    <AtroHeading class="text-white">Loading...</AtroHeading>
  </AtroContent>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'

const { isLoading, loginWithRedirect } = useAuth0()

// we have to watch to wait for auth0 to load
watch(
  isLoading,
  async () => {
    if (!isLoading.value) {
      loginWithRedirect({
        screen_hint: 'signup',
        appState: {
          target: `/signup${window.location.search}`,
        },
      })
    }
  },
  { immediate: true }
)
</script>
