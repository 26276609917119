<template>
  <AtroAsync
    empty-heading="No assessments left to assign"
    empty-icon-name="empty_tab_happy"
    :config="fetchState"
  >
    <div
      v-auto-animate
      class="w-full space-y-3 sm:grid sm:grid-cols-assessments sm:gap-3 sm:space-y-0"
    >
      <AssessmentAdminCard
        v-for="(assessment, i) in assessments"
        :key="assessment.id"
        :assessment="assessment"
        :disabled="isOnboarding && i !== onboardingAssessmentIndex"
        responsive
      >
        <template v-if="isOnboarding && i === onboardingAssessmentIndex" #tooltip="{ target }">
          <AtroOnboardingTooltip
            :heading="ONBOARDING_COPY.teamAssessmentsDiscoverTooltip.heading"
            :body="ONBOARDING_COPY.teamAssessmentsDiscoverTooltip.body"
            :target="target"
          />
        </template>
      </AssessmentAdminCard>

      <!-- Suggest Assessment -->
      <AtroCard class="w-full" hoverable @click="isSuggestAssessmentModalOpen = true">
        <AtroContent class="flex-1" justify="center" col>
          <AtroContent class-="w-full" items="center" wrap="nowrap" col>
            <AtroIconSquare class="mb-4 h-16 w-16 shrink-0" icon-name="plus" circle />
            <AtroHeading
              class="text-atro-dark-purple group-hover:text-atro-purple"
              size="small"
              semibold
              >Request an assessment</AtroHeading
            >

            <AtroParagraph class="mt-2 text-center text-atro-gray-1 line-clamp-3" size="small"
              >Not seeing what you need? We’re here to help.</AtroParagraph
            >
          </AtroContent>
        </AtroContent>

        <AtroContent class="mt-auto w-full self-end pt-10" items="center" justify="end">
          <AtroButton icon-right="chevron_right" variant="transparent">Request</AtroButton>
        </AtroContent>
      </AtroCard>
    </div>
  </AtroAsync>

  <SuggestAssessmentModal
    :is-open="isSuggestAssessmentModalOpen"
    @close="isSuggestAssessmentModalOpen = false"
  />
</template>

<script setup lang="ts">
import { ONBOARDING_COPY } from '@/lib/constants'
import useAssessments from '@/composables/data/useAssessments'
import useOnboarding from '@/composables/useOnboarding'
import AssessmentAdminCard from '@/components/assessment/admin/AssessmentAdminCard.vue'
import SuggestAssessmentModal from '@/components/assessments/SuggestAssessmentModal.vue'

const { fetchState, data: assessments } = $(useAssessments('discover'))
const { onboardingKeys } = $(useOnboarding())

const onboardingAssessmentIndex = $computed(() =>
  assessments.findIndex(assessment => assessment.isOnboarding)
)

const isOnboarding = $computed(
  () =>
    onboardingKeys.seenTeamAssessments &&
    !onboardingKeys.activatedFirstAssessment &&
    onboardingAssessmentIndex >= 0
)

let isSuggestAssessmentModalOpen = $ref(false)
</script>
