<template>
  <AtroGridTable padding="small" :columns="columnsConfig" :data="securityFixes" rollup>
    <template #default="{ columnStyle, items }">
      <SecurityFixesTeamAssignedToTableRow
        v-for="securityFix in items"
        :key="securityFix.id"
        :col-style="columnStyle"
        :security-fix="securityFix"
      />
    </template>
  </AtroGridTable>
</template>
<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'
import SecurityFixesTeamAssignedToTableRow from '@/components/securityFixes/team/SecurityFixesTeamAssignedToTableRow.vue'

export interface Props {
  securityFixes: SecurityFix[]
}

const { securityFixes } = defineProps<Props>()

const isMobile = $(useIsMobile())

const columnsConfig: ColumnConfig[] = [
  {
    heading: {
      align: 'start',
      text: 'Affected member',
    },
  },
  {
    heading: {
      text: 'Fix status',
    },
    fixedWidth: true,
    minColumnWidth: isMobile ? 110 : 150,
  },
]
</script>
