<template>
  <AtroModal fullscreen-when-mobile>
    <template #default="{ close }">
      <AtroContent class="w-full space-y-6" col>
        <AtroContent class="w-full" items="center" col>
          <AtroIconSquare class="!p-2" icon-name="integration_detailed" circle />
          <AtroHeading class="mb-2 mt-6" semibold>Manage connection</AtroHeading>
          <AtroSpan>Control the connection to this service</AtroSpan>
        </AtroContent>

        <!-- <AtroContent class="mb-6 w-full" col>
      <AtroHeading size="small" semibold>Connection</AtroHeading>
      <AtroSpan class="text-atro-gray-1">Temporarily pause this integration</AtroSpan>
      <AtroButton class="mt-6" icon-right="connect" variant="light" block-when-mobile>{{
        isDisconnected ? 'Reconnect' : 'Disconnect'
      }}</AtroButton>
    </AtroContent> -->

        <AtroContent class="w-full" items="center" col>
          <AtroButton
            variant="light"
            block-when-mobile
            :icon-right="refreshButtonIcon"
            :is-pending="refreshPending"
            @click="onRefresh"
            >{{ refreshButtonText }}</AtroButton
          >
        </AtroContent>

        <AtroContent class="w-full rounded-2xl bg-atro-gray-4 p-4" items="center" col>
          <AtroHeading size="small" semibold>Delete this service</AtroHeading>
          <AtroSpan class="text-atro-gray-1">Remove the connection and delete all data</AtroSpan>

          <AtroButton
            class="mt-6 !text-atro-magenta !ring-atro-magenta"
            icon-right="trash"
            variant="light"
            block-when-mobile
            @click="close({ openDeleteModal: true })"
            >Delete</AtroButton
          >
        </AtroContent>
      </AtroContent>
    </template>

    <template #actions="{ close }">
      <AtroContent class="mt-8 w-full" justify="center">
        <AtroButton class="min-w-[200px]" block-when-mobile @click="close">Done</AtroButton>
      </AtroContent>
    </template>
  </AtroModal>
</template>

<script setup lang="ts">
import { useIntegrationRefreshMutation } from '@/composables/mutations/useIntegrationMutation'
import { Props as ModalProps } from '@/components/globals/atro/Modal.vue'
import { Integration } from '@/types/integration'
import { IconName } from '@/assets/icons/atro-icon.model'

interface Props extends ModalProps {
  integration: Integration
}

const { integration } = defineProps<Props>()

const { refreshIntegration } = useIntegrationRefreshMutation(integration)

let refreshButtonText = $ref('Refresh Data')
let refreshButtonIcon = $ref<IconName>('integration')
let refreshPending = $ref(false)

const onRefresh = async () => {
  refreshPending = true
  try {
    await refreshIntegration()
    refreshButtonText = 'Data Refreshed!'
    refreshButtonIcon = null
    setTimeout(() => {
      refreshButtonText = 'Refresh Data'
      refreshButtonIcon = 'integration'
    }, 3000)
  } catch (e) {}
  refreshPending = false
}
</script>
