<template>
  <AtroAsync :config="fetchState">
    <MobileScoreInline
      v-if="isMobile"
      heading="Assessment Score"
      sub-heading="Risk in one number"
      :score="fetchState.data?.value.score"
    />

    <AssessmentPageHeader :assessment="assessment" view="admin" />

    <AtroContent class="mt-10 w-full space-y-10">
      <AssessmentSectionSecurityFixes v-if="isStarted" view="adminRead" :assessment="assessment" />
      <AssessmentAdminSectionTeamProgress v-if="isStarted" :assessment="assessment" />
      <AssessmentSectionTopics view="admin" :assessment="assessment" />
    </AtroContent>
  </AtroAsync>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { ResourcePath } from '@/utils/resourcePaths'
import useAssessment from '@/composables/data/useAssessment'
import useIsMobile from '@/composables/useIsMobile'
import AssessmentPageHeader from '@/components/assessment/AssessmentPageHeader.vue'
import AssessmentSectionSecurityFixes from '@/components/assessment/AssessmentSectionSecurityFixes.vue'
import AssessmentSectionTopics from '@/components/assessment/AssessmentSectionTopics.vue'
import AssessmentAdminSectionTeamProgress from '@/components/assessment/admin/AssessmentAdminSectionTeamProgress.vue'
import MobileScoreInline from '@/components/mobile/MobileScoreInline.vue'

const route = useRoute()
const isMobile = useIsMobile()

const {
  fetchState,
  data: assessment,
  meta: { isStarted },
} = useAssessment(route.name as ResourcePath, route.params.id as string)
</script>
