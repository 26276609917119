import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'
import { org1 } from '@/data/org'
import { scoreHalf } from '@/data/scores'

export const mockPaths = {
  org: 'org',
  orgScore: 'org/score',
}

const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  // GET Org
  rest.get(getPath('org'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(org1))
  }),

  // GET Org Score
  rest.get(getPath('orgScore'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(scoreHalf))
  }),

  // PATCH Org
  rest.patch(getPath('org'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),

  // DELETE Org
  rest.delete(getPath('org'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),
]
