import * as Sentry from '@sentry/vue'
import { FormKitNode } from '@formkit/core'

export default function useFormSubmit<T>(submit: (data: T) => Promise<any>) {
  let isPending = $ref(false)

  const onSubmit = async (data, node: FormKitNode) => {
    node.clearErrors()
    isPending = true
    try {
      await submit(data)
    } catch (e) {
      if (e?.response?.data?.message) {
        node.setErrors(e?.response?.data?.message)
      } else {
        node.setErrors('There was an error submitting the form')
      }
      if (e?.response?.status >= 500) {
        Sentry.captureException(e)
      }
    }
    isPending = false
  }

  return {
    onSubmit,
    ...$$({
      isPending,
    }),
  }
}
