<template>
  <AtroButton class="!absolute top-4 right-4 text-xs" variant="dark" @click="signout"
    >Logout</AtroButton
  >
  <AtroContent col class="max-w-md text-white">
    <AtroHeading size="larger" semibold>Almost There...</AtroHeading>
    <AtroParagraph class="mb-8" size="large"
      >You’ve been added to our Beta waitlist, we’ll email you when your account is
      ready.</AtroParagraph
    >
    <AtroParagraph class="mb-4" size="large"
      >You can skip the line and get instant access by signing up for the 14 day
      trial</AtroParagraph
    >
    <AtroContent class="mb-4 w-full" items="center" col>
      <a href="https://buy.stripe.com/4gweV37vO7S55y0000" target="_blank">
        <AtroButton class="px-16 !ring-0" icon-left="key_solid" variant="light"
          >Get a token now</AtroButton
        >
      </a>
      <AtroSpan class="mt-4">or</AtroSpan>
    </AtroContent>

    <AtroSpan class="mb-4" size="large">Enter a token to continue</AtroSpan>

    <SignupValidateTokenInput @token-valid="onTokenValidated" />
  </AtroContent>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import SignupValidateTokenInput, {
  TokenValidProperties,
} from '@/components/signup/SignupValidateTokenInput.vue'

const { logout } = useAuth0()

const emit = defineEmits<{
  (e: 'tokenInvalid'): void
  (e: 'tokenValidated', value: TokenValidProperties): void
}>()

const onTokenValidated = (value: TokenValidProperties) => {
  emit('tokenValidated', value)
}

const signout = () => {
  logout({ returnTo: window.location.origin })
}
</script>
