import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'
import {
  awsIntegration,
  githubIntegration,
  googleIntegration,
  gustoIntegration,
  quickbooksIntegration,
} from '@/data/integrations'

export const mockPaths = {
  integrationMonitoring: 'integrations/:id/monitoring',
  integrationDiscovery: 'integrations/discovery/:id',
  integrationsDiscovery: 'integrations/discovery',
  integrationOrg: 'integrations/org-integrations/:id',
  integrationsOrg: 'integrations/org-integrations',
  monitoredIntegrations: 'integrations/monitoring',
}

const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  // GET: SHOW Integration Discovery
  rest.get(getPath('integrationDiscovery'), (req, res, ctx) => {
    const response = [
      awsIntegration,
      githubIntegration,
      googleIntegration,
      gustoIntegration,
      quickbooksIntegration,
    ].find(integration => integration.id === req.params.id)
    return res(ctx.delay(), ctx.json(response))
  }),

  // GET: INDEX Integrations Discovery
  rest.get(getPath('integrationsDiscovery'), (req, res, ctx) => {
    return res(
      ctx.delay(),
      ctx.json([
        githubIntegration,
        googleIntegration,
        awsIntegration,
        gustoIntegration,
        quickbooksIntegration,
      ])
    )
  }),

  // GET: SHOW Org Integration
  rest.get(getPath('integrationOrg'), (req, res, ctx) => {
    const response = [
      awsIntegration,
      githubIntegration,
      googleIntegration,
      gustoIntegration,
      quickbooksIntegration,
    ].find(integration => integration.id === req.params.id)
    return res(ctx.delay(), ctx.json(response))
  }),

  // GET: INDEX Org Integrations
  rest.get(getPath('integrationsOrg'), (req, res, ctx) => {
    return res(
      ctx.delay(),
      ctx.json([
        githubIntegration,
        googleIntegration,
        awsIntegration,
        gustoIntegration,
        quickbooksIntegration,
      ])
    )
  }),

  // GET: SHOW Integration Monitoring
  // rest.get(getPath('integrationMonitoring'), (req, res, ctx) => {
  //   return res(ctx.delay(), ctx.json(integrationMonitoring))
  // }),

  // // GET: SHOW Mintored Integrations
  // rest.get(getPath('monitoredIntegrations'), (req, res, ctx) => {
  //   return res(
  //     ctx.delay(),
  //     ctx.json([
  //       googleMonitoredIntegration,
  //       quickbooksMonitoredIntegration,
  //       githubMonitoredIntegration,
  //       awsMonitoredIntegration,
  //       gustoMonitoredIntegration,
  //     ])
  //   )
  // }),
]
