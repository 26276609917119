<template>
  <AtroUserDisplay size="large" :user="user" show-role-badge>
    <AtroContent
      class="w-full space-y-4 sm:space-x-8 sm:space-y-0"
      items="center"
      justify="between"
      wrap="nowrap"
      :col="isMobile"
    >
      <AtroContent items="center" :wrap="isMobile ? 'wrap' : 'nowrap'">
        <AtroProgress
          class="mt-2 mr-4 max-w-[374px] sm:mt-0"
          size="small"
          :progress="user.progress"
        />
        <AtroSpan class="mt-2 whitespace-nowrap text-atro-gray-1 sm:mt-0" size="small" semibold>{{
          progressTextExtended
        }}</AtroSpan>
      </AtroContent>

      <NudgeButton
        v-if="!isMobile"
        variant="light"
        :config="{ user, type: 'userGeneral' }"
        :disabled="!hasPendingWork"
        @nudge="nudgeUser"
      />
    </AtroContent>
  </AtroUserDisplay>

  <NudgeButton
    v-if="isMobile"
    class="mt-6"
    variant="light"
    block-when-mobile
    :config="{ user, type: 'userGeneral' }"
    :disabled="!hasPendingWork"
    @nudge="nudgeUser"
  />
</template>

<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'
import useUserMeta from '@/composables/meta/useUserMeta'
import { useNudgeUserMutation } from '@/composables/mutations/useNudgeMutation'
import NudgeButton from '@/components/nudge/NudgeButton.vue'

export interface Props {
  hasPendingWork: boolean
  user: User
}

const { user } = defineProps<Props>()

const isMobile = $(useIsMobile())
const { progressTextExtended } = useUserMeta(user)
const { nudgeUser } = useNudgeUserMutation(user.id)
</script>
