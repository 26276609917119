<template>
  <AtroContent
    class="fixed top-0 left-0 z-10 h-12 w-screen bg-white px-4 shadow-modal"
    justify="between"
    items="center"
  >
    <AtroIcon
      class="h-8 w-8 p-1 text-atro-purple"
      name="bars_sort_solid"
      @click="emit('openNav')"
    />

    <RouterLink :to="logoNavRoute">
      <AtroIcon
        class="absolute left-1/2 top-1/2 h-4 -translate-x-1/2 -translate-y-1/2 text-atro-purple"
        name="atro_logo_horizontal"
      />
    </RouterLink>

    <AtroIcon
      v-if="user.role === 'admin'"
      class="h-8 w-8 p-1 text-atro-purple"
      name="mobile_activity"
      @click="emit('openScore')"
    />
  </AtroContent>
</template>

<script setup lang="ts">
import useCurrentUser from '@/composables/data/useCurrentUser'

const { data: user } = $(useCurrentUser())

const emit = defineEmits<{
  (e: 'openNav'): void
  (e: 'openScore'): void
}>()

const logoNavRoute = $computed(() =>
  user.role === 'admin' ? { name: 'dashboardAdmin' } : { name: 'home' }
)
</script>
