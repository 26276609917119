import useData from '@/composables/useData'
import useSecurityFixesMeta from '@/composables/meta/useSecurityFixesMeta'
import { ResourcePath } from '@/utils/resourcePaths'

export default function useSecurityFixes(
  name: 'assessmentTeam' | 'assessmentUser' | 'org' | 'user',
  params:
    | {
        id: string
      }
    | {
        type: 'resolved' | 'unresolved' | 'inbox' | 'addressed'
      }
) {
  const resourcePathMap = {
    assessmentTeam: 'assessmentTeamSecurityFixes',
    assessmentUser: 'assessmentUserSecurityFixes',
    org: 'securityFixesOrg',
    user: 'securityFixesUser',
  }

  const fetchState = useData<SecurityFix[]>(resourcePathMap[name] as ResourcePath, params)
  const meta = useSecurityFixesMeta(fetchState.data)

  return {
    fetchState,
    meta,
    data: fetchState.data,
  }
}
