<template>
  <button
    ref="el"
    :class="[
      'btn',
      variant,
      block && 'w-full',
      error && 'error',
      isPending && 'is-pending',
      blockWhenMobile && isMobile && 'w-full',
    ]"
    :disabled="isPending"
    :style="{ width: buttonWidth }"
    @click="(e: any) => e.target.blur()"
  >
    <template v-if="isPending">
      <AtroLoading size="small" />
    </template>
    <template v-else>
      <AtroIcon v-if="iconLeft" class="icon left shrink-0 text-current" :name="iconLeft" />
      <slot />
      <AtroIcon v-if="iconRight" class="icon right shrink-0 text-current" :name="iconRight" />
    </template>
  </button>
</template>

<script setup lang="ts">
import { ButtonHTMLAttributes, ref } from 'vue'
import { useElementBounding } from '@vueuse/core'
import { IconName } from '@/assets/icons/atro-icon.model'
import useIsMobile from '@/composables/useIsMobile'

export interface Props extends ButtonHTMLAttributes {
  block?: boolean
  blockWhenMobile?: boolean
  error?: boolean
  iconLeft?: IconName
  iconRight?: IconName
  isPending?: boolean
  variant?: 'primary' | 'series' | 'light' | 'dark' | 'transparent'
}

const {
  iconLeft,
  iconRight,
  isPending,
  block = false,
  blockWhenMobile = false,
  error = false,
  variant = 'primary',
} = defineProps<Props>()

const isMobile = $(useIsMobile())
const el = ref(null)
const { width } = useElementBounding(el)

const buttonWidth = $computed(() => {
  return isPending && width.value !== 0 ? `${width.value}px` : ''
})
</script>

<style lang="postcss">
.btn {
  @apply relative flex items-center justify-center whitespace-nowrap rounded-xl py-3 px-8 text-base font-semibold text-white transition-all enabled:cursor-pointer disabled:cursor-default disabled:opacity-25;
}

/* Icons */
.btn .icon {
  @apply h-4 w-4;
}

.btn .icon.left {
  @apply mr-2;
}

.btn .icon.right {
  @apply ml-2;
}

/* States */
.btn.is-pending {
  @apply pointer-events-none;
}

/* Variants */
.btn.primary {
  @apply bg-atro-purple text-white active:bg-atro-purple/50 hover:enabled:bg-atro-purple/75 disabled:opacity-25;
}
/* .btn.primary:focus {
  @apply bg-atro-purple after:absolute after:h-[calc(100%+8px)] after:w-[calc(100%+8px)] after:rounded-2xl after:border-2 after:border-atro-purple;
} */

.btn.light {
  @apply bg-white text-atro-purple ring-2 ring-inset ring-atro-purple hover:enabled:text-atro-purple/75 hover:enabled:ring-atro-purple/75 active:enabled:bg-atro-purple/25;
}
/* .btn.light:focus {
  @apply bg-white ring-atro-purple after:absolute after:h-[calc(100%+8px)] after:w-[calc(100%+8px)] after:rounded-2xl after:border-2 after:border-atro-purple;
} */

.btn.dark {
  @apply bg-[#030000]/20 hover:enabled:bg-[#030000]/80 disabled:opacity-40;
}

.btn.transparent {
  @apply bg-transparent px-0 py-1 text-atro-purple focus:ring-atro-purple hover:enabled:text-atro-purple-75 group-hover:enabled:text-atro-purple-75 group-hover:hover:enabled:text-atro-purple;
}
</style>
