<template>
  <div
    ref="el"
    class="max-h-screen w-full space-y-3 overflow-y-auto"
    :style="{ height: `${listHeight}px` }"
  >
    <AtroContent
      v-for="activitiesInDay in activitiesByDay"
      :key="activitiesInDay.dateText"
      v-auto-animate
      class="w-full space-y-3"
      col
    >
      <AtroContent class="sticky top-0 w-full border-b-2 bg-white pb-2">
        <AtroSpan class="text-atro-bright-purple" size="small" semibold>{{
          activitiesInDay.dateText
        }}</AtroSpan>
      </AtroContent>
      <ActivityItem
        v-for="activity in activitiesInDay.activities"
        :key="activity.id"
        :activity="activity"
      />
    </AtroContent>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { format, startOfDay } from 'date-fns'
import { useElementBounding, useWindowSize } from '@vueuse/core'
import ActivityItem from '@/components/ActivityItem.vue'

interface Props {
  activities: Activity[]
  bottomOffset?: number
}

const { activities, bottomOffset = 40 } = defineProps<Props>()

const el = ref()
const { top } = useElementBounding(el)
const { height: windowHeight } = useWindowSize()

const listHeight = $computed(() => windowHeight.value - top.value - bottomOffset)

// creates an object of signature {dayISOString: activity}
const activitiesByDay = computed(() => {
  return activities.reduce((obj, activity) => {
    const activityStartOfDay = startOfDay(new Date(activity.createdAt)).toISOString()

    if (obj[activityStartOfDay]) {
      obj[activityStartOfDay].activities.push(activity)
    } else {
      obj[activityStartOfDay] = {
        dateText: format(new Date(activity.createdAt), 'EEEE, MMM d yyyy'),
        activities: [activity],
      }
    }

    return obj
  }, {} as Record<string, { dateText: string; activities: Activity[] }>)
})
</script>
