<template>
  <slot v-if="isVisible" />
  <slot v-else name="else" />
</template>

<script setup lang="ts">
import { FeatureName } from '@/utils/features'
import useCurrentUser from '@/composables/data/useCurrentUser'
import useFeatureFlag from '@/composables/useFeatureFlag'

export interface Props {
  name: FeatureName
}

const { name } = defineProps<Props>()

const { data: user } = useCurrentUser()
const { isVisible } = useFeatureFlag(name, user)
</script>
