import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import features, { FeatureName } from '@/utils/features'
import { MaybeRef } from '@/types/utils'

export const overrides = ref({})
let initialized = false

export default function useFeatureFlags(user: MaybeRef<User>) {
  const router = useRouter()

  const flagMap = $computed<Record<string, boolean>>(() => {
    return Object.keys(features).reduce((obj, key) => {
      obj[key] =
        overrides.value[key] !== undefined
          ? overrides.value[key]
          : typeof features[key] === 'function'
          ? features[key](user)
          : features[key]
      return obj
    }, {})
  })

  const isVisible = (name: FeatureName): boolean => {
    return flagMap[name]
  }

  const overrideFeature = (name: FeatureName, enabled: boolean) => {
    overrides.value = { ...overrides.value, [name]: enabled }
  }

  // initilize with router feature flag overrides
  router.isReady().then(() => {
    if (initialized) return
    const _overides = {}
    for (const [key, value] of Object.entries(router.currentRoute.value.query)) {
      const featureName = key.match('feature\\[(.+?)\\]')?.[1]
      if (featureName) {
        _overides[featureName] = value === 'true'
      }
    }
    overrides.value = _overides

    watch([overrides, router.currentRoute], () => {
      const currentQueryParams = { ...router.currentRoute.value.query }
      const overridesAsQueryObj = Object.keys(overrides.value).reduce((obj, key) => {
        if (overrides.value[key]) {
          obj[`feature[${key}]`] = overrides.value[key]
        } else {
          delete currentQueryParams[`feature[${key}]`]
        }
        return obj
      }, {})
      router.replace({
        ...router.currentRoute.value,
        query: { ...currentQueryParams, ...overridesAsQueryObj },
      })
    })
    initialized = true
  })

  return { ...$$({ flagMap }), isVisible, overrideFeature }
}
