import { unref } from 'vue'
import { Topic } from '@/types/topic'
import { MaybeRef } from '@/types/utils'

export default function useTopicMeta(topic: MaybeRef<Topic>) {
  const _topic = $computed(() => unref(topic))
  const isStarted = $computed(() => _topic.state !== 'initial')
  const isCompleted = $computed(() => _topic.state === 'completed')
  const stepCountText = $computed(() =>
    _topic.stepsCount === 1 ? '1 Step' : `${_topic.stepsCount} Steps`
  )

  return $$({ isCompleted, isStarted, stepCountText })
}
