<template>
  <AtroContent col items="center">
    <AtroLoading size="large" />
    <AtroHeading size="larger">{{ heading }}</AtroHeading>
  </AtroContent>
</template>

<script lang="ts">
interface StepPendingConfig extends BaseStepConfig {
  headings: string[]
}
</script>

<script setup lang="ts">
import { onMounted } from 'vue'
import { getRandomInteger } from '@/utils/numbers'
import { BaseStep, BaseStepConfig } from '@/types/topicStep'

export interface StepPending extends BaseStep {
  config: StepPendingConfig
  kind: 'pending'
}

const { config } = defineProps<StepPending>()

const emit = defineEmits<{
  (e: 'next'): void
}>()

let heading = $ref(null)

onMounted(async () => {
  emit('next')
})

const updateHeading = () => {
  heading = config.headings?.[getRandomInteger(0, config.headings.length - 1)]
}

setInterval(updateHeading, 2000)
updateHeading()
</script>
