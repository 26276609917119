<template>
  <span v-html="_html" />
</template>

<script setup lang="ts">
import { micromark } from 'micromark'

export interface Props {
  markdown: string
  safe?: boolean
}

const { markdown, safe = false } = defineProps<Props>()

const _html = $computed(() => micromark(markdown, { allowDangerousHtml: !safe }))
</script>
