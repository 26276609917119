<template>
  <FormKit
    v-model="enabled"
    type="switch"
    :disabled="item.disabled"
    :help="helpText"
    :name="formFieldName"
  />
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { NOTIFICATION_TOGGLE_COPY } from '@/lib/constants'

export type NotificationScope = 'user' | 'org'

export interface Props {
  item: NotificationItem
  type: NotificationType
  notificationKey: string
  scope: NotificationScope
}

const { item, notificationKey, scope, type } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'change', updatedNotificationSetting: Notifications): void
}>()

const helpText = $computed(() => NOTIFICATION_TOGGLE_COPY?.[scope]?.[notificationKey]?.[type])
const formFieldName = $computed(() => `${type}-${notificationKey}-${type}`)

let enabled = $ref(item.enabled)

watch($$(enabled), () => {
  let updatedNotificationSetting: Notifications = {}
  updatedNotificationSetting[notificationKey] = { email: { ...item, enabled } }
  emit('change', updatedNotificationSetting)
})
</script>
