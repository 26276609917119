import { unref } from 'vue'
import { MaybeRef } from '@/types/utils'

export default function useSecurityFixMeta(_securityFix: MaybeRef<SecurityFix>) {
  const securityFix = $computed(() => unref(_securityFix))
  const userStatuses = $computed(() => unref(securityFix.userStatuses))
  const resolvedUserCount = $computed(
    () => userStatuses?.filter(securityFix => securityFix.status === 'fixed').length
  )

  const resolvedUserText = $computed(() => `${resolvedUserCount}/${userStatuses.length}`)

  const resolutionStateColorClass = $computed(() => {
    switch (securityFix.status) {
      case 'fixed':
        return 'bg-atro-sky-blue'
      default:
        return 'bg-atro-magenta'
    }
  })

  const resolutionText = $computed(() => {
    switch (securityFix.status) {
      case 'cantFix':
        return "Can't fix"
      case 'fixed':
        return 'Fixed'
      case 'ignored':
        return 'Ignored for now'
      case 'unaddressed':
        return 'Unaddressed'
      default:
        return ''
    }
  })

  const isResolved = $computed(() =>
    userStatuses ? resolvedUserCount === userStatuses?.length : securityFix.status !== 'unaddressed'
  )

  return $$({
    isResolved,
    resolvedUserCount,
    resolvedUserText,
    resolutionStateColorClass,
    resolutionText,
  })
}
