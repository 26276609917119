<template>
  <component
    :is="actionComponent"
    :class="[isMobile && ' w-full']"
    :action="action"
    @action="action => emit('action', action)"
  />
</template>

<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'
import IntegrationMonitoringCheckItemActionExternalLink from '@/components/integration/monitoring/checkItemActions/IntegrationMonitoringCheckItemActionExternalLink.vue'
import IntegrationMonitoringCheckItemActionInternalLink from '@/components/integration/monitoring/checkItemActions/IntegrationMonitoringCheckItemActionInternalLink.vue'
import IntegrationMonitoringCheckItemActionModal from '@/components/integration/monitoring/checkItemActions/IntegrationMonitoringCheckItemActionModal.vue'
import { DynamicAction } from '@/types/dynamicAction'

export interface Props {
  action: MonitoringCheckAction
}

const { action } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'action', action: DynamicAction): void
}>()

const isMobile = $(useIsMobile())

const actionComponent = $computed(() => {
  switch (action.type) {
    case 'external_link':
      return IntegrationMonitoringCheckItemActionExternalLink
    case 'internal_link':
      return IntegrationMonitoringCheckItemActionInternalLink
    case 'show_report':
      return IntegrationMonitoringCheckItemActionModal
  }
})
</script>
