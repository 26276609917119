import { watch } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

export default function useIsMobile() {
  let isMobile = $ref(false)
  const breakpoints = useBreakpoints(breakpointsTailwind)

  watch(
    breakpoints.sm,
    () => {
      isMobile = !breakpoints.isGreater('sm')
    },
    { immediate: true }
  )

  return $$(isMobile)
}
