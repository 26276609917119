import { computed } from 'vue'
import useData from '@/composables/useData'
import useOrgIntegrationsMeta from '@/composables/meta/useOrgIntegrationsMeta'
import { Integration } from '@/types/integration'

export default function useIntegrations(type?: 'monitoring' | 'discovery') {
  let data
  const fetchState = useData<Integration[]>('integrations')
  const meta = useOrgIntegrationsMeta(fetchState.data)
  if (type === 'monitoring') {
    data = computed(() =>
      fetchState.data.value?.filter(integration => integration.status !== undefined)
    )
    fetchState.isEmpty = computed(() => !data.value.length)
  } else {
    data = fetchState.data
  }

  return {
    data,
    fetchState,
    meta,
  }
}
