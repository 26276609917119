<template>
  <AtroGridTableRow>
    <AtroContent items="center" justify="start">
      <AtroUserDisplay :user="user" show-email />
    </AtroContent>

    <AtroContent items="center" justify="center">
      <AtroSpan v-if="user.isOwner" class="w-28 text-center text-atro-purple" semibold
        >Owner</AtroSpan
      >
      <FormKit
        v-else
        type="select"
        variant="transparent"
        :classes="{
          outer: 'w-28',
        }"
        :value="user.role"
        :options="ROLE_OPTIONS"
        @change="onRoleChange"
      />
    </AtroContent>

    <AtroContent v-if="false" :class="actionsColumnConfig?.rowClass" items="center" justify="end">
      <AtroButton class="w-full" variant="light" @click="emit('onDelete', user)"
        >Delete User</AtroButton
      >
    </AtroContent>
  </AtroGridTableRow>
</template>
<script setup lang="ts">
import { ROLE_OPTIONS } from '@/lib/constants'
import { useTeamMemberUpdateMutation } from '@/composables/mutations/useTeamMutation'
import { Props as GridTableRowProps } from '@/components/globals/atro/GridTableRow.vue'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'

export interface Props extends GridTableRowProps {
  user: User
  columnsConfig?: ColumnConfig[]
}

const { columnsConfig, user } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'onDelete', user: User): void
}>()

const { updateTeamMember } = useTeamMemberUpdateMutation(user)

const actionsColumnConfig = $computed(() =>
  columnsConfig?.find(config => config.name === 'actions')
)

const onRoleChange = e => {
  updateTeamMember({ ...user, role: e.target.value })
}
</script>
