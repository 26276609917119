<template>
  <AtroContent class="w-full" wrap="nowrap" col>
    <AtroContent class="w-full" wrap="nowrap">
      <AtroIcon class="h-20 w-20 shrink-0" :name="integration.icon" />
      <AtroContent class="ml-4 h-full w-full" wrap="nowrap" justify="center" col>
        <AtroHeading size="large" semibold>{{ integration.title }}</AtroHeading>
        <IntegrationDetailHeadingStatus
          v-if="!isMobile"
          :cta-pending="isConnecting"
          :integration="integration"
          @on-cta-click="onCtaClick"
        />
      </AtroContent>
    </AtroContent>

    <IntegrationDetailHeadingStatus
      v-if="isMobile"
      class="mt-2"
      :cta-pending="isConnecting"
      :integration="integration"
      @on-cta-click="onCtaClick"
    />

    <IntegrationManagementModal
      :integration="integration"
      :is-open="isManageIntegrationModalShown"
      @close="isManageIntegrationModalShown = false"
      @after-close="onAfterIntegrationManagmentModalClose"
    />

    <IntegrationErrorModal
      :integration-error="integration.error"
      :is-open="isIntegrationErrorModalShown"
      :on-continue="onConnectService"
      @close="isIntegrationErrorModalShown = false"
    />

    <DeleteModal
      button-text="Delete Integration"
      :is-open="isDeleteIntegrationModalShown"
      :sub-text="{
        confirmed: 'The integration has been removed',
        initial: 'This will disconnect the integration and delete all of its data',
      }"
      :on-delete="deleteIntegration"
      @close="onDeleteModalClose"
    />
  </AtroContent>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
import useIntegrationConnect from '@/composables/useIntegrationConnect'
import useIntegrationMeta from '@/composables/meta/useIntegrationMeta'
import { useIntegrationDestroyMutation } from '@/composables/mutations/useIntegrationMutation'
import useIsMobile from '@/composables/useIsMobile'
import DeleteModal from '@/components/DeleteModal.vue'
import IntegrationDetailHeadingStatus from '@/components/integration/IntegrationDetailHeadingStatus.vue'
import IntegrationErrorModal from '@/components/integration/IntegrationErrorModal.vue'
import IntegrationManagementModal from '@/components/integration/IntegrationManagementModal.vue'
import { Integration } from '@/types/integration'

export interface Props {
  integration: Integration
}

const { integration } = defineProps<Props>()

const isMobile = $(useIsMobile())
const router = useRouter()
const { isConnected, isErrored, recentlyConnected } = $(useIntegrationMeta($$(integration)))
const { destoryIntegration } = useIntegrationDestroyMutation(integration)

const connectService = useIntegrationConnect({
  onCancel: () => {
    isConnecting = false
  },
  onFailure: () => {
    isConnecting = false
  },
  onSuccess: (orgIntegrationId: string) => {
    isConnecting = false
    router.replace({
      name: 'integration',
      params: { id: orgIntegrationId },
      query: { ...router.currentRoute.value.query, tab: 'monitoring' },
      force: true,
    })
  },
})

let isConnecting = $ref(false)
let isManageIntegrationModalShown = $ref(false)
let isIntegrationErrorModalShown = $ref(recentlyConnected && !!isErrored)

const onCtaClick = async () => {
  if (isConnected && !isErrored) {
    isManageIntegrationModalShown = !isManageIntegrationModalShown
  } else if (isErrored) {
    isIntegrationErrorModalShown = true
  } else {
    onConnectService()
  }
}

const onConnectService = () => {
  isConnecting = true
  connectService(isErrored ? integration.integrationId : integration.id)
}

let isDeleteIntegrationModalShown = $ref(false)
const onAfterIntegrationManagmentModalClose = ({ openDeleteModal = false }) => {
  isDeleteIntegrationModalShown = openDeleteModal
}

let integrationDeleted = $ref(false)
const deleteIntegration = async () => {
  await destoryIntegration()
  integrationDeleted = true
  return true
}

const onDeleteModalClose = () => {
  isDeleteIntegrationModalShown = false
  if (integrationDeleted) {
    router.replace({ name: 'integrations' })
  }
}
</script>
