<template>
  <nav
    ref="target"
    v-scroll-lock="lockScroll"
    :class="[
      'fixed left-0 top-0 z-20 flex h-full max-h-screen flex-col items-center rounded-r-3xl transition-transform duration-300 ease-in-out sm:rounded-r-none',
      isMember ? 'bg-atro-purple' : 'bg-atro-dark-purple',
      ...computedClasses,
    ]"
  >
    <AtroContent col class="w-full flex-1 overflow-y-auto sm:overflow-y-visible" wrap="nowrap">
      <AtroContent col :class="['w-full flex-1', !showNav && 'sm:items-center']">
        <AtroContent
          col
          :class="[
            'w-full py-4 pl-5',
            isAdmin && 'rounded-br-3xl bg-atro-purple',
            !showNav && 'sm:items-center sm:px-0',
          ]"
        >
          <AtroCloseButton
            class="!absolute right-4 top-4 sm:!hidden"
            variant="dark"
            @click="emit('close')"
          />
          <AtroContent
            :class="['w-full', showNav && 'pr-4']"
            :justify="showNav ? 'between' : 'start'"
            :items="showNav ? 'start' : 'center'"
            :col="!showNav"
          >
            <RouterLink to="/">
              <AtroIcon :class="['w-10 text-white', showNav && 'hidden']" name="nav_atro_logo" />
              <AtroIcon
                :class="['h-[28px] w-[116px] ml-2 text-white', !showNav && 'sm:hidden']"
                name="atro_logo_horizontal"
              />
            </RouterLink>

            <AtroButton
              :class="[
                'hidden sm:block !px-2 !text-white/75 hover:!text-white',
                !showNav && 'mt-1',
              ]"
              variant="transparent"
              @click="toggleNav"
            >
              <AtroIcon
                :class="['w-6 h-6 transition-transform duration-200', showNav && 'rotate-180']"
                name="chevrons_right"
              />
            </AtroButton>
          </AtroContent>

          <AtroContent
            :class="[
              'mt-8 w-full flex-1 justify-center',
              !showNav && 'sm:items-center sm:justify-start',
            ]"
            col
          >
            <AtroSpan v-if="isAdmin" class="mb-3 text-white" size="xsmall">Admin</AtroSpan>
            <AtroNavList
              class="space-y-3"
              :nav-items="isAdmin ? navItems.admin : navItems.user"
              :show-tooltips="!showNav"
            >
              <template #link="{ navItem, isActive = false }">
                <NavDashboardNavItem
                  :expanded="showNav"
                  :is-active="isActive"
                  :nav-item="navItem"
                />
              </template>
            </AtroNavList>
          </AtroContent>
        </AtroContent>

        <AtroContent
          v-if="isAdmin"
          :class="[
            'mt-6 w-full justify-center pl-5',
            !showNav && 'sm:items-center sm:justify-start sm:px-0',
          ]"
          col
        >
          <AtroSpan class="mb-3 text-white" size="xsmall">Personal</AtroSpan>
          <AtroNavList class="space-y-3" :nav-items="navItems.user">
            <template #link="{ navItem, isActive = false }">
              <NavDashboardNavItem :expanded="showNav" :is-active="isActive" :nav-item="navItem" />
            </template>
          </AtroNavList>
        </AtroContent>
      </AtroContent>

      <AtroContent
        :class="['my-6 w-full space-y-3 sm:mt-0 sm:space-y-4', showNav ? 'px-6' : 'px-4']"
        :items="showNav ? 'start' : 'center'"
        col
      >
        <SystemAdminMenu
          v-if="SYSTEM_ADMINS.includes(auth0.user.value?.email)"
          class="w-full"
          :expanded="showNav"
        />
        <UserNav v-if="isMobile" />
        <template v-else>
          <UserMenu class="w-full" :expanded="showNav" />
        </template>
      </AtroContent>
    </AtroContent>
  </nav>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { auth0 } from '@/plugins/auth0'
import { onClickOutside } from '@vueuse/core'
import { SYSTEM_ADMINS } from '@/lib/constants'
import useCurrentUser from '@/composables/data/useCurrentUser'
import useDashboardNavItems from '@/composables/useDashboardNavItems'
import useIsMobile from '@/composables/useIsMobile'
import NavDashboardNavItem from '@/components/NavDashboardNavItem.vue'
import SystemAdminMenu from '@/components/systemAdmin/SystemAdminMenu.vue'
import UserMenu from '@/components/user/UserMenu.vue'
import UserNav from '@/components/user/UserNav.vue'

export interface Props {
  showNav: boolean
}

const { showNav } = defineProps<Props>()
const emit = defineEmits<{
  (e: 'close'): void
  (e: 'open'): void
}>()

const {
  meta: { isAdmin, isMember },
} = $(useCurrentUser())
const isMobile = $(useIsMobile())
const navItems = $(useDashboardNavItems())

const computedClasses = $computed(() => {
  let classes = []

  if (isMobile) {
    classes.push('w-[var(--nav-dashboard-mobile-width)]')
    if (showNav) {
      classes.push('translate-x-0')
    } else {
      classes.push('-translate-x-full')
    }
  } else {
    if (showNav) {
      classes.push('w-[var(--nav-dashboard-expanded-width)]')
    } else {
      classes.push('w-[var(--nav-dashboard-width)]')
    }
  }

  return classes
})

const lockScroll = $computed(() => isMobile && showNav)

const target = ref(null)
onClickOutside(target, () => isMobile && emit('close'))

const toggleNav = () => {
  showNav ? emit('close') : emit('open')
}
</script>
