<template>
  <AtroContent class="mb-6 w-full" col>
    <AtroContent class="w-full" justify="between" items="center">
      <AtroContent class="space-x-2" items="baseline" wrap="nowrap">
        <AtroContent col>
          <RouterLink v-if="routeTo" :to="routeTo">
            <AtroHeading
              class="whitespace-nowrap text-atro-slate-purple hover:text-atro-purple"
              semibold
              >{{ heading }}</AtroHeading
            >
          </RouterLink>
          <AtroHeading v-else class="whitespace-nowrap text-atro-slate-purple" semibold>{{
            heading
          }}</AtroHeading>
          <AtroParagraph
            v-if="subHeading && subHeadingWrap"
            class="text-atro-gray-1 sm:block sm:truncate"
            >{{ subHeading }}</AtroParagraph
          >
        </AtroContent>
        <template v-if="subHeading && !subHeadingWrap">
          <AtroDot class="mt-1 hidden shrink-0 self-center bg-atro-gray-1 sm:block" />
          <AtroParagraph class="hidden truncate text-atro-gray-1 sm:block" size="large">{{
            subHeading
          }}</AtroParagraph>
        </template>
      </AtroContent>
      <div v-if="slots.default" class="place-self-end">
        <slot />
      </div>
    </AtroContent>
    <AtroDivider v-if="divider" class="!mb-0" />
  </AtroContent>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export interface Props {
  heading: string
  divider?: boolean
  routeTo?: RouteLocationRaw
  subHeading?: string
  subHeadingWrap?: boolean
}

defineProps<Props>()

const slots = useSlots()
</script>
