<template>
  <AtroPageHeading heading="Team overview" sub-heading="Member scores and progress">
    <TeamInviteButton />
  </AtroPageHeading>

  <TeamStatusTable :fetch-state="fetchState" :rollup="rollup" />

  <AtroOnboardingModal
    icon-name="nav_item_team"
    onboarding-key="seenTeam"
    heading="Team Overview"
    body="This page shows the overall status of your team and individual team member details. Click on an individual member to see more information."
  />
</template>

<script setup lang="ts">
import TeamInviteButton from '@/components/team/TeamInviteButton.vue'
import TeamStatusTable from '@/components/team/TeamStatusTable.vue'
import useTeam from '@/composables/data/useTeam'

export interface Props {
  rollup?: boolean
}

const { rollup = false } = defineProps<Props>()

const { fetchState } = useTeam()
</script>
