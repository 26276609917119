<template>
  <MobileScoreInline
    v-if="isMobile"
    heading="Atro Score"
    sub-heading="Risk in one number"
    :score="score"
  />

  <AtroPageHeading heading="Security fixes" sub-heading="Identified risks in your environment" />

  <AtroTabGroup :tab-configs="tabConfigs" />

  <AtroOnboardingModal
    icon-name="nav_item_team_security_fixes"
    onboarding-key="seenTeamSecurityFixes"
    :heading="ONBOARDING_COPY.teamSecurityFixesModal.heading"
    :body="ONBOARDING_COPY.teamSecurityFixesModal.body"
  />
</template>

<script setup lang="ts">
import { ONBOARDING_COPY } from '@/lib/constants'
import useIsMobile from '@/composables/useIsMobile'
import { useOrgScore } from '@/composables/data/useOrgScore'
import useSecurityFixes from '@/composables/data/useSecurityFixes'
import SecurityFixesTeamResolved from '@/components/securityFixes/team/SecurityFixesTeamResolved.vue'
import SecurityFixesTeamUnresolved from '@/components/securityFixes/team/SecurityFixesTeamUnresolved.vue'
import MobileScoreInline from '@/components/mobile/MobileScoreInline.vue'

const isMobile = useIsMobile()
const { data: score } = useOrgScore()

const { data: securityFixesUnresolved } = $(useSecurityFixes('org', { type: 'unresolved' }))
const { data: securityFixesResolved } = $(useSecurityFixes('org', { type: 'resolved' }))

const tabConfigs = $computed(() => [
  {
    title: 'Unresolved',
    count: securityFixesUnresolved?.length,
    component: SecurityFixesTeamUnresolved,
  },
  {
    title: 'Resolved',
    count: securityFixesResolved?.length,
    deepLink: true,
    component: SecurityFixesTeamResolved,
  },
])
</script>
