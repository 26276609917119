<template>
  <div :class="classNames">
    <slot />
  </div>
</template>

<script lang="ts">
export const itemsOptions = {
  start: 'items-start',
  end: 'items-end',
  center: 'items-center',
  baseline: 'items-baseline',
  strech: 'items-strech',
}

export const justifyOptions = {
  start: 'justify-start',
  end: 'justify-end',
  center: 'justify-center',
  between: 'justify-between',
  around: 'justify-around',
  evenly: 'justify-evenly',
}

export const wrapOptions = {
  nowrap: 'flex-nowrap',
  wrap: 'flex-wrap',
  'wrap-reverse': 'flex-wrap-reverse',
}
</script>

<script setup lang="ts">
export interface Props {
  col?: boolean
  items?: keyof typeof itemsOptions
  justify?: keyof typeof justifyOptions
  wrap?: keyof typeof wrapOptions
}

const { col = false, items = 'start', justify = 'start', wrap = 'wrap' } = defineProps<Props>()

const classNames = $computed(() => [
  'flex min-w-0',
  col ? 'flex-col' : 'flex-row',
  itemsOptions[items],
  justifyOptions[justify],
  wrapOptions[wrap],
])
</script>
