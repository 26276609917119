import { worker } from '@/mocks/browser'

let demoModeEnabled = $ref(false)

export default function useDemoMode() {
  const disableDemoMode = () => {
    worker.stop()
    demoModeEnabled = false
  }

  const enableDemoMode = () => {
    if (!demoModeEnabled) {
      worker.start()
      demoModeEnabled = true
    }
    return demoModeEnabled
  }

  return {
    disableDemoMode,
    enableDemoMode,
    ...$$({
      isDemoMode: demoModeEnabled,
    }),
  }
}
