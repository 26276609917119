<template>
  <AtroPageHeading heading="Integrations" sub-heading="Connect Atro to your services" />

  <AtroAsync :config="fetchState">
    <div
      v-auto-animate
      class="w-full space-y-3 sm:grid sm:grid-cols-integrations sm:gap-4 sm:space-y-0"
    >
      <IntegrationCard
        v-for="integration in data"
        :key="integration.id"
        :integration="integration"
        :active="!!integration.status"
      />

      <!-- Suggest Integration -->
      <AtroCard class="w-full" hoverable @click="isSuggestIntegrationModalOpen = true">
        <AtroContent class-="w-full" wrap="nowrap">
          <AtroIconSquare class="h-16 w-16 shrink-0" icon-name="plus" circle />
          <AtroContent class="ml-5 w-full" col>
            <AtroContent class="w-full" items="start" justify="between" wrap="nowrap">
              <AtroHeading
                class="text-atro-dark-purple group-hover:text-atro-purple"
                size="small"
                semibold
                >Suggest an integration</AtroHeading
              >
            </AtroContent>

            <AtroParagraph class="mt-2 text-atro-gray-1 line-clamp-3" size="small"
              >Not seeing what you need? We’re here to help.</AtroParagraph
            >
          </AtroContent>
        </AtroContent>

        <AtroContent class="mt-auto w-full self-end pt-10" items="center" justify="end">
          <AtroButton icon-right="chevron_right" variant="transparent">Request</AtroButton>
        </AtroContent>
      </AtroCard>
    </div>
  </AtroAsync>

  <AtroOnboardingModal
    icon-name="integration"
    onboarding-key="seenIntegrations"
    :heading="ONBOARDING_COPY.integrationsModal.heading"
    :body="ONBOARDING_COPY.integrationsModal.body"
  />

  <SuggestIntegrationModal
    :is-open="isSuggestIntegrationModalOpen"
    @close="isSuggestIntegrationModalOpen = false"
  />
</template>

<script setup lang="ts">
import { ONBOARDING_COPY } from '@/lib/constants'
import useIntegrations from '@/composables/data/useIntegrations'
import IntegrationCard from '@/components/integration/IntegrationCard.vue'
import SuggestIntegrationModal from '@/components/integrations/SuggestIntegrationModal.vue'

const { data, fetchState } = $(useIntegrations())

let isSuggestIntegrationModalOpen = $ref(false)
</script>
