import { ref } from 'vue'
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'
import LogRocket from 'logrocket'

export const analytics = ref<Analytics>()

export default function useAnaytics() {
  if (!import.meta.env.DEV && !analytics.value) {
    AnalyticsBrowser.load({
      writeKey: import.meta.env.VITE_SEGMENT_WRITE_KEY as string,
    })
      .then(([response]) => {
        analytics.value = response

        LogRocket.getSessionURL(function (sessionURL) {
          analytics.value.track('LogRocket', {
            sessionURL: sessionURL,
          })
        })
      })
      .catch(e => {
        console.log('error loading segment')
      })
  }

  return analytics
}
