<template>
  <MobileScoreInline
    v-if="isMobile"
    heading="Atro Score"
    sub-heading="Overall security level"
    :score="user.score"
  />

  <AtroPageHeading heading="My security fixes" sub-heading="Address fixes to improve your score" />

  <AtroTabGroup :tab-configs="tabConfigs" />

  <AtroOnboardingModal
    icon-name="nav_item_my_security_fixes"
    onboarding-key="seenMySecurityFixes"
    :heading="ONBOARDING_COPY.mySecurityFixesModal.heading"
    :body="ONBOARDING_COPY.mySecurityFixesModal.body"
  />
</template>

<script setup lang="ts">
import { ONBOARDING_COPY } from '@/lib/constants'
import useIsMobile from '@/composables/useIsMobile'
import useCurrentUser from '@/composables/data/useCurrentUser'
import useSecurityFixes from '@/composables/data/useSecurityFixes'
import SecurityFixesMyAddressed from '@/components/securityFixes/my/SecurityFixesMyAddressed.vue'
import SecurityFixesMyInbox from '@/components/securityFixes/my/SecurityFixesMyInbox.vue'
import MobileScoreInline from '@/components/mobile/MobileScoreInline.vue'

const isMobile = useIsMobile()
const { data: user } = useCurrentUser()
const { data: securityFixesInbox } = $(useSecurityFixes('user', { type: 'inbox' }))
const { data: securityFixesAddressed } = $(useSecurityFixes('user', { type: 'addressed' }))

const tabConfigs = $computed(() => [
  {
    title: 'Inbox',
    count: securityFixesInbox?.length,
    component: SecurityFixesMyInbox,
  },
  {
    title: 'Addressed',
    count: securityFixesAddressed?.length,
    deepLink: true,
    component: SecurityFixesMyAddressed,
  },
])
</script>
