<template>
  <AtroContent class="w-full" col>
    <AtroHeading class="mb-8 flex items-center text-atro-slate-purple" semibold>
      <AtroIcon class="mr-2.5 h-4 w-4 text-atro-slate-purple" name="chart_line" />
      <span>Activity</span>
    </AtroHeading>
    <AtroAsync :config="fetchState">
      <ActivityList :activities="activities" />
    </AtroAsync>
  </AtroContent>
</template>

<script setup lang="ts">
import ActivityList from '@/components/ActivityList.vue'
import useActivity from '@/composables/data/useActivity'

const { fetchState, data: activities } = useActivity()
</script>
