import { auth0 } from '@/plugins/auth0'
import { popup } from '@/utils/window'
import { useQueryClient } from '@tanstack/vue-query'
import useAnaytics from '@/composables/useAnalytics'
import { onBeforeUnmount } from 'vue'

type Props = {
  onCancel: () => void
  onFailure: () => void
  onSuccess: (integrationId: string) => void
}

export default function useIntegrationConnect({ onCancel, onFailure, onSuccess }: Props) {
  const analytics = $(useAnaytics())
  const queryClient = useQueryClient()
  let receivedMessage = $ref(false)
  let _integrationId = $ref<string>()

  const onMessage = e => {
    receivedMessage = true
    if (e.data?.state === 'connectionSuccess') {
      queryClient.removeQueries(['integrations'])
      queryClient.invalidateQueries(['integrationOrg'])
      queryClient.invalidateQueries(['integrationDiscovery'])
      analytics?.track('integration:connection:success', _integrationId as any)
      onSuccess(e.data.data.integrationId)
    } else {
      analytics?.track('integration:connection:failure', _integrationId as any)
      onFailure()
    }
  }

  const removeListener = () => window.removeEventListener('message', onMessage)
  onBeforeUnmount(removeListener)

  return async function connect(integrationId: string) {
    _integrationId = integrationId
    const token = await auth0.getAccessTokenSilently()
    const url = `${
      import.meta.env.VITE_API_URL
    }/integrations/org-integrations/init?atro_authorization=${token}&integration_id=${integrationId}`

    const popupWindow = popup({ url })

    const timer = setInterval(function () {
      if (popupWindow.closed) {
        analytics?.track('integration:connection:cancel', integrationId as any)
        !receivedMessage && onCancel()
        clearInterval(timer)
        removeListener()
        receivedMessage = false
      }
    }, 500)

    window.addEventListener('message', onMessage)
  }
}
