<template>
  <AtroContent>
    <AtroSectionHeading heading="Team status" sub-heading="Your team’s progress so far" />
    <TeamStatusTable progress-type="topics" :fetch-state="fetchState" expandable />
  </AtroContent>
</template>

<script setup lang="ts">
import useAssessmentTeamMemberStatus from '@/composables/data/useAssessmentTeamMemberStatus'
import TeamStatusTable from '@/components/team/TeamStatusTable.vue'
import { Assessment } from '@/types/assessment'

export interface Props {
  assessment: Assessment
}

const { assessment } = defineProps<Props>()

const { fetchState } = useAssessmentTeamMemberStatus(assessment.id)
</script>
