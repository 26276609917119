import useData from '@/composables/useData'
import useAssessmentMeta from '@/composables/meta/useAssessmentMeta'
import { ResourcePath } from '@/utils/resourcePaths'
import { Assessment } from '@/types/assessment'

export default function useAssessment(name, id) {
  const fetchState = useData<Assessment>(name as ResourcePath, {
    id,
  })
  const meta = useAssessmentMeta(fetchState.data)

  return {
    fetchState,
    meta,
    data: fetchState.data,
  }
}
