<template>
  <AtroPageHeading :heading="heading" :previous-route-to="{ name: previousRouteName }" />
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()

const previousRouteName = $computed(() =>
  router.options.history.state.back?.toString().includes('/monitoring-center')
    ? 'monitoringCenter'
    : 'integrations'
)

const heading = $computed(() =>
  previousRouteName === 'monitoringCenter' ? 'Monitoring Center' : 'Integrations'
)
</script>
