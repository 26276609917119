import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'
import { topicDevices, topicRemote, topicWelcome } from '@/data/topics'

export const mockPaths = {
  completeTopic: 'topics/:id/complete',
  setCurrentStep: 'topics/:id/current-step',
  startTopic: 'topics/:id/start',
  topic: 'topics/:id',
}

const topics = [topicDevices, topicWelcome, topicRemote]

const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  // GET: SHOW Topic
  rest.get(getPath('topic'), (req, res, ctx) => {
    const response = topics.find(topic => topic.id === req.params.id)
    return res(ctx.delay(), ctx.json(response))
  }),

  // PATCH: Set Current Step
  rest.patch(getPath('setCurrentStep'), (req, res, ctx) => {
    const topic = topics.find(t => t.id === req.params.id)
    // @ts-ignore
    topic.currentStep = req.body.currentStep
    return res(ctx.delay(), ctx.json(null))
  }),

  // POST: Complete Topic
  rest.post(getPath('completeTopic'), (req, res, ctx) => {
    const topic = topics.find(t => t.id === req.params.id)
    topic.state = 'completed'
    return res(ctx.delay(), ctx.json(null))
  }),

  // POST: Start Topic
  rest.post(getPath('startTopic'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),
]
