<template>
  <div ref="root" class="icon-wrap"></div>
</template>

<script setup lang="ts">
import { pascalCase } from 'change-case'
import { onMounted, ref, watch } from 'vue'
import { IconName } from '@/assets/icons/atro-icon.model'
import * as icons from '@/assets/icons/atro-icon.model'

const root = ref(null)

interface Props {
  name: IconName
}

const { name = '' } = defineProps<Props>()

const setInnerHTML = () => {
  if (name) {
    const iconName = `icon${pascalCase(name)}`
    root.value.innerHTML = icons[iconName]?.data
  }
}

onMounted(() => {
  setInnerHTML()
})

watch(
  () => name,
  () => setInnerHTML()
)
</script>

<style lang="postcss">
.icon-wrap svg {
  @apply h-full w-full shrink-0;
}
</style>
