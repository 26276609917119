<template>
  <AtroGridTableRow :expand-toggleable="hasSecurityFixes">
    <template #default>
      <AtroContent wrap="nowrap">
        <AtroIconSquare v-if="!isMobile" size="small" :icon-name="assessment.icon" />

        <AtroContent class="ml-4" col>
          <AtroHeading
            class="text-atro-dark-purple group-hover:text-atro-purple"
            size="xsmall"
            semibold
            >{{ assessment.title }}</AtroHeading
          >
          <AtroProgress
            class="mt-1 mr-4 max-w-[241px] sm:mt-0"
            size="small"
            show-postfix-percentage
            :progress="assessment.progress"
          />
        </AtroContent>
      </AtroContent>

      <AtroContent items="center" justify="center">
        <SecurityFixesStateLabel
          text-style="short"
          :count="securityFixesNotFixedCount"
          :has-addressed-security-fixes="hasAddressedSecurityFixes"
        />
      </AtroContent>

      <AtroContent items="center" justify="center">
        <AtroCircleGauge
          size="small"
          :fill-percent="assessment.score?.percent"
          :count="assessment.score?.atroScore"
        />
      </AtroContent>
    </template>

    <!-- EXPANDED VIEW -->
    <template #expandableContent>
      <AtroContent class="mt-6 sm:px-16" col>
        <SecurityFixListItem
          v-for="securityFix in assessment.securityFixes"
          :key="securityFix.id"
          class="w-full border-t-2 py-2 sm:pr-6"
          :security-fix="securityFix"
        />
      </AtroContent>
    </template>
  </AtroGridTableRow>
</template>

<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'
import useSecurityFixesMeta from '@/composables/meta/useSecurityFixesMeta'
import SecurityFixListItem from '@/components/securityFix/SecurityFixListItem.vue'
import SecurityFixesStateLabel from '@/components/securityFixes/SecurityFixesStateLabel.vue'
import { Assessment } from '@/types/assessment'

export interface Props {
  assessment: Assessment
}

const { assessment } = defineProps<Props>()

const isMobile = $(useIsMobile())
const { hasSecurityFixes, hasAddressedSecurityFixes, securityFixesNotFixedCount } = $(
  useSecurityFixesMeta(assessment.securityFixes)
)
</script>
