<template>
  <AtroContent
    class="topic-taker-bottom-bar"
    items="center"
    :justify="isMobile ? 'between' : 'center'"
    col
  >
    <AtroProgress
      v-if="isMobile"
      class="relative z-0 mx-auto"
      variant="topic-taker"
      :percent-complete="percentComplete"
    />

    <AtroContent class="space-x-6" items="center" justify="center">
      <AtroButton class="nav-button" variant="dark" :disabled="prevDisabled" @click="$emit('prev')">
        <AtroIcon class="h-full w-full text-white" name="chevron_left" />
      </AtroButton>

      <AtroButton
        variant="light"
        :is-pending="continuePending"
        :disabled="continueDisabled"
        @click="$emit('continue')"
        >{{ continueButtonText }}</AtroButton
      >

      <AtroButton class="nav-button" variant="dark" :disabled="nextDisabled" @click="$emit('next')">
        <AtroIcon class="h-full w-full text-white" name="chevron_right" />
      </AtroButton>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { onKeyUp } from '@vueuse/core'
import useIsMobile from '@/composables/useIsMobile'

interface Props {
  continueButtonText: string
  continueDisabled: boolean
  continuePending: boolean
  nextDisabled: boolean
  prevDisabled: boolean
  percentComplete: number
}

const {
  nextDisabled,
  percentComplete,
  prevDisabled,
  continueButtonText = 'Continue',
  continueDisabled = false,
  continuePending = false,
} = defineProps<Props>()

const emit = defineEmits<{
  (e: 'continue'): void
  (e: 'next'): void
  (e: 'prev'): void
}>()

const isMobile = useIsMobile()

onKeyUp(['ArrowLeft', 'ArrowRight'], e => (e?.key == 'ArrowLeft' ? emit('prev') : emit('next')))
</script>

<style lang="postcss" scoped>
.topic-taker-bottom-bar {
  @apply absolute bottom-0 left-0 z-10 h-[var(--topic-taker-bottom-bar-height)] w-full transform-gpu bg-atro-purple/65 p-4 backdrop-blur-2xl;
}

.nav-button {
  @apply h-10 w-10 !items-center !justify-center !rounded-full border-2 border-black/15 !bg-transparent !p-3;
}

.nav-button:hover {
  @apply !bg-black/15;
}

.nav-button:disabled {
  @apply !opacity-0;
}
</style>
