<template>
  <AtroContent
    ref="target"
    class="mobile-score-slide-over fixed top-0 right-0 z-20 h-screen w-[349px] rounded-l-3xl bg-white"
    col
  >
    <AtroCloseButton class="!absolute right-4 top-4" variant="light" @click="emit('close')" />
    <AtroContent class="w-full flex-1 p-6" items="center" col>
      <DashboardSideBarTeam />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import DashboardSideBarTeam from '@/components/dashboard/DashboardSideBarTeam.vue'

const emit = defineEmits<{
  (e: 'close'): void
}>()

const target = ref(null)
onClickOutside(target, () => emit('close'))
</script>

<style>
.mobile-score-slide-over {
  box-shadow: -4px 0px 25px rgba(84, 84, 147, 0.1);
}
</style>
