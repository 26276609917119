<template>
  <AtroBadge
    class="shrink-0 capitalize !text-atro-gray-1"
    variant="neutral"
    :label="statusText"
    :size="size"
  >
    <template #prefix>
      <div
        v-if="variant === 'simple'"
        :class="[
          'mr-1 h-2.5 w-2.5 rounded-full',
          isActive && 'bg-atro-highlight-green',
          (isDisconnected || isErrored) && 'bg-atro-magenta',
          !isConnected && 'bg-atro-gray-5',
          isPending && 'bg-atro-gray-1',
        ]"
      />
      <AtroIcon
        v-if="variant === 'icon'"
        :class="['mr-1 h-4 w-4', statusIconColorClass]"
        :name="statusIconName"
      />
    </template>
  </AtroBadge>
</template>

<script setup lang="ts">
import useIntegrationMeta from '@/composables/meta/useIntegrationMeta'
import { Integration } from '@/types/integration'
import { Props as BadgeProps } from '@/components/globals/atro/Badge.vue'

export interface Props {
  integration: Integration
  size?: BadgeProps['size']
  variant?: 'simple' | 'icon'
}

const { integration, size = 'small', variant = 'simple' } = defineProps<Props>()

const {
  isActive,
  isDisconnected,
  isErrored,
  isConnected,
  isPending,
  statusIconColorClass,
  statusIconName,
  statusText,
} = $(useIntegrationMeta($$(integration)))
</script>
