import useAssessments from '@/composables/data/useAssessments'
import useCurrentUser from '@/composables/data/useCurrentUser'
import useSecurityFixes from '@/composables/data/useSecurityFixes'
import { UsePopperOptions } from '@/composables/usePopper'
import { NavItem } from '@/types/nav'

export default function useDashboardNavItems() {
  const { data: user } = $(useCurrentUser())
  // Used for counts
  const { data: assessmentsInProgress } = $(useAssessments('user', 'in-progress'))
  const { data: securityFixesInbox } = $(useSecurityFixes('user', { type: 'inbox' }))

  const tooltipOptions: UsePopperOptions = {
    placement: 'right',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  }

  const dashboardAdmin: NavItem = {
    type: 'link',
    iconName: 'nav_item_dashboard',
    linkTo: { name: 'home' },
    tooltip: {
      options: tooltipOptions,
      text: 'Dashboard',
    },
  }
  const monitoringCenter: NavItem = {
    type: 'link',
    iconName: 'nav_item_monitoring_center',
    linkTo: { name: 'monitoringCenter' },
    tooltip: {
      options: tooltipOptions,
      text: 'Monitoring center',
    },
  }
  const myAssessments = $computed<NavItem>(() => ({
    type: 'link',
    activeAtRouteNames: [user.role === 'admin' ? 'assessmentUser' : 'home'],
    count: assessmentsInProgress?.length,
    iconName: 'nav_item_my_assessments',
    linkTo: { name: 'myAssessments' },
    tooltip: {
      options: tooltipOptions,
      text: 'My assessments',
    },
  }))
  const team: NavItem = {
    type: 'link',
    activeAtRouteNames: ['team', 'teamMember'],
    iconName: 'nav_item_team',
    linkTo: { name: 'team' },
    tooltip: {
      options: tooltipOptions,
      text: 'Team',
    },
  }
  const teamAssessments: NavItem = {
    type: 'link',
    activeAtRouteNames: ['assessmentDiscover', 'assessmentTeam'],
    iconName: 'nav_item_team_assessments',
    linkTo: { name: 'teamAssessments' },
    tooltip: {
      options: tooltipOptions,
      text: 'Assessments',
    },
  }
  const mySecurityFixes = $computed<NavItem>(() => ({
    type: 'link',
    count: securityFixesInbox?.length,
    iconName: 'nav_item_my_security_fixes',
    linkTo: { name: 'mySecurityFixes' },
    tooltip: {
      options: tooltipOptions,
      text: 'My security fixes',
    },
  }))

  const teamSecurityFixes: NavItem = {
    type: 'link',
    iconName: 'nav_item_team_security_fixes',
    linkTo: { name: 'teamSecurityFixes' },
    tooltip: {
      options: tooltipOptions,
      text: 'Security fixes',
    },
  }

  const navItems = $computed(() => {
    return {
      admin: [dashboardAdmin, monitoringCenter, teamAssessments, teamSecurityFixes, team],
      user: [myAssessments, mySecurityFixes],
    }
  })

  return $$(navItems)
}
