<template>
  <AtroContent class="space-y-3 sm:space-x-3 sm:space-y-reverse" justify="center">
    <AtroTag
      v-for="tag in tags"
      :key="tag.id"
      :size="size"
      :tag="tag"
      :active="selectedTagIds.includes(tag.id)"
      @toggle="toggleInSelected"
    />
  </AtroContent>
</template>

<script setup lang="ts">
import { Props as TagProps, Tag } from '@/components/globals/atro/Tag.vue'

export interface Props {
  tags: Tag[]
  selectedTagIds?: string[]
  size?: TagProps['size']
}

const { selectedTagIds = [], size = 'normal', tags = [] } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'selectTag', selectedTagIds: string[]): void
}>()

function toggleInSelected(tagId: string) {
  const tag = tags.find(tag => tag.id === tagId)
  const selectedMutuallyExclusiveTag = tags.find(
    tag => tag.exclusive && selectedTagIds.includes(tag.id)
  )
  let changedSelectedTagIds: string[] = []

  // toggling a tag
  if (selectedTagIds.includes(tagId)) {
    changedSelectedTagIds = selectedTagIds.filter(id => id !== tagId)
  } else {
    // selected the mutually exclusive tag
    if (tag.exclusive) {
      changedSelectedTagIds = [tagId]
    } else {
      // normal tag selection. makes sure to remove the mutually selected tag if it was already selected
      changedSelectedTagIds = [
        ...selectedTagIds.filter(id => id !== selectedMutuallyExclusiveTag?.id),
        tagId,
      ]
    }
  }

  emit('selectTag', changedSelectedTagIds)
}
</script>
