<template>
  <AtroGridTableRow :expand-toggleable="expandable">
    <template #default>
      <AtroContent items="center">
        <AtroHeading ref="tooltipTargetTitle" size="xsmall" semibold>{{
          securityFix.title
        }}</AtroHeading>
      </AtroContent>

      <AtroContent items="center" justify="center">
        <AtroRiskLevelIndicator :risk-level="securityFix.riskLevel" />
      </AtroContent>

      <AtroContent class="text-atro-dark-purple-75" items="center" justify="center">
        <AtroIcon class="mr-1.5 w-4 text-atro-gray-5" name="user" />
        <AtroSpan size="large" semibold>{{ isResolved ? 'Yes' : 'No' }}</AtroSpan>
      </AtroContent>
    </template>

    <!-- EXPANDED VIEW -->
    <template #expandableContent>
      <AtroContent class="col-span-3 mt-2" col>
        <AtroParagraph class="text-atro-dark-purple-75" size="small">{{
          securityFix.description
        }}</AtroParagraph>
        <AtroContent
          class="mt-3 w-full flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
        >
          <AtroContent v-if="showAttribution" class="pr-2" items="center" wrap="nowrap">
            <AtroSpan class="mr-2 text-atro-dark-purple-50" size="xxsmall" semibold
              >Assessment</AtroSpan
            >
            <AtroBadge variant="neutral" :label="securityFix.assessmentTitle" />
          </AtroContent>
        </AtroContent>
      </AtroContent>
    </template>
  </AtroGridTableRow>
</template>

<script setup lang="ts">
import useSecurityFixMeta from '@/composables/meta/useSecurityFixMeta'

export interface Props {
  securityFix: SecurityFix
  expandable?: boolean
  showAttribution?: boolean
}

const { securityFix, expandable = false, showAttribution = true } = defineProps<Props>()

const { isResolved } = useSecurityFixMeta($$(securityFix))
</script>
