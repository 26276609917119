<template>
  <component :is="signupStepComponent" :on-continue="onContinue" :on-skip="nextStep" :user="user" />
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import useSignupSteps from '@/composables/useSignupSteps'
import axios from '@/utils/axios'

export interface Props {
  token: string
  tokenType: TokenType
  user: SignupUser
}

const { token, tokenType, user } = defineProps<Props>()

const router = useRouter()

let signupParams = $ref({
  token,
  tokenType,
  user: null,
})

const { nextStep, signupStepComponent } = useSignupSteps({
  onComplete: async () => {
    await axios.post('signup', { signup: signupParams })
    router.replace('/')
  },
  signupType: 'user',
})

const onContinue = async data => {
  signupParams = { ...signupParams, ...data }
  await nextStep()
}
</script>
