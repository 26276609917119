<template>
  <div>
    <SecurityFixesStateLabel
      v-if="hasSecurityFixes"
      text-style="long"
      :count="securityFixesUnaddressedCount"
    />
    <AssessmentCardLineItems v-if="!isCaughtUp" :assessment="assessment" />
  </div>
</template>

<script setup lang="ts">
import useSecurityFixesMeta from '@/composables/meta/useSecurityFixesMeta'
import useAssessmentMeta from '@/composables/meta/useAssessmentMeta'
import AssessmentCardLineItems from '@/components/assessment/AssessmentCardLineItems.vue'
import SecurityFixesStateLabel from '@/components/securityFixes/SecurityFixesStateLabel.vue'
import { Assessment } from '@/types/assessment'

export interface Props {
  assessment: Assessment
}

const { assessment } = defineProps<Props>()
const { isCaughtUp } = $(useAssessmentMeta($$(assessment)))
const { hasSecurityFixes, securityFixesUnaddressedCount } = $(
  useSecurityFixesMeta(assessment.securityFixes)
)
</script>
