<template>
  <AtroButton block-when-mobile :icon-right="(ctaButtonIconName as IconName)">
    {{ ctaButtonText }}
  </AtroButton>
</template>

<script setup lang="ts">
import useIntegrationMeta from '@/composables/meta/useIntegrationMeta'
import { IconName } from '@/assets/icons/atro-icon.model'
import { Integration } from '@/types/integration'

export interface Props {
  integration: Integration
}

const { integration } = defineProps<Props>()

const { isConnected, isErrored } = $(useIntegrationMeta($$(integration)))

const ctaButtonText = $computed(() => {
  if (isErrored) {
    return 'Fix Connection'
  } else if (isConnected) {
    return 'Manage Connection'
  } else {
    return 'Connect'
  }
})

const ctaButtonIconName = $computed(() =>
  isConnected ? null : 'arrow_up_right_from_square_duotone'
)
</script>
