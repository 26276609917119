<template>
  <AtroContent class="w-full" items="center" justify="between" :wrap="isMobile ? 'wrap' : 'nowrap'">
    <AtroContent :class="[isMobile && 'mb-4']" items="center">
      <IntegrationStatusBadge size="normal" variant="icon" :integration="integration" />
      <AtroContent ref="tooltipRef" class="ml-2" col>
        <AtroSpan v-if="isErrored" class="text-atro-magenta" size="xsmall">{{
          integration.error.summary
        }}</AtroSpan>
        <AtroSpan
          v-if="showLastUpdated"
          :class="['!leading-5', isErrored ? 'text-atro-magenta' : 'text-atro-gray-1']"
          size="xsmall"
          >Latest update: {{ lastUpdatedAt }} ago</AtroSpan
        >
        <AtroSpan v-if="showLastRequested" class="!leading-5 text-atro-gray-1" size="xsmall"
          >Last requested: {{ lastRequestedAt }} ago</AtroSpan
        >
      </AtroContent>
      <AtroTooltip
        v-if="isConnected && !isMobile"
        text="Services control how often data is updated, we check every 20m"
        :tooltip-target-fn="() => tooltipRef"
      />
    </AtroContent>

    <IntegrationConnectButton
      block-when-mobile
      :is-pending="ctaPending"
      :integration="integration"
      @click="emit('onCtaClick')"
    />
  </AtroContent>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import IntegrationStatusBadge from '@/components/integration/IntegrationStatusBadge.vue'
import IntegrationConnectButton from '@/components/integration/IntegrationConnectButton.vue'
import useIntegrationMeta from '@/composables/meta/useIntegrationMeta'
import { Integration } from '@/types/integration'
import useIsMobile from '@/composables/useIsMobile'

export interface Props {
  integration: Integration
  ctaPending?: boolean
}

const { ctaPending, integration } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'onCtaClick'): void
}>()

const isMobile = $(useIsMobile())
const { isConnected, isErrored, lastRequestedAt, lastUpdatedAt } = $(
  useIntegrationMeta($$(integration))
)

let tooltipRef = ref()

const showLastRequested = $computed(() => isConnected && lastRequestedAt && !isErrored)
const showLastUpdated = $computed(() => isConnected && lastUpdatedAt)
</script>
