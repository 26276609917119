<template>
  <AtroGridTableRow>
    <AtroContent items="center" wrap="nowrap">
      <AtroUserAvatar class="mr-3 w-8" :name="securityFix.userName" />
      <AtroSpan size="small" semibold>{{ securityFix.userName }}</AtroSpan>
    </AtroContent>

    <AtroContent items="center" justify="center">
      <AtroDot :class="['mr-2', resolutionStateColorClass]" />
      <AtroSpan size="small" semibold>{{ resolutionText }}</AtroSpan>
    </AtroContent>
  </AtroGridTableRow>
</template>
<script setup lang="ts">
import useSecurityFixMeta from '@/composables/meta/useSecurityFixMeta'
import { Props as GridTableRowProps } from '@/components/globals/atro/GridTableRow.vue'

export interface Props extends GridTableRowProps {
  securityFix: SecurityFix
}

const { securityFix } = defineProps<Props>()

const { resolutionStateColorClass, resolutionText } = useSecurityFixMeta($$(securityFix))
</script>
