<template>
  <AtroContent
    :class="[
      'group p-1 sm:p-0',
      isActive ? 'text-white' : 'text-white/75',
      !expanded && 'sm:justify-center',
    ]"
    items="center"
  >
    <div
      :class="['relative w-10 rounded-xl p-1 group-hover:bg-white/20', isActive && 'bg-white/20']"
    >
      <AtroIcon
        :class="['rounded-xl transition-colors hover:text-white ']"
        :name="navItem.iconName"
      />
      <AtroSpan
        v-if="navItem.count"
        class="absolute -top-1 -right-1 h-4 min-w-[1rem] rounded-full bg-atro-magenta px-1 text-center !leading-snug text-white"
        size="xsmall"
        bold
        >{{ navItem.count }}</AtroSpan
      >
    </div>

    <AtroSpan
      :class="['ml-1 sm:group-hover:text-white', !expanded && 'sm:hidden']"
      :size="textSize"
      >{{ navItem.tooltip.text }}</AtroSpan
    >
  </AtroContent>
</template>
<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'
import { Props as SpanProps } from '@/components/globals/atro/Span.vue'
import { NavItem } from '@/types/nav'

export interface Props {
  isActive: boolean
  expanded: boolean
  navItem: NavItem
}

const { expanded } = defineProps<Props>()

const isMobile = $(useIsMobile())

const textSize = $computed<SpanProps['size']>(() => {
  return isMobile ? 'large' : expanded ? 'small' : 'large'
})
</script>
