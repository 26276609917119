<template>
  <AtroCard :class="hasData && 'group cursor-pointer'" @click="openReport">
    <AtroContent class="w-full flex-1" justify="between" col>
      <AtroContent class="w-full" justify="between" wrap="nowrap">
        <AtroContent class="pr-8" col>
          <AtroContent items="center" wrap="nowrap">
            <AtroHeading size="huge" semibold :class="[!hasData && 'text-atro-gray-5']">{{
              hasData ? report.highlight : '-'
            }}</AtroHeading>
            <AtroHeading class="ml-2">{{ report.title }}</AtroHeading>
          </AtroContent>

          <AtroParagraph class="mt-2 text-atro-gray-1">{{ report.description }}</AtroParagraph>
        </AtroContent>

        <AtroIconSquare :icon-name="report.icon" circle />
      </AtroContent>

      <AtroContent v-if="action" class="mt-6 w-full" justify="end">
        <AtroButton
          icon-right="chevron_right"
          variant="transparent"
          :disabled="!hasData"
          @click="openReport"
          >{{ action.label }}</AtroButton
        >
      </AtroContent>
    </AtroContent>
  </AtroCard>
</template>

<script setup lang="ts">
import { DynamicAction } from '@/types/dynamicAction'
import { IntegrationReport } from '@/types/integrationReport'

export interface Props {
  report: IntegrationReport
}

const { report } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'action', action: DynamicAction): void
}>()

const action = $computed(() => report.actions[0])
const hasData = $computed(() => report.data?.rows?.length)

const openReport = () => {
  if (action && hasData) {
    emit('action', action)
  }
}
</script>
