import { Assessment } from '@/types/assessment'
import { useBaseMutation } from './useBaseMutation'

type AssignParams = {
  includeFuture: boolean
  userIds: string[]
}

type AssessmentAssignMutationResponse = {
  assessmentId: string
}

export function useAssessmentAssignMutation(assessment: Assessment) {
  // assessment can be either in discovery or activated states which have different endpoints
  const assessmentPathType = assessment.status ? 'team' : 'discovery'
  const mutation = useBaseMutation<{ assign: AssignParams }, AssessmentAssignMutationResponse>({
    method: 'patch',
    url: `/assessments/${assessmentPathType}/${assessment.id}/assign`,
    invalidateKeys: [
      ['assessmentTeamMembersStatus'],
      ['assessmentsUser'],
      ['assessmentTeam', assessment.id],
    ],
    removeKeys: [['assessmentsDiscover'], ['assessmentsTeam']],
  })

  const assignAssessment = (assignConfig: AssignParams) => {
    return mutation.mutateAsync({ assign: assignConfig })
  }

  return { assignAssessment, ...mutation }
}
