<template>
  <AtroModal v-slot="{ close }" size="small" @after-close="reset">
    <AtroContent class="w-full text-center" items="center" col>
      <AtroIcon v-if="confirmed" class="h-18 w-18 text-atro-highlight-green" name="circle_check" />
      <AtroIconSquare v-else :icon-name="iconName" circle />
      <AtroHeading class="mt-4" size="large" semibold>{{ heading }}</AtroHeading>
      <AtroParagraph v-if="body" class="mt-2 mb-6 text-atro-gray-1">{{ body }}</AtroParagraph>
    </AtroContent>

    <AtroContent class="mt-6 w-full space-y-3" wrap="nowrap" col>
      <AtroButton v-if="confirmed" block @click="close">Close</AtroButton>
      <template v-else>
        <AtroButton block :is-pending="isPending" @click="confirm(close)">{{
          confirmActionText
        }}</AtroButton>

        <AtroButton v-if="cancelable" block variant="light" @click="cancel(close)">{{
          cancelActionText
        }}</AtroButton>
      </template>
    </AtroContent>
  </AtroModal>
</template>

<script setup lang="ts">
import { IconName } from '@/assets/icons/atro-icon.model'
import { Props as ModalProps } from '@/components/globals/atro/Modal.vue'

export interface Props extends ModalProps {
  onConfirm: () => boolean | Promise<boolean>
  afterConfifmHeadingText?: string
  afterConfifmBodyText?: string
  bodyText?: string
  cancelable?: boolean
  cancelActionText?: string
  confirmActionText?: string
  headingText?: string
  iconName?: IconName
  onCancel?: () => void
}

const {
  bodyText,
  afterConfifmBodyText,
  afterConfifmHeadingText,
  cancelable = true,
  cancelActionText = 'Nevermind',
  confirmActionText = 'Yes',
  headingText = 'Are you sure?',
  iconName = 'question',
  onCancel = () => null,
  onConfirm,
} = defineProps<Props>()

let confirmed = $ref(false)
let isPending = $ref(false)

const heading = $computed(() => (confirmed ? afterConfifmHeadingText : headingText))
const body = $computed(() => (confirmed ? afterConfifmBodyText : bodyText))

const cancel = (close: (params?: any) => void) => {
  onCancel()
  close()
}

const confirm = async (close: (params?: any) => void) => {
  isPending = true
  try {
    const result = await onConfirm()
    if (result) {
      if (afterConfifmHeadingText) {
        confirmed = true
      } else {
        close()
      }
    }
  } catch (e) {
    // TODO: handle error
  }
  isPending = false
}

const reset = () => {
  isPending = false
}
</script>
