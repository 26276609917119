export const activityAssessmentAssigned: Activity = {
  createdAt: '2022-01-10T19:50:06',
  id: '1',
  heading: 'Remote Work Essentials',
  subText: 'Assigned to everyone',
}

export const activityUserJoined: Activity = {
  createdAt: '2022-01-09T19:50:06',
  id: '2',
  heading: 'Roberto Sanabria',
  subText: 'Joined',
}

export const activityTeamScoreImproved: Activity = {
  createdAt: '2022-01-06T19:50:06',
  id: '3',
  heading: 'Team score improved!',
  subText: 'From 300 to 360',
}
