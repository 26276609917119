<template>
  <AtroExpandableContent class="w-full p-4 sm:p-6" wrap="nowrap" col>
    <template #default="{ isExpanded, toggleExpand }">
      <AtroContent
        class="group w-full cursor-pointer"
        wrap="nowrap"
        @click="onExpandToggle(isExpanded, toggleExpand)"
      >
        <AtroIcon
          :class="[
            'mt-2.5 h-6 w-6 shrink-0',
            integrationConnected ? statusIconColorClass : 'text-atro-gray-5',
          ]"
          :name="statusIconName"
        />
        <AtroContent class="ml-6 w-full" justify="between" items="center" wrap="nowrap">
          <AtroContent class="w-full" wrap="nowrap" col>
            <AtroHeading
              :class="['relative transition-all group-hover:text-atro-purple']"
              size="small"
              semibold
              >{{ monitoringCheck.title }}</AtroHeading
            >
            <AtroParagraph :class="['transition-opacity', statusSubTextColorClass]" size="small">{{
              statusSubText
            }}</AtroParagraph>
          </AtroContent>

          <AtroContent class="shrink-0 space-x-2" items="center" wrap="nowrap">
            <AtroMenu
              v-if="integrationConnected"
              class="peer"
              align="bottomLeft"
              :items="miniMenuItems"
              @action="onMenuAction"
            >
              <template #menuButton="{ open }">
                <MenuButton
                  :class="[
                    'rounded p-1 hover:bg-atro-gray-2 focus:bg-atro-gray-2 focus:ring-2 focus:ring-atro-focus-light',
                    open && 'bg-atro-gray-2',
                  ]"
                  @click.stop="() => {}"
                >
                  <AtroIcon class="h-6 w-6" name="mini_menu" />
                </MenuButton>
              </template>
            </AtroMenu>

            <AtroExpandToggle class="peer-hover:!bg-transparent" :expanded="isExpanded" />
          </AtroContent>
        </AtroContent>
      </AtroContent>
    </template>

    <template #expanded-content>
      <div class="w-full sm:pl-12 sm:pr-12">
        <AtroDivider size="large" />
        <AtroParagraph class="prose mt-2 text-atro-gray-1">
          <AtroMarkdown :markdown="monitoringCheck.description" />
        </AtroParagraph>
        <AtroContent
          v-if="integrationConnected"
          class="mt-8 space-y-2 sm:space-y-0 sm:space-x-4"
          items="center"
        >
          <IntegrationMonitoringCheckItemAction
            v-for="(action, i) in monitoringCheck.actions"
            :key="i"
            :action="action"
            @action="onAction"
          />
        </AtroContent>

        <AtroContent
          v-if="isDismissable && integrationConnected"
          wrap="nowrap"
          :class="['mt-4 rounded-2xl bg-atro-gray-4 p-4', isMobile ? 'space-y-4' : 'space-x-6']"
          :col="isMobile"
        >
          <FormKit
            v-model="monitoringCheck.dismissed"
            type="checkbox"
            :label="dismissAction.label"
            :classes="{ label: 'font-semibold' }"
            @change="e => toggleDismiss(e)"
          />
          <AtroSpan class="text-atro-gray-1" size="small"
            >This item will no longer be included in alert counts
          </AtroSpan>
        </AtroContent>
      </div>
    </template>
  </AtroExpandableContent>
</template>

<script setup lang="ts">
import template from '@/utils/template'
import useIsMobile from '@/composables/useIsMobile'
import IntegrationMonitoringCheckItemAction from '@/components/integration/monitoring/IntegrationMonitoringCheckItemAction.vue'
import useIntegrationMeta from '@/composables/meta/useIntegrationMeta'
import useMonitoringCheckMeta from '@/composables/meta/useMonitoringCheckMeta'
import {
  useIntegrationMonitoringCheckDisableMutation,
  useIntegrationMonitoringCheckDismissMutation,
} from '@/composables/mutations/useIntegrationMonitoringCheckMutation'
import { DynamicAction } from '@/types/dynamicAction'
import { IconName } from '@/assets/icons/atro-icon.model'
import { Integration } from '@/types/integration'
import { MenuItem } from '@/types/menu'

export interface Props {
  integration: Integration
  monitoringCheck: MonitoringCheck
}

const { integration, monitoringCheck } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'action', action: DynamicAction): void
  (e: 'onClose'): void
  (e: 'onExpand', close: () => void): void
}>()

const { disableMonitoringCheck } = useIntegrationMonitoringCheckDisableMutation(
  monitoringCheck.id,
  integration
)
const { dismissMonitoringCheck } = useIntegrationMonitoringCheckDismissMutation(
  monitoringCheck.id,
  integration
)

const { isConnected: integrationConnected } = $(useIntegrationMeta(integration))
const { dismissAction, isDismissable, isEnabled, isPending, status } = $(
  useMonitoringCheckMeta($$(monitoringCheck))
)
const isMobile = $(useIsMobile())

const miniMenuItems = $computed<MenuItem[]>(() => {
  if (isEnabled) {
    return [
      {
        label: 'Disable',
        action: 'disable',
      },
    ]
  } else {
    return [
      {
        label: 'Enable',
        action: 'enable',
      },
    ]
  }
})

const statusIconColorClass = $computed(() => {
  if (monitoringCheck.dismissed) return 'text-atro-highlight-green'
  switch (status) {
    case 'critical':
      return 'text-atro-magenta'
    case 'info':
      return 'text-atro-gray-1'
    case 'ok':
      return 'text-atro-highlight-green'
    case 'warning':
      return 'text-atro-yellow'
    default:
      return 'text-atro-gray-5'
  }
})

const statusSubText = $computed(() => {
  if (isPending) {
    return 'Processing...'
  } else {
    return template(monitoringCheck.subtitle, monitoringCheck.data)
  }
})

const statusSubTextColorClass = $computed(() => {
  switch (status) {
    case 'critical':
      return 'text-atro-magenta'
    case 'warning':
      return 'text-atro-yellow'
    default:
      return 'text-atro-gray-1'
  }
})

const statusIconName = $computed<IconName>(() => {
  if (monitoringCheck.dismissed) return 'circle_check'
  if (integrationConnected && isEnabled) {
    if (status === 'pending') {
      return 'spinner_bg'
    } else {
      switch (status) {
        case 'ok':
          return 'circle_check'
        default:
          return 'exclamation_square'
      }
    }
  } else {
    return 'circle_with_line'
  }
})

const onAction = (action: DynamicAction) => {
  emit('action', action)
}

const onExpandToggle = (isExpanded, toggle) => {
  if (isExpanded) {
    emit('onClose')
  } else {
    emit('onExpand', toggle)
  }
  toggle()
}

const onMenuAction = menuActionName => {
  switch (menuActionName) {
    case 'disable':
      disableMonitoringCheck(true)
      break
    case 'enable':
      disableMonitoringCheck(false)
      break
  }
}

const toggleDismiss = e => {
  dismissMonitoringCheck(e.target.checked)
}
</script>
