<template>
  <AtroCard class="assessment-discover-card"
    ><AtroContent class="flex-1 space-y-2 text-center" items="center" justify="center" col>
      <AtroIcon name="assessment_discover_card_graphic" />
      <AtroHeading class="mb-6" size="small" semibold>Assign assessments to your team</AtroHeading>
      <AtroButtonLink variant="light" :to="{ name: 'teamAssessments' }"
        >Browse Assessments</AtroButtonLink
      >
    </AtroContent></AtroCard
  >
</template>

<style lang="postcss" scoped>
.assessment-discover-card {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6E0FFFF' stroke-width='4' stroke-dasharray='15%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  @apply h-[var(--assessment-card-height)] min-w-[var(--assessment-card-width)] flex-1 border-none bg-atro-gray-4;
}
</style>
