import { unref } from 'vue'
import { RouteLocation, RouteLocationNormalized } from 'vue-router'
import { QueryClient } from '@tanstack/vue-query'
import { identifyUserInTrackers } from '@/lib/trackers'
import { buildFetcher } from '@/utils/fetcher'
import isSubset from '@/utils/objects'
import { watchEffectOnceAsync } from '@/utils/watch'
import { auth0 } from '@/plugins/auth0'
import { RedirectLoginOptions } from '@auth0/auth0-spa-js'

export type AuthGuardConfig = {
  user: boolean
  redirectToIfUser?: RouteLocationNormalized
  role?: User['role']
}

let apiUser: User

async function getApiUser(queryClient: QueryClient) {
  if (!apiUser) {
    apiUser = await queryClient.fetchQuery(['currentUser'], buildFetcher<User>('profile'), {
      staleTime: 1000 * 60 * 5,
    })
    identifyUserInTrackers(apiUser)
  }
}

async function auth0AuthGaurd(to: RouteLocation, options: RedirectLoginOptions = {}) {
  const fn = async () => {
    if (unref(auth0.isAuthenticated)) {
      return true
    }

    await auth0.loginWithRedirect({
      appState: { target: to.fullPath },
      ...options,
    })

    return false
  }

  if (!unref(auth0.isLoading)) {
    return fn()
  }

  await watchEffectOnceAsync(() => !unref(auth0.isLoading))

  return fn()
}

export default async function authGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  config: AuthGuardConfig | true,
  queryClient: QueryClient
) {
  if (to.query.demo) {
    await getApiUser(queryClient)
    return true
  }
  const isAuthenticated = await auth0AuthGaurd(to, {
    screen_hint: to.name === 'signup' ? 'signup' : 'login',
  })

  // handles case when we want to redirect an existing user if they try and access a unauthed route (signup)
  if (isAuthenticated && typeof config === 'object' && config.redirectToIfUser) {
    try {
      await getApiUser(queryClient)
      return config.redirectToIfUser
    } catch (e) {}
  }

  // check that an api user exists unless user check is specified as false in route config
  if (
    isAuthenticated &&
    (config === true || (typeof config === 'object' && config?.user !== false))
  ) {
    try {
      await getApiUser(queryClient)
      // user role does not match the route's required role - redirect to index
      if (typeof config === 'object' && !isSubset(apiUser, config)) {
        return '/'
      }
    } catch (e) {
      // TODO: handle other error types without redirecting to singup
      // no api user found so redirect to signup
      return { name: 'signup' }
    }
  }

  return isAuthenticated
}
