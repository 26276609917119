import { org1 } from '@/data/org'
import { unaddressedUserSecurityFix } from '@/data/securityFixes'

export const user1: User = {
  id: '1',
  createdAt: '2022-01-04T19:50:06',
  activeSeriesCount: '5',
  display: 'John Doe',
  firstName: 'John',
  lastName: 'Doe',
  isOwner: true,
  email: 'john.doe@example.com',
  role: 'admin',
  org: org1,
  notifications: {
    assessments: {
      email: {
        disabled: false,
        enabled: true,
      },
    },
    integrations: {
      email: {
        disabled: false,
        enabled: true,
      },
    },
  },
  progress: {
    numerator: 2,
    denominator: 5,
  },
  settings: {
    activatedFirstAssessment: true,
    addressedSecurityFix: true,
    completedFirstAssessment: true,
    seenApp: true,
    seenDashboard: true,
    seenDashboardAssessments: true,
    seenFirstAssessment: true,
    seenMyAssessments: true,
    seenIntegrations: true,
    seenMySecurityFixes: true,
    seenMonitoringCenter: true,
    seenTeam: true,
    seenTeamAssessments: true,
    seenTeamSecurityFixes: true,
    seenTeamSecurityFixesPopulated: true,
    startedFirstAssessment: true,
  },
  score: {
    atroScore: 200,
    percent: 20,
    riskText: 'Needs Work',
  },
  securityFixes: [unaddressedUserSecurityFix],
}

export const user2: User = {
  id: '2',
  createdAt: '2022-01-06T19:50:06',
  activeSeriesCount: '2',
  display: 'Jane Doe',
  firstName: 'Jane',
  lastName: 'Doe',
  email: 'jane.doe@example.com',
  role: 'member',
  org: org1,
  progress: {
    numerator: 4,
    denominator: 5,
  },
  settings: {},
  score: {
    atroScore: 700,
    percent: 70,
    riskText: 'Amazing',
  },
  securityFixes: [unaddressedUserSecurityFix],
}
