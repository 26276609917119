<template>
  <AtroContent class="w-full space-y-6" wrap="nowrap" col>
    <!-- About -->
    <AtroCard v-if="hasAboutSection" class="w-full">
      <AtroContent>
        <AtroIcon class="h-6 w-6" name="info" />
        <AtroHeading class="ml-2 mb-2" size="small" semibold>About</AtroHeading>
      </AtroContent>

      <AtroParagraph class="prose text-atro-gray-1">
        <AtroMarkdown :markdown="integration.description" />
      </AtroParagraph>
    </AtroCard>

    <!-- Automations -->
    <AtroCard v-if="hasAutomationsSection" class="w-full">
      <AtroContent>
        <AtroIcon class="h-6 w-6" name="integration" />
        <AtroHeading class="ml-2 mb-4" size="small" semibold>Automations</AtroHeading>
      </AtroContent>

      <AtroContent class="w-full" col>
        <AtroContent
          v-for="(automation, i) in integration.automations"
          :key="automation.title"
          class="w-full"
          col
        >
          <AtroSpan class="block">{{ automation.title }}</AtroSpan>
          <AtroParagraph class="prose text-atro-gray-1">
            <AtroMarkdown :markdown="automation.subtitle" />
          </AtroParagraph>
          <AtroDivider v-if="i !== integration.automations.length - 1" />
        </AtroContent>
      </AtroContent>
    </AtroCard>

    <!-- How it works -->
    <AtroCard v-if="hasHowItWorks" class="w-full">
      <AtroContent>
        <AtroIcon class="h-6 w-6" name="gear" />
        <AtroHeading class="ml-2 mb-2" size="small" semibold>How it works</AtroHeading>
      </AtroContent>

      <AtroParagraph class="prose text-atro-gray-1">
        <AtroMarkdown :markdown="integration.instructions" />
      </AtroParagraph>
    </AtroCard>

    <!-- Privacy -->
    <AtroCard v-if="hasPrivacy" class="w-full">
      <AtroContent>
        <AtroIcon class="h-6 w-6" name="privacy" />
        <AtroHeading class="ml-2 mb-2" size="small" semibold>Privacy</AtroHeading>
      </AtroContent>

      <AtroParagraph class="prose text-atro-gray-1">
        <AtroMarkdown :markdown="integration.privacyNotice" />
      </AtroParagraph>
    </AtroCard>
  </AtroContent>
</template>

<script setup lang="ts">
import { Integration } from '@/types/integration'

export interface Props {
  integration: Integration
}

const { integration } = defineProps<Props>()

const hasAboutSection = $computed(() => integration?.description)
const hasAutomationsSection = $computed(() => integration?.automations?.length)
const hasHowItWorks = $computed(() => integration?.instructions)
const hasPrivacy = $computed(() => integration?.privacyNotice)
</script>
