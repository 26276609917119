<template>
  <AtroModal fullscreen-when-mobile>
    <template #default="{ close }">
      <AtroContent class="w-full" items="center" col>
        <AtroIconSquare class="mb-6" icon-name="plus" circle />

        <AtroHeading class="mb-2 text-atro-slate-purple" semibold
          >Suggest an assessment</AtroHeading
        >
        <AtroParagraph class="mb-8 text-atro-gray-1"
          >Tell us about assessment you would like</AtroParagraph
        >

        <FormKit v-model="formData" type="form" @submit="data => onSubmit(data, close)">
          <FormKit
            autofocus
            type="text"
            label="Topic"
            placeholder="What is the assessment about?"
            name="topic"
            validation="required|length:2,50"
          />
          <FormKit
            autofocus
            type="textarea"
            label="Details"
            placeholder="What do you want this to do for you?"
            name="details"
          />
          <template #submit="{ state: { valid } }">
            <AtroButton class="mx-auto" :is-pending="isPending" :disabled="!valid">Send</AtroButton>
          </template>
        </FormKit>
      </AtroContent>
    </template>
  </AtroModal>
</template>

<script setup lang="ts">
import axios from '@/utils/axios'
import { Props as ModalProps } from '@/components/globals/atro/Modal.vue'
import useCurrentUser from '@/composables/data/useCurrentUser'

interface Props extends ModalProps {}

defineProps<Props>()

const { data: currentUser } = $(useCurrentUser())

let formData = $ref({
  topic: '',
  details: '',
})

let isPending = $ref(false)
const onSubmit = async (data, close) => {
  isPending = true
  try {
    await axios.post('suggestions', {
      suggestion: {
        data: {
          ...data,
          email: currentUser.email,
        },
        table: 'Assessments',
      },
    })
    close()
  } catch (e) {}
  isPending = false
}
</script>
