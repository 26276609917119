import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'
import { pendingInvite } from '@/data/invite'

export const mockPaths = {
  teamInvite: 'team-invites/:id',
  teamInvites: 'team-invites',
}

const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  // GET: SHOW
  rest.get(getPath('teamInvites'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json([pendingInvite]))
  }),

  // DELETE
  rest.delete(getPath('teamInvite'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),
]
