import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'
import {
  handlers as activityHandlers,
  mockPaths as activityMockPaths,
} from '@/mocks/handlers/activity'
import {
  handlers as assessmentHandlers,
  mockPaths as assessmentMockPaths,
} from '@/mocks/handlers/assessments'
import {
  handlers as securityFixesHandlers,
  mockPaths as securityFixesMockPaths,
} from '@/mocks/handlers/securityFixes'
import {
  handlers as integrationsHandlers,
  mockPaths as integrationsMockPaths,
} from '@/mocks/handlers/integrations'
import { handlers as inviteHandlers, mockPaths as inviteMockPaths } from '@/mocks/handlers/invites'
import { handlers as orgHandlers, mockPaths as orgMockPaths } from '@/mocks/handlers/org'
import { handlers as signupHandlers, mockPaths as signupMockPaths } from '@/mocks/handlers/signup'
import { handlers as stepsHandlers, mockPaths as stepsMockPaths } from '@/mocks/handlers/steps'
import { handlers as teamHandlers, mockPaths as teamMockPaths } from '@/mocks/handlers/team'
import { handlers as topicHandlers, mockPaths as topicMockPaths } from '@/mocks/handlers/topics'
import { handlers as userHandlers, mockPaths as userMockPaths } from '@/mocks/handlers/user'

export const mockPaths = {
  ...activityMockPaths,
  ...assessmentMockPaths,
  ...integrationsMockPaths,
  ...inviteMockPaths,
  ...orgMockPaths,
  ...securityFixesMockPaths,
  ...signupMockPaths,
  ...stepsMockPaths,
  ...teamMockPaths,
  ...topicMockPaths,
  ...userMockPaths,

  validateInviteToken: 'invites/:token',
  validateShareToken: 'shares/active',
}

export const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  ...activityHandlers,
  ...assessmentHandlers,
  ...integrationsHandlers,
  ...inviteHandlers,
  ...orgHandlers,
  ...securityFixesHandlers,
  ...signupHandlers,
  ...stepsHandlers,
  ...teamHandlers,
  ...topicHandlers,
  ...userHandlers,

  // GET: SHOW Validate Invite Token
  rest.get(getPath('validateInviteToken'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),

  // GET: SHOW Validate Share Token
  rest.get(getPath('validateShareToken'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(null))
  }),
]
