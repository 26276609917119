<template>
  <div id="dashboard" class="flex h-full flex-1 flex-col bg-white">
    <MobileTopBar v-if="isMobile" @open-nav="openNav" @open-score="showingMobileScore = true" />

    <NavDashboard :show-nav="showingNav" @close="closeNav" @open="openNav" />

    <Transition
      enter-from-class="translate-x-full"
      enter-to-class="translate-x-0"
      enter-active-class="duration-300"
      leave-to-class="translate-x-full"
      leave-active-class="duration-300"
    >
      <MobileScoreSlideover
        v-if="showingMobileScore"
        v-scroll-lock="showingMobileScore"
        @close="showingMobileScore = false"
      />
    </Transition>

    <Transition name="fade">
      <div
        v-if="isContentBlured"
        class="fixed top-0 left-0 z-[11] h-screen w-screen backdrop-blur-sm"
      />
    </Transition>

    <AtroContent
      :class="[
        'mt-12 flex-1 px-4 py-6 sm:mt-0 sm:px-14 sm:py-8',
        showingNav
          ? 'sm:ml-[var(--nav-dashboard-expanded-width)]'
          : 'sm:ml-[var(--nav-dashboard-width)]',
      ]"
      col
    >
      <component
        :is="PageHeadingComponent"
        v-if="route.meta.PageHeading"
        v-bind="route.meta.pageHeadingProps"
      />

      <AtroContent class="w-full flex-1" justify="center">
        <AtroContent
          :class="['h-full w-full flex-1', route.meta.SideBar ? 'max-w-[920px]' : 'max-w-[1224px]']"
          col
        >
          <slot />
        </AtroContent>

        <AtroContent
          v-if="showSideBar"
          :key="route.name"
          :class="[
            'sticky top-8 ml-12 h-full w-64 flex-shrink-0 border-l-2 border-l-atro-blue-10 bg-white pl-14',
            route.meta.SideBar === true && 'hidden lg:block',
          ]"
          col
        >
          <component :is="route.meta.SideBar" />
        </AtroContent>
      </AtroContent>
    </AtroContent>

    <AtroOnboardingModal
      cta-icon="chevron_right"
      onboarding-key="seenApp"
      :heading="ONBOARDING_COPY.welcomeToAtroModal.heading"
      :sub-heading="ONBOARDING_COPY.welcomeToAtroModal.subHeading"
      :body="onboardingModalBody"
      :cta-text="ONBOARDING_COPY.welcomeToAtroModal.cta"
      :dependent-keys="[]"
    />
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { ONBOARDING_COPY } from '@/lib/constants'
import useCurrentUser from '@/composables/data/useCurrentUser'
import useIsMobile from '@/composables/useIsMobile'
import NavDashboard from '@/components/NavDashboard.vue'
import AtroPageHeading from '@/components/globals/atro/PageHeading.vue'
import MobileScoreSlideover from '@/components/mobile/MobileScoreSlideover.vue'
import MobileTopBar from '@/components/mobile/MobileTopBar.vue'

const route = useRoute()
const router = useRouter()

let showingNav = $ref(false)
let showingMobileScore = $ref(false)
let showSideBar = $ref(false)

const { data: user } = $(useCurrentUser())
const isMobile = $(useIsMobile())
const breakpoints = useBreakpoints(breakpointsTailwind)

const isContentBlured = $computed(() => isMobile && (showingNav || showingMobileScore))

const PageHeadingComponent = $computed(() =>
  route.meta.PageHeading === true ? AtroPageHeading : route.meta.PageHeading
)

const onboardingModalBody = $computed(() => {
  if (user.isOwner) {
    return ONBOARDING_COPY.welcomeToAtroModal.owner.body
  } else if (user.role === 'admin') {
    return ONBOARDING_COPY.welcomeToAtroModal.admin.body
  } else if (user.role === 'member') {
    return ONBOARDING_COPY.welcomeToAtroModal.member.body
  }
})

const closeNav = () => {
  showingNav = false
}

const openNav = () => {
  showingNav = true
}

watch(
  [breakpoints.lg],
  () => {
    breakpoints.isGreater('lg') ? openNav() : closeNav()
  },
  { immediate: true }
)

watch(
  [$$(isMobile), router.currentRoute],
  ([toMobile]) => {
    if (toMobile) {
      closeNav()
      showSideBar = false
    } else {
      showSideBar = !!route.meta.SideBar
    }
    showingMobileScore = false
  },
  { immediate: true }
)
</script>
