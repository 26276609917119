import { useBaseMutation } from './useBaseMutation'

export function useSecurityFixOrgUpdate(securityFix: SecurityFix) {
  const mutation = useBaseMutation({
    method: 'patch',
    url: `org-security-fixes/${securityFix.id}`,
    invalidateKeys: ['*'],
  })

  const updateOrgSecurityFix = async (securityFix: SecurityFix) => {
    await mutation.mutateAsync({ securityFix })
  }

  return { updateOrgSecurityFix, ...mutation }
}

export function useSecurityFixUserUpdate(securityFix: SecurityFix) {
  const mutation = useBaseMutation({
    method: 'patch',
    url: `user-security-fixes/${securityFix.id}`,
    invalidateKeys: ['*'],
  })

  const updateUserSecurityFix = async (securityFix: SecurityFix) => {
    await mutation.mutateAsync({ securityFix })
  }

  return { updateUserSecurityFix, ...mutation }
}
