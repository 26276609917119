import { useBaseMutation } from './useBaseMutation'

export function useNudgeUserMutation(id: string) {
  const mutation = useBaseMutation({
    method: 'post',
    url: `org/team/${id}/nudge`,
  })

  return { nudgeUser: mutation.mutateAsync, ...mutation }
}

export function useNudgeTeamAssessmentMutation(id: string) {
  const mutation = useBaseMutation({
    method: 'post',
    url: `assessments/team/${id}/nudge`,
  })

  return { nudgeTeamAssessment: mutation.mutateAsync, ...mutation }
}
