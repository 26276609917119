<template>
  <AtroContent :justify="cellConfig.align || 'center'">{{ value }}</AtroContent>
</template>

<script setup lang="ts">
import { formatDistanceToNowStrict } from 'date-fns'
import { Props as ContentProps } from '@/components/globals/atro/Content.vue'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'

export type CellConfig = {
  type: ColumnConfig['type']
  value: any
  align?: ContentProps['justify']
}

export interface Props {
  cellConfig: CellConfig
}

const { cellConfig } = defineProps<Props>()

const value = $computed(() => {
  switch (cellConfig.type) {
    case 'boolean':
      if (typeof cellConfig.value === 'boolean') {
        return cellConfig.value === true ? 'Yes' : 'No'
      } else {
        return 'N/A'
      }
    case 'date:time-since':
      if (typeof cellConfig.value === 'string') {
        return formatDistanceToNowStrict(new Date(cellConfig.value as string))
      } else {
        return 'Never'
      }
    default:
      return cellConfig.value
  }
})
</script>
