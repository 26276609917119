<template>
  <AtroMenu align="topRight">
    <template #menuButton="{ open }">
      <MenuButton
        :class="[
          'rounded-xl sm:p-2 sm:hover:bg-white/20 hover:text-white w-full',
          open ? 'sm:bg-white/20 text-white' : 'text-white/75',
        ]"
      >
        <AtroContent items="center">
          <AtroIcon class="h-4 w-4 sm:h-8 sm:w-8" name="screwdriver_wrench_light" />
          <AtroSpan v-if="expanded" class="ml-4" :size="isMobile ? 'normal' : 'xsmall'"
            >Admin menu</AtroSpan
          >
        </AtroContent>
      </MenuButton>
    </template>

    <template #menuItems>
      <AtroContent class="!min-w-[200px] space-y-6 p-4 w-full" col>
        <!-- SYSTEM -->
        <AtroContent col>
          <AtroHeading class="mb-2" size="small" semibold>System</AtroHeading>
          <FormKit type="checkbox" label="Demo mode" :value="isDemoMode" @change="toggleDemoMode" />
        </AtroContent>

        <!-- FEATURES -->
        <AtroContent col>
          <AtroHeading class="mb-2" size="small" semibold>Features</AtroHeading>
          <AtroContent class="space-y-2" col>
            <FormKit
              v-for="(value, key) in flagMap"
              :key="key"
              type="checkbox"
              :classes="{
                label: 'capitalize',
              }"
              :name="key"
              :label="key"
              :value="value"
              @change="e => onFeatureChange(key, e)"
            />
          </AtroContent>
        </AtroContent>

        <!-- SERVICES -->
        <AtroContent class="w-full" col>
          <AtroHeading class="mb-2" size="small" semibold>Services</AtroHeading>
          <AtroContent class="w-full space-y-2.5" col>
            <div v-for="service in externalServices" :key="service.name" class="w-full group">
              <a :href="service.url" target="_blank">
                <AtroContent class="w-full" justify="between" wrap="nowrap">
                  <AtroContent class="space-x-2.5" items="center">
                    <AtroIcon v-if="service.iconName" :name="service.iconName" class="w-5 h-5" />
                    <AtroSpan>{{ service.name }}</AtroSpan>
                  </AtroContent>
                  <AtroIcon
                    class="invisible w-4 h-4 group-hover:visible"
                    name="arrow_up_right_from_square_duotone"
                  />
                </AtroContent>
              </a>
            </div>
          </AtroContent>
        </AtroContent>
      </AtroContent>
    </template>
  </AtroMenu>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'
import useCurrentUser from '@/composables/data/useCurrentUser'
import useDemoMode from '@/composables/useDemoMode'
import useIsMobile from '@/composables/useIsMobile'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { IconName } from '@/assets/icons/atro-icon.model'

export interface Props {
  expanded?: boolean
}

defineProps<Props>()

const router = useRouter()
const { data: user } = useCurrentUser()
const { disableDemoMode, isDemoMode } = $(useDemoMode())
const { flagMap, overrideFeature } = useFeatureFlags(user)
const isMobile = $(useIsMobile())
const queryClient = useQueryClient()

const externalServices: { name: string; url: string; iconName?: IconName }[] = [
  {
    name: 'Auth0',
    iconName: 'auth_zero',
    url: 'https://manage.auth0.com/dashboard/us/atro/',
  },
  {
    name: 'GA',
    iconName: 'google_analytics',
    url: 'https://analytics.google.com/analytics/web/#/p291718741/reports/intelligenthome',
  },
  {
    name: 'LogRocket',
    iconName: 'logrocket',
    url: 'https://app.logrocket.com/6ogrqi/atro-spa',
  },
  {
    name: 'Mixpanel',
    iconName: 'mixpanel',
    url: 'https://mixpanel.com/project/2661643/view/3199063/app/dashboards',
  },
  {
    name: 'Segment',
    iconName: 'segment',
    url: 'https://app.segment.com/atro-spa-production/overview',
  },
  {
    name: 'Sentry',
    iconName: 'sentry',
    url: 'https://sentry.io/organizations/atro/issues/?project=6196904',
  },
]

const onFeatureChange = (key, e) => {
  overrideFeature(key, e.target.checked)
}

const toggleDemoMode = async e => {
  let queryParams = { ...router.currentRoute.value.query }
  if (e.target.checked) {
    queryParams = { ...queryParams, demo: 'true' }
  } else {
    delete queryParams.demo
  }
  router.replace({
    ...router.currentRoute.value,
    query: queryParams,
  })
  if (!e.target.checked) {
    disableDemoMode()
  }
  setTimeout(() => {
    queryClient.invalidateQueries()
  }, 1000)
}
</script>
