<template>
  <AtroGridTableRow>
    <AtroAbstractTableCell
      v-for="(cellConfig, i) in cellConfigs"
      :key="i"
      :cell-config="cellConfig"
    />
  </AtroGridTableRow>
</template>

<script setup lang="ts">
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'
import { Props as GridTableRowProps } from '@/components/globals/atro/GridTableRow.vue'
import { camelCase } from 'change-case'

export interface Props extends GridTableRowProps {
  columnsConfig: ColumnConfig[]
  rowData: Record<string, unknown>
}

const { columnsConfig, rowData } = defineProps<Props>()

const cellConfigs = columnsConfig.map(columnConfig => {
  const camelCaseKey = camelCase(columnConfig.key)
  return {
    align: columnConfig.heading?.align || 'start',
    type: columnConfig.type,
    value: rowData[camelCaseKey] as string,
  }
})
</script>
