<template>
  <AtroModal fullscreen-when-mobile size="large" @after-close="onAfterClose">
    <template #default="{ close }">
      <AtroContent class="mb-6 w-full" items="center" col>
        <AtroHeading class="text-atro-slate-purple" semibold>Assign your team</AtroHeading>
        <AtroSpan class="mt-2 text-atro-gray-1">Select team members for this assignment</AtroSpan>
      </AtroContent>

      <AtroAsync :config="fetchState">
        <TeamListSelect
          :selected-user-ids="selectedUserIds"
          :users="sortedUsers"
          @change="userIds => (selectedUserIds = userIds)"
          @invite-members="onInviteMemebers(close)"
        />
      </AtroAsync>

      <AtroContent class="mt-6 w-full" items="center" justify="center">
        <FormKit
          v-model="includeFutureUsers"
          type="checkbox"
          label="Assign to all future users"
          :classes="{ label: 'font-semibold' }"
        />
      </AtroContent>
    </template>

    <template #actions="{ close }">
      <AtroModalActions>
        <AtroButton
          icon-right="check"
          block-when-mobile
          :disabled="isAssignButtonDisabled"
          :is-pending="isPending"
          @click="() => onAssign(close)"
          >Assign</AtroButton
        >
      </AtroModalActions>
    </template>
  </AtroModal>
</template>

<script setup lang="ts">
import { sort } from 'fast-sort'
import { useRouter } from 'vue-router'
import { useAssessmentAssignMutation } from '@/composables/mutations/useAssessmentMutation'
import useAssessmentTeamMemberStatus from '@/composables/data/useAssessmentTeamMemberStatus'
import useAnaytics from '@/composables/useAnalytics'
import useTeam from '@/composables/data/useTeam'
import useAssessmentMeta from '@/composables/meta/useAssessmentMeta'
import TeamListSelect from '@/components/team/TeamListSelect.vue'
import { Props as ModalProps } from '@/components/globals/atro/Modal.vue'
import { Assessment } from '@/types/assessment'

export interface Props extends ModalProps {
  assessment: Assessment
}

const { assessment } = defineProps<Props>()

const router = useRouter()
const analytics = $(useAnaytics())
const { isStarted } = $(useAssessmentMeta($$(assessment)))
const { fetchState, data: users } = $(useTeam({ withInvites: true }))
const { data: assignedTeamMembers, fetchState: teamMemberFetchState } = $(
  useAssessmentTeamMemberStatus(isStarted ? assessment.id : null)
)
teamMemberFetchState?.isLoading.value && (await teamMemberFetchState.suspense())

const { assignAssessment, isLoading: isPending } = useAssessmentAssignMutation(assessment)

let selectedUserIds = $ref<string[]>(
  assignedTeamMembers?.map(assignedTeamMemeber => assignedTeamMemeber.id) || []
)
let initialSelectedUserIds = $ref([...selectedUserIds])
let includeFutureUsers = $ref(assessment.settings?.includeFuture || false)

const isAssignButtonDisabled = $computed(() => !selectedUserIds.length)

const sortedUsers = $computed(() => {
  return sort(users).asc([
    u => initialSelectedUserIds.includes(u.id),
    u => u.role === 'invited',
    u => u.display,
  ])
})

const onAssign = async closeModal => {
  const {
    data: { assessmentId },
  } = await assignAssessment({
    includeFuture: includeFutureUsers,
    userIds: selectedUserIds,
  })

  initialSelectedUserIds = selectedUserIds
  assessment.status === 'initial' && analytics?.track('assessment:activated', assessment as any)
  analytics?.track('assessment:assigned', assessment as any)
  router.replace({ name: 'assessmentTeam', params: { id: assessmentId }, force: true })
  closeModal()
}

const onAfterClose = () => {
  selectedUserIds = initialSelectedUserIds
}

const onInviteMemebers = closeModal => {
  closeModal({ openInviteModal: true })
}
</script>
