<template>
  <AtroModal fullscreen-when-mobile>
    <template #default>
      <AtroContent class="w-full text-center" items="center" col>
        <AtroIconSquare class="!p-3 !text-atro-magenta" icon-name="circle_with_cross" circle />
        <AtroHeading class="text-atro-slate-purple mb-4 mt-6" semibold>{{
          integrationError.title
        }}</AtroHeading>
        <div class="prose text-atro-gray-1">
          <AtroMarkdown :markdown="integrationError.details" />
        </div>
      </AtroContent>
    </template>

    <template #actions="{ close }">
      <AtroContent class="mt-8 w-full" justify="center">
        <AtroButton block-when-mobile @click="() => onCtaClick(close)">Continue</AtroButton>
      </AtroContent>
    </template>
  </AtroModal>
</template>

<script setup lang="ts">
import { Props as ModalProps } from '@/components/globals/atro/Modal.vue'
import { IntegrationErrorDetails } from '@/types/integration'

interface Props extends ModalProps {
  integrationError: IntegrationErrorDetails
  onContinue: () => void
}

const { onContinue } = defineProps<Props>()

const onCtaClick = (close: (params?) => void) => {
  close()
  onContinue()
}
</script>
