import { useBaseMutation } from './useBaseMutation'
import { useQueryClient } from '@tanstack/vue-query'

export function useUserUpdateMutation() {
  const queryClient = useQueryClient()

  const mutation = useBaseMutation<{ user: User }, User>(
    {
      method: 'patch',
      url: `profile`,
    },
    {
      // optimistically set answers
      onMutate: data => {
        const user = queryClient.getQueryData<User>(['currentUser'])
        queryClient.setQueryData(['currentUser'], data.user)
        return user
      },
      onSuccess: response => {
        queryClient.setQueryData(['currentUser'], response.data)
      },
      onError: (error, variables, context) => {
        queryClient.setQueryData(['currentUser'], context)
      },
    }
  )

  const updateUser = async (user: User) => {
    return mutation.mutateAsync({ user })
  }

  return { updateUser, ...mutation }
}

export function useUserEmailUpdateMutation() {
  const mutation = useBaseMutation({
    method: 'patch',
    url: `profile/email`,
    invalidateKeys: [['currentUser']],
  })

  const updateUserEmail = async (data: { email: string }) => {
    return mutation.mutateAsync(data)
  }

  return { updateUserEmail, ...mutation }
}

export function useUserPasswordUpdateMutation() {
  const mutation = useBaseMutation({
    method: 'patch',
    url: `profile/password`,
    invalidateKeys: [['currentUser']],
  })

  const updateUserPassword = async (data: { password: string; password_confirm: string }) => {
    return mutation.mutateAsync(data)
  }

  return { updateUserPassword, ...mutation }
}
