<template>
  <div class="w-full rounded-3xl border-2">
    <!-- headings -->
    <div
      v-if="!hideHeader"
      class="grid w-full gap-x-2 rounded-t-3xl border-b-2 bg-atro-gray-4 px-4 py-2 sm:gap-x-6 sm:px-6"
      :style="{ gridTemplateColumns: gridTemplateColumnsStyle }"
    >
      <AtroContent
        v-for="(column, i) in columns"
        :key="i"
        items="center"
        wrap="nowrap"
        :class="[
          'group transition-colors ',
          column.sortBy !== undefined && sortByColumnIndex === i
            ? 'text-atro-purple'
            : 'text-atro-purple-75',
          column.sortBy !== undefined && 'cursor-pointer hover:text-atro-purple',
        ]"
        :justify="column.heading?.align ? column.heading?.align : 'center'"
        @click="setSortByColumn(column, i)"
      >
        <AtroSpan size="xsmall" semibold>{{ column.heading?.text }}</AtroSpan>
        <AtroIcon
          v-if="column.sortBy !== undefined"
          :class="[
            'ml-2 h-2 w-2 shrink-0 transition-all group-hover:opacity-100',
            sortByColumnIndex === i ? 'opacity-100' : 'opacity-0',
            sortByDirection === 'asc' ? 'rotate-0' : 'rotate-180',
          ]"
          name="sort_chevron"
        />
      </AtroContent>
    </div>

    <template v-if="showAsync">
      <div class="grid w-full" :style="{ gridTemplateColumns: gridTemplateColumnsStyle }">
        <div class="col-span-full p-4 sm:p-6">
          <AtroAsync v-bind="async" />
        </div>
      </div>
    </template>

    <!-- body -->
    <div v-else v-auto-animate :class="PADDING_OPTIONS[padding]">
      <slot :column-style="gridTemplateColumnsStyle" :items="adjustedData" />
    </div>

    <AtroContent
      v-if="rollup && !isViewingAll"
      class="cursor-pointer rounded-b-3xl border-t-2 bg-atro-gray-4 px-4 py-2 sm:px-6"
      items="center"
      justify="center"
      @click="viewAll = true"
    >
      <AtroSpan class="text-atro-purple" size="xsmall" semibold
        >View All ({{ remainingItemCount }})</AtroSpan
      >
    </AtroContent>
  </div>
</template>

<script lang="ts">
type HeadingConfig = {
  text: string
  align?: 'start' | 'center' | 'end'
  tooltip?: string
}
</script>

<script setup lang="ts">
import { unref } from 'vue'
import { getProperty } from 'dot-prop'
import useIsMobile from '@/composables/useIsMobile'
import { Props as AsyncProps } from '@/components/globals/atro/Async.vue'
import { Props as ContentProps } from '@/components/globals/atro/Content.vue'

export type ColumnConfig = {
  align?: ContentProps['items']
  heading?: HeadingConfig
  fixedWidth?: boolean
  initialSortedBy?: boolean | 'asc' | 'desc'
  key?: string
  minColumnWidth?: number | string
  name?: string
  rowClass?: string
  sortBy?: string | string[] | ((item) => unknown | unknown[])
  type?: 'boolean' | 'date:time-since' | 'string'
  widthRatio?: number
}

export interface Props {
  columns: ColumnConfig[]
  async?: AsyncProps
  data?: any[]
  hideHeaderWhenMobile?: boolean
  padding?: 'small' | 'normal'
  rollup?: boolean
  sortDir?: 'asc' | 'desc'
}

const DEFAULT_WIDTH_RATIO = 1
const ROLL_UP_COUNT = 5
const ROLL_UP_COUNT_MOBILE = 3
const PADDING_OPTIONS = {
  small: 'py-4',
  normal: 'py-6',
}

const {
  async,
  columns,
  data = [],
  hideHeaderWhenMobile = false,
  padding = 'normal',
  rollup = false,
} = defineProps<Props>()

const isMobile = $(useIsMobile())

const hideHeader = $computed(() => hideHeaderWhenMobile && isMobile)
const defaultMinColumnWidth = $computed(() => (isMobile ? 50 : 150))
const tableData = $computed(() => (async !== undefined ? unref(async.config.data) || [] : data))

const showAsync = $computed(
  () =>
    async &&
    (unref(async.config.isEmpty) || unref(async.config.isError) || unref(async.config.isLoading))
)

const gridTemplateColumnsStyle = $computed(() => {
  return columns.reduce((str, column) => {
    let columnMin =
      column.minColumnWidth !== undefined ? column.minColumnWidth : defaultMinColumnWidth
    let columnMax = column.fixedWidth
      ? columnMin
      : column.widthRatio !== undefined
      ? column.widthRatio
      : DEFAULT_WIDTH_RATIO
    const columnMaxUnit = column.fixedWidth ? 'px' : 'fr'

    if (typeof columnMin === 'string') {
      columnMin = columnMin.replace('px', '')
    }
    if (typeof columnMax === 'string') {
      columnMax = columnMax.replace('px', '')
    }

    return str.concat(`minmax(${columnMin}px, ${columnMax}${columnMaxUnit}) `)
  }, '')
})

// Sort
let sortByColumnIndex = $ref(
  Math.max(
    columns.findIndex(column => column.initialSortedBy !== undefined),
    0
  ) ||
    Math.max(
      columns.findIndex(column => column.sortBy !== undefined),
      0
    )
)
let initialSortedBy = columns[sortByColumnIndex]?.initialSortedBy
let sortBy = $ref(columns[sortByColumnIndex]?.sortBy)
let sortByDirection = $ref<'asc' | 'desc'>(
  typeof initialSortedBy === 'string' ? initialSortedBy : 'desc'
)

const sortedData = $computed(() => {
  return sortBy === undefined
    ? tableData
    : [...tableData].sort((a, b) => {
        let aProp, bProp
        if (typeof sortBy === 'string') {
          aProp = getProperty(a, sortBy) || null
          bProp = getProperty(b, sortBy) || null
        } else if (Array.isArray(sortBy)) {
          aProp = sortBy.map(sortByPropName => getProperty(a, sortByPropName) || null)
          bProp = sortBy.map(sortByPropName => getProperty(b, sortByPropName) || null)
        } else if (typeof sortBy === 'function') {
          aProp = sortBy(a)
          bProp = sortBy(b)
        }

        // multi-dimension sort
        if (Array.isArray(aProp)) {
          for (let i = 0; i < aProp.length; i++) {
            if (aProp[i] < bProp[i]) {
              return sortByDirection === 'desc' ? 1 : -1
            } else if (aProp[i] > bProp[i]) {
              return sortByDirection === 'desc' ? -1 : 1
            } else {
              continue
            }
          }
          return 0
        } else {
          if (aProp < bProp) {
            return sortByDirection === 'desc' ? 1 : -1
          } else if (aProp > bProp) {
            return sortByDirection === 'desc' ? -1 : 1
          } else return 0
        }
      })
})

const setSortByColumn = (column: ColumnConfig, i: number) => {
  if (column.sortBy === undefined) return
  if (sortByColumnIndex === i) {
    sortByDirection = sortByDirection === 'asc' ? 'desc' : 'asc'
  } else {
    sortByDirection = 'desc'
  }
  sortByColumnIndex = i
  sortBy = column.sortBy
}

// Rollup
let viewAll = $ref(!rollup)
const rollupSize = $computed(() => (isMobile ? ROLL_UP_COUNT_MOBILE : ROLL_UP_COUNT))
const adjustedData = $computed(() => sortedData?.slice(0, viewAll ? undefined : rollupSize))
const remainingItemCount = $computed(() => sortedData.length - adjustedData.length)
const isViewingAll = $computed(() => sortedData?.length === adjustedData?.length)
</script>
