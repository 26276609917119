import { useQueryClient } from '@tanstack/vue-query'
import { buildFetcher } from '@/utils/fetcher'
import keyPathMap, { ResourcePath } from '@/utils/resourcePaths'

export default function usePrefetchData() {
  const queryClient = useQueryClient()

  return function <T>(resourceName: ResourcePath, params?: Record<string, unknown>) {
    const resourceConfig = keyPathMap[resourceName]
    const key =
      typeof resourceConfig.key === 'function'
        ? resourceConfig.key(params as any)
        : resourceConfig.key

    const path =
      typeof resourceConfig.path === 'function'
        ? resourceConfig.path(params as any)
        : resourceConfig.path

    const fetcher = buildFetcher<T>(path)

    return queryClient.prefetchQuery(key, fetcher)
  }
}
