<template>
  <AtroAsync :config="fetchState">
    <TeamMemberPageHeader :has-pending-work="hasPendingWork" :user="user" />

    <AtroContent class="mt-10 w-full">
      <AtroTabGroup :tab-configs="tabConfigs" />
    </AtroContent>
  </AtroAsync>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import useTeamMember from '@/composables/data/useTeamMember'
import useTeamMemberAssessments from '@/composables/data/useTeamMemberAssessments'
import useTeamMemberSecurityFixes from '@/composables/data/useTeamMemberSecurityFixes'
import TeamMemberPageHeader from '@/components/team/TeamMemberPageHeader.vue'
import TeamMemberAssessments from '@/components/team/TeamMemberAssessments.vue'
import TeamMemberSecurityFixes from '@/components/team/TeamMemberSecurityFixes.vue'
import { TabConfig } from '@/components/globals/atro/TabGroup.vue'

const router = useRoute()
const userId = router.params.id as string
const { fetchState, data: user } = useTeamMember(userId)
const {
  data: assessments,
  meta: { isAllCaughtUp },
} = $(useTeamMemberAssessments(userId))
const {
  data: securityFixes,
  meta: { hasAddressedSecurityFixes },
} = $(useTeamMemberSecurityFixes(userId))

const hasPendingWork = $computed(() => !isAllCaughtUp || !hasAddressedSecurityFixes)

const tabConfigs = $computed<TabConfig[]>(() => [
  {
    title: 'Assessments',
    count: assessments?.length,
    component: TeamMemberAssessments,
    props: {
      userId,
    },
  },
  {
    title: 'Security Fixes',
    count: securityFixes?.length,
    deepLink: true,
    component: TeamMemberSecurityFixes,
    props: {
      userId,
    },
  },
])
</script>
