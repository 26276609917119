import useCurrentUser from '@/composables/data/useCurrentUser'
import { MenuItem } from '@/types/menu'

export default function useUserNavItems() {
  const { data: user } = $(useCurrentUser())

  const integrations: MenuItem = {
    label: 'Integrations',
    iconLeft: 'integration',
    linkTo: { name: 'integrations' },
  }
  const myProfileNavItem: MenuItem = {
    label: 'My profile',
    iconLeft: 'user',
    linkTo: { name: 'manageProfile' },
  }
  const myTeamNavItem: MenuItem = {
    label: 'Team management',
    iconLeft: 'users',
    linkTo: { name: 'manageTeam' },
  }
  const myOrgNavItem: MenuItem = {
    label: 'Organization settings',
    iconLeft: 'gears',
    linkTo: { name: 'manageOrg' },
  }
  const logoutNavItem: MenuItem = {
    label: 'Log out',
    iconLeft: 'logout',
    linkTo: { name: 'logout' },
  }

  const menuItems: MenuItem[] = $computed(() =>
    user.role === 'admin'
      ? user.isOwner
        ? [myProfileNavItem, myTeamNavItem, myOrgNavItem, integrations, logoutNavItem]
        : [myProfileNavItem, myTeamNavItem, integrations, logoutNavItem]
      : [myProfileNavItem, logoutNavItem]
  )

  return $$(menuItems)
}
