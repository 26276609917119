import LogRocket from 'logrocket'
import * as Sentry from '@sentry/vue'
import { analytics } from '@/composables/useAnalytics'

let trackersInitialized = false

export const initializeTrackers = () => {
  if (import.meta.env.DEV || trackersInitialized) return
  try {
    LogRocket.init(import.meta.env.VITE_LOG_ROCKET_ID as string)
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL)
      })
    })
  } catch (e) {}
  trackersInitialized = true
}

export const identifyUserInTrackers = (user: User) => {
  if (!user || import.meta.env.DEV) return
  const userFields = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    username: user.display,
    email: user.email,
    role: user.role,
    orgId: user.org.id,
    orgName: user.org.name,
  }

  try {
    analytics?.value?.identify(user.id, userFields)
  } catch (e) {
    console.error('Analyitics identify error: ', e)
  }
  try {
    LogRocket.identify(user.id, userFields)
  } catch (e) {}
  try {
    Sentry.setUser(userFields)
  } catch (e) {}
}
