<template>
  <RouterLink ref="tooltipTarget" class="relative w-full" :to="navItem.linkTo">
    <slot :is-active="isActive" />

    <AtroTooltip
      v-if="hasTooltip"
      :config="navItem.tooltip.options"
      :tooltip-target-fn="() => tooltipTarget"
      :text="navItem.tooltip.text"
    />
  </RouterLink>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import useIsMobile from '@/composables/useIsMobile'
import { NavItemLink } from '@/types/nav'

export interface Props {
  navItem: NavItemLink
  showTooltips?: boolean
}

const { navItem, showTooltips = true } = defineProps<Props>()

const route = useRoute()
const isMobile = $(useIsMobile())
const tooltipTarget = ref()

const isActive = $computed(
  () =>
    route.name === navItem.linkTo.name || navItem.activeAtRouteNames?.includes(route.name as string)
)

const hasTooltip = $computed(() => !isMobile && !!navItem.tooltip && showTooltips)
</script>
