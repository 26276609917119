<template>
  <AtroGridTableRow>
    <RouterLink
      class="group contents"
      :to="{
        name: 'integration',
        params: { id: integration.id },
        query: { tab: 'monitoring' },
      }"
    >
      <!-- Title -->
      <AtroContent class="mb-4 sm:mb-0" items="center" wrap="nowrap">
        <AtroIcon class="mr-2 h-10 w-10 shrink-0" :name="integration.icon" />
        <AtroHeading
          class="text-atro-dark-purple group-hover:text-atro-purple"
          size="small"
          semibold
          >{{ integration.title }}</AtroHeading
        >
      </AtroContent>

      <!-- Check States -->
      <AtroContent class="mb-4 sm:mb-0" items="center">
        <MonitoredIntegrationChecksOverview
          v-if="canShowChecks"
          :monitoring-checks="sortedChecks"
        />
        <AtroSpan v-else class="text-atro-gray-1">{{ connectionText }}</AtroSpan>
      </AtroContent>

      <!-- Status -->
      <AtroContent items="center" justify="between" wrap="nowrap">
        <IntegrationStatusBadge size="normal" variant="icon" :integration="integration" />
        <AtroIcon class="h-4 w-4" name="chevron_right" />
      </AtroContent>
    </RouterLink>
  </AtroGridTableRow>
</template>

<script setup lang="ts">
import IntegrationStatusBadge from '@/components/integration/IntegrationStatusBadge.vue'
import MonitoredIntegrationChecksOverview from '@/components/integrations/MonitoredIntegrationChecksOverview.vue'
import useIntegrationMeta from '@/composables/meta/useIntegrationMeta'
import { Integration } from '@/types/integration'

export interface Props {
  integration: Integration
}

const { integration } = defineProps<Props>()

const { sortedChecks } = $(useIntegrationMeta($$(integration)))

const canShowChecks = $computed(() => ['active', 'disconnected'].includes(integration.status))
const connectionText = $computed(() => {
  switch (integration.status) {
    case 'error':
      return 'Connection failed'
    case 'pending':
      return 'Initiating connection...'
    default:
      break
  }
})
</script>
