<template>
  <AtroContent class="space-x-1 text-white/40" justify="center">
    <a class="footer-link" href="https://atro.com/privacy">Privacy</a>
    <span>-</span>
    <a class="footer-link" href="https://atro.com/terms">Terms & Conditions</a>
  </AtroContent>
</template>

<style lang="postcss" scoped>
.footer-link {
  @apply transition-colors hover:text-white;
}
</style>
