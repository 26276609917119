import useData from '@/composables/useData'
import useAssessmentsMeta from '@/composables/meta/useAssessmentsMeta'
import { Assessment } from '@/types/assessment'

export default function useTeamMember(id: string) {
  const fetchState = useData<Assessment[]>('teamMemberAssessments', { id })
  const meta = useAssessmentsMeta(fetchState.data)

  return {
    fetchState,
    meta,
    data: fetchState.data,
  }
}
