import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'
import { user1 } from '@/data/user'

export const mockPaths = {
  profile: 'profile',
}

let user = user1

const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  // GET: SHOW Profile
  rest.get(getPath('profile'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json(user))
  }),

  // GET: PATCH Profile
  rest.patch(getPath('profile'), (req, res, ctx) => {
    // @ts-ignore
    user = req.body.user as User
    return res(ctx.delay(), ctx.json(user))
  }),
]
