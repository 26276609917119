<template>
  <AtroGridTable hide-header-when-mobile :columns="columnsConfig" :data="integrations">
    <template #default="{ columnStyle, items }">
      <MonitoredIntegrationsTableRow
        v-for="integration in items"
        :key="integration.id"
        :col-style="columnStyle"
        :integration="integration"
      />
    </template>
  </AtroGridTable>
</template>

<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'
import MonitoredIntegrationsTableRow from '@/components/integrations/MonitoredIntegrationsTableRow.vue'
import { Integration } from '@/types/integration'

export interface Props {
  integrations: Integration[]
}

defineProps<Props>()

const isMobile = $(useIsMobile())

const columnsConfig = $computed<ColumnConfig[]>(() =>
  isMobile
    ? []
    : [
        {
          heading: {
            align: 'start',
            text: 'Integration',
          },
          sortBy: 'title',
          minColumnWidth: 260,
          widthRatio: 4,
        },
        {
          heading: {
            align: 'start',
            text: 'Checks and Alerts',
          },
          initialSortedBy: true,
          sortBy: (item: Integration) => {
            if (item.status === 'pending') {
              return [-1, item.title]
            } else {
              return [
                item.monitors.reduce((count, monitoringCheck) => {
                  if (monitoringCheck.status === 'critical') {
                    count += 1000
                  } else if (monitoringCheck.status === 'warning') {
                    count += 100
                  } else if (monitoringCheck.status === 'info') {
                    count += 10
                  } else {
                    count += 0
                  }
                  return count
                }, 0),
                item.title,
              ]
            }
          },
          widthRatio: 6,
        },
        {
          heading: {
            align: 'start',
            text: 'Connection Status',
          },
          fixedWidth: true,
          minColumnWidth: 200,
        },
      ]
)
</script>
