import { Topic } from '@/types/topic'

export const topicWelcome: Topic = {
  id: '9c1c87d3-ccb0-4531-901b-c3ede54d778c',
  createdAt: '2022-03-10T19:27:09Z',
  currentStepId: '71c5915f-1706-4ca7-a763-457c50674807',
  description:
    'Learn about Atro and get started securing your team with this intro Series built for new users',
  duration: '5 Minutes',
  progress: {
    numerator: 5,
    denominator: 12,
  },
  state: 'started',
  stepsCount: 11,
  steps: [
    {
      id: '71c5915f-1706-4ca7-a763-457c50674807',
      kind: 'text',
      config: {
        text: 'In Atro, **Series** (like this one) guide you to better security. Inside Series you’ll answer simple questions like this → ',
      },
      position: 0,
      requiresAnswer: false,
      createdAt: '2022-03-10T19:27:12Z',
      updatedAt: '2022-03-10T19:27:12Z',
    },
    {
      id: '02ea54f9-8af4-4ee4-b2e2-6d552b50c67d',
      kind: 'choice',
      config: {
        text: 'How would you rate your general **understanding of cyber security**?',
      },
      options: [
        {
          id: '68ec3793-5b69-442b-8db7-7d458e8a17aa',
          label: 'Beginner',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 0,
          score: null,
        },
        {
          id: '7eb1d02f-e756-4d23-ae4c-a3c36332881e',
          label: 'Moderate',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 1,
          score: null,
        },
        {
          id: 'e143f277-d621-4607-8ff9-8b1331f9ee47',
          label: 'Expert',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 2,
          score: null,
        },
        {
          id: '1f6483b6-a1d8-4fd0-94a1-507244f43553',
          label: 'Not sure',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 3,
          score: null,
        },
      ],
      position: 1,
      requiresAnswer: true,
      createdAt: '2022-03-10T19:27:10Z',
      updatedAt: '2022-03-10T19:27:10Z',
    },
    {
      id: 'fe470cbb-f760-4601-b410-d9a941ef4861',
      kind: 'text',
      config: {
        text: 'Based on your answers, Atro suggests fixes and next steps to improve your security',
        title: 'Well done!',
      },
      position: 2,
      requiresAnswer: true,
      createdAt: '2022-03-10T19:27:12Z',
      updatedAt: '2022-03-10T19:27:12Z',
    },
    {
      id: '1f2a750f-6f41-43f6-8696-91bab17c5049',
      kind: 'text',
      config: {
        text: 'Atro can also **answer questions for you**. Just connect up your services and we’ll do the rest...',
      },
      position: 3,
      requiresAnswer: true,
      createdAt: '2022-03-10T19:27:12Z',
      updatedAt: '2022-03-10T19:27:12Z',
    },
    {
      id: '0d753c39-b97a-48f0-88de-2d75b19e8085',
      kind: 'text',
      config: {
        text: 'Now that you’ve got the basics, let’s get a little bit more information...',
        title: 'That’s all there is to it!',
      },
      position: 4,
      requiresAnswer: true,
      createdAt: '2022-03-10T19:27:12Z',
      updatedAt: '2022-03-10T19:27:12Z',
    },
    {
      id: '0a48b5ad-6adc-49b3-ac3c-43148d530176',
      kind: 'tag',
      config: {
        text: 'Select any of these **job roles you cover**',
      },
      options: [
        {
          id: '4ce15bdd-9244-4ef4-a26e-9974e3be4d47',
          label: 'Human Resources',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 0,
          score: null,
        },
        {
          id: '58ce7441-30a9-40b5-9e37-95e3ff90e1fa',
          label: 'Engineer',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 1,
          score: null,
        },
        {
          id: 'cca4b8a1-6b10-478d-94b6-35e50dee8c97',
          label: 'Security',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 2,
          score: null,
        },
        {
          id: '17de37a3-71b6-4be1-824b-3cd99c299def',
          label: 'Executive',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 3,
          score: null,
        },
        {
          id: '5d750113-f674-48c2-a98f-b412c74dadac',
          label: 'People Manager',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 4,
          score: null,
        },
        {
          id: 'c3ae51a2-4306-4fad-aa1b-a4ec3a1e5354',
          label: 'Finance',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 5,
          score: null,
        },
        {
          id: 'a4b9ad91-78a3-40ef-843c-5734ae9e136b',
          label: 'Sales',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 6,
          score: null,
        },
        {
          id: '9c7fa105-29b4-422c-a1b0-7fa7dc7f5e1c',
          label: 'Marketing',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 7,
          score: null,
        },
        {
          id: '93656466-83c9-4a90-8cfb-6361fab2c627',
          label: 'Executive',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 8,
          score: null,
        },
        {
          id: 'a72f9c79-cae6-4f3e-94f1-a2e55ef9fea0',
          label: 'IT',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 9,
          score: null,
        },
        {
          id: '67e60c34-d98c-4e91-aeac-5b73fe2a352c',
          label: 'Something else',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 10,
          score: null,
        },
        {
          id: '5ec57c88-3cf9-4f87-9349-5e56394a5783',
          label: "I'm not sure",
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: true,
          position: 11,
          score: null,
        },
      ],
      position: 5,
      requiresAnswer: true,
      createdAt: '2022-03-10T19:27:12Z',
      updatedAt: '2022-03-10T19:27:12Z',
    },
    {
      id: 'd0148015-3736-4c28-aa7b-1682a342f15d',
      kind: 'choice',
      config: {
        text: '**Are you an administrator** on any of your team’s systems, software or services?',
      },
      options: [
        {
          id: '621ada53-aaa6-4e3c-b208-37b31065ffa2',
          label: 'Yes',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 0,
          score: null,
        },
        {
          id: '10f2cd6e-28b6-45ba-bd31-fdd6ae368d67',
          label: 'No',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 1,
          score: null,
        },
        {
          id: '5d9f1046-ddc4-4ac4-9329-e98bbf5947db',
          label: 'Not Sure',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 2,
          score: null,
        },
      ],
      position: 6,
      requiresAnswer: true,
      createdAt: '2022-03-10T19:27:10Z',
      updatedAt: '2022-03-10T19:27:10Z',
    },
    {
      id: 'c4d2277b-4688-4f45-8dc6-6584362ba411',
      kind: 'tag',
      config: {
        text: 'Select any of these **cloud services you use** for work',
      },
      options: [
        {
          id: '67fa2488-84a5-49ec-9017-2f8cb131bb66',
          icon: 'dropbox_logo',
          label: 'Dropbox',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 0,
          score: null,
        },
        {
          id: '6d96155e-98ab-4315-8556-127c8f369147',
          icon: 'box_icon',
          label: 'Box',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 1,
          score: null,
        },
        {
          id: '78d4f07a-e988-4dbc-8437-21499a80e66f',
          icon: 'creative_cloud_icon',
          label: 'Creative Cloud',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 2,
          score: null,
        },
        {
          id: '5ebb79ff-4b50-4e3a-9d85-621b0079772e',
          icon: 'slack_icon',
          label: 'Slack',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 3,
          score: null,
        },
        {
          id: 'bc40a070-91c6-49d9-8d0a-85ddcdbd6532',
          icon: 'egnyte_icon',
          label: 'Egnyte',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 4,
          score: null,
        },
        {
          id: 'b9fae8f1-199d-48e8-a0c2-2c42a6419955',
          icon: 'trello_icon',
          label: 'Trello',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 5,
          score: null,
        },
        {
          id: '5724ebfe-11cb-4fe6-9950-fc2823b871eb',
          icon: 'zoho_icon',
          label: 'Zoho',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 6,
          score: null,
        },
        {
          id: 'bc621fdb-7708-4fb5-bc3f-b884a0d2de9e',
          icon: 'salesforce_icon',
          label: 'Salesforce',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 7,
          score: null,
        },
        {
          id: 'b643d6ad-a4cc-439a-944b-b7f41524a849',
          icon: 'hubspot_icon',
          label: 'Hubspot',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 8,
          score: null,
        },
        {
          id: 'e56ce0b7-2b9a-474e-8aec-6ff1b86223a6',
          icon: 'docusign_icon',
          label: 'Docusign',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 9,
          score: null,
        },
        {
          id: '9b80b5c8-ea7c-4261-b237-002ea807c48d',
          icon: 'mailchimp_icon',
          label: 'Mailchimp',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 10,
          score: null,
        },
        {
          id: '0c11cec7-ca80-469c-a59e-ace78b62b9d1',
          icon: 'grammarly_icon',
          label: 'Grammarly',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 11,
          score: null,
        },
        {
          id: '2373edfd-fa7e-4460-8feb-ff39aac7c287',
          label: 'Something else',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 12,
          score: null,
        },
        {
          id: 'bc24cd0d-df94-4858-92c8-29017f63f142',
          label: 'None of these',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: true,
          position: 13,
          score: null,
        },
      ],
      position: 7,
      requiresAnswer: true,
      createdAt: '2022-03-10T19:27:12Z',
      updatedAt: '2022-03-10T19:27:12Z',
    },
    {
      id: '06beeb1d-1276-42cf-b67b-0c824dfd0670',
      kind: 'tag',
      config: {
        text: 'What **email apps** do you use for work?',
      },
      options: [
        {
          id: 'af612d4f-dec1-4fa3-a5f1-0faa31b2d7ef',
          icon: 'outlook_icon',
          label: 'Outlook',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 0,
          score: null,
        },
        {
          id: '0c345c6b-37a4-4009-9ab3-c4f7ab81b8bc',
          icon: 'superhuman_icon',
          label: 'Superhuman',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 1,
          score: null,
        },
        {
          id: '296a466c-f846-4520-bb1d-23db1454ae14',
          icon: 'outlook_mobile_icon',
          label: 'Outlook Mobile',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 2,
          score: null,
        },
        {
          id: '59164081-8b0d-468d-90ba-a9b9edcc14c8',
          icon: 'gmail_icon',
          label: 'Gmail (web)',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 3,
          score: null,
        },
        {
          id: '413ac5c8-60dd-4cc5-a3e1-7ba881234ced',
          icon: 'gmail_app_icon',
          label: 'Gmail (mobile app)',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 4,
          score: null,
        },
        {
          id: '740a1b2e-2823-4383-8f45-c5cf9f274143',
          icon: 'thunderbird_icon',
          label: 'Thunderbird',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 5,
          score: null,
        },
        {
          id: '48400453-1a07-44e3-a78f-88f14b25760a',
          icon: 'front_icon',
          label: 'Front',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 6,
          score: null,
        },
        {
          id: 'ce81a5d7-5f00-4c9c-ac30-1f4a7efc03d8',
          icon: 'apple_mail_icon',
          label: 'Apple Mail',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 7,
          score: null,
        },
        {
          id: '9098bde0-5b92-4d1d-8cc5-2c927e1bcb1b',
          icon: 'spike_icon',
          label: 'Spike',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 8,
          score: null,
        },
        {
          id: '8e9fca56-a104-41ca-89ac-8897ad3ffa4f',
          icon: 'yahoo_icon',
          label: 'Yahoo Mail',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 9,
          score: null,
        },
        {
          id: 'e1c866cd-ecbc-4285-90df-959884e55816',
          icon: 'spark_icon',
          label: 'Spark',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 10,
          score: null,
        },
        {
          id: '3acd2437-58d2-467a-9b3f-4e5da912a78e',
          icon: 'hey_icon',
          label: 'Hey',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 11,
          score: null,
        },
        {
          id: '1c9df4d2-02ae-44a5-8db4-dd633698c8fb',
          label: 'Something else',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 12,
          score: null,
        },
        {
          id: '78bf1932-b0b0-4c33-b3b6-ab2ae1616b91',
          label: "I'm not sure",
          exclusive: true,
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          position: 12,
          score: null,
        },
      ],
      position: 8,
      requiresAnswer: true,
      createdAt: '2022-03-10T19:27:12Z',
      updatedAt: '2022-03-10T19:27:12Z',
    },
    {
      id: '7a80d6cd-e7bc-4176-9c33-822c2f9aa7eb',
      kind: 'choice',
      config: {
        text: 'How would you **rank your organization’s cyber security**?',
      },
      options: [
        {
          id: '0631c4dd-187a-42bd-b816-a82ec29be316',
          label: 'Not great',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 0,
          score: null,
        },
        {
          id: '2d13b53f-8331-4e5a-b283-1010601d54f9',
          label: 'Ok',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 1,
          score: null,
        },
        {
          id: 'abbdd7f9-f3bd-4416-a393-b1a88591a9d4',
          label: 'Excellent',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 2,
          score: null,
        },
        {
          id: '5d5db279-de3b-42a5-9018-5e220c4dd061',
          label: 'Not sure',
          createdAt: '2022-03-10T19:27:10Z',
          updatedAt: '2022-03-10T19:27:10Z',
          exclusive: false,
          position: 3,
          score: null,
        },
      ],
      position: 9,
      requiresAnswer: true,
      createdAt: '2022-03-10T19:27:10Z',
      updatedAt: '2022-03-10T19:27:10Z',
    },
    {
      id: '49abab2b-4260-469d-b017-404b44bd4176',
      kind: 'finished',
      config: null,
      topic: null,
      position: 10,
      requiresAnswer: false,
      createdAt: '2022-03-10T19:27:12Z',
      updatedAt: '2022-03-10T19:27:12Z',
    },
  ],
  title: 'Welcome to Atro',
  updatedAt: '2022-03-10T19:27:09Z',
}

export const topicRemote: Topic = {
  id: '1',
  createdAt: '2022-03-10T19:27:09Z',
  updatedAt: '2022-03-10T19:27:09Z',
  title: 'Remote Work Discovery',
  currentStepId: '',
  description:
    'Curabitur suscipit lectus lorem, convallis convallis est blandit quis. Nunc sagittis at leo eu faucibus. Maecenas rutrum lorem eros, ut sollicitudin urna blandit vel.',
  duration: '4 Minutes',
  progress: {
    numerator: 14,
    denominator: 14,
  },
  state: 'completed',
  stepsCount: 14,
  steps: [],
}

export const topicDevices: Topic = {
  id: '35008c75-a557-438b-8e13-f4f2511d04aa',
  createdAt: '2022-03-10T19:27:09Z',
  updatedAt: '2022-03-10T19:27:09Z',
  title: 'Devices on the Go',
  description: 'Information gathering and basic security for the devices you use remotely.',
  duration: '2 Minutes',
  stepsCount: 3,
  currentStepId: '1658a8e0-ddc9-47f4-82da-40f508ec5fae',
  state: 'started',
  progress: { numerator: 1, denominator: 3 },
  steps: [
    {
      id: '1658a8e0-ddc9-47f4-82da-40f508ec5fae',
      position: 0,
      kind: 'option',
      requiresAnswer: true,
      config: {
        text: 'How often do you work on **company devices** outside of the office?',
        labelOptions: ['Never', 'Rarely', 'Sometimes', 'Always'],
      },
      createdAt: '2022-09-01T22:26:02Z',
      updatedAt: '2022-09-01T22:26:02Z',
      options: [
        {
          id: 'f04b03ad-01db-4b62-92bc-e22c50a0a5f3',
          position: 0,
          score: null,
          label: 'Laptop',
          icon: 'laptop_duotone',
          exclusive: false,
          createdAt: '2022-09-01T22:26:02Z',
          updatedAt: '2022-09-01T22:26:02Z',
        },
        {
          id: '616b2ae8-2e0d-4659-9dcb-c508ac65c0e1',
          position: 1,
          score: null,
          label: 'Desktop',
          icon: 'desktop_duotone',
          exclusive: false,
          createdAt: '2022-09-01T22:26:02Z',
          updatedAt: '2022-09-01T22:26:02Z',
        },
        {
          id: '8454c698-a635-490a-90dd-8c850ad1dfdc',
          position: 2,
          score: null,
          label: 'Mobile',
          icon: 'mobile_button_duotone',
          exclusive: false,
          createdAt: '2022-09-01T22:26:02Z',
          updatedAt: '2022-09-01T22:26:02Z',
        },
        {
          id: '33db5f86-c193-4ee2-810b-0e6aafffff4b',
          position: 3,
          score: null,
          label: 'Other',
          icon: 'laptop_mobile_duotone',
          exclusive: false,
          createdAt: '2022-09-01T22:26:02Z',
          updatedAt: '2022-09-01T22:26:02Z',
        },
      ],
      answers: [
        {
          userId: '2b033260-43ca-4e6a-be70-58b30292de4b',
          stepId: '1658a8e0-ddc9-47f4-82da-40f508ec5fae',
          value: 'Never',
          createdAt: '2022-09-30T16:50:56Z',
          updatedAt: '2022-09-30T16:50:56Z',
          optionId: '616b2ae8-2e0d-4659-9dcb-c508ac65c0e1',
        },
      ],
    },
    {
      id: 'd87f7974-c2f7-4c38-adab-3ec5d1f666fa',
      position: 1,
      kind: 'option',
      requiresAnswer: false,
      config: {
        text: 'Now, select how often you do work on **personal devices** outside of the office',
        labelOptions: ['Never', 'Rarely', 'Sometimes', 'Always'],
      },
      createdAt: '2022-09-01T22:26:02Z',
      updatedAt: '2022-09-01T22:26:02Z',
      options: [
        {
          id: '04fa6524-5dc3-43d3-89ea-af578b465839',
          position: 0,
          score: null,
          label: 'Laptop',
          icon: 'laptop_duotone',
          exclusive: false,
          createdAt: '2022-09-01T22:26:02Z',
          updatedAt: '2022-09-01T22:26:02Z',
        },
        {
          id: '5adb41e2-bcf0-4a9a-9e43-67a8a6aa8529',
          position: 1,
          score: null,
          label: 'Desktop',
          icon: 'desktop_duotone',
          exclusive: false,
          createdAt: '2022-09-01T22:26:02Z',
          updatedAt: '2022-09-01T22:26:02Z',
        },
        {
          id: '6b199f13-08cd-4654-aeb7-da12284819f4',
          position: 2,
          score: null,
          label: 'Mobile',
          icon: 'mobile_button_duotone',
          exclusive: false,
          createdAt: '2022-09-01T22:26:02Z',
          updatedAt: '2022-09-01T22:26:02Z',
        },
        {
          id: '11fe281c-d8a1-4af9-a1aa-d89604621660',
          position: 3,
          score: null,
          label: 'Other',
          icon: 'laptop_mobile_duotone',
          exclusive: false,
          createdAt: '2022-09-01T22:26:02Z',
          updatedAt: '2022-09-01T22:26:02Z',
        },
      ],
      answers: [],
    },
    {
      id: '1ab107cf-aa4b-4726-bcfd-e04305e6181f',
      position: 2,
      kind: 'finished',
      requiresAnswer: false,
      config: {},
      createdAt: '2022-09-01T22:26:02Z',
      updatedAt: '2022-09-01T22:26:02Z',
      options: [],
      answers: [],
    },
  ],
}
