<template>
  <AtroLoading v-if="isLoading" />
  <template v-else>
    <SignupWaitlistGate v-if="noValidSignupToken" @token-validated="onTokenValidated" />
    <component
      :is="SignupStepsComponent"
      v-else
      :token="token"
      :token-type="tokenType"
      :user="signupUserData"
    />
  </template>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import axios from '@/utils/axios'
import { useAuth0 } from '@auth0/auth0-vue'
import SignupUser from '@/components/signup/SignupUser.vue'
import SignupOrg from '@/components/signup/SignupOrg.vue'
import SignupWaitlistGate from '@/components/signup/SignupWaitlistGate.vue'
import { TokenValidProperties } from '@/components/signup/SignupValidateTokenInput.vue'

const route = useRoute()
const { user: auth0User, isLoading: isAuthLoading } = useAuth0()

let isLoading = $ref(true)
let noTokenFound = $ref(false)

let token = $ref('')
let tokenType = $ref<TokenType>()
let tokenInvalid = $ref(false)

const SignupStepsComponent = $computed(() => (tokenType === 'share' ? SignupOrg : SignupUser))
const noValidSignupToken = $computed(() => noTokenFound || tokenInvalid)
const signupUserData = {
  auth0Id: auth0User.value?.sub,
  email: auth0User.value?.email,
  firstName: auth0User.value?.given_name,
  lastName: auth0User.value?.family_name,
} as SignupUser

watch(
  isAuthLoading,
  async () => {
    if (!isAuthLoading.value) {
      if (route.query.shareToken) {
        try {
          await axios.get('shares/active', {
            params: { token: route.query.shareToken },
          })
          token = route.query.shareToken as string
          tokenType = 'share'
        } catch (e) {
          tokenInvalid = true
        }
      } else if (route.query.orgShareToken) {
        try {
          await axios.get(`org/share-token/${route.query.orgShareToken}`)
          token = route.query.orgShareToken as string
          tokenType = 'orgShare'
        } catch (e) {
          tokenInvalid = true
        }
      } else if (route.query.inviteToken) {
        try {
          await axios.get(`invites/${route.query.inviteToken}`)
          token = route.query.inviteToken as string
          tokenType = 'invite'
        } catch (e) {
          tokenInvalid = true
        }
      } else {
        noTokenFound = true
      }
      isLoading = false
    }
  },
  { immediate: true }
)

const onTokenValidated = (data: TokenValidProperties) => {
  token = data.token
  tokenType = data.type
  tokenInvalid = false
  noTokenFound = false
}
</script>
