<template>
  <AtroContent col>
    <AtroContent class="mb-6" items="center" wrap="nowrap">
      <AtroIconSquare size="large" :icon-name="assessment.icon" />

      <AtroContent col class="ml-6">
        <AtroHeading class="mb-2 text-atro-dark-purple" semibold>{{
          assessment.title
        }}</AtroHeading>
        <AtroContent class="space-x-6" wrap="nowrap">
          <AtroBadge v-if="isStarted && !isMobile" variant="neutral" :label="statusText" />
          <AtroLineItem icon-name="clock_solid" :text="assessment.duration" />
          <AtroLineItem icon-name="campaign_series_count" :text="topicCountText" />
        </AtroContent>
      </AtroContent>
    </AtroContent>

    <AtroProgress
      v-if="isStarted"
      class="mb-4 w-full max-w-sm"
      :progress="assessment.progress"
      show-postfix-percentage
    />

    <AtroParagraph class="text-atro-main-75">{{ assessment.description }}</AtroParagraph>

    <AtroContent
      v-if="view === 'admin' || showStartOrContinueAssessmentButton"
      class="mt-6 w-full space-y-4 sm:space-x-6 sm:space-y-0"
    >
      <!-- Nudge button -->
      <NudgeButton
        v-if="view === 'admin' && isStarted"
        block-when-mobile
        :config="{ assessment, type: 'teamAssessment' }"
        @nudge="nudgeTeamAssessment"
      />

      <!-- Assign button -->
      <AtroButton
        v-if="view === 'admin'"
        ref="tooltipTarget"
        block-when-mobile
        :disabled="teamFetchState.isLoading.value"
        :icon-right="isStarted ? 'plus' : null"
        :variant="isStarted ? 'light' : 'primary'"
        @click="onAssignButtonClick"
        >{{ ctaText }}</AtroButton
      >

      <!-- Open topic taker button -->
      <AtroButton
        v-if="showStartOrContinueAssessmentButton"
        ref="tooltipTarget"
        icon-right="chevron_right"
        block-when-mobile
        @click="openFirstAvailableTopic()"
        >{{ startOrContinueAssessmentButtonText }}</AtroButton
      >
    </AtroContent>

    <AssessmentAdminAssignModal
      v-if="isAdminView"
      :assessment="assessment"
      :is-open="isAssessmentTeamAssignModalOpen"
      @close="isAssessmentTeamAssignModalOpen = false"
      @after-close="onAfterAssignModalClose"
    />

    <TeamInviteModal
      :is-open="isTeamInviteModalOpen"
      @close="isTeamInviteModalOpen = false"
      @after-close="isAssessmentTeamAssignModalOpen = true"
    />

    <AtroOnboardingTooltip
      v-if="showOnboardingTooltip"
      :heading="onboardingTooltipHeading"
      :body="onboardingTooltipBody"
      :offset-skid="-40"
      :target="tooltipTarget"
    />
    <AtroOnboardingModal
      v-if="showOnboardingAssessmentActivatedModal"
      icon-name="check"
      onboarding-key="activatedFirstAssessment"
      :heading="ONBOARDING_COPY.assessmentFirstActivationModal.heading"
      :body="ONBOARDING_COPY.assessmentFirstActivationModal.body"
      :cta-text="ONBOARDING_COPY.assessmentFirstActivationModal.cta"
    />
    <AssessmentAdminShareOnboardingModal
      v-if="showAssessmentAdminShareOnboardingModalOpen"
      :assessment="assessment"
      :closable="false"
    />
  </AtroContent>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { ONBOARDING_COPY } from '@/lib/constants'
import useAssessmentMeta from '@/composables/meta/useAssessmentMeta'
import useIsMobile from '@/composables/useIsMobile'
import useOnboarding from '@/composables/useOnboarding'
import useTeam from '@/composables/data/useTeam'
import { useNudgeTeamAssessmentMutation } from '@/composables/mutations/useNudgeMutation'
import AssessmentAdminShareOnboardingModal from '@/components/assessment/admin/AssessmentAdminShareOnboardingModal.vue'
import AssessmentAdminAssignModal from '@/components/assessment/admin/AssessmentAdminAssignModal.vue'
import NudgeButton from '@/components/nudge/NudgeButton.vue'
import TeamInviteModal from '@/components/team/TeamInviteModal.vue'
import { Assessment } from '@/types/assessment'

export interface Props {
  assessment: Assessment
  view: 'admin' | 'user'
}

const { assessment, view } = defineProps<Props>()

const isMobile = useIsMobile()
const router = useRouter()
const { onboardingKeys } = $(useOnboarding())
const { data: users, fetchState: teamFetchState } = $(useTeam())
const { isCaughtUp, isStarted, openFirstAvailableTopic, statusText, topicCountText } = $(
  useAssessmentMeta($$(assessment))
)
const { nudgeTeamAssessment } = useNudgeTeamAssessmentMutation(assessment.id)

const tooltipTarget = ref<Element>()
let manageAssignmentsButtonClicked = $ref(false)
let isAssessmentTeamAssignModalOpen = $ref(false)
let isTeamInviteModalOpen = $ref(false)

const isAdminView = $computed(() => view === 'admin')
const isUserView = $computed(() => view === 'user')

const ctaText = $computed(() => (isStarted ? 'Manage Assignees' : 'Activate Assessment'))

const showStartOrContinueAssessmentButton = $computed(() => isUserView && !isCaughtUp)

const startOrContinueAssessmentButtonText = $computed(() =>
  isStarted ? 'Continue Assessment' : 'Start Assessment'
)

const showOnboardingTooltip = $computed(() =>
  isAdminView
    ? !onboardingKeys.activatedFirstAssessment && !isStarted
    : !onboardingKeys.startedFirstAssessment
)
const onboardingTooltipHeading = $computed(() =>
  isAdminView
    ? ONBOARDING_COPY.assessmentPageHeaderAdminTooltip.heading
    : ONBOARDING_COPY.assessmentPageHeaderUserTooltip.heading
)
const onboardingTooltipBody = $computed(() =>
  isAdminView
    ? ONBOARDING_COPY.assessmentPageHeaderAdminTooltip.body
    : ONBOARDING_COPY.assessmentPageHeaderUserTooltip.body
)

const showOnboardingAssessmentActivatedModal = $computed(
  () =>
    isAdminView &&
    !onboardingKeys.activatedFirstAssessment &&
    router.options.history.state.replaced &&
    router.options.history.state.back === '/team-assessments'
)

const showAssessmentAdminShareOnboardingModalOpen = $computed(
  () => manageAssignmentsButtonClicked && users?.length === 1
)

const onAssignButtonClick = () => {
  manageAssignmentsButtonClicked = true
  if (users?.length > 1) {
    isAssessmentTeamAssignModalOpen = true
  }
}

const onAfterAssignModalClose = ({ openInviteModal = false } = {}) => {
  isTeamInviteModalOpen = openInviteModal
}
</script>
