<template>
  <div class="w-full">
    <AtroSectionHeading
      heading="Team"
      sub-heading="Member scores and progress"
      :route-to="{ name: 'team' }"
    >
      <TeamInviteButton />
    </AtroSectionHeading>

    <TeamStatusTable :fetch-state="fetchState" :rollup="rollup" />
  </div>
</template>

<script setup lang="ts">
import TeamInviteButton from '@/components/team/TeamInviteButton.vue'
import TeamStatusTable from '@/components/team/TeamStatusTable.vue'
import useTeam from '@/composables/data/useTeam'

export interface Props {
  rollup?: boolean
}

const { rollup = false } = defineProps<Props>()

const { fetchState } = useTeam()
</script>
