<template>
  <AtroGridTable :columns="columnsConfig" :data="securityFixes">
    <template #default="{ columnStyle, items }">
      <SecurityFixesTableRow
        v-for="(securityFix, i) in items"
        :key="securityFix.id"
        :col-style="columnStyle"
        :columns-config="columnsConfig"
        :expanded="expanded"
        :security-fix="securityFix"
        :view="view"
      >
        <template v-if="isOnboarding && i === 0" #tooltip="{ target }">
          <AtroOnboardingTooltip
            placement="bottom-start"
            :heading="onboardingTooltipHeading"
            :body="onboardingTooltipBody"
            :dependent-keys="onboardingDependentKeys"
            :target="target"
          />
        </template>
      </SecurityFixesTableRow>
    </template>
  </AtroGridTable>
</template>
<script setup lang="ts">
import { ONBOARDING_COPY } from '@/lib/constants'
import useIsMobile from '@/composables/useIsMobile'
import useOnboarding from '@/composables/useOnboarding'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'
import SecurityFixesTableRow, {
  Props as SecurityFixesTableRowProps,
} from '@/components/securityFixes/SecurityFixesTableRow.vue'

export interface Props {
  securityFixes: SecurityFix[]
  view: SecurityFixesTableRowProps['view']
  canShowOnboarding?: boolean
  expanded?: boolean
}

const { canShowOnboarding = false, securityFixes, view } = defineProps<Props>()

const isMobile = $(useIsMobile())
const { onboardingKeys } = $(useOnboarding())

let onboardingTooltipHeading = $ref('')
let onboardingTooltipBody = $ref('')
let onboardingDependentKeys = $ref([])

const isOnboarding = $computed(() => {
  if (!canShowOnboarding) return false
  switch (view) {
    case 'adminWrite':
      onboardingTooltipHeading = ONBOARDING_COPY.securityFixesAdminTooltip.heading
      onboardingTooltipBody = ONBOARDING_COPY.securityFixesAdminTooltip.body
      onboardingDependentKeys = ['seenApp', 'seenTeamSecurityFixes']
      return !onboardingKeys.seenTeamSecurityFixesPopulated
    case 'userWrite':
      onboardingTooltipHeading = ONBOARDING_COPY.securityFixesUserTooltip.heading
      onboardingTooltipBody = ONBOARDING_COPY.securityFixesUserTooltip.body
      onboardingDependentKeys = ['seenApp', 'seenMySecurityFixes']
      return !onboardingKeys.addressedSecurityFix
    default:
      return false
  }
})

const securityFixColumnConfig = $ref<ColumnConfig>({
  heading: {
    align: 'start',
    text: 'Security fix',
  },
  minColumnWidth: 120,
  widthRatio: 6,
  sortBy: 'title',
})

const riskLevelColumnConfig = $computed<ColumnConfig>(() => ({
  heading: {
    text: 'Risk level',
    ...(view === 'userRead' && { align: 'end' }),
  },
  initialSortedBy: true,
  sortBy: 'riskLevel',
  minColumnWidth: 80,
  widthRatio: 1.66,
}))

const resolvedUserCountColumnConfig = $ref<ColumnConfig>({
  heading: {
    text: 'Resolved',
  },
  widthRatio: 1.66,
  minColumnWidth: 64,
  sortBy: (item: SecurityFix) =>
    item.userStatuses.filter(userStatus => userStatus.status === 'fixed').length /
    Math.max(item.userStatuses.length, 1),
})

const statusColumnConfig = $computed<ColumnConfig>(() => ({
  name: 'status',
  heading: {
    text: isMobile ? '' : 'Fix status',
  },
  minColumnWidth: isMobile ? 0 : 150,
  rowClass: isMobile ? 'col-start-1 col-span-2 mt-2' : '',
  widthRatio: isMobile ? 0 : 1.66,
}))

const expandRowColumnConfig = $computed<ColumnConfig>(() => ({
  fixedWidth: true,
  minColumnWidth: isMobile ? 32 : 64,
}))

const columnsConfig = $computed(() => {
  switch (view) {
    case 'adminRead':
      return [
        securityFixColumnConfig,
        riskLevelColumnConfig,
        resolvedUserCountColumnConfig,
        expandRowColumnConfig,
      ]
    case 'adminWrite':
      return [
        securityFixColumnConfig,
        riskLevelColumnConfig,
        resolvedUserCountColumnConfig,
        expandRowColumnConfig,
      ]
    case 'userRead':
      return [securityFixColumnConfig, riskLevelColumnConfig]
    case 'userWrite':
      return [securityFixColumnConfig, riskLevelColumnConfig, statusColumnConfig]
    default:
      return []
  }
})
</script>
