<template>
  <AtroContent justify="between" wrap="nowrap">
    <AtroSpan class="text-atro-dark-purple" size="small" semibold>{{ securityFix.title }}</AtroSpan>

    <AtroContent class="shrink-0 pl-2" items="center" justify="end" wrap="nowrap">
      <AtroDot :class="['mr-2 shrink-0', resolutionStateColorClass]" />
      <AtroSpan size="small" semibold>{{ resolutionText }}</AtroSpan>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import useSecurityFixMeta from '@/composables/meta/useSecurityFixMeta'

export interface Props {
  securityFix: SecurityFix
}

const { securityFix } = defineProps<Props>()

const { resolutionStateColorClass, resolutionText } = useSecurityFixMeta(securityFix)
</script>
