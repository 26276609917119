<template>
  <div ref="target" class="contents">
    <slot v-if="showLoading" name="loading">
      <AtroLoading :size="loadingSize" />
    </slot>

    <slot v-else-if="config.isError.value" name="error">
      <AtroError
        :error="unref(config.error)"
        :icon-name="errorIconName"
        :size="errorSize"
        :text="errorText"
      />
    </slot>

    <slot v-else-if="showEmpty" name="empty">
      <AtroEmpty
        :heading="emptyHeading"
        :icon-name="emptyIconName"
        :sub-heading="emptySubHeading"
      />
    </slot>

    <slot v-else :data="config.data.value" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, unref } from 'vue'
import autoAnimate from '@formkit/auto-animate'
import { UseDataReturnProps } from '@/composables/useData'
import { Props as LoadingProps } from '@/components/globals/atro/Loading.vue'
import { Props as ErrorProps } from '@/components/globals/atro/Error.vue'
import { IconName } from '@/assets/icons/atro-icon.model'

export interface Props {
  config: Partial<UseDataReturnProps>
  animated?: boolean
  emptyHeading?: string
  emptyIconName?: IconName
  emptySubHeading?: string
  errorIconName?: IconName
  errorSize?: ErrorProps['size']
  errorText?: string
  loadingSize?: LoadingProps['size']
}

const {
  animated = true,
  config,
  emptyHeading,
  emptyIconName,
  emptySubHeading,
  loadingSize,
} = defineProps<Props>()

const target = ref()

const showEmpty = $computed(() => unref(config.isEmpty))

const showLoading = $computed(
  () => unref(config.isLoading) || (unref(config.isRefetching) && unref(config.isEmpty))
)

onMounted(() => {
  animated && autoAnimate(target.value)
})
</script>
