<template>
  <AtroModal v-slot="{ close }" variant="topicTaker" :closable="false" @after-close="afterClose">
    <TopicTakerLayout>
      <Transition mode="out-in" name="fade">
        <div :key="(route.params.id as string)" class="flex h-full w-full flex-1">
          <TopicTaker :key="(route.params.id as string)" opened-from-modal @close="close" />
        </div>
      </Transition>
    </TopicTakerLayout>
  </AtroModal>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'
import TopicTaker from '@/components/topicTaker/TopicTaker.vue'

const route = useRoute()
const router = useRouter()
const queryClient = useQueryClient()

const afterClose = () => {
  queryClient.invalidateQueries(['assessmentUser'])
  route.meta.returnTo && router.back()
}
</script>
