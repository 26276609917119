import AssessmentAdminPage from '@/pages/AssessmentAdminPage.vue'
import AssessmentUserPage from '@/pages/AssessmentUserPage.vue'

import AssessmentAdminSideBar from '@/components/assessment/admin/AssessmentAdminSideBar.vue'
import { Props as AtroPageHeadingProps } from '@/components/globals/atro/PageHeading.vue'

const AdminAssessmentShowRouteBaseConfig = {
  component: AssessmentAdminPage,
  meta: {
    forceUpdateOnPathChange: true,
    layout: 'Dashboard',
    requiresAuth: { role: 'admin' },
    title: 'Assessment',
    PageHeading: true,
    SideBar: AssessmentAdminSideBar,
    pageHeadingProps: {
      heading: 'Assessments',
      previousRouteTo: { name: 'teamAssessments' },
    } as AtroPageHeadingProps,
  },
}

export default [
  {
    path: '/my-assessments/:id',
    name: 'assessmentUser',
    component: AssessmentUserPage,
    meta: {
      layout: 'Dashboard',
      requiresAuth: true,
      title: 'Assessment',
      PageHeading: true,
      pageHeadingProps: {
        heading: 'My assessments',
        previousRouteTo: { name: 'myAssessments' },
      } as AtroPageHeadingProps,
    },
  },
  {
    ...AdminAssessmentShowRouteBaseConfig,
    path: '/assessments/:id',
    name: 'assessmentDiscover',
  },
  {
    ...AdminAssessmentShowRouteBaseConfig,
    path: '/team-assessments/:id',
    name: 'assessmentTeam',
  },
]
