<template>
  <AtroContent :class="[isMobile && 'w-full']" justify="center">
    <button :class="['btn__google-connect', disabled && 'disabled']"></button>
    <div class="hidden">
      <img src="/connect-buttons/google/btn_google_signin_dark_disabled_web@2x.png" />
      <img src="/connect-buttons/google/btn_google_signin_dark_focus_web@2x.png" />
      <img src="/connect-buttons/google/btn_google_signin_dark_normal_web@2x.png" />
      <img src="/connect-buttons/google/btn_google_signin_dark_pressed_web@2x.png" />
    </div>
  </AtroContent>
</template>

<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'

export interface Props {
  disabled: boolean
}

defineProps<Props>()

const isMobile = $(useIsMobile())
</script>

<style lang="postcss" scoped>
.btn__google-connect {
  width: 192px;
  height: 46px;
  background-image: url(/connect-buttons/google/btn_google_signin_dark_normal_web@2x.png);
  background-size: 192px 46px;
}

.btn__google-connect.disabled {
  background-image: url(/connect-buttons/google/btn_google_signin_dark_disabled_web@2x.png);
}

.btn__google-connect:focus {
  background-image: url(/connect-buttons/google/btn_google_signin_dark_focus_web@2x.png);
}

.btn__google-connect:active {
  background-image: url(/connect-buttons/google/btn_google_signin_dark_pressed_web@2x.png);
}
</style>
