<template>
  <AtroAsync :config="fetchState">
    <SideBarScore
      heading="Atro Score"
      sub-heading="Overall security level"
      help-text="Score is calculated live as team members complete assessments and correct security fixes"
      :score="score"
    >
      <template #activity>
        <DashboardActivity />
      </template>
    </SideBarScore>
  </AtroAsync>
</template>

<script setup lang="ts">
import { useOrgScore } from '@/composables/data/useOrgScore'
import DashboardActivity from '@/components/dashboard/DashboardActivity.vue'
import SideBarScore from '@/components/SideBarScore.vue'

const { fetchState, data: score } = useOrgScore()
</script>
