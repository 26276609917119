import { unref } from 'vue'
import { useRouter } from 'vue-router'
import { getTopicTakerRoute } from '@/utils/routes'
import usePercent from '@/composables/usePercent'
import { Assessment } from '@/types/assessment'
import { MaybeRef } from '@/types/utils'

const TOPIC_SORT_ORDER = {
  started: 1,
  initial: 2,
  completed: 3,
}

export default function useAssessmentMeta(_assessment: MaybeRef<Assessment>) {
  const assessment = $computed(() => unref(_assessment))
  const progress = $computed(() => unref(assessment?.progress))
  const topics = $computed(() => unref(assessment?.topics))
  const router = useRouter()

  const hasSecurityFixes = $computed(() => !!assessment?.securityFixCount)
  const isCaughtUp = $computed(() => assessment?.status === 'caughtUp')
  const isInProgress = $computed(() => assessment?.status === 'inProgress')
  const isStarted = $computed(() => assessment?.status && assessment?.status !== 'initial')

  const statusText = $computed(() => {
    switch (assessment?.status) {
      case 'inProgress':
        return 'In progress'
      case 'caughtUp':
        return 'Completed'
      default:
        return 'Not started'
    }
  })

  const topicCountText = $computed(() =>
    assessment?.topicsCount === 1 ? '1 Topic' : `${assessment?.topicsCount} Topics`
  )
  const topicsRemainingCount = $computed(
    () => topics?.filter(topic => topic.state !== 'completed').length
  )

  const percentComplete = usePercent(topics?.length - topicsRemainingCount, topics?.length)

  const usersCompleteText = $computed(
    () => `${progress?.numerator}/${progress?.denominator} Members complete`
  )

  const openFirstAvailableTopic = () => {
    const topic = [...topics].sort(
      (a, b) => TOPIC_SORT_ORDER[a.state] - TOPIC_SORT_ORDER[b.state]
    )[0]
    if (topic) router.push(getTopicTakerRoute(topic, assessment as Assessment))
  }

  return {
    openFirstAvailableTopic,
    ...$$({
      hasSecurityFixes,
      isCaughtUp,
      isInProgress,
      isStarted,
      percentComplete,
      statusText,
      topicCountText,
      topicsRemainingCount,
      usersCompleteText,
    }),
  }
}
