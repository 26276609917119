<template>
  <AtroPageHeading heading="Manage your team" divider>
    <AtroButton variant="transparent" icon-right="plus" @click="isTeamInviteModalOpen = true">
      Invite your team
    </AtroButton>
  </AtroPageHeading>

  <AtroContent class="w-full space-y-14" col>
    <TeamManageTable :fetch-state="teamFetchState" />

    <AtroContent
      v-if="!invitesFetchState.isLoading.value && !invitesFetchState.isEmpty.value"
      class="w-full"
      col
    >
      <AtroSectionHeading heading="Pending invites" />

      <TeamManageInvitesTable :fetch-state="invitesFetchState" />
    </AtroContent>
  </AtroContent>

  <TeamInviteModal :is-open="isTeamInviteModalOpen" @close="isTeamInviteModalOpen = false" />
</template>

<script setup lang="ts">
import useTeam from '@/composables/data/useTeam'
import useInvites from '@/composables/data/useInvites'
import TeamInviteModal from '@/components/team/TeamInviteModal.vue'
import TeamManageTable from '@/components/team/TeamManageTable.vue'
import TeamManageInvitesTable from '@/components/team/TeamManageInvitesTable.vue'

const { fetchState: teamFetchState } = useTeam()
const { fetchState: invitesFetchState } = useInvites()

let isTeamInviteModalOpen = $ref(false)
</script>
