<template>
  <AtroContent class="h-14 w-full flex-shrink-0" items="center" justify="between">
    <AtroContent>
      <div @click="onClose">
        <AtroIcon class="h-7 w-20 text-white" name="atro_logo_horizontal" />
      </div>
      <span v-if="title" class="ml-4 text-white/75">{{ title }}</span>
    </AtroContent>
    <div @click="onClose">
      <AtroCloseButton variant="dark" />
    </div>
  </AtroContent>
</template>

<script setup lang="ts">
interface Props {
  title?: string
}

const { title } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const onClose = () => {
  emit('close')
}
</script>
