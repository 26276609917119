<template>
  <AtroAsync
    empty-heading="No assessments assigned yet"
    empty-icon-name="empty_tab"
    :config="fetchState"
  >
    <TeamMemberAssessmentsStatusTable :assessments="assessments" />
  </AtroAsync>
</template>

<script setup lang="ts">
import useTeamMemberAssessments from '@/composables/data/useTeamMemberAssessments'
import TeamMemberAssessmentsStatusTable from '@/components/team/TeamMemberAssessmentsStatusTable.vue'

export interface Props {
  userId: string
}

const { userId } = defineProps<Props>()

const { fetchState, data: assessments } = $(useTeamMemberAssessments(userId))
</script>
