export default {
  securityFixOrg: {
    key: (params: { id: string }) => ['securityFixOrg', params.id],
    path: (params: { id: string }) => `org-security-fixes/${params.id}`,
  },

  securityFixesOrg: {
    key: (params: { type: 'resolved' | 'unresolved' }) => ['securityFixesOrg', params.type],
    path: (params: { type: 'resolved' | 'unresolved' }) => `org-security-fixes?type=${params.type}`,
  },

  securityFixUser: {
    key: (params: { id: string }) => ['securityFixUser', params.id],
    path: (params: { id: string }) => `user-security-fixes/${params.id}`,
  },

  securityFixesUser: {
    key: (params: { type: 'inbox' | 'addressed' }) => ['securityFixesUser', params.type],
    path: (params: { type: 'inbox' | 'addressed' }) => `user-security-fixes?type=${params.type}`,
  },
}
