<template>
  <AtroAsync :config="fetchState">
    <SideBarScore
      v-if="isStarted"
      heading="Assessment score"
      help-text="This score is calculated as team members complete this assessment and correct related security fixes"
      sub-heading="Evaluation security level"
      :score="assessment.score"
    />
  </AtroAsync>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import useAssessment from '@/composables/data/useAssessment'
import SideBarScore from '@/components/SideBarScore.vue'

const route = useRoute()

const {
  fetchState,
  data: assessment,
  meta: { isStarted },
} = useAssessment(route.name, route.params.id)
</script>
