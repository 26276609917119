<template>
  <SideBarScore
    heading="My Score"
    sub-heading="Individual security level"
    help-text="Risk score is calculated live as you complete assessments and correct security fixes"
    :score="user.score"
  >
    <template #activity>
      <DashboardActivity />
    </template>
  </SideBarScore>
</template>

<script setup lang="ts">
import DashboardActivity from '@/components/dashboard/DashboardActivity.vue'
import SideBarScore from '@/components/SideBarScore.vue'
import useCurrentUser from '@/composables/data/useCurrentUser'

const { data: user } = useCurrentUser()
</script>
