import { rest } from 'msw'
import { BASE_MOCK_PATH } from '@/lib/constants'
import {
  notStartedRemoteWorkAssessment,
  notStartedWelcomeAssessment,
  inProgressWelcomeAssessment,
  caughtUpRemoteWorkAssessment,
} from '@/data/assessments'
import { ignoredUserSecurityFix, unresolvedAdminSecurityFix } from '@/data/securityFixes'
import { user1, user2 } from '@/data/user'

export const mockPaths = {
  assessmentsDiscover: 'assessments/discovery',
  assessmentDiscover: 'assessments/discovery/:id',
  assessmentAssign: 'assessments/discovery/:id/assign',

  assessmentsTeam: 'assessments/team',
  assessmentTeam: 'assessments/team/:id',
  assessmentTeamMembersStatus: 'assessments/team/:id/team-members-status',
  assessmentTeamSecurityFixes: 'assessments/team/:id/security-fixes',

  assessmentsUser: 'assessments/user',
  assessmentUser: 'assessments/user/:id',
  assessmentUserSecurityFixes: 'assessments/user/:id/security-fixes',
}

const getPath = (pathName: keyof typeof mockPaths) => {
  const pathConfig = mockPaths[pathName]
  return BASE_MOCK_PATH.concat(pathConfig)
}

export const handlers = [
  // GET: SHOW Discover Assessment
  rest.get(getPath('assessmentDiscover'), (req, res, ctx) => {
    const response = [notStartedRemoteWorkAssessment, notStartedWelcomeAssessment].find(
      assessment => assessment.id === req.params.id
    )
    return res(ctx.delay(), ctx.json(response))
  }),

  // GET: SHOW Discover Assessments
  rest.get(getPath('assessmentsDiscover'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json([notStartedWelcomeAssessment, notStartedRemoteWorkAssessment]))
  }),

  // GET: SHOW Org Assessment
  rest.get(getPath('assessmentTeam'), (req, res, ctx) => {
    const response = [caughtUpRemoteWorkAssessment, inProgressWelcomeAssessment].find(
      assessment => assessment.id === req.params.id
    )
    return res(ctx.delay(), ctx.json(response))
  }),

  // GET: SHOW Assessment Team Members Status
  rest.get(getPath('assessmentTeamMembersStatus'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json([user1, user2]))
  }),

  // GET: SHOW Assessment Team Security Fix
  rest.get(getPath('assessmentTeamSecurityFixes'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json([unresolvedAdminSecurityFix]))
  }),

  // GET: SHOW Org Assessments
  rest.get(getPath('assessmentsTeam'), (req, res, ctx) => {
    const type = req.url.searchParams.get('type')
    let response
    switch (type) {
      case 'caught-up':
        response = [caughtUpRemoteWorkAssessment]
        break
      case 'in-progress':
        response = [inProgressWelcomeAssessment]
        break
      default:
        response = []
    }
    return res(ctx.delay(), ctx.json(response))
  }),

  // GET: SHOW User Assessment
  rest.get(getPath('assessmentUser'), (req, res, ctx) => {
    const response = [caughtUpRemoteWorkAssessment, inProgressWelcomeAssessment].find(
      assessment => assessment.id === req.params.id
    )
    return res(ctx.delay(), ctx.json(response))
  }),

  // GET: SHOW User Assessments
  rest.get(getPath('assessmentsUser'), (req, res, ctx) => {
    const type = req.url.searchParams.get('type')
    let response
    switch (type) {
      case 'caught-up':
        response = [caughtUpRemoteWorkAssessment]
        break
      case 'in-progress':
        response = [inProgressWelcomeAssessment]
        break
      default:
        response = []
    }
    return res(ctx.delay(), ctx.json(response))
  }),

  // GET: SHOW Assessment User Security Fix
  rest.get(getPath('assessmentUserSecurityFixes'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json([ignoredUserSecurityFix]))
  }),

  // PATCH: Assign Assessment
  rest.patch(getPath('assessmentAssign'), (req, res, ctx) => {
    return res(ctx.delay(), ctx.json({ assessmentId: req.params.id }))
  }),
]
