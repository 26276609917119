export const org1: Org = {
  id: '1',
  name: 'Atro',
  createdAt: '2022-01-07T19:50:06',
  domain: 'atro.com',
  ownerId: '1',
  orgSize: '5...11',
  shareToken: '1234',
  usersCount: 2,
  notifications: {
    assessments: {
      email: {
        disabled: false,
        enabled: true,
      },
    },
    integrations: {
      email: {
        disabled: false,
        enabled: true,
      },
    },
  },
}
