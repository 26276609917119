import { createRouter, createWebHistory } from 'vue-router'
import assessmentRoutes from '@/router/assessmentRoutes'
import dashboardRoutes from '@/router/dashboardRoutes'
import topicRoutes from '@/router/topicRoutes'

import AuthCallback from '@/pages/sessions/AuthCallback.vue'
import Logout from '@/pages/sessions/Logout.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'
import RedirectToSignupPage from '@/pages/sessions/RedirectToSignup.vue'

import SignupPage from '@/pages/SignupPage.vue'

export const routes = [
  ...assessmentRoutes,
  ...dashboardRoutes,
  ...topicRoutes,
  {
    path: '/',
    name: 'initial',
    component: NotFoundPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/auth/callback',
    name: 'authCallback',
    component: AuthCallback,
    meta: { requiresAuth: { user: false } },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: { requiresAuth: { user: false } },
  },

  // Sign up
  {
    path: '/signup',
    name: 'signup',
    component: SignupPage,
    meta: { layout: 'Signup', requiresAuth: { user: false, redirectToIfUser: '/' } },
  },

  // Unauthed
  {
    path: '/accept-invite',
    name: 'validateInviteToken',
    component: RedirectToSignupPage,
    meta: { layout: 'StaticBlured' },
  },
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: NotFoundPage,
    meta: { layout: 'StaticWhite' },
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
