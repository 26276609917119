import useData from '@/composables/useData'
import useIntegrationMeta from '@/composables/meta/useIntegrationMeta'
import { Integration } from '@/types/integration'

export default function useIntegrations(view: 'discovery' | 'org', id: string) {
  const fetchState = useData<Integration>(
    view === 'org' ? 'integrationOrg' : 'integrationDiscovery',
    { id },
    {
      refetchInterval: (data: Integration) => {
        // poll for updated data when the integration is pending or one or more monitoring checks is pending
        if (
          data?.status === 'pending' ||
          (data?.status === 'active' &&
            data?.monitors?.some(monitor => monitor.status === 'pending'))
        ) {
          return 2000
        }
        return undefined
      },
    }
  )
  const meta = useIntegrationMeta(fetchState.data)

  return {
    fetchState,
    meta,
    data: fetchState.data,
  }
}
