<template>
  <AtroContent col items="center">
    <AtroHeading centered class="mb-6" size="larger">
      <AtroMarkdown :markdown="config.text" />
    </AtroHeading>

    <AtroTagCloud
      size="large"
      :tags="options"
      :selected-tag-ids="selectedTagIds"
      @select-tag="selectTag"
    />
  </AtroContent>
</template>

<script lang="ts">
interface StepTagCloudOptions {
  id: string
  createdAt: string
  updatedAt: string
  exclusive: boolean
  label: string
  position: number
  score: Score
  icon?: IconName
}
</script>

<script setup lang="ts">
import { IconName } from '@/assets/icons/atro-icon.model'
import { BaseStep, BaseStepConfig } from '@/types/topicStep'

export interface StepTagCloud extends BaseStep {
  config: BaseStepConfig
  kind: 'tag'
  answers?: Answer[]
  options?: StepTagCloudOptions[]
}

const { config, answers = [], options = [] } = defineProps<StepTagCloud>()

const emit = defineEmits<{
  (e: 'addAnswer', answers: Answer[]): void
}>()

const answersIds = answers.map(a => a.optionId)

let selectedTagIds = $ref(answersIds)

const selectTag = (ids: string[]) => {
  const answers = ids.map(id => ({ optionId: id }))

  selectedTagIds = ids
  emit('addAnswer', answers)
}
</script>
