<template>
  <div :class="['relative flex flex-shrink-0', size]">
    <svg v-if="showGauge" class="-rotate-90 scale-y-[-1]" viewBox="0 0 100 100">
      <defs>
        <linearGradient id="TOP_LEFT">
          <stop offset="0%" stop-color="#A445E1" />
          <stop offset="100%" stop-color="#7356EE" />
        </linearGradient>
        <linearGradient id="TOP_RIGHT">
          <stop offset="0%" stop-color="#507EEB" />

          <stop offset="100%" stop-color="#7356EE" />
        </linearGradient>
        <linearGradient id="BOTTOM_RIGHT">
          <stop offset="0%" stop-color="#51AFF0" />
          <stop offset="100%" stop-color="#507EEB" />
        </linearGradient>
        <linearGradient id="BOTTOM_LEFT">
          <stop offset="0%" stop-color="#D951A3" />
          <stop offset="100%" stop-color="#A445E1" />
        </linearGradient>
        <pattern id="Pattern" x="0" y="0" width="100" height="100" patternUnits="userSpaceOnUse">
          <g>
            <!-- TOP LEFT -->
            <rect x="50" y="50" width="50" height="100" fill="url(#TOP_LEFT)" />

            <!-- TOP RIGHT -->
            <rect x="50" y="-50" width="50" height="100" fill="url(#TOP_RIGHT)" />

            <!-- BOTTOM RIGHT -->
            <rect x="0" y="-50" width="50" height="100" fill="url(#BOTTOM_RIGHT)" />

            <!-- BOTTOM LEFT -->
            <rect x="0" y="50" width="50" height="100" fill="url(#BOTTOM_LEFT)" />
          </g>
        </pattern>

        <linearGradient id="START_MARKER_GRADIENT">
          <stop offset="100%" stop-color="#D851A4" />
          <stop offset="0%" stop-color="#BC5FAD" />
        </linearGradient>
        <marker id="round" viewBox="-1 -1 2 2" markerWidth="1" orient="auto">
          <circle r="1" fill="url(#START_MARKER_GRADIENT)" />
        </marker>
      </defs>

      <circle class="circle" cx="50" cy="50" :r="RADIUS" fill="transparent" stroke="#F2F5FF" />

      <path
        v-if="countDefined"
        ref="gaugeAnimationTarget"
        class="circle"
        :d="`
        M 50, 50
        m -${RADIUS}, 0
        a ${RADIUS},${RADIUS} 0 1,0 ${RADIUS * 2},0
        a ${RADIUS},${RADIUS} 0 1,0 -${RADIUS * 2},0
        `"
        fill="transparent"
        stroke="url(#Pattern)"
        marker-end="url(#round)"
        stroke-linecap="round"
        :stroke-dasharray="strokeLengthMax"
      />
    </svg>

    <AtroContent
      class="text-wrap absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      items="center"
      col
    >
      <div v-if="!countDefined" class="empty-pill" />
      <AtroCounter
        v-else
        :key="count"
        class="count"
        :delay="ANIMATION_DELAY"
        :duration="countAnimationDuration"
        :from="oldCount"
        :to="(count as number)"
      />
    </AtroContent>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUpdated, ref, watch } from 'vue'
import { useMotion } from '@vueuse/motion'
import useIsMobile from '@/composables/useIsMobile'

export interface Props {
  count?: string | number
  fillPercent?: number
  size?: 'normal' | 'small'
}

const ANIMATION_DELAY = 300
const ANIMATION_DURATION = 750
const RADIUS = 44

const props = defineProps<Props>()
const { count, fillPercent, size = 'normal' } = $(props)

const transition = {
  delay: ANIMATION_DELAY,
  duration: ANIMATION_DURATION,
  ease: 'easeOut',
}

const isMobile = $(useIsMobile())

const showGauge = $computed(() => !(isMobile && size === 'small'))
const countDefined = $computed(() => typeof count === 'number')
const adjustedFillPercent = $computed(() => (fillPercent === 0 ? 1 : fillPercent))
const percent = $computed(() => (adjustedFillPercent === undefined ? 0 : adjustedFillPercent / 100))

const strokeLengthMax = Math.ceil(RADIUS * Math.PI * 2)
const offset = $computed(() => (1 - percent) * strokeLengthMax)

const gaugeAnimationTarget = ref<HTMLElement>()
const { apply } = useMotion(gaugeAnimationTarget, {
  initial: {
    strokeDashoffset: strokeLengthMax,
  },
  enter: {
    transition,
    strokeDashoffset: offset,
  },
  animate: {
    transition,
    strokeDashoffset: offset,
  },
})

watch(props, () => {
  apply({
    transition,
    strokeDashoffset: offset,
  })
})

let countAnimationDuration = $ref(0)
let oldCount = $ref(0)

onMounted(() => {
  countAnimationDuration = ANIMATION_DURATION
  oldCount = count as number
})

onUpdated(() => {
  oldCount = count as number
})
</script>

<style lang="postcss" scoped>
.count {
  @apply font-semibold text-atro-slate-purple;
}
.circle {
  stroke-width: 10px;
}

.empty-pill {
  @apply rounded-xl bg-atro-gray-3;
}

/* sizes */
.normal {
  @apply h-18 w-18;
}
.normal .empty-pill {
  @apply h-2 w-6;
}
.normal .count {
  @apply text-base;
}

.small {
  @apply h-8 w-8  sm:h-12 sm:w-12;
}
.small .empty-pill {
  @apply h-1 w-3 sm:w-4;
}
.small .count {
  @apply text-xs;
}
</style>
