<template>
  <AtroPageHeading heading="My Profile" divider />

  <AtroContent>
    <AtroUserDisplay size="large" :user="user" show-role-badge show-email />
  </AtroContent>

  <AtroContent class="mt-10 w-full">
    <FormKit v-model="editabledUser" type="form" @submit="onSubmit">
      <div class="w-full grid sm:grid-cols-2 gap-6">
        <FormKit
          name="firstName"
          type="text"
          label="First name"
          validation="required|length:2,50"
        />
        <FormKit name="lastName" type="text" label="Last name" validation="required|length:2,50" />

        <FormKit
          v-if="canChangeEmail"
          name="email"
          type="text"
          label="Email"
          validation="required|email"
          disabled
        >
          <template #help>
            <AtroButton
              class="mt-2"
              icon-right="chevron_right"
              variant="transparent"
              @click.prevent="isChangeEmailModalOpen = true"
              >Change Email</AtroButton
            >
          </template>
        </FormKit>

        <FormKit
          v-if="canChangePassword"
          value="*********"
          type="text"
          label="Password"
          validation="required"
          disabled
        >
          <template #help>
            <AtroButton
              class="mt-2"
              icon-right="chevron_right"
              variant="transparent"
              @click.prevent="isChangePasswordModalOpen = true"
              >Change Password</AtroButton
            >
          </template>
        </FormKit>
      </div>

      <AtroContent v-if="editabledUser.notifications" class="w-full !mt-10" col>
        <AtroSectionHeading heading="Email preferences" divider />

        <NotificationToggleList
          scope="user"
          :notifications="editabledUser.notifications"
          @change="onNotificationSettingChange"
        />
      </AtroContent>

      <template #submit="{ state: { valid } }">
        <AtroButton class="w-full sm:w-auto" :is-pending="isPending" :disabled="!valid"
          >Save Changes</AtroButton
        >
      </template>
    </FormKit>
  </AtroContent>

  <ChangeEmailModal
    :user="user"
    :is-open="isChangeEmailModalOpen"
    @close="isChangeEmailModalOpen = false"
  />
  <ChangePasswordModal
    :user="user"
    :is-open="isChangePasswordModalOpen"
    @close="isChangePasswordModalOpen = false"
  />
</template>

<script setup lang="ts">
import useCurrentUser from '@/composables/data/useCurrentUser'
import useFormSubmit from '@/composables/useFormSubmit'
import { useUserUpdateMutation } from '@/composables/mutations/useUserMutation'
import ChangeEmailModal from '@/components/user/ChangeEmailModal.vue'
import ChangePasswordModal from '@/components/user/ChangePasswordModal.vue'
import NotificationToggleList from '@/components/NotificationToggleList.vue'

const { data: user } = $(useCurrentUser())
const { isLoading: isPending, updateUser } = useUserUpdateMutation()

let editabledUser = $ref({ ...user })
let isChangeEmailModalOpen = $ref(false)
let isChangePasswordModalOpen = $ref(false)

const canChangeEmail = $computed(() => true)
const canChangePassword = $computed(() => true)

const { onSubmit } = useFormSubmit<User>(updateUser)

const onNotificationSettingChange = (updatedNotificationSetting: Notifications) => {
  editabledUser.notifications = { ...editabledUser.notifications, ...updatedNotificationSetting }
}
</script>
