<template>
  <AtroGridTableRow>
    <AtroContent items="center" justify="start" wrap="nowrap">
      <div class="h-12 w-12 shrink-0 rounded-full bg-atro-blue-10" />
      <AtroSpan class="ml-3 truncate text-atro-dark-purple">{{ invite.email }}</AtroSpan>
    </AtroContent>

    <AtroContent items="center" justify="center">
      <FormKit
        type="select"
        variant="transparent"
        :value="invite.role"
        :options="ROLE_OPTIONS"
        @change="onRoleChange"
      />
    </AtroContent>

    <AtroContent :class="actionsColumnConfig?.rowClass" items="center" justify="end">
      <AtroButton class="w-full" variant="light" @click="emit('uninvite', invite)"
        >Cancel Invite</AtroButton
      >
    </AtroContent>
  </AtroGridTableRow>
</template>

<script setup lang="ts">
import { ROLE_OPTIONS } from '@/lib/constants'
import { useTeamInviteUpdateMutation } from '@/composables/mutations/useTeamInviteMutation'
import { ColumnConfig } from '@/components/globals/atro/GridTable.vue'
import { Props as GridTableRowProps } from '@/components/globals/atro/GridTableRow.vue'

export interface Props extends GridTableRowProps {
  invite: Invite
  columnsConfig?: ColumnConfig[]
}

const { columnsConfig, invite } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'uninvite', invite: Invite): void
}>()

const { updateTeamInvite } = useTeamInviteUpdateMutation(invite.id)

const actionsColumnConfig = $computed(() =>
  columnsConfig?.find(config => config.name === 'actions')
)

const onRoleChange = e => {
  updateTeamInvite({ ...invite, role: e.target.value })
}
</script>
