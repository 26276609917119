<template>
  <div class="w-full">
    <AtroSectionHeading
      heading="Monitoring"
      sub-heading="Automated checks for your environment"
      :route-to="{ name: 'monitoringCenter' }"
    >
      <AtroButtonLink :to="{ name: 'monitoringCenter' }">Monitoring Center</AtroButtonLink>
    </AtroSectionHeading>

    <AtroAsync :config="fetchState">
      <template #default>
        <RouterLink :to="{ name: 'monitoringCenter' }">
          <MonitoredIntegrationsCountsPill
            :total-active-checks-count="totalActiveChecksCount"
            :total-ok-count="totalOkChecksCount"
            :total-critical-alerts-count="totalCriticalAlertsCount"
            :total-info-alerts-count="totalInfoAlertsCount"
            :total-warning-alerts-count="totalWarningAlertsCount"
          />
        </RouterLink>
      </template>

      <template #empty>
        <AtroCard class="w-full !py-3">
          <AtroContent
            class="w-full"
            justify="between"
            wrap="nowrap"
            :items="isMobile ? 'end' : 'center'"
            :col="isMobile"
          >
            <AtroContent class="mb-4 sm:mb-0" items="center" wrap="nowrap">
              <AtroIconSquare
                class="mr-4 shrink-0 !bg-atro-yellow !p-1.5 !text-white sm:mr-6"
                icon-name="integration_detailed"
                size="small"
                circle
              />
              <AtroParagraph size="large" semibold
                >Monitor your services automatically</AtroParagraph
              >
            </AtroContent>

            <AtroButtonLink :to="{ name: 'integrations' }">View Services</AtroButtonLink>
          </AtroContent>
        </AtroCard>
      </template>
    </AtroAsync>
  </div>
</template>

<script setup lang="ts">
import MonitoredIntegrationsCountsPill from '@/components/integrations/MonitoredIntegrationsCountsPill.vue'
import useIntegrations from '@/composables/data/useIntegrations'
import useIsMobile from '@/composables/useIsMobile'

const {
  fetchState,
  meta: {
    totalActiveChecksCount,
    totalCriticalAlertsCount,
    totalInfoAlertsCount,
    totalOkChecksCount,
    totalWarningAlertsCount,
  },
} = $(useIntegrations('monitoring'))

const isMobile = $(useIsMobile())
</script>
