<template>
  <span>{{ Math.floor(output as number) }}</span>
</template>

<script setup lang="ts">
import { TransitionPresets, useTransition } from '@vueuse/core'

export interface Props {
  to: number
  delay?: number
  duration?: number
  from?: number
}

const { to, delay = 0, duration = 2000, from = 0 } = defineProps<Props>()

let source = $ref(from)
const output =
  duration === 0
    ? to
    : useTransition($$(source), {
        delay,
        duration,
        transition: TransitionPresets.easeOutQuart,
      })

source = to
</script>
