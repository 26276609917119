<template>
  <AtroAsync
    empty-heading="No security fixes"
    empty-icon-name="empty_tab_happy"
    :config="fetchState"
  >
    <TeamMemberSecurityFixesStatusTable expandable :security-fixes="securityFixes" />
  </AtroAsync>
</template>

<script setup lang="ts">
import useTeamMemberSecurityFixes from '@/composables/data/useTeamMemberSecurityFixes'
import TeamMemberSecurityFixesStatusTable from '@/components/team/TeamMemberSecurityFixesStatusTable.vue'

export interface Props {
  userId: string
}

const { userId } = defineProps<Props>()

const { fetchState, data: securityFixes } = $(useTeamMemberSecurityFixes(userId))
</script>
