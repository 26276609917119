<template>
  <AtroAsync
    empty-icon-name="empty_tab_happy"
    empty-heading="No assessments completed yet"
    :config="fetchState"
  >
    <AtroContent v-auto-animate class="space-y-3" col>
      <AssessmentUserCard
        v-for="assessment in assessments"
        :key="assessment.id"
        :assessment="assessment"
      />
    </AtroContent>
  </AtroAsync>
</template>

<script setup lang="ts">
import useAssessments from '@/composables/data/useAssessments'
import AssessmentUserCard from '@/components/assessment/user/AssessmentUserCard.vue'

const { fetchState, data: assessments } = useAssessments('user', 'caught-up')
</script>
