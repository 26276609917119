<template>
  <RouterLink class="flex" :to="integrationDetailsLinkTo">
    <AtroCard class="w-full" hoverable>
      <AtroContent class-="w-full" wrap="nowrap">
        <AtroIcon class="h-16 w-16 shrink-0" :name="integration.icon" />
        <AtroContent class="ml-5 w-full" col>
          <AtroContent class="w-full" items="center" justify="between" wrap="nowrap">
            <AtroHeading
              class="text-atro-dark-purple group-hover:text-atro-purple"
              size="small"
              semibold
              >{{ integration.title }}</AtroHeading
            >
            <IntegrationStatusBadge :integration="integration" />
          </AtroContent>

          <AtroParagraph class="mt-2 text-atro-gray-1 line-clamp-3" size="small">{{
            integration.description
          }}</AtroParagraph>
        </AtroContent>
      </AtroContent>

      <AtroContent
        class="mt-auto w-full pt-10"
        items="center"
        :justify="active && lastUpdatedAt ? 'between' : 'end'"
      >
        <AtroSpan v-if="active && lastUpdatedAt" class="text-atro-gray-1" size="xsmall"
          >Last updated: {{ lastUpdatedAt }} ago</AtroSpan
        >
        <AtroButtonLink :to="integrationDetailsLinkTo">{{
          !active ? 'Activate' : 'Manage'
        }}</AtroButtonLink>
      </AtroContent>
    </AtroCard>
  </RouterLink>
</template>
<script setup lang="ts">
import { RouteLocationRaw } from 'vue-router'
import useIntegrationMeta from '@/composables/meta/useIntegrationMeta'
import IntegrationStatusBadge from '@/components/integration/IntegrationStatusBadge.vue'
import { Integration } from '@/types/integration'

export interface Props {
  integration: Integration
  active?: boolean
}

const { active, integration } = defineProps<Props>()

const { lastUpdatedAt } = $(useIntegrationMeta($$(integration)))

const integrationDetailsLinkTo = $computed<RouteLocationRaw>(() => {
  if (active) {
    return { name: 'integration', params: { id: integration.id } }
  } else {
    return { name: 'integrationDiscover', params: { id: integration.id } }
  }
})
</script>
