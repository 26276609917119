import { unref } from 'vue'
import cloneDeep from 'clone-deep'
import { useQueryClient } from '@tanstack/vue-query'
import { useBaseMutation } from '@/composables/mutations/useBaseMutation'
import { Integration } from '@/types/integration'

export function useIntegrationMonitoringCheckDisableMutation(
  monitoringCheckId: string,
  integration: Integration
) {
  const queryClient = useQueryClient()
  const url = $computed(() => `integrations/monitors/${monitoringCheckId}/disable`)

  const mutation = useBaseMutation<{ disable: boolean }>(
    {
      method: 'post',
      url: $$(url),
      invalidateKeys: [['integrations']],
    },
    {
      // optimistically set current step id
      onMutate: data => {
        const key = ['integrationOrg', integration.id]
        const _integration = cloneDeep(queryClient.getQueryData<Integration>(key))
        _integration.monitors.forEach((check, i) => {
          if (check.id === monitoringCheckId) {
            _integration.monitors.splice(i, 1, {
              ...check,
              status: data.disable ? 'disabled' : 'pending',
            })
            queryClient.setQueryData(key, _integration)
          }
        })
      },
    }
  )

  const disableMonitoringCheck = async (disable: boolean) => {
    return mutation.mutateAsync({ disable })
  }

  return { disableMonitoringCheck, ...mutation }
}

export function useIntegrationMonitoringCheckDismissMutation(
  monitoringCheckId: string,
  integration: Integration
) {
  const queryClient = useQueryClient()
  const url = $computed(() => `integrations/monitors/${monitoringCheckId}/dismiss`)

  const mutation = useBaseMutation<{ dismiss: boolean }>(
    {
      method: 'post',
      url: $$(url),
      invalidateKeys: [['integrations']],
    },
    {
      // optimistically set current step id
      onMutate: data => {
        const key = ['integrationOrg', integration.id]
        const _integration = cloneDeep(queryClient.getQueryData<Integration>(key))
        _integration.monitors.forEach((check, i) => {
          if (check.id === monitoringCheckId) {
            _integration.monitors.splice(i, 1, { ...unref(check), dismissed: data.dismiss })
            queryClient.setQueryData(key, _integration)
          }
        })
      },
    }
  )

  const dismissMonitoringCheck = async (dismiss: boolean) => {
    return mutation.mutateAsync({ dismiss })
  }

  return { dismissMonitoringCheck, ...mutation }
}
