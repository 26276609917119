<template>
  <AtroAsync :config="fetchState">
    <SideBarScore
      heading="Member Score"
      sub-heading="Risk in one number"
      help-text="Score is calculated live as team members complete assessments and correct security fixes"
      :score="user.score"
    />
  </AtroAsync>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import useTeamMember from '@/composables/data/useTeamMember'
import SideBarScore from '@/components/SideBarScore.vue'

const router = useRoute()
const { fetchState, data: user } = useTeamMember(router.params.id as string)
</script>
