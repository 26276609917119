<template>
  <AtroContent class="h-full w-full flex-1" items="center" justify="center" col>
    <AtroIcon v-if="iconName" :class="['icon', 'text-atro-magenta', size]" :name="iconName" />
    <AtroHeading class="text-center" :size="size" bold>{{ errorText }}</AtroHeading>
  </AtroContent>
</template>

<script setup lang="ts">
import { AxiosError } from 'axios'
import { IconName } from '@/assets/icons/atro-icon.model'

export interface Props {
  error?: AxiosError
  iconName?: IconName
  size?: 'small' | 'normal' | 'large'
  text?: string
}

const { error, text, iconName = 'exclamation_circle', size = 'small' } = defineProps<Props>()

const errorText = $computed(() => {
  if (error?.response?.status) {
    switch (error.response.status) {
      case 401:
        return "You don't have permission to access the requested resource"
      case 404:
        return 'The requested resource could not be found'
    }
  } else if (text) {
    return text
  }
  return 'Uh oh, there was an error'
})
</script>

<style lang="postcss" scoped>
.icon.small {
  @apply mb-2 w-8;
}

.icon.normal {
  @apply mb-4 w-12;
}

.icon.large {
  @apply mb-4 w-16;
}
</style>
