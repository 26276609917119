<template>
  <AtroContent class="space-y-6" col>
    <NotificationToggle
      v-for="(NotificationTypes, notificationKey) in notifications"
      :key="notificationKey"
      type="email"
      :notification-key="notificationKey"
      :item="NotificationTypes.email"
      :scope="scope"
      @change="onUpdatedNotificationSetting"
    />
  </AtroContent>
</template>

<script setup lang="ts">
import NotificationToggle, { NotificationScope } from '@/components/NotificationToggle.vue'

export interface Props {
  notifications: Notifications
  scope: NotificationScope
}

const { notifications } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'change', updatedNotificationSetting: Notifications): void
}>()

const onUpdatedNotificationSetting = (updatedNotificationSetting: Notifications) => {
  emit('change', updatedNotificationSetting)
}
</script>
