<template>
  <div class="w-full">
    <AtroSectionHeading
      heading="Assessments in progress"
      sub-heading="Assigned to your team"
      :route-to="{ name: 'teamAssessments', query: { tab: 'in-progress' } }"
    />

    <div class="h-[var(--assessment-card-height)] w-full">
      <AtroAsync :config="fetchState">
        <template #default>
          <DashboardAssessmentsList :assessments="assessments" />
        </template>

        <template #empty>
          <DashboardAssessmentListDiscoverMoreCard />
        </template>
      </AtroAsync>
    </div>
  </div>
</template>

<script setup lang="ts">
import useAssessments from '@/composables/data/useAssessments'
import DashboardAssessmentsList from '@/components/dashboard/DashboardAssessmentsList.vue'
import DashboardAssessmentListDiscoverMoreCard from '@/components/dashboard/DashboardAssessmentListDiscoverMoreCard.vue'

const { fetchState, data: assessments } = useAssessments('team', 'in-progress')
</script>
