import cloneDeep from 'clone-deep'
import { useQueryClient } from '@tanstack/vue-query'
import { useRoute } from 'vue-router'
import { useBaseMutation } from './useBaseMutation'
import { Topic } from '@/types/topic'

type Params = {
  stepAnswer: { answers: Answer[] }
}

export function useTopicStepAnswerMutation() {
  const route = useRoute()
  const queryClient = useQueryClient()
  const url = $computed(() => `steps/${route.query.step}/answers`)
  const mutation = useBaseMutation<Params>(
    {
      url: $$(url),
      method: 'put',
    },
    {
      // optimistically set answers
      onMutate: data => {
        const key = ['topic', route.params.id]
        const topic = cloneDeep(queryClient.getQueryData<Topic>(key))
        const step = topic.steps.find(t => t.id === route.query.step)
        step.answers = data.stepAnswer.answers
        queryClient.setQueryData(key, { ...topic })
      },
    }
  )

  const addAnswers = async (answers: Answer[]) => {
    await mutation.mutateAsync({
      stepAnswer: { answers },
    })
  }

  return { addAnswers, ...mutation }
}
