<template>
  <div ref="animationContainer"></div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted } from 'vue'
import { ReactiveVariable } from 'vue/macros'
import lottie, { AnimationConfigWithData, AnimationItem } from 'lottie-web'

export interface Props {
  animationData: Record<string, unknown>
  loop?: boolean
  autoPlay?: boolean
  renderer?: 'svg'
}

const { animationData, loop = false, autoPlay = true, renderer = 'svg' } = defineProps<Props>()

const emit = defineEmits<{
  (e: 'complete', anim: ReactiveVariable<AnimationItem>): void
  (e: 'enterFrame', anim: ReactiveVariable<AnimationItem>): void
  (e: 'loopComplete', anim: ReactiveVariable<AnimationItem>): void
}>()

const animationContainer = $ref()
let animationRef = $ref<AnimationItem>()

const init = () => {
  const settings: AnimationConfigWithData = {
    animationData,
    loop,
    renderer,
    autoplay: autoPlay,
    container: animationContainer as Element,
  }

  animationRef = lottie.loadAnimation(settings)

  animationRef.addEventListener('loopComplete', () => {
    emit('loopComplete', animationRef)
  })
  animationRef.addEventListener('complete', () => {
    emit('complete', animationRef)
  })
  animationRef.addEventListener('enterFrame', () => {
    emit('enterFrame', animationRef)
  })
}

onMounted(init)
onBeforeUnmount(() => {
  animationRef.destroy()
})
</script>
