<template>
  <AtroModal v-slot="{ close }">
    <AtroContent class="text-center" items="center" col>
      <AtroIconSquare class="text-atro-purple" icon-name="users" size="large" circle />

      <AtroHeading class="mt-4 text-atro-slate-purple" semibold
        >Wait! We need to get some people in</AtroHeading
      >
      <AtroParagraph class="mt-2 text-atro-gray-1">
        Share this URL to your team to get them into Atro. Anyone that joins with this link will be
        automatically added to this assessment.
      </AtroParagraph>

      <AtroButton class="mt-6" variant="light" :icon-right="copyButtonIcon" @click="copy()">{{
        copyButtonText
      }}</AtroButton>

      <AtroDivider size="larger" />

      <AtroButton @click="onContinue(close)">Continue</AtroButton>
    </AtroContent>
  </AtroModal>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useClipboard } from '@vueuse/core'
import useCurrentUser from '@/composables/data/useCurrentUser'
import useOrgInviteUrl from '@/composables/useOrgInviteUrl'
import { useAssessmentAssignMutation } from '@/composables/mutations/useAssessmentMutation'
import { Props as ModalProps } from '@/components/globals/atro/Modal.vue'
import { IconName } from '@/assets/icons/atro-icon.model'
import { Assessment } from '@/types/assessment'

export interface Props extends ModalProps {
  assessment: Assessment
}

const { assessment } = defineProps<Props>()

const router = useRouter()
const { data: user } = $(useCurrentUser())
const shareUrl = useOrgInviteUrl()
const { assignAssessment } = useAssessmentAssignMutation(assessment)

let orgAssessmentId = $ref('')
const source = $ref(shareUrl)
const { copy, copied } = $(useClipboard({ source }))

const copyButtonText = $computed(() => (copied ? 'Link Copied!' : 'Copy Link Invite'))
const copyButtonIcon = $computed(() => (copied ? null : ('link_regular' as IconName)))

const onContinue = async close => {
  router.replace({ name: 'assessmentTeam', params: { id: orgAssessmentId }, force: true })
  close()
}

onMounted(async () => {
  const {
    data: { assessmentId },
  } = await assignAssessment({
    includeFuture: false,
    userIds: [user.id],
  })
  orgAssessmentId = assessmentId
})
</script>
