<template>
  <AtroCard class="w-full !pl-6 !p-4 bg-atro-gray-4">
    <AtroContent
      class="space-y-2 sm:space-y-0 sm:space-x-4"
      items="center"
      :justify="isMobile ? 'center' : 'between'"
      :col="isMobile"
    >
      <AtroContent items="center">
        <AtroHeading class="text-atro-dark-purple" size="huge" semibold>{{
          totalActiveChecksCount
        }}</AtroHeading>
        <AtroSpan class="ml-2 text-atro-dark-purple-85" size="large" semibold
          >Total checks active</AtroSpan
        >
      </AtroContent>

      <AtroContent class="space-x-2" items="center" wrap="nowrap">
        <AtroContent class="check-pill" items="center" wrap="nowrap">
          <AtroIcon class="check-pill-icon text-atro-highlight-green" name="circle_check" />
          <AtroHeading semibold>{{ totalOkCount }}</AtroHeading>
          <AtroSpan class="sm:block hidden ml-1 text-atro-gray-1" size="small" semibold
            >Secure</AtroSpan
          >
        </AtroContent>

        <AtroContent class="check-pill" items="center" wrap="nowrap">
          <AtroIcon class="check-pill-icon text-atro-magenta" name="exclamation_square" />
          <AtroHeading semibold>{{ totalCriticalAlertsCount }}</AtroHeading>
          <AtroSpan class="sm:block hidden ml-1 text-atro-gray-1" size="small" semibold
            >Critical</AtroSpan
          >
        </AtroContent>

        <AtroContent class="check-pill" items="center" wrap="nowrap">
          <AtroIcon class="check-pill-icon text-atro-yellow" name="exclamation_square" />
          <AtroHeading semibold>{{ totalWarningAlertsCount }}</AtroHeading>
          <AtroSpan class="sm:block hidden ml-1 text-atro-gray-1" size="small" semibold
            >Warning</AtroSpan
          >
        </AtroContent>

        <AtroContent class="check-pill" items="center" wrap="nowrap">
          <AtroIcon class="check-pill-icon text-atro-gray-1" name="exclamation_square" />
          <AtroHeading semibold>{{ totalInfoAlertsCount }}</AtroHeading>
          <AtroSpan class="sm:block hidden ml-1 text-atro-gray-1" size="small" semibold
            >Info</AtroSpan
          >
        </AtroContent>
      </AtroContent>
    </AtroContent>
  </AtroCard>
</template>

<script setup lang="ts">
import useIsMobile from '@/composables/useIsMobile'

export interface Props {
  totalActiveChecksCount: number
  totalCriticalAlertsCount: number
  totalInfoAlertsCount: number
  totalOkCount: number
  totalWarningAlertsCount: number
}

const {
  totalActiveChecksCount,
  totalCriticalAlertsCount,
  totalInfoAlertsCount,
  totalWarningAlertsCount,
} = defineProps<Props>()

const isMobile = $(useIsMobile())
</script>

<style lang="postcss" scoped>
.check-pill {
  @apply border-2 bg-white px-3 py-2 rounded-xl shrink-0;
}

.check-pill-icon {
  @apply w-6 h-6 mr-2;
}
</style>
