export default {
  invite: {
    key: (params: { inviteToken: string }) => ['invite', params.inviteToken],
    path: (params: { inviteToken: string }) => `invites/${params.inviteToken}`,
  },
  teamInvites: {
    key: ['team-invites'],
    path: 'team-invites',
  },
}
