import { computed } from 'vue'
import { AxiosError } from 'axios'
import {
  useQuery,
  UseQueryOptions,
  QueryObserverResult,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { buildFetcher } from '@/utils/fetcher'
import keyPathMap, { ResourcePath } from '@/utils/resourcePaths'

type UseDataOptions = Omit<UseQueryOptions<any, AxiosError, any>, 'queryFn' | 'queryKey'>

export type UseDataReturnProps = Partial<
  UseQueryReturnType<any, AxiosError<any, any>> & {
    isEmpty: boolean
  }
>

export default function useData<T>(
  resourceName: ResourcePath,
  params?: UseDataOptions | Record<string, unknown>,
  options?: UseDataOptions
) {
  const resourceConfig = keyPathMap[resourceName]

  const key =
    typeof resourceConfig.key === 'function'
      ? resourceConfig.key(params as any)
      : resourceConfig.key

  const path =
    typeof resourceConfig.path === 'function'
      ? resourceConfig.path(params as any)
      : resourceConfig.path

  const fetcher = buildFetcher<T>(path)
  const useQueryReturnParams = useQuery<T, AxiosError>({
    queryKey: key,
    queryFn: fetcher,
    // allows for only providing options as the second param of useData
    // defaults to a retry of one unless overrided in options
    // defaults to a cache state time of 60 seconds
    ...{ retry: 1, staleTime: 1000 * 60 },
    ...(options ? options : params ? params : {}),
  })

  const isEmpty = computed(() => {
    if (useQueryReturnParams.isFetched.value && !useQueryReturnParams.isError.value) {
      if (
        Array.isArray(useQueryReturnParams.data.value) &&
        useQueryReturnParams.data.value.length === 0
      )
        return true
      if (
        typeof useQueryReturnParams.data.value === 'object' &&
        Object.keys(useQueryReturnParams.data.value).length === 0
      ) {
        return true
      }
      return !useQueryReturnParams.data.value
    }
    return false
  })

  return { ...useQueryReturnParams, isEmpty }
}
