<template>
  <AtroContent col class="mb-4 w-full text-center" items="center">
    <AtroHeading class="text-atro-slate-purple" semibold>{{ heading }}</AtroHeading>
    <AtroParagraph v-if="subHeading" class="text-atro-gray-1">{{ subHeading }}</AtroParagraph>
  </AtroContent>

  <AtroGauge :count="score?.atroScore" :fill-percent="score?.percent" :sub-text="score?.riskText" />

  <AtroSpan v-if="helpText" class="mt-8 text-center text-atro-gray-1" size="xxsmall">{{
    helpText
  }}</AtroSpan>

  <AtroContent v-if="slots.activity" class="w-full">
    <AtroDivider size="larger" />
    <AtroFeature name="activity">
      <slot name="activity" />
    </AtroFeature>
  </AtroContent>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

export interface Props {
  heading: string
  score: Score
  helpText?: string
  subHeading?: string
}

const { helpText, heading, score, subHeading } = defineProps<Props>()

const slots = useSlots()
</script>
