<template>
  <AtroPageHeading
    heading="Assessments"
    sub-heading="Browse assessments and assign to team members"
  />

  <AtroTabGroup :tab-configs="tabConfigs" />

  <AtroOnboardingModal
    icon-name="nav_item_team_assessments"
    onboarding-key="seenTeamAssessments"
    :heading="ONBOARDING_COPY.teamAssessmentsModal.heading"
    :body="ONBOARDING_COPY.teamAssessmentsModal.body"
  />
</template>

<script setup lang="ts">
import { ONBOARDING_COPY } from '@/lib/constants'
import useAssessments from '@/composables/data/useAssessments'
import AssessmentsTeamCaughtUp from '@/components/assessments/team/AssessmentsTeamCaughtUp.vue'
import AssessmentsTeamDiscover from '@/components/assessments/team/AssessmentsTeamDiscover.vue'
import AssessmentsTeamInProgress from '@/components/assessments/team/AssessmentsTeamInProgress.vue'

const { data: assessmentsDiscover } = $(useAssessments('discover'))
const { data: assessmentsInProgress } = $(useAssessments('team', 'in-progress'))
const { data: assessmentsCaughtUp } = $(useAssessments('team', 'caught-up'))

const tabConfigs = $computed(() => [
  {
    title: 'Browse',
    count: assessmentsDiscover?.length,
    component: AssessmentsTeamDiscover,
  },
  {
    title: 'In Progress',
    count: assessmentsInProgress?.length,
    deepLink: true,
    component: AssessmentsTeamInProgress,
  },
  {
    title: 'Caught Up',
    count: assessmentsCaughtUp?.length,
    deepLink: true,
    component: AssessmentsTeamCaughtUp,
  },
])
</script>
